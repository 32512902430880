@media only screen and (max-width: 480px) {
    .dashboard_layout {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #EEEEF0;
    }

    .dashboard_layout_header {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #1C1CA8;
        color: white;
        padding: 4.722vw 4.444vw 4.722vw 4.444vw;
        height: 16.111vw;
    }

    .dashboard_layout_header_left {
        display: flex;
        align-items: center;
        z-index: 1;
        visibility: visible;
    }

    .dashboard_layout_header_menu_btn {
        background-color: transparent;
        border: 0;
        outline: none;
        height: 6.666vw;
    }

    .dashboard_layout_header_right {
        display: flex;
        align-items: center;
        z-index: 1;
        visibility: visible;
    }

    .dashboard_layout_header_menu_icon {
        width: 6.666vw;
        height: 6.666vw;
    }

    .dashboard_dashboard_layout .dashboard_layout_banner {
        height: 102.222vw;
    }

    .dashboard_training_layout .dashboard_layout_banner {
        height: 71.666vw;
    }

    .dashboard_layout_banner {
        overflow: hidden;
        height: 102.222vw;
        background-color: #1C1CA8;
        position: relative;
    }

    .dashboard_layout_banner_container {
        grid-row: 1;
        grid-column: 1 / span 4;
        padding: 7.5vw 2.222vw 0px 2.222vw;
    }

    .dashboard_layout_banner_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 9.166vw;
        line-height: 10vw;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 4.444vw;
        position: relative;
        z-index: 1;
    }

    .dashboard_layout_banner_description {
        font-weight: 500;
        font-size: 5.555vw;
        line-height: 8.333vw;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        max-width: 100%;
        position: relative;
        z-index: 1;
    }

    .dashboard_layout_banner_union_image_mobile {
        display: block;
        width: 100%;
        position: absolute;
        bottom: 0.833vw;
        right: 0px;
        z-index: 0;
    }

    .dashboard_layout_banner_union_image_desktop {
        display: none;
        height: 100%;
        position: absolute;
        bottom: 0px;
        right: 0px;
        z-index: 0;
    }

    .dashboard_training_layout .dashboard_layout_body {
        margin-top: -17.5vw;
    }

    .dashboard_training_layout .dashboard_layout_banner_union_image_mobile {
        bottom: -29.166vw;
    }

    .dashboard_layout_body {
        margin-top: -37.777vw;
        background-color: transparent;
        margin-bottom: 4.444vw;
    }


    .dashboard_layout_sidebar {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 1 / span 3;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: none;
        margin-bottom: 22.5vw;
    }

    .dashboard_layout_sidebar_content {
        width: 100%;
        border-radius: 16px;
        background-color: #055CF5;
        overflow: hidden;
    }

    .dashboard_layout_sidebar_close_btn {
        background-color: transparent;
        border: 0px;
        outline: none;
        cursor: pointer;
        position: absolute;
        right: 4.444vw;
        top: 4.444vw;
    }

    .dashboard_layout_sidebar_close_icon {
        width: 6.666vw;
        height: 6.666vw;
    }

    .dashboard_layout_sidebar_content_header {
        padding: 15vw 7.777vw 9.722vw 7.777vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .dashboard_layout_sidebar_content_header_avatar {
        margin-bottom: 5vw;
    }

    .dashboard_layout_sidebar_content_header_name {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 6.666vw;
        line-height: 9.444vw;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 0.277vw;
    }

    .dashboard_layout_sidebar_content_header_total_point {
        font-weight: 500;
        font-size: 4.444vw;
        line-height: 6.666vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 5.277vw;
    }

    .dashboard_layout_sidebar_progress_bar {
        width: 100%;
        display: flex;
        align-items: center;
        height: 2.222vw;
        gap: 1.3vw;
    }

    .dashboard_layout_sidebar_progress_item {
        height: 100%;
        background: #FFFFFF;
        opacity: 0.2;
        width: 100%;
        border-radius: 2px;
    }

    .dashboard_layout_sidebar_progress_item.active {
        opacity: 1;
    }

    .dashboard_layout_sidebar_content_body {
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
        overflow: hidden;
    }

    .dashboard_layout_sidebar_menu_lists {
        background-color: #FFFFFF;
        padding: 6.111vw 7.777vw 8.888vw 7.777vw;
        display: flex;
        flex-direction: column;
        gap: 2.222vw;
    }

    .dashboard_layout_sidebar_menu_list {
        color: #27272F;
        cursor: pointer;
        display: flex;
        font-size: 4.444vw;
        align-items: center;
        font-weight: 700;
        line-height: 5.555vw;
        padding-top: 5.555vw;
        border-radius: 4.444vw;
        padding-right: 5.555vw;
        letter-spacing: -0.01em;
        padding-bottom: 5.555vw;
        background-color: #FFFFFF;
    }

    .dashboard_layout_sidebar_menu_list.active {
        color: #FFFFFF;
        background-color: #055CF5;
    }

    .dashboard_layout_sidebar_menu_list_icon {
        flex: none;
        width: 17.777vw;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        padding-left: 5.555vw;
        padding-right: 5.555vw;
        justify-content: center;
    }

    .dashboard_layout_sidebar_menu_list_icon img {
        width: 6.666vw;
    }

    .dashboard_layout_sidebar_menu_list_text {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .dashboard_logout_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 1000;
        width: 100vw;
        height: 100vh;
    }

    .dashboard_logout_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .dashboard_logout_modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
    }

    .dashboard_logout_modal_close_btn {
        position: absolute;
        right: 0.763vw;
        top: 0px;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: 11.111vw;
        height: 11.111vw;
    }

    .dashboard_logout_modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .dashboard_logout_modal_content {
        width: 100%;
        padding: 8.888vw 6.666vw 11.111vw 6.666vw;
        background-color: white;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        display: flex;
        flex-direction: column;
    }

    .dashboard_logout_modal_icon {
        width: 18.055vw;
        height: 18.055vw;
        margin: auto;
        margin-bottom: 4.166vw;
    }

    .dashboard_logout_modal_title {
        font-weight: 500;
        font-size: 5.555vw;
        line-height: 8.333vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 11.111vw;
    }

    .dashboard_logout_modal_actions {
        display: flex;
        flex-direction: column;
        gap: 4.444vw;
    }

    .dashboard_logout_modal_back {
        width: 69.444vw !important;
        margin: auto;
    }

    .dashboard_logout_modal_next {
        width: 69.444vw !important;
        margin: auto;
    }

    .dashboard_layout_header_sidebar_wrapper {
        z-index: 1000;
        position: fixed;
        width: 100vw;
        top: 0px;
        right: 0px;
        height: 100vh;
        overflow: auto;
    }

    .dashboard_layout_header_sidebar_wrapper.show {
        display: block !important;
    }

    .dashboard_layout_header_sidebar_wrapper.hide {
        display: none !important;
    }

    .dashboard_layout_header_sidebar_wrapper .dashboard_layout_sidebar {
        width: 100%;
        display: block;
        margin-bottom: 0px;
        min-height: 100vh;
        background: white;
    }

    .dashboard_layout_header_sidebar_wrapper .dashboard_layout_sidebar .dashboard_layout_sidebar_content {
        border-radius: 0px;
        margin-bottom: 0px;
        background-color: #1C1CA8;
    }

    .dashboard_layout_header_sidebar_wrapper .dashboard_layout_sidebar .dashboard_layout_sidebar_content .dashboard_layout_sidebar_content_body {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    #root.position-fixed {
        position: fixed;
        width: 100vw;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .dashboard_layout {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #EEEEF0;
    }

    .dashboard_layout_header {
        display: none;
    }

    .dashboard_dashboard_blayout .dashboard_layout_banner{}

    .dashboard_training_layout .dashboard_layout_banner {}

    .dashboard_layout_banner {
        height: 25.902vw;
        background-color: #1C1CA8;
        position: relative;
    }

    .dashboard_layout_banner_container {
        grid-row: 1;
        grid-column: 1 / span 8;
        padding: 5.347vw 1.388vw;
    }

    .dashboard_layout_banner_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 3.333vw;
        line-height: 3.68vw;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 0.347vw;
        position: relative;
        z-index: 1;
    }

    .dashboard_layout_banner_description {
        font-weight: 500;
        font-size: 1.736vw;
        line-height: 2.638vw;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        max-width: 65.972vw;
        position: relative;
        z-index: 1;
    }

    .dashboard_layout_banner_union_image_mobile {
        display: none;
        height: 100%;
        position: absolute;
        bottom: 0px;
        right: 11.491vw;
        z-index: 0;
    }

    .dashboard_layout_banner_union_image_desktop {
        display: block;
        height: 100%;
        position: absolute;
        bottom: 0px;
        right: 11.491vw;
        z-index: 0;
    }

    .dashboard_layout_banner.dashboard_training_banner .dashboard_layout_body {}

    .dashboard_layout_body {
        margin-top: -8.263vw;
        background-color: transparent;
    }


    .dashboard_layout_sidebar {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 1 / span 2;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
        margin-bottom: 5.625vw;
    }

    .dashboard_layout_sidebar_content {
        width: 100%;
        border-radius: 1.111vw;
        background-color: #055CF5;
        overflow: hidden;
    }

    .dashboard_layout_sidebar_close_btn {
        background-color: transparent;
        border: 0px;
        outline: none;
        cursor: pointer;
        position: absolute;
        right: 4.444vw;
        top: 4.444vw;
    }

    .dashboard_layout_sidebar_close_icon {
        width: 3.333vw;
        height: 3.333vw;
    }

    .dashboard_layout_sidebar_content_header {
        padding: 2.222vw 1.597vw 1.666vw 1.597vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .dashboard_layout_sidebar_content_header_avatar {
        margin-bottom: 1.666vw;
    }

    .dashboard_layout_sidebar_content_header_name {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 1.666vw;
        line-height: 2.361vw;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 0.069vw;
    }

    .dashboard_layout_sidebar_content_header_total_point {
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.666vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 1.458vw;
    }

    .dashboard_layout_sidebar_progress_bar {
        width: 100%;
        display: flex;
        align-items: center;
        height: 0.555vw;
        gap: 0.416vw;
    }

    .dashboard_layout_sidebar_progress_item {
        height: 100%;
        background: #FFFFFF;
        opacity: 0.2;
        width: 100%;
        border-radius: 0.138vw;
    }

    .dashboard_layout_sidebar_progress_item.active {
        opacity: 1;
    }

    .dashboard_layout_sidebar_content_body {
        border-top-right-radius: 1.111vw;
        border-top-left-radius: 1.111vw;
        overflow: hidden;
    }

    .dashboard_layout_sidebar_menu_lists {
        background-color: #FFFFFF;
        padding: 1.527vw 1.792vw 4.305vw 1.72vw;
        display: flex;
        flex-direction: column;
        gap: 0.555vw;
    }

    .dashboard_layout_sidebar_menu_list {
        color: #27272F;
        cursor: pointer;
        display: flex;
        font-size: 1.111vw;
        align-items: center;
        font-weight: 700;
        line-height: 1.388vw;
        padding-top: 1.388vw;
        border-radius: 1.111vw;
        padding-right: 1.388vw;
        letter-spacing: -0.01em;
        padding-bottom: 1.388vw;
        background-color: #FFFFFF;
    }

    .dashboard_layout_sidebar_menu_list.active {
        color: #FFFFFF;
        background-color: #055CF5;
    }

    .dashboard_layout_sidebar_menu_list_icon {
        flex: none;
        width: 4.444vw;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        padding-left: 1.388vw;
        padding-right: 1.388vw;
        justify-content: center;
    }

    .dashboard_layout_sidebar_menu_list_icon img {
        width: 1.666vw;
    }

    .dashboard_layout_sidebar_menu_list_text {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .dashboard_logout_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 1000;
        width: 100vw;
        height: 100vh;
    }

    .dashboard_logout_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .dashboard_logout_modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 17.916vw;
        left: 50%;
        transform: translateX(-50%);
        width: 38.68vw;
    }

    .dashboard_logout_modal_close_btn {
        position: absolute;
        right: 0.763vw;
        top: 0px;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: 2.777vw;
        height: 2.777vw;
    }

    .dashboard_logout_modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .dashboard_logout_modal_content {
        width: 100%;
        padding: 5vw 1.666vw 5.555vw 1.666vw;
        background-color: white;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
    }

    .dashboard_logout_modal_icon {
        width: 4.513vw;
        height: 4.513vw;
        margin: auto;
        margin-bottom: 1.597vw;
    }

    .dashboard_logout_modal_title {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 2.083vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 2.777vw;
    }

    .dashboard_logout_modal_actions {
        display: flex;
        flex-direction: column;
        gap: 1.111vw;
    }

    .dashboard_logout_modal_back {
        width: 21.666vw !important;
        margin: auto;
    }

    .dashboard_logout_modal_next {
        width: 21.666vw !important;
        margin: auto;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .dashboard_layout {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #EEEEF0;
    }

    .dashboard_layout_header {
        display: none;
    }

    .dashboard_dashboard_blayout .dashboard_layout_banner{}

    .dashboard_training_layout .dashboard_layout_banner {}

    .dashboard_layout_banner {
        height: 25.902vw;
        background-color: #1C1CA8;
        position: relative;
    }

    .dashboard_layout_banner_container {
        grid-row: 1;
        grid-column: 1 / span 12;
        padding: 5.347vw 1.388vw;
    }

    .dashboard_layout_banner_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 3.333vw;
        line-height: 3.68vw;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 0.347vw;
        position: relative;
        z-index: 1;
    }

    .dashboard_layout_banner_description {
        font-weight: 500;
        font-size: 1.736vw;
        line-height: 2.638vw;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        max-width: 65.972vw;
        position: relative;
        z-index: 1;
    }

    .dashboard_layout_banner_union_image_mobile {
        display: none;
        height: 100%;
        position: absolute;
        bottom: 0px;
        right: 11.491vw;
        z-index: 0;
    }

    .dashboard_layout_banner_union_image_desktop {
        display: block;
        height: 100%;
        position: absolute;
        bottom: 0px;
        right: 11.491vw;
        z-index: 0;
    }

    .dashboard_layout_banner.dashboard_training_banner .dashboard_layout_body {}

    .dashboard_layout_body {
        margin-top: -8.263vw;
        background-color: transparent;
    }


    .dashboard_layout_sidebar {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 1 / span 3;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
        margin-bottom: 5.625vw;
    }

    .dashboard_layout_sidebar_content {
        width: 100%;
        border-radius: 1.111vw;
        background-color: #055CF5;
        overflow: hidden;
    }

    .dashboard_layout_sidebar_close_btn {
        background-color: transparent;
        border: 0px;
        outline: none;
        cursor: pointer;
        position: absolute;
        right: 16px;
        top: 16px;
    }

    .dashboard_layout_sidebar_close_icon {
        width: 3.333vw;
        height: 3.333vw;
    }

    .dashboard_layout_sidebar_content_header {
        padding: 2.222vw 1.597vw 1.666vw 1.597vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .dashboard_layout_sidebar_content_header_avatar {
        margin-bottom: 1.666vw;
    }

    .dashboard_layout_sidebar_content_header_name {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 1.666vw;
        line-height: 2.361vw;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 0.069vw;
    }

    .dashboard_layout_sidebar_content_header_total_point {
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.666vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 1.458vw;
    }

    .dashboard_layout_sidebar_progress_bar {
        width: 100%;
        display: flex;
        align-items: center;
        height: 0.555vw;
        gap: 0.416vw;
    }

    .dashboard_layout_sidebar_progress_item {
        height: 100%;
        background: #FFFFFF;
        opacity: 0.2;
        width: 100%;
        border-radius: 0.138vw;
    }

    .dashboard_layout_sidebar_progress_item.active {
        opacity: 1;
    }

    .dashboard_layout_sidebar_content_body {
        border-top-right-radius: 1.111vw;
        border-top-left-radius: 1.111vw;
        overflow: hidden;
    }

    .dashboard_layout_sidebar_menu_lists {
        background-color: #FFFFFF;
        padding: 1.527vw 1.792vw 4.305vw 1.72vw;
        display: flex;
        flex-direction: column;
        gap: 0.555vw;
    }

    .dashboard_layout_sidebar_menu_list {
        color: #27272F;
        cursor: pointer;
        display: flex;
        font-size: 1.111vw;
        align-items: center;
        font-weight: 700;
        line-height: 1.388vw;
        padding-top: 1.388vw;
        border-radius: 1.111vw;
        padding-right: 1.388vw;
        letter-spacing: -0.01em;
        padding-bottom: 1.388vw;
        background-color: #FFFFFF;
    }

    .dashboard_layout_sidebar_menu_list.active {
        color: #FFFFFF;
        background-color: #055CF5;
    }

    .dashboard_layout_sidebar_menu_list_icon {
        flex: none;
        width: 4.444vw;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        padding-left: 1.388vw;
        padding-right: 1.388vw;
        justify-content: center;
    }

    .dashboard_layout_sidebar_menu_list_icon img {
        width: 1.666vw;
    }

    .dashboard_layout_sidebar_menu_list_text {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .dashboard_logout_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 1000;
        width: 100vw;
        height: 100vh;
    }

    .dashboard_logout_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .dashboard_logout_modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 17.916vw;
        left: 50%;
        transform: translateX(-50%);
        width: 38.68vw;
    }

    .dashboard_logout_modal_close_btn {
        position: absolute;
        right: 0.763vw;
        top: 0px;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: 2.777vw;
        height: 2.777vw;
    }

    .dashboard_logout_modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .dashboard_logout_modal_content {
        width: 100%;
        padding: 5vw 1.666vw 5.555vw 1.666vw;
        background-color: white;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
    }

    .dashboard_logout_modal_icon {
        width: 4.513vw;
        height: 4.513vw;
        margin: auto;
        margin-bottom: 1.597vw;
    }

    .dashboard_logout_modal_title {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 2.083vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 2.777vw;
    }

    .dashboard_logout_modal_actions {
        display: flex;
        flex-direction: column;
        gap: 1.111vw;
    }

    .dashboard_logout_modal_back {
        width: 21.666vw !important;
        margin: auto;
    }

    .dashboard_logout_modal_next {
        width: 21.666vw !important;
        margin: auto;
    }
}

@media only screen and (min-width: 1441px) {
    .dashboard_layout {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #EEEEF0;
    }

    .dashboard_layout_header {
        display: none;
    }

    .dashboard_dashboard_blayout .dashboard_layout_banner{}

    .dashboard_training_layout .dashboard_layout_banner {}

    .dashboard_layout_banner {
        height: 373px;
        background-color: #1C1CA8;
        position: relative;
    }

    .dashboard_layout_banner_container {
        grid-row: 1;
        grid-column: 1 / span 12;
        padding: 77px 20px;
    }

    .dashboard_layout_banner_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 48px;
        line-height: 53px;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 5px;
        position: relative;
        z-index: 1;
    }

    .dashboard_layout_banner_description {
        font-weight: 500;
        font-size: 25px;
        line-height: 38px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        max-width: 950px;
        position: relative;
        z-index: 1;
    }

    .dashboard_layout_banner_union_image_mobile {
        display: none;
        height: 100%;
        position: absolute;
        bottom: 0px;
        right: 165.48px;
        z-index: 0;
    }

    .dashboard_layout_banner_union_image_desktop {
        display: block;
        height: 100%;
        position: absolute;
        bottom: 0px;
        right: 165.48px;
        z-index: 0;
    }

    .dashboard_layout_banner.dashboard_training_banner .dashboard_layout_body {}

    .dashboard_layout_body {
        margin-top: -119px;
        background-color: transparent;
    }


    .dashboard_layout_sidebar {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 1 / span 3;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
        margin-bottom: 81px;
    }

    .dashboard_layout_sidebar_content {
        width: 100%;
        border-radius: 16px;
        background-color: #055CF5;
        overflow: hidden;
    }

    .dashboard_layout_sidebar_close_btn {
        background-color: transparent;
        border: 0px;
        outline: none;
        cursor: pointer;
        position: absolute;
        right: 16px;
        top: 16px;
    }

    .dashboard_layout_sidebar_close_icon {
        width: 48px;
        height: 48px;
    }

    .dashboard_layout_sidebar_content_header {
        padding: 32px 23px 24px 23px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .dashboard_layout_sidebar_content_header_avatar {
        margin-bottom: 24px;
    }

    .dashboard_layout_sidebar_content_header_name {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 24px;
        line-height: 34px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 1px;
    }

    .dashboard_layout_sidebar_content_header_total_point {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 21px;
    }

    .dashboard_layout_sidebar_progress_bar {
        width: 100%;
        display: flex;
        align-items: center;
        height: 8px;
        gap: 6px;
    }

    .dashboard_layout_sidebar_progress_item {
        height: 100%;
        background: #FFFFFF;
        opacity: 0.2;
        width: 100%;
        border-radius: 2px;
    }

    .dashboard_layout_sidebar_progress_item.active {
        opacity: 1;
    }

    .dashboard_layout_sidebar_content_body {
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
        overflow: hidden;
    }

    .dashboard_layout_sidebar_menu_lists {
        background-color: #FFFFFF;
        padding: 22px 25.81px 62px 24.78px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .dashboard_layout_sidebar_menu_list {
        color: #27272F;
        cursor: pointer;
        display: flex;
        font-size: 16px;
        align-items: center;
        font-weight: 700;
        line-height: 20px;
        padding-top: 20px;
        border-radius: 16px;
        padding-right: 20px;
        letter-spacing: -0.01em;
        padding-bottom: 20px;
        background-color: #FFFFFF;
    }

    .dashboard_layout_sidebar_menu_list.active {
        color: #FFFFFF;
        background-color: #055CF5;
    }

    .dashboard_layout_sidebar_menu_list_icon {
        flex: none;
        width: 64px;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        justify-content: center;
    }

    .dashboard_layout_sidebar_menu_list_icon img {
        width: 24px;
    }

    .dashboard_layout_sidebar_menu_list_text {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .dashboard_logout_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 1000;
        width: 100vw;
        height: 100vh;
    }

    .dashboard_logout_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .dashboard_logout_modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 258px;
        left: 50%;
        transform: translateX(-50%);
        width: 557px;
    }

    .dashboard_logout_modal_close_btn {
        position: absolute;
        right: 11px;
        top: 0px;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: 40px;
        height: 40px;
    }

    .dashboard_logout_modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .dashboard_logout_modal_content {
        width: 100%;
        padding: 72px 24px 80px 24px;
        background-color: white;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
    }

    .dashboard_logout_modal_icon {
        width: 65px;
        height: 65px;
        margin: auto;
        margin-bottom: 23px;
    }

    .dashboard_logout_modal_title {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 40px;
    }

    .dashboard_logout_modal_actions {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .dashboard_logout_modal_back {
        width: 312px !important;
        margin: auto;
    }

    .dashboard_logout_modal_next {
        width: 312px !important;
        margin: auto;
    }
}