@media only screen and (max-width: 480px) {
    button {
        cursor: pointer;
    }

    a {
        text-decoration: none;
    }

    .btn {
        width: 100%;
        height: 13.888vw;
        padding: 3.611vw 5.555vw;
        border: 0px;
        outline: none;
        background-color: #FF4600;
        border-radius: 9999px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .btn:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }

    .btn span {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        text-decoration: none;
        font-weight: 700;
        font-size: 4.444vw;
        line-height: 5.555vw;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        white-space: nowrap;
    }

    .btn svg {
        flex: none;
        width: 6.666vw;
        height: 6.666vw;
    }

    .btn.btn_primary {
        background-color: #055CF5;
    }

    .btn.btn_secondary {
        background-color: #FF4600;
    }

    .progress_bar {
        display: flex;
        align-items: center;
        height: 8px;
        gap: 6px;
    }

    .progress_item {
        height: 100%;
        background: #EEEEF0;
        width: 100%;
        border-radius: 2px;
    }

    .progress_item.active {
        opacity: 0.2;
        background: #055CF5;
    }

    .dropdown {
        border: 1px solid #EDEDF0;
        border-radius: 8px;
    }

    .dropdown-select {
        height: 13.888vw;
        padding: 4.444vw 5.277vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }

    .dropdown-select.open {
        border-bottom: 1px solid #EDEDF0;
    }

    .dropdown-select-text {
        font-weight: 500;
        font-size: 4.444vw;
        line-height: 6.666vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .dropdown-select-icon {
        width: 4.444vw;
        height: 4.444vw;
    }

    .dropdown-menu {
        display: flex;
        flex-direction: column;
        gap: 2.777vw;
        padding: 3.055vw;
    }

    .dropdown-menu-item {
        height: 11.666vw;
        padding: 2.777vw;
        cursor: pointer;
        border-radius: 8px;
    }

    .dropdown-menu-item:hover {
        background-color: #F2F7FE;
    }

    .avatar {
        position: relative;
        width: 30.277vw;
        height: 30.277vw;
    }

    .avatar_wrapper {
        width: 100%;
        height: 100%;
        border: 1.111vw solid #FFFFFF;
        border-radius: 9999px;
        overflow: hidden;
    }

    .avatar_wrapper img {
        width: 100%;
    }

    .avatar_badge {
        width: 11.111vw;
        height: 11.111vw;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 5.555vw;
        line-height: 6.666vw;
        text-align: center;
        letter-spacing: -0.02em;
        color: #A9AAAE;
        position: absolute;
        right: -2.5vw;
        bottom: -1.111vw;
        background-color: white;
    }

    .task_item_action_btn {
        font-weight: 700;
        font-size: 3.888vw;
        line-height: 6.111vw;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        gap: 1.111vw;
        flex: none;
        display: flex;
        padding: 1.666vw 3.333vw;
        color: white;
        align-items: center;
        border-radius: 9999px;
    }

    .task_item_action_btn img {
        width: 6.666vw;
        height: 6.666vw;
    }

    .task_item_action_btn.go_btn {
        background-color: #055CF5;
    }

    .task_item_action_btn.done_btn {
        background-color: #41AD49;
    }

    .task_item_action_btn.locked_btn {
        background-color: #A9AAAE;
    }

    .task_item_action_btn.hold_btn {
        background-color: #055CF5;
    }

    .task_item_action_btn.retry_btn {
        background-color: #FF4600;
    }

    .task_item_action_btn.review_btn {
        background-color: #41AD49;
    }

    .task_item_divider {
        height: 1px;
        margin: auto;
        background-color: #EEEEF0;
    }

    .task_item_container {
        cursor: pointer;
        display: flex;
        gap: 2.222vw;
        padding-top: 5.555vw;
        padding-bottom: 5.555vw;
    }

    .task_item_index {
        flex: none;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        font-family: Azo Sans, sans-serif !important;
        font-weight: 900;
        border-radius: 9999px;
        letter-spacing: -0.02em;
        justify-content: center;
        width: 14.444vw;
        height: 14.444vw;
        font-size: 8.888vw;
        line-height: 8.888vw;
    }

    .task_item_right_container {
        gap: 4.444vw;
        display: flex;
        flex-grow: 1;
        align-items: flex-start;
        flex-direction: column;
    }

    .task_item_text_container {
        flex-grow: 1;
    }

    .task_item_top_title {
        font-weight: 900;
        font-size: 3.333vw;
        line-height: 5vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #41AD49;
    }

    .task_item_title {
        color: #0D0D16;
        font-size: 5.555vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-weight: 700;
        letter-spacing: -0.01em;
        line-height: 8.333vw;
    }

    .task_item_description {
        color: #5B5B62;
        font-size: 4.444vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-weight: 400;
        line-height: 7.111vw;
        letter-spacing: -0.01em;
    }

    .task_item_icon {
        width: 17.777vw;
        height: 17.777vw;
        max-width: 100%;
        max-height: 100%;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 9999px;
    }

    .task_item_icon img {
        width: 8.888vw;
        height: 8.888vw;
    }

    .task_item_checked_icon {
        width: 8.194vw;
        height: 7.083vw;
        margin: auto;
    }

    .list_item {
        display: flex;
        align-items: flex-start;
        gap: 3.055vw;
    }

    .list_item_icon {
        flex: none;
        width: 2.5vw;
        height: 2.5vw;
        margin-top: 2.222vw;
        border-radius: 9999px;
    }

    .list_item_text {
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 7.222vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .list_item_orange .list_item_icon {
        background-color: #FF4600;
    }

    .list_item_blue .list_item_icon {
        background-color: #055CF5;
    }

    .notification_container {
        width: 100%;
        min-height: 15.555vw;
        padding: 4.444vw 6.666vw;
        display: flex;
        align-items: center;
        gap: 4.444vw;
        border-radius: 4.444vw;
    }

    .notification_container_success {
        background-color: #D9EFDB;
        color: #41AD49;
    }

    .notification_container_primary {
        background-color: #CDDEFD;
        color: #1C1CA8;
    }

    .notification_container_warn {
        background-color: #FFD0CC;
        color: #E90808;
    }

    .notification_prefix_icon {
        flex: none;
        width: 6.666vw;
        height: 6.666vw;
        display: flex;
        align-items: center;
    }

    .notification_prefix_icon img,
    .notification_prefix_icon svg {
        width: 100%;
        height: 100%;
    }

    .notification_text {
        flex-grow: 1;
        font-weight: 500;
        font-size: 4.444vw;
        line-height: 6.666vw;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
    }

    .notification_suffix_icon {
        flex: none;
        width: 6.666vw;
        height: 6.666vw;
        display: flex;
        align-items: center;
    }

    .notification_suffix_icon img,
    .notification_suffix_icon svg {
        width: 100%;
        height: 100%;
    }

    .input {
        width: 100%;
        height: 18.333vw;
        border-radius: 2.222vw;
        padding: 5.555vw;
        display: flex;
        align-items: center;
        background-color: #EEEEF0;
    }

    .input_field {
        flex-grow: 1;
        border: 0px;
        outline: none;
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 7.222vw;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #27272F;
        background-color: transparent;
    }

    .input:has(.input_field.danger) {
        border: 1px solid red !important;
    }

    .input_lock_icon {
        cursor: pointer;
        width: 6.666vw;
        height: 6.666vw;
        flex: none;
    }

    .input_lock_tooltip_container {
        background-color: #FFD0CC !important;
        border-radius: 4.444vw !important;
        padding: 4.444vw 6.666vw !important;
        display: flex;
        align-items: center;
        gap: 2.777vw;
        width: fit-content;
    }

    .input_lock_tooltip_container img {
        width: 6.666vw;
        height: 6.666vw;
        flex: none;
    }

    .input_lock_tooltip_container span {
        white-space: nowrap;
        font-weight: 500;
        font-size: 4.444vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #E90808;
    }

    .modal {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 1000;
        width: 100vw;
        height: 100vh;
    }

    .modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
        background-color: white;
        border-top-left-radius: 4.444vw;
        border-top-right-radius: 4.444vw;
    }

    .modal_close_btn {
        position: absolute;
        right: 0.972vw;
        top: 0px;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: 11.111vw;
        height: 11.111vw;
    }

    .modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .modal_content {
        width: 100%;
        padding: 11.111vw 6.666vw 6.666vw 6.666vw;
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    button {
        cursor: pointer;
    }

    a {
        text-decoration: none;
    }

    .btn {
        width: 100%;
        height: 3.472vw;
        padding: 0.902vw 1.388vw;
        border: 0px;
        outline: none;
        background-color: #FF4600;
        border-radius: 9999px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .btn:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }

    .btn span {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        text-decoration: none;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        white-space: nowrap;
    }

    .btn svg {
        flex: none;
        width: 1.666vw;
        height: 1.666vw;
    }

    .btn.btn_primary {
        background-color: #055CF5;
    }

    .btn.btn_secondary {
        background-color: #FF4600;
    }

    .progress_bar {
        display: flex;
        align-items: center;
        height: 0.555vw;
        gap: 0.416vw;
    }

    .progress_item {
        height: 100%;
        background: #EEEEF0;
        width: 100%;
        border-radius: 2px;
    }

    .progress_item.active {
        opacity: 0.2;
        background: #055CF5;
    }

    .dropdown {
        border: 1px solid #EDEDF0;
        border-radius: 0.555vw;
    }

    .dropdown-select {
        height: 3.472vw;
        padding: 1.111vw 1.319vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }

    .dropdown-select.open {
        border-bottom: 1px solid #EDEDF0;
    }

    .dropdown-select-text {
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.666vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .dropdown-select-icon {
        width: 1.111vw;
        height: 1.111vw;
    }

    .dropdown-menu {
        display: flex;
        flex-direction: column;
        gap: 0.694vw;
        padding: 0.763vw;
    }

    .dropdown-menu-item {
        height: 2.916vw;
        padding: 0.694vw;
        cursor: pointer;
    }

    .dropdown-menu-item:hover {
        background-color: #F2F7FE;
    }

    .avatar {
        position: relative;
        width: 7.569vw;
        height: 7.569vw;
    }

    .avatar_wrapper {
        width: 100%;
        height: 100%;
        border: 0.277vw solid #FFFFFF;
        border-radius: 9999px;
        overflow: hidden;
    }

    .avatar_wrapper img {
        width: 100%;
    }

    .avatar_badge {
        width: 2.777vw;
        height: 2.777vw;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 1.666vw;
        text-align: center;
        letter-spacing: -0.02em;
        color: #A9AAAE;
        position: absolute;
        right: -0.652vw;
        bottom: -0.277vw;
        background-color: white;
    }

    .task_item_action_btn {
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.527vw;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        gap: 0.277vw;
        flex: none;
        display: flex;
        padding: 0.416vw 0.833vw;
        color: white;
        align-items: center;
        border-radius: 9999px;
    }

    .task_item_action_btn img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .task_item_action_btn.go_btn {
        background-color: #055CF5;
    }

    .task_item_action_btn.done_btn {
        background-color: #41AD49;
    }

    .task_item_action_btn.locked_btn {
        background-color: #A9AAAE;
    }

    .task_item_action_btn.hold_btn {
        background-color: #055CF5;
    }

    .task_item_action_btn.retry_btn {
        background-color: #FF4600;
    }

    .task_item_action_btn.review_btn {
        background-color: #41AD49;
    }

    .task_item_divider {
        height: 1px;
        margin: auto;
        background-color: #EEEEF0;
    }

    .task_item_container {
        cursor: pointer;
        display: flex;
        gap: 1.111vw;
        padding-top: 1.388vw;
        padding-bottom: 1.388vw;
    }

    .task_item_index {
        flex: none;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        font-family: Azo Sans, sans-serif !important;
        font-weight: 900;
        border-radius: 9999px;
        letter-spacing: -0.02em;
        justify-content: center;
        width: 4.444vw;
        height: 4.444vw;
        font-size: 2.5vw;
        line-height: 2.5vw;
    }

    .task_item_right_container {
        gap: 1.111vw;
        display: flex;
        flex-grow: 1;
        align-items: center;
        flex-direction: row;
    }

    .task_item_text_container {
        flex-grow: 1;
    }

    .task_item_top_title {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #41AD49;
    }

    .task_item_title {
        color: #0D0D16;
        font-size: 1.388vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-weight: 700;
        letter-spacing: -0.01em;
        line-height: 2.083vw;
    }

    .task_item_description {
        color: #5B5B62;
        font-size: 1.111vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-weight: 400;
        line-height: 1.777vw;
        letter-spacing: -0.01em;
    }

    .task_item_icon {
        width: 4.444vw;
        height: 4.444vw;
        max-width: 100%;
        max-height: 100%;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 9999px;
    }

    .task_item_icon img {
        width: 2.222vw;
        height: 2.222vw;
    }

    .task_item_checked_icon {
        width: 2.048vw;
        height: 1.77vw;
        margin: auto;
    }

    .list_item {
        display: flex;
        align-items: flex-start;
        gap: 0.763vw;
    }

    .list_item_icon {
        flex: none;
        width: 0.625vw;
        height: 0.625vw;
        margin-top: 0.555vw;
        border-radius: 9999px;
    }

    .list_item_text {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .list_item_orange .list_item_icon {
        background-color: #FF4600;
    }

    .list_item_blue .list_item_icon {
        background-color: #055CF5;
    }

    .notification_container {
        width: 100%;
        min-height: 3.888vw;
        padding: 1.111vw 1.666vw;
        display: flex;
        align-items: center;
        gap: 1.111vw;
        border-radius: 1.111vw;
    }

    .notification_container_success {
        background-color: #D9EFDB;
        color: #41AD49;
    }

    .notification_container_primary {
        background-color: #CDDEFD;
        color: #1C1CA8;
    }

    .notification_container_warn {
        background-color: #FFD0CC;
        color: #E90808;
    }

    .notification_prefix_icon {
        flex: none;
        width: 1.666vw;
        height: 1.666vw;
        display: flex;
        align-items: center;
    }

    .notification_prefix_icon img,
    .notification_prefix_icon svg {
        width: 100%;
        height: 100%;
    }

    .notification_text {
        flex-grow: 1;
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.666vw;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
    }

    .notification_suffix_icon {
        flex: none;
        width: 1.666vw;
        height: 1.666vw;
        display: flex;
        align-items: center;
    }

    .notification_suffix_icon img,
    .notification_suffix_icon svg {
        width: 100%;
        height: 100%;
    }

    .input {
        width: 100%;
        height: 4.583vw;
        border-radius: 8px;
        padding: 1.388vw;
        display: flex;
        align-items: center;
        background-color: #EEEEF0;
    }

    .input_field {
        flex-grow: 1;
        border: 0px;
        outline: none;
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #27272F;
        background-color: transparent;
    }

    .input:has(.input_field.danger) {
        border: 1px solid red !important;
    }

    .input_lock_icon {
        cursor: pointer;
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .input_lock_tooltip_container {
        background-color: #FFD0CC !important;
        border-radius: 1.111vw !important;
        padding: 1.111vw 1.666vw !important;
        display: flex;
        align-items: center;
        gap: 0.694vw;
        width: fit-content;
    }

    .input_lock_tooltip_container img {
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .input_lock_tooltip_container span {
        white-space: nowrap;
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #E90808;
    }

    .modal {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 1000;
        width: 100vw;
        height: 100vh;
    }

    .modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 7.911vw;
        left: 50%;
        transform: translateX(-50%);
        width: 38.680vw;
        background-color: white;
        border-radius: 1.111vw;
    }

    .modal_close_btn {
        position: absolute;
        right: 0.763vw;
        top: 0px;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: 2.777vw;
        height: 2.777vw;
    }

    .modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .modal_content {
        width: 100%;
        padding: 2.777vw;
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    button {
        cursor: pointer;
    }

    a {
        text-decoration: none;
    }

    .btn {
        width: 100%;
        height: 3.472vw;
        padding: 0.902vw 1.388vw;
        border: 0px;
        outline: none;
        background-color: #FF4600;
        border-radius: 9999px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .btn:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }

    .btn span {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        text-decoration: none;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        white-space: nowrap;
    }

    .btn svg {
        flex: none;
        width: 1.666vw;
        height: 1.666vw;
    }

    .btn.btn_primary {
        background-color: #055CF5;
    }

    .btn.btn_secondary {
        background-color: #FF4600;
    }

    .progress_bar {
        display: flex;
        align-items: center;
        height: 0.555vw;
        gap: 0.416vw;
    }

    .progress_item {
        height: 100%;
        background: #EEEEF0;
        width: 100%;
        border-radius: 2px;
    }

    .progress_item.active {
        opacity: 0.2;
        background: #055CF5;
    }

    .dropdown {
        border: 1px solid #EDEDF0;
        border-radius: 0.555vw;
    }

    .dropdown-select {
        height: 3.472vw;
        padding: 1.111vw 1.319vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }

    .dropdown-select.open {
        border-bottom: 1px solid #EDEDF0;
    }

    .dropdown-select-text {
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.666vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .dropdown-select-icon {
        width: 1.111vw;
        height: 1.111vw;
    }

    .dropdown-menu {
        display: flex;
        flex-direction: column;
        gap: 0.694vw;
        padding: 0.763vw;
    }

    .dropdown-menu-item {
        height: 2.916vw;
        padding: 0.694vw;
        cursor: pointer;
    }

    .dropdown-menu-item:hover {
        background-color: #F2F7FE;
    }

    .avatar {
        position: relative;
        width: 7.569vw;
        height: 7.569vw;
    }

    .avatar_wrapper {
        width: 100%;
        height: 100%;
        border: 0.277vw solid #FFFFFF;
        border-radius: 9999px;
        overflow: hidden;
    }

    .avatar_wrapper img {
        width: 100%;
    }

    .avatar_badge {
        width: 2.777vw;
        height: 2.777vw;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 1.666vw;
        text-align: center;
        letter-spacing: -0.02em;
        color: #A9AAAE;
        position: absolute;
        right: -0.652vw;
        bottom: -0.277vw;
        background-color: white;
    }

    .task_item_action_btn {
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.527vw;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        gap: 0.277vw;
        flex: none;
        display: flex;
        padding: 0.416vw 0.833vw;
        color: white;
        align-items: center;
        border-radius: 9999px;
    }

    .task_item_action_btn img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .task_item_action_btn.go_btn {
        background-color: #055CF5;
    }

    .task_item_action_btn.done_btn {
        background-color: #41AD49;
    }

    .task_item_action_btn.locked_btn {
        background-color: #A9AAAE;
    }

    .task_item_action_btn.hold_btn {
        background-color: #055CF5;
    }

    .task_item_action_btn.retry_btn {
        background-color: #FF4600;
    }

    .task_item_action_btn.review_btn {
        background-color: #41AD49;
    }

    .task_item_divider {
        height: 1px;
        margin: auto;
        background-color: #EEEEF0;
    }

    .task_item_container {
        cursor: pointer;
        display: flex;
        gap: 1.111vw;
        padding-top: 1.388vw;
        padding-bottom: 1.388vw;
    }

    .task_item_index {
        flex: none;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        font-family: Azo Sans, sans-serif !important;
        font-weight: 900;
        border-radius: 9999px;
        letter-spacing: -0.02em;
        justify-content: center;
        width: 4.444vw;
        height: 4.444vw;
        font-size: 2.5vw;
        line-height: 2.5vw;
    }

    .task_item_right_container {
        gap: 1.111vw;
        display: flex;
        flex-grow: 1;
        align-items: center;
        flex-direction: row;
    }

    .task_item_text_container {
        flex-grow: 1;
    }

    .task_item_top_title {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #41AD49;
    }

    .task_item_title {
        color: #0D0D16;
        font-size: 1.388vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-weight: 700;
        letter-spacing: -0.01em;
        line-height: 2.083vw;
    }

    .task_item_description {
        color: #5B5B62;
        font-size: 1.111vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-weight: 400;
        line-height: 1.777vw;
        letter-spacing: -0.01em;
    }

    .task_item_icon {
        width: 4.444vw;
        height: 4.444vw;
        max-width: 100%;
        max-height: 100%;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 9999px;
    }

    .task_item_icon img {
        width: 2.222vw;
        height: 2.222vw;
    }

    .task_item_checked_icon {
        width: 2.048vw;
        height: 1.77vw;
        margin: auto;
    }

    .list_item {
        display: flex;
        align-items: flex-start;
        gap: 0.763vw;
    }

    .list_item_icon {
        flex: none;
        width: 0.625vw;
        height: 0.625vw;
        margin-top: 0.555vw;
        border-radius: 9999px;
    }

    .list_item_text {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .list_item_orange .list_item_icon {
        background-color: #FF4600;
    }

    .list_item_blue .list_item_icon {
        background-color: #055CF5;
    }

    .notification_container {
        width: 100%;
        min-height: 3.888vw;
        padding: 1.111vw 1.666vw;
        display: flex;
        align-items: center;
        gap: 1.111vw;
        border-radius: 1.111vw;
    }

    .notification_container_success {
        background-color: #D9EFDB;
        color: #41AD49;
    }

    .notification_container_primary {
        background-color: #CDDEFD;
        color: #1C1CA8;
    }

    .notification_container_warn {
        background-color: #FFD0CC;
        color: #E90808;
    }

    .notification_prefix_icon {
        flex: none;
        width: 1.666vw;
        height: 1.666vw;
        display: flex;
        align-items: center;
    }

    .notification_prefix_icon img,
    .notification_prefix_icon svg {
        width: 100%;
        height: 100%;
    }

    .notification_text {
        flex-grow: 1;
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.666vw;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
    }

    .notification_suffix_icon {
        flex: none;
        width: 1.666vw;
        height: 1.666vw;
        display: flex;
        align-items: center;
    }

    .notification_suffix_icon img,
    .notification_suffix_icon svg {
        width: 100%;
        height: 100%;
    }

    .input {
        width: 100%;
        height: 4.583vw;
        border-radius: 8px;
        padding: 1.388vw;
        display: flex;
        align-items: center;
        background-color: #EEEEF0;
    }

    .input_field {
        flex-grow: 1;
        border: 0px;
        outline: none;
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #27272F;
        background-color: transparent;
    }

    .input:has(.input_field.danger) {
        border: 1px solid red !important;
    }

    .input_lock_icon {
        cursor: pointer;
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .input_lock_tooltip_container {
        background-color: #FFD0CC !important;
        border-radius: 1.111vw !important;
        padding: 1.111vw 1.666vw !important;
        display: flex;
        align-items: center;
        gap: 0.694vw;
        width: fit-content;
    }

    .input_lock_tooltip_container img {
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .input_lock_tooltip_container span {
        white-space: nowrap;
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #E90808;
    }

    .modal {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 1000;
        width: 100vw;
        height: 100vh;
    }

    .modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 7.911vw;
        left: 50%;
        transform: translateX(-50%);
        width: 38.680vw;
        background-color: white;
        border-radius: 1.111vw;
    }

    .modal_close_btn {
        position: absolute;
        right: 0.763vw;
        top: 0px;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: 2.777vw;
        height: 2.777vw;
    }

    .modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .modal_content {
        width: 100%;
        padding: 2.777vw;
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (min-width: 1441px) {
    button {
        cursor: pointer;
    }

    a {
        text-decoration: none;
    }

    .btn {
        width: 100%;
        height: 50px;
        padding: 13px 20px;
        border: 0px;
        outline: none;
        background-color: #FF4600;
        border-radius: 9999px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .btn:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }

    .btn span {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        text-decoration: none;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        white-space: nowrap;
    }

    .btn svg {
        flex: none;
        width: 24px;
        height: 24px;
    }

    .btn.btn_primary {
        background-color: #055CF5;
    }

    .btn.btn_secondary {
        background-color: #FF4600;
    }

    .progress_bar {
        display: flex;
        align-items: center;
        height: 8px;
        gap: 6px;
    }

    .progress_item {
        height: 100%;
        background: #EEEEF0;
        width: 100%;
        border-radius: 2px;
    }

    .progress_item.active {
        opacity: 0.2;
        background: #055CF5;
    }

    .dropdown {
        border: 1px solid #EDEDF0;
        border-radius: 8px;
    }

    .dropdown-select {
        height: 50px;
        padding: 16px 19px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }

    .dropdown-select.open {
        border-bottom: 1px solid #EDEDF0;
    }

    .dropdown-select-text {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .dropdown-select-icon {
        width: 16px;
        height: 16px;
    }

    .dropdown-menu {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 11px;
    }

    .dropdown-menu-item {
        height: 42px;
        padding: 10px;
        cursor: pointer;
    }

    .dropdown-menu-item:hover {
        background-color: #F2F7FE;
    }

    .avatar {
        position: relative;
        width: 109px;
        height: 109px;
    }

    .avatar_wrapper {
        width: 100%;
        height: 100%;
        border: 4px solid #FFFFFF;
        border-radius: 9999px;
        overflow: hidden;
    }

    .avatar_wrapper img {
        width: 100%;
    }

    .avatar_badge {
        width: 40px;
        height: 40px;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #A9AAAE;
        position: absolute;
        right: -9px;
        bottom: -4px;
        background-color: white;
    }

    .task_item_action_btn {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        gap: 4px;
        flex: none;
        display: flex;
        padding: 6px 12px;
        color: white;
        align-items: center;
        border-radius: 9999px;
    }

    .task_item_action_btn img {
        width: 24px;
        height: 24px;
    }

    .task_item_action_btn.go_btn {
        background-color: #055CF5;
    }

    .task_item_action_btn.done_btn {
        background-color: #41AD49;
    }

    .task_item_action_btn.locked_btn {
        background-color: #A9AAAE;
    }

    .task_item_action_btn.hold_btn {
        background-color: #055CF5;
    }

    .task_item_action_btn.retry_btn {
        background-color: #FF4600;
    }

    .task_item_action_btn.review_btn {
        background-color: #41AD49;
    }

    .task_item_divider {
        height: 1px;
        margin: auto;
        background-color: #EEEEF0;
    }

    .task_item_container {
        cursor: pointer;
        display: flex;
        gap: 16px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .task_item_index {
        flex: none;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        font-family: Azo Sans, sans-serif !important;
        font-weight: 900;
        border-radius: 9999px;
        letter-spacing: -0.02em;
        justify-content: center;
        width: 64px;
        height: 64px;
        font-size: 36px;
        line-height: 36px;
    }

    .task_item_right_container {
        gap: 16px;
        display: flex;
        flex-grow: 1;
        align-items: center;
        flex-direction: row;
    }

    .task_item_text_container {
        flex-grow: 1;
    }

    .task_item_top_title {
        font-weight: 900;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #41AD49;
    }

    .task_item_title {
        color: #0D0D16;
        font-size: 20px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-weight: 700;
        letter-spacing: -0.01em;
        line-height: 30px;
    }

    .task_item_description {
        color: #5B5B62;
        font-size: 16px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-weight: 400;
        line-height: 25.6px;
        letter-spacing: -0.01em;
    }

    .task_item_icon {
        width: 64px;
        height: 64px;
        max-width: 100%;
        max-height: 100%;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 9999px;
    }

    .task_item_icon img {
        width: 32px;
        height: 32px;
    }

    .task_item_checked_icon {
        width: 29.5px;
        height: 25.5px;
        margin: auto;
    }

    .list_item {
        display: flex;
        align-items: flex-start;
        gap: 11px;
    }

    .list_item_icon {
        flex: none;
        width: 9px;
        height: 9px;
        margin-top: 8px;
        border-radius: 9999px;
    }

    .list_item_text {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .list_item_orange .list_item_icon {
        background-color: #FF4600;
    }

    .list_item_blue .list_item_icon {
        background-color: #055CF5;
    }

    .notification_container {
        width: 100%;
        min-height: 56px;
        padding: 16px 24px;
        display: flex;
        align-items: center;
        gap: 16px;
        border-radius: 16px;
    }

    .notification_container_success {
        background-color: #D9EFDB;
        color: #41AD49;
    }

    .notification_container_primary {
        background-color: #CDDEFD;
        color: #1C1CA8;
    }

    .notification_container_warn {
        background-color: #FFD0CC;
        color: #E90808;
    }

    .notification_prefix_icon {
        flex: none;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
    }

    .notification_prefix_icon img,
    .notification_prefix_icon svg {
        width: 100%;
        height: 100%;
    }

    .notification_text {
        flex-grow: 1;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
    }

    .notification_suffix_icon {
        flex: none;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
    }

    .notification_suffix_icon img,
    .notification_suffix_icon svg {
        width: 100%;
        height: 100%;
    }

    .input {
        width: 100%;
        height: 66px;
        border-radius: 8px;
        padding: 20px;
        display: flex;
        align-items: center;
        background-color: #EEEEF0;
    }

    .input_field {
        flex-grow: 1;
        border: 0px;
        outline: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #27272F;
        background-color: transparent;
    }

    .input:has(.input_field.danger) {
        border: 1px solid red !important;
    }

    .input_lock_icon {
        cursor: pointer;
        width: 24px;
        height: 24px;
        flex: none;
    }

    .input_lock_tooltip_container {
        background-color: #FFD0CC !important;
        border-radius: 16px !important;
        padding: 16px 24px !important;
        display: flex;
        align-items: center;
        gap: 10px;
        width: fit-content;
    }

    .input_lock_tooltip_container img {
        width: 24px;
        height: 24px;
        flex: none;
    }

    .input_lock_tooltip_container span {
        white-space: nowrap;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #E90808;
    }

    .modal {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 1000;
        width: 100vw;
        height: 100vh;
    }

    .modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 114px;
        left: 50%;
        transform: translateX(-50%);
        width: 557px;
        background-color: white;
        border-radius: 16px;
    }

    .modal_close_btn {
        position: absolute;
        right: 11px;
        top: 0px;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: 40px;
        height: 40px;
    }

    .modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .modal_content {
        width: 100%;
        padding: 40px;
        display: flex;
        flex-direction: column;
    }
}