@media only screen and (max-width: 480px) {
    .task_buy_starter_kit_body {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        padding-top: 4.444vw;
        padding-bottom: 3.055vw;
        background-color: #EEEEF0;
    }

    .task_buy_starter_kit_main {
        width: calc(100% + 16px);
        margin-left: -8px;
        grid-column: 1 / span 4;
        grid-row: 1;
    }

    .task_buy_starter_kit_main_container {
        position: relative;
        background-color: white;
        border-radius: 4.444vw;
        padding: 25vw 4.444vw 5.555vw 4.444vw;
        margin-bottom: 4.444vw;
        margin-top: -15.277vw;
        z-index: 1;
    }

    .task_buy_starter_kit_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 9.166vw;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 4.444vw;
    }

    .task_buy_starter_kit_description {
        height: 21.666vw;
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 7.222vw;
    }

    .task_buy_starter_kit_product_container {
        display: flex;
        flex-direction: column;
        margin-bottom: 8.055vw;
    }

    .task_buy_starter_kit_product_image_container {
        position: relative;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 86.944vw;
        border-radius: 4.444vw;
        background-color: #EEEEF0;
    }

    .task_buy_starter_kit_product_image {
        width: 100%;
        height: 100%;
    }

    .task_buy_starter_kit_product_price {
        display: none;
        position: absolute;
        top: 15px;
        right: 23px;
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #055CF5;
    }

    .task_buy_starter_kit_product_include {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 5.833vw;
    }

    .task_buy_starter_kit_product_include_title {
        font-weight: 400;
        font-size: 5.555vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 4.166vw;
    }

    .task_buy_starter_kit_product_include_list {
        display: flex;
        flex-direction: column;
        gap: 2.777vw;
        max-width: 100%;
    }

    .task_buy_starter_kit_step1 {
        margin-bottom: 10.855vw;
    }

    .task_buy_starter_kit_step_number {
        font-weight: 900;
        font-size: 3.333vw;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .task_buy_starter_kit_step_title {
        font-weight: 400;
        font-size: 5.555vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 6.111vw;
    }

    .task_buy_starter_kit_size_container {
        display: flex;
        gap: 3.361vw;
    }

    .task_buy_starter_kit_size_item {
        width: 14.7vw;
        height: 14.7vw;
        border-radius: 2.222vw;
        background-color: #EEEEF0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 4.444vw;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #8F9095;
        cursor: pointer;
    }

    .task_buy_starter_kit_size_item.active {
        background-color: #055CF5;
        color: white;
    }

    .task_buy_starter_kit_optionals_list {
        display: flex;
        gap: 4.444vw;
        flex-direction: column;
    }

    .task_buy_starter_kit_optional_item {
        height: 118.055vw;
        border-radius: 4.444vw;
        background-color: #EEEEF0;
        padding: 4.444vw;
    }

    .task_buy_starter_kit_optional_item_header {
        height: 8.333vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .task_buy_starter_kit_optional_item_name {
        font-weight: 400;
        font-size: 3.888vw;
        line-height: 160%;
        display: flex;
        align-items: flex-end;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .task_buy_starter_kit_optional_item_price {
        font-weight: 700;
        font-size: 5.555vw;
        line-height: 150%;
        display: flex;
        align-items: flex-end;
        text-align: right;
        letter-spacing: -0.01em;
        color: #055CF5;
    }

    .task_buy_starter_kit_optional_item_image {
        width: 100%;
        height: 85.277vw;
        max-width: 100%;
        margin-bottom: 2.222vw;
    }

    .task_buy_starter_kit_optional_item_image img {
        width: 100%;
        height: 100%;
    }

    .task_buy_starter_kit_optional_item_add {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 11.111vw;
        padding: 2.5vw 4.444vw;
        background-color: white;
        border-radius: 1.666vw;
        cursor: pointer;
    }

    .task_buy_starter_kit_optional_item_add span {
        font-weight: 700;
        font-size: 3.888vw;
        line-height: 157%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .task_buy_starter_kit_optional_item_add_image {
        width: 5.555vw;
        height: 5.555vw;
        flex: none;
    }

    .task_buy_starter_kit_footer {
        background-color: white;
        box-shadow: 0px 2px 12px rgba(11, 14, 26, 0.08);
        width: 100vw;
        border-top: 1px solid #DDDEE1;
        position: fixed;
        left: 0px;
        bottom: 5.555vw;
        padding-top: 3.611vw;
        padding-bottom: 2.777vw;
        padding-right: 6.388vw;
        padding-left: 6.388vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 1;
    }

    .task_buy_starter_kit_total_price {
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 150%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .task_buy_starter_kit_footer_btn {
        width: 42.777vw !important;
    }

    .task_buy_starter_kit_tips {
        width: calc(100% + 16px);
        margin-left: -8px;
        grid-column: 1 / span 4;
        grid-row: 2;
    }

    .task_buy_starter_kit_tips_container {
        background-color: white;
        border-radius: 4.444vw;
        padding: 6.666vw 4.444vw;
    }

    .task_buy_starter_kit_tip_header {
        display: flex;
        align-items: center;
        gap: 3.333vw;
        margin-bottom: 3.333vw;
    }

    .task_buy_starter_kit_tip_header img {
        width: 6.666vw;
        height: 6.666vw;
        flex: none;
    }

    .task_buy_starter_kit_tip_header span {
        font-weight: 900;
        font-size: 3.888vw;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
    }

    .task_buy_starter_kit_tip_title {
        font-weight: 500;
        font-size: 5.555vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 3.333vw;
    }

    .task_buy_starter_kit_tip_lists {
        display: flex;
        flex-direction: column;
        gap: 2.777vw;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .task_buy_starter_kit_body {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        padding-top: 1.736vw;
        padding-bottom: 9.027vw;
        background-color: #EEEEF0;
    }

    .task_buy_starter_kit_main {
        grid-column: 1 / span 6;
        grid-row: 1;
    }

    .task_buy_starter_kit_main_container {
        position: relative;
        background-color: white;
        border-radius: 1.111vw;
        padding: 3.541vw 3.333vw 8.472vw 3.333vw;
    }

    .task_buy_starter_kit_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 2.708vw;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 0.833vw;
    }

    .task_buy_starter_kit_description {
        height: 64px;
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 1.388vw;
        max-width: 33.958vw;
    }

    .task_buy_starter_kit_product_container {
        display: flex;
        align-items: center;
        margin-bottom: 3.75vw;
    }

    .task_buy_starter_kit_product_image_container {
        position: relative;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34.583vw;
        height: 25.069vw;
        border-radius: 1.111vw;
        background-color: #EEEEF0;
    }

    .task_buy_starter_kit_product_image {
        width: 29.513vw;
        height: 25.069vw;
    }

    .task_buy_starter_kit_product_price {
        position: absolute;
        top: 1.041vw;
        right: 1.597vw;
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #055CF5;
    }

    .task_buy_starter_kit_product_include {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 1.736vw;
    }

    .task_buy_starter_kit_product_include_title {
        font-weight: 400;
        font-size: 1.388vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 0.902vw;
    }

    .task_buy_starter_kit_product_include_list {
        display: flex;
        flex-direction: column;
        gap: 0.694vw;
        max-width: 19.652vw;
    }

    .task_buy_starter_kit_step1 {
        margin-bottom: 4.165vw;
    }

    .task_buy_starter_kit_step_number {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .task_buy_starter_kit_step_title {
        font-weight: 400;
        font-size: 1.388vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 1.527vw;
    }

    .task_buy_starter_kit_size_container {
        display: flex;
        gap: 0.952vw;
    }

    .task_buy_starter_kit_size_item {
        width: 4.168vw;
        height: 4.168vw;
        border-radius: 0.555vw;
        background-color: #EEEEF0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #8F9095;
        cursor: pointer;
    }

    .task_buy_starter_kit_size_item.active {
        background-color: #055CF5;
        color: white;
    }

    .task_buy_starter_kit_optionals_list {
        display: grid;
        gap: 1.041vw;
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .task_buy_starter_kit_optional_item {
        height: 22.361vw;
        border-radius: 1.111vw;
        background-color: #EEEEF0;
        padding: 1.111vw;
    }

    .task_buy_starter_kit_optional_item_header {
        height: 2.083vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .task_buy_starter_kit_optional_item_name {
        font-weight: 400;
        font-size: 0.972vw;
        line-height: 160%;
        display: flex;
        align-items: flex-end;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .task_buy_starter_kit_optional_item_price {
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 150%;
        display: flex;
        align-items: flex-end;
        text-align: right;
        letter-spacing: -0.01em;
        color: #055CF5;
    }

    .task_buy_starter_kit_optional_item_image {
        width: 12.916vw;
        height: 14.166vw;
        max-width: 100%;
        margin-bottom: 0.555vw;
    }

    .task_buy_starter_kit_optional_item_image img {
        width: 100%;
        height: 100%;
    }

    .task_buy_starter_kit_optional_item_add {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 2.777vw;
        padding: 0.625vw 1.111vw;
        background-color: white;
        border-radius: 0.416vw;
        cursor: pointer;
    }

    .task_buy_starter_kit_optional_item_add span {
        font-weight: 700;
        font-size: 0.972vw;
        line-height: 157%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .task_buy_starter_kit_optional_item_add_image {
        width: 1.388vw;
        height: 1.388vw;
        flex: none;
    }

    .task_buy_starter_kit_footer {
        width: 100%;
        border-top: 1px solid #DDDEE1;
        position: absolute;
        left: 0px;
        bottom: 0px;
        padding-top: 0.833vw;
        padding-bottom: 0.902vw;
        padding-right: 3.333vw;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1.18vw;
    }

    .task_buy_starter_kit_total_price {
        font-weight: 400;
        font-size: 1.388vw;
        line-height: 150%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .task_buy_starter_kit_footer_btn {
        width: 12.5vw !important;
    }

    .task_buy_starter_kit_tips {
        grid-column: 7 / span 2;
        grid-row: 1;
    }

    .task_buy_starter_kit_tips_container {
        background-color: white;
        border-radius: 1.111vw;
        padding: 1.666vw;
    }

    .task_buy_starter_kit_tip_header {
        display: flex;
        align-items: center;
        gap: 0.833vw;
        margin-bottom: 0.833vw;
    }

    .task_buy_starter_kit_tip_header img {
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .task_buy_starter_kit_tip_header span {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
    }

    .task_buy_starter_kit_tip_title {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 0.833vw;
    }

    .task_buy_starter_kit_tip_lists {
        display: flex;
        flex-direction: column;
        gap: 0.694vw;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .task_buy_starter_kit_body {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        padding-top: 1.736vw;
        padding-bottom: 9.027vw;
        background-color: #EEEEF0;
    }

    .task_buy_starter_kit_main {
        grid-column: 1 / span 9;
        grid-row: 1;
    }

    .task_buy_starter_kit_main_container {
        position: relative;
        background-color: white;
        border-radius: 1.111vw;
        padding: 3.541vw 3.333vw 8.472vw 3.333vw;
    }

    .task_buy_starter_kit_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 2.708vw;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 0.833vw;
    }

    .task_buy_starter_kit_description {
        height: 64px;
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 1.388vw;
        max-width: 33.958vw;
    }

    .task_buy_starter_kit_product_container {
        display: flex;
        align-items: center;
        margin-bottom: 3.75vw;
    }

    .task_buy_starter_kit_product_image_container {
        position: relative;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34.583vw;
        height: 25.069vw;
        border-radius: 1.111vw;
        background-color: #EEEEF0;
    }

    .task_buy_starter_kit_product_image {
        width: 29.513vw;
        height: 25.069vw;
    }

    .task_buy_starter_kit_product_price {
        position: absolute;
        top: 1.041vw;
        right: 1.597vw;
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #055CF5;
    }

    .task_buy_starter_kit_product_include {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 1.736vw;
    }

    .task_buy_starter_kit_product_include_title {
        font-weight: 400;
        font-size: 1.388vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 0.902vw;
    }

    .task_buy_starter_kit_product_include_list {
        display: flex;
        flex-direction: column;
        gap: 0.694vw;
        max-width: 19.652vw;
    }

    .task_buy_starter_kit_step1 {
        margin-bottom: 4.165vw;
    }

    .task_buy_starter_kit_step_number {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .task_buy_starter_kit_step_title {
        font-weight: 400;
        font-size: 1.388vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 1.527vw;
    }

    .task_buy_starter_kit_size_container {
        display: flex;
        gap: 0.952vw;
    }

    .task_buy_starter_kit_size_item {
        width: 4.168vw;
        height: 4.168vw;
        border-radius: 0.555vw;
        background-color: #EEEEF0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #8F9095;
        cursor: pointer;
    }

    .task_buy_starter_kit_size_item.active {
        background-color: #055CF5;
        color: white;
    }

    .task_buy_starter_kit_optionals_list {
        display: grid;
        gap: 1.041vw;
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .task_buy_starter_kit_optional_item {
        height: 22.361vw;
        border-radius: 1.111vw;
        background-color: #EEEEF0;
        padding: 1.111vw;
    }

    .task_buy_starter_kit_optional_item_header {
        height: 2.083vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .task_buy_starter_kit_optional_item_name {
        font-weight: 400;
        font-size: 0.972vw;
        line-height: 160%;
        display: flex;
        align-items: flex-end;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .task_buy_starter_kit_optional_item_price {
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 150%;
        display: flex;
        align-items: flex-end;
        text-align: right;
        letter-spacing: -0.01em;
        color: #055CF5;
    }

    .task_buy_starter_kit_optional_item_image {
        width: 12.916vw;
        height: 14.166vw;
        max-width: 100%;
        margin-bottom: 0.555vw;
    }

    .task_buy_starter_kit_optional_item_image img {
        width: 100%;
        height: 100%;
    }

    .task_buy_starter_kit_optional_item_add {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 2.777vw;
        padding: 0.625vw 1.111vw;
        background-color: white;
        border-radius: 0.416vw;
        cursor: pointer;
    }

    .task_buy_starter_kit_optional_item_add span {
        font-weight: 700;
        font-size: 0.972vw;
        line-height: 157%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .task_buy_starter_kit_optional_item_add_image {
        width: 1.388vw;
        height: 1.388vw;
        flex: none;
    }

    .task_buy_starter_kit_footer {
        width: 100%;
        border-top: 1px solid #DDDEE1;
        position: absolute;
        left: 0px;
        bottom: 0px;
        padding-top: 0.833vw;
        padding-bottom: 0.902vw;
        padding-right: 3.333vw;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1.18vw;
    }

    .task_buy_starter_kit_total_price {
        font-weight: 400;
        font-size: 1.388vw;
        line-height: 150%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .task_buy_starter_kit_footer_btn {
        width: 12.5vw !important;
    }

    .task_buy_starter_kit_tips {
        grid-column: 10 / span 3;
        grid-row: 1;
    }

    .task_buy_starter_kit_tips_container {
        background-color: white;
        border-radius: 1.111vw;
        padding: 1.666vw;
    }

    .task_buy_starter_kit_tip_header {
        display: flex;
        align-items: center;
        gap: 0.833vw;
        margin-bottom: 0.833vw;
    }

    .task_buy_starter_kit_tip_header img {
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .task_buy_starter_kit_tip_header span {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
    }

    .task_buy_starter_kit_tip_title {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 0.833vw;
    }

    .task_buy_starter_kit_tip_lists {
        display: flex;
        flex-direction: column;
        gap: 0.694vw;
    }
}

@media only screen and (min-width: 1441px) {
    .task_buy_starter_kit_body {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        padding-top: 25px;
        padding-bottom: 130px;
        background-color: #EEEEF0;
    }

    .task_buy_starter_kit_main {
        grid-column: 1 / span 9;
        grid-row: 1;
    }

    .task_buy_starter_kit_main_container {
        position: relative;
        background-color: white;
        border-radius: 16px;
        padding: 51px 48px 122px 48px;
    }

    .task_buy_starter_kit_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 39px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 12px;
    }

    .task_buy_starter_kit_description {
        height: 64px;
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 20px;
        max-width: 489px;
    }

    .task_buy_starter_kit_product_container {
        display: flex;
        align-items: center;
        margin-bottom: 54px;
    }

    .task_buy_starter_kit_product_image_container {
        position: relative;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 498px;
        height: 361px;
        border-radius: 16px;
        background-color: #EEEEF0;
    }

    .task_buy_starter_kit_product_image {
        width: 425px;
        height: 361px;
    }

    .task_buy_starter_kit_product_price {
        position: absolute;
        top: 15px;
        right: 23px;
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #055CF5;
    }

    .task_buy_starter_kit_product_include {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 25px;
    }

    .task_buy_starter_kit_product_include_title {
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 13px;
    }

    .task_buy_starter_kit_product_include_list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 283px;
    }

    .task_buy_starter_kit_step1 {
        margin-bottom: 59.98px;
    }

    .task_buy_starter_kit_step_number {
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
    }

    .task_buy_starter_kit_step_title {
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 22px;
    }

    .task_buy_starter_kit_size_container {
        display: flex;
        gap: 13.72px;
    }

    .task_buy_starter_kit_size_item {
        width: 60.02px;
        height: 60.02px;
        border-radius: 8px;
        background-color: #EEEEF0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #8F9095;
        cursor: pointer;
    }

    .task_buy_starter_kit_size_item.active {
        background-color: #055CF5;
        color: white;
    }

    .task_buy_starter_kit_optionals_list {
        display: grid;
        gap: 15px;
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .task_buy_starter_kit_optional_item {
        height: 322px;
        border-radius: 16px;
        background-color: #EEEEF0;
        padding: 16px;
    }

    .task_buy_starter_kit_optional_item_header {
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .task_buy_starter_kit_optional_item_name {
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        display: flex;
        align-items: flex-end;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .task_buy_starter_kit_optional_item_price {
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        display: flex;
        align-items: flex-end;
        text-align: right;
        letter-spacing: -0.01em;
        color: #055CF5;
    }

    .task_buy_starter_kit_optional_item_image {
        width: 186px;
        height: 204px;
        max-width: 100%;
        margin-bottom: 8px;
    }

    .task_buy_starter_kit_optional_item_image img {
        width: 100%;
        height: 100%;
    }

    .task_buy_starter_kit_optional_item_add {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        padding: 9px 16px;
        background-color: white;
        border-radius: 6px;
        cursor: pointer;
    }

    .task_buy_starter_kit_optional_item_add span {
        font-weight: 700;
        font-size: 14px;
        line-height: 157%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .task_buy_starter_kit_optional_item_add_image {
        width: 20px;
        height: 20px;
        flex: none;
    }

    .task_buy_starter_kit_footer {
        width: 100%;
        border-top: 1px solid #DDDEE1;
        position: absolute;
        left: 0px;
        bottom: 0px;
        padding-top: 12px;
        padding-bottom: 13px;
        padding-right: 48px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 17px;
    }

    .task_buy_starter_kit_total_price {
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .task_buy_starter_kit_footer_btn {
        width: 180px !important;
    }

    .task_buy_starter_kit_tips {
        grid-column: 10 / span 3;
        grid-row: 1;
    }

    .task_buy_starter_kit_tips_container {
        background-color: white;
        border-radius: 16px;
        padding: 24px;
    }

    .task_buy_starter_kit_tip_header {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-bottom: 12px;
    }

    .task_buy_starter_kit_tip_header img {
        width: 24px;
        height: 24px;
        flex: none;
    }

    .task_buy_starter_kit_tip_header span {
        font-weight: 900;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
    }

    .task_buy_starter_kit_tip_title {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 12px;
    }

    .task_buy_starter_kit_tip_lists {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}