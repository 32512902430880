@media only screen and (max-width: 480px) {

    .login_cellphone_form {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        position: relative;
        overflow: hidden;
    }

    .login_cellphone_form_padding {
        padding-top: 1.388vw;
    }

    .login_cellphone_form_notification_container {
        position: fixed;
        top: 4.444vw;
        z-index: 100;
        left: 0px;
        width: 100vw;
    }

    .login_cellphone_form_notification_container .notification_container {
        grid-column: 1 / span 12;
    }

    .login_cellphone_form_left_side_form {
        z-index: 2;
        grid-column: 1 / span 12;
        grid-row: 2;
        width: 100%;
    }

    .login_cellphone_form_background_img_desktop {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .login_cellphone_form_background_img_tablet {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .login_cellphone_form_background_img_mobile {
        display: block;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
    }

    .login_cellphone_form_background_img_desktop img,
    .login_cellphone_form_background_img_tablet img,
    .login_cellphone_form_background_img_mobile img {
        width: calc(100% + 32px);
        transform: translateX(-18px);
        margin-bottom: -1.388vw;
    }

    .login_cellphone_form_container {
        width: calc(100% + 4.444vw);
        margin-left: -2.222vw;
        background-color: white;
        border-radius: 16px;
        padding: 10vw 4.444vw;
        margin-bottom: 35.833vw;
    }

    .login_cellphone_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 6.666vw;
    }

    .login_cellphone_form_logo {
        width: 33.333vw;
        height: 8.611vw;
    }

    .login_cellphone_form_title {
        font-size: 6.666vw;
        font-weight: bold;
        line-height: 8.666vw;
        color: #1C1CA8;
        margin-bottom: 6.666vw;
    }

    .login_cellphone_form_step {
        font-size: 3.888vw;
        font-weight: 900;
        line-height: 5vw;
        color: #FF4600;
        letter-spacing: 0.02em;
    }

    .login_cellphone_form_group {
        margin-bottom: 6.666vw;
        display: flex;
        flex-direction: column;
    }

    .login_cellphone_form_label {
        margin-bottom: 2.222vw;
        font-size: 3.888vw;
        line-height: 6.222vw;
        color: #1C1CA8;
        font-weight: 500;
    }

    .login_cellphone_form_label.error {
        color: #FF1200;
    }

    .login_cellphone_form_input {
        font-size: 4.444vw;
        font-weight: 450;
        line-height: 7.111vw;
        height: 18.333vw;
        background-color: #EEEEF0;
        border-radius: 8px;
        padding: 5.555vw;
        outline: none;
        border: 0px;
    }

    .login_cellphone_form_input.error {
        border: 1px solid #FF1200;
    }

    .login_cellphone_form_error {
        font-size: 3.888vw;
        line-height: 5.833vw;
        font-weight: 500;
        margin-top: 2.222vw;
        color: #FF1200;
    }

    .login_cellphone_form_submit_btn {
        height: 13.888vw;
        background-color: #FF4600;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 9999px;
        padding-left: 5.555vw;
        padding-right: 5.555vw;
    }

    .login_cellphone_form_submit_btn:disabled {
        opacity: 0.6;
    }

    .login_cellphone_form_submit_btn span {
        font-size: 4.444vw;
        color: white;
        line-height: 5.555vw;
        font-weight: bold;
    }

    .login_cellphone_form_submit_btn svg {
        width: 6.111vw;
        height: 6.666vw;
    }

    .login_cellphone_form_footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #5B5B62;
        font-weight: 400;
        font-size: 3.888vw;
        line-height: 5.833vw;
    }

    .login_cellphone_form_footer a {
        text-decoration: underline;
        color: #5B5B62;
    }

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* personal_details_form starts */

    .login_cellphone_form {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        position: relative;
        overflow: hidden;
    }

    .login_cellphone_form_padding {
        padding-top: 10.416vw;
    }

    .login_cellphone_form_notification_container {
        position: fixed;
        top: 3.125vw;
        z-index: 100;
        left: 0px;
        width: 100vw;
    }

    .login_cellphone_form_notification_container .notification_container {
        grid-column: 1 / span 12;
        min-height: 7.291vw;
        gap: 1.302vw;
    }

    .login_cellphone_form_notification_container .notification_container .notification_prefix_icon,
    .login_cellphone_form_notification_container .notification_container .notification_suffix_icon {
        width: 3.125vw;
        height: 3.125vw;
    }

    .login_cellphone_form_notification_container .notification_container .notification_text {
        font-size: 2.083vw;
        line-height: 3.125vw;
    }

    .login_cellphone_form_left_side_form {
        z-index: 2;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .login_cellphone_form_background_img_desktop {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .login_cellphone_form_background_img_tablet {
        display: block;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .login_cellphone_form_background_img_mobile {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .login_cellphone_form_background_img_desktop img,
    .login_cellphone_form_background_img_tablet img,
    .login_cellphone_form_background_img_mobile img {
        width: calc(100% + 24px);
        transform: translateX(-24px);
        position: absolute;
        bottom: 0;
    }

    .login_cellphone_form_container {
        background-color: white;
        border-radius: 16px;
        padding: 4.166vw 3.125vw 3.125vw 3.125vw;
        margin-top: 3.125vw;
        margin-bottom: 8.203vw;
    }

    .login_cellphone_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3.125vw;
    }

    .login_cellphone_form_logo {
        width: 9.114vw;
        height: 2.343vw;
    }

    .login_cellphone_form_title {
        font-size: 3.125vw;
        font-weight: bold;
        line-height: 4.036vw;
        color: #1C1CA8;
        margin-bottom: 2.083vw;
    }

    .login_cellphone_form_step {
        font-size: 1.562vw;
        font-weight: 900;
        line-height: 2.343vw;
        color: #FF4600;
        letter-spacing: 0.02em;
    }

    .login_cellphone_form_group {
        margin-bottom: 2.083vw;
        display: flex;
        flex-direction: column;
    }

    .login_cellphone_form_label {
        margin-bottom: 1.041vw;
        font-size: 1.822vw;
        line-height: 2.864vw;
        color: #1C1CA8;
        font-weight: 500;
    }

    .login_cellphone_form_label.error {
        color: #FF1200;
    }

    .login_cellphone_form_input {
        font-size: 2.083vw;
        font-weight: 450;
        line-height: 3.385vw;
        height: 8.593vw;
        background-color: #EEEEF0;
        border-radius: 8px;
        padding: 2.604vw;
        outline: none;
        border: 0px;
    }

    .login_cellphone_form_input.error {
        border: 1px solid #FF1200;
    }

    .login_cellphone_form_error {
        font-size: 0.972vw;
        line-height: 1.458vw;
        font-weight: 500;
        margin-top: 0.555vw;
        color: #FF1200;
    }

    .login_cellphone_form_submit_btn {
        height: 6.51vw;
        background-color: #FF4600;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 9999px;
        padding-left: 2.604vw;
        padding-right: 2.604vw;
    }

    .login_cellphone_form_submit_btn:disabled {
        opacity: 0.6;
    }

    .login_cellphone_form_submit_btn span {
        font-size: 2.083vw;
        color: white;
        line-height: 2.604vw;
        font-weight: bold;
    }

    .login_cellphone_form_submit_btn svg {
        width: 2.864vw;
        height: 3.125vw;
    }

    .login_cellphone_form_footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #5B5B62;
        font-weight: 400;
        font-size: 1.822vw;
        line-height: 2.734vw;
    }

    .login_cellphone_form_footer a {
        text-decoration: underline;
        color: #5B5B62;
    }

}

@media only screen and (min-width: 601px) and (max-width: 840px) {

    /* personal_details_form starts */

    .login_cellphone_form {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        position: relative;
        overflow: hidden;
    }

    .login_cellphone_form_padding {
        padding-top: 10.416vw;
    }

    .login_cellphone_form_notification_container {
        position: fixed;
        top: 3.125vw;
        z-index: 100;
        left: 0px;
        width: 100vw;
    }

    .login_cellphone_form_notification_container .notification_container {
        grid-column: 1 / span 12;
        min-height: 7.291vw;
        gap: 1.302vw;
    }

    .login_cellphone_form_notification_container .notification_container .notification_prefix_icon,
    .login_cellphone_form_notification_container .notification_container .notification_suffix_icon {
        width: 3.125vw;
        height: 3.125vw;
    }

    .login_cellphone_form_notification_container .notification_container .notification_text {
        font-size: 2.083vw;
        line-height: 3.125vw;
    }

    .login_cellphone_form_left_side_form {
        z-index: 2;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .login_cellphone_form_background_img_desktop {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .login_cellphone_form_background_img_tablet {
        display: block;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .login_cellphone_form_background_img_mobile {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .login_cellphone_form_background_img_desktop img,
    .login_cellphone_form_background_img_tablet img,
    .login_cellphone_form_background_img_mobile img {
        width: calc(100% + 24px);
        transform: translateX(-24px);
        position: absolute;
        bottom: 0;
    }

    .login_cellphone_form_container {
        background-color: white;
        border-radius: 16px;
        padding: 4.166vw 3.125vw 3.125vw 3.125vw;
        margin-top: 3.125vw;
        margin-bottom: 8.203vw;
    }

    .login_cellphone_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3.125vw;
    }

    .login_cellphone_form_logo {
        width: 9.114vw;
        height: 2.343vw;
    }

    .login_cellphone_form_title {
        font-size: 3.125vw;
        font-weight: bold;
        line-height: 4.036vw;
        color: #1C1CA8;
        margin-bottom: 2.083vw;
    }

    .login_cellphone_form_step {
        font-size: 1.562vw;
        font-weight: 900;
        line-height: 2.343vw;
        color: #FF4600;
        letter-spacing: 0.02em;
    }

    .login_cellphone_form_group {
        margin-bottom: 2.083vw;
        display: flex;
        flex-direction: column;
    }

    .login_cellphone_form_label {
        margin-bottom: 1.041vw;
        font-size: 1.822vw;
        line-height: 2.864vw;
        color: #1C1CA8;
        font-weight: 500;
    }

    .login_cellphone_form_label.error {
        color: #FF1200;
    }

    .login_cellphone_form_input {
        font-size: 2.083vw;
        font-weight: 450;
        line-height: 3.385vw;
        height: 8.593vw;
        background-color: #EEEEF0;
        border-radius: 8px;
        padding: 2.604vw;
        outline: none;
        border: 0px;
    }

    .login_cellphone_form_input.error {
        border: 1px solid #FF1200;
    }

    .login_cellphone_form_error {
        font-size: 0.972vw;
        line-height: 1.458vw;
        font-weight: 500;
        margin-top: 0.555vw;
        color: #FF1200;
    }

    .login_cellphone_form_submit_btn {
        height: 6.51vw;
        background-color: #FF4600;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 9999px;
        padding-left: 2.604vw;
        padding-right: 2.604vw;
    }

    .login_cellphone_form_submit_btn:disabled {
        opacity: 0.6;
    }

    .login_cellphone_form_submit_btn span {
        font-size: 2.083vw;
        color: white;
        line-height: 2.604vw;
        font-weight: bold;
    }

    .login_cellphone_form_submit_btn svg {
        width: 2.864vw;
        height: 3.125vw;
    }

    .login_cellphone_form_footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #5B5B62;
        font-weight: 400;
        font-size: 1.822vw;
        line-height: 2.734vw;
    }

    .login_cellphone_form_footer a {
        text-decoration: underline;
        color: #5B5B62;
    }

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* personal_details_form starts */
    .login_cellphone_form {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        position: relative;
        overflow: hidden;
    }

    .login_cellphone_form_padding {
        padding-top: 0px;
    }

    .login_cellphone_form_notification_container {
        position: fixed;
        top: 2.291vw;
        z-index: 100;
        left: 0px;
        width: 100vw;
    }

    .login_cellphone_form_notification_container .notification_container {
        grid-column: 1 / span 12;
    }

    .login_cellphone_form_left_side_form {
        z-index: 2;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .login_cellphone_form_background_img_desktop {
        display: block;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .login_cellphone_form_background_img_tablet {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .login_cellphone_form_background_img_mobile {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .login_cellphone_form_background_img_desktop img,
    .login_cellphone_form_background_img_tablet img,
    .login_cellphone_form_background_img_mobile img {
        width: calc(100% + 96px);
        transform: translateX(-48px);
        position: absolute;
        bottom: 0;
    }

    .login_cellphone_form_container {
        background-color: white;
        border-radius: 16px;
        padding: 2.5vw 1.667vw;
        margin-top: 8.194vw;
        margin-bottom: 20.208vw;
    }

    .login_cellphone_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.667vw;
    }

    .login_cellphone_form_logo {
        width: 8.333vw;
        height: 2.152vw;
    }

    .login_cellphone_form_title {
        font-size: 2.15vw;
        font-weight: bold;
        line-height: 2.79vw;
        color: #1C1CA8;
        margin-bottom: 1.666vw;
    }

    .login_cellphone_form_step {
        font-size: 0.97vw;
        font-weight: 900;
        line-height: 1.458vw;
        color: #FF4600;
        letter-spacing: 0.02em;
    }

    .login_cellphone_form_group {
        margin-bottom: 1.667vw;
        display: flex;
        flex-direction: column;
    }

    .login_cellphone_form_label {
        margin-bottom: 0.555vw;
        font-size: 0.972vw;
        line-height: 1.555vw;
        color: #1C1CA8;
        font-weight: 500;
    }

    .login_cellphone_form_label.error {
        color: #FF1200;
    }

    .login_cellphone_form_input {
        font-size: 1.111vw;
        font-weight: 450;
        line-height: 1.777vw;
        height: 4.583vw;
        background-color: #EEEEF0;
        border-radius: 8px;
        padding: 1.388vw;
        outline: none;
        border: 0px;
    }

    .login_cellphone_form_input.error {
        border: 1px solid #FF1200;
    }

    .login_cellphone_form_error {
        font-size: 0.972vw;
        line-height: 1.458vw;
        font-weight: 500;
        margin-top: 0.555vw;
        color: #FF1200;
    }

    .login_cellphone_form_submit_btn {
        height: 4.583vw;
        background-color: #FF4600;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 9999px;
        padding-left: 1.388vw;
        padding-right: 1.388vw;
    }

    .login_cellphone_form_submit_btn:disabled {
        opacity: 0.6;
    }

    .login_cellphone_form_submit_btn span {
        font-size: 1.111vw;
        color: white;
        line-height: 1.388vw;
        font-weight: bold;
    }

    .login_cellphone_form_submit_btn svg {
        width: 1.527vw;
        height: 1.666vw;
    }

    .login_cellphone_form_footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #5B5B62;
        font-weight: 400;
        font-size: 0.972vw;
        line-height: 1.458vw;
    }

    .login_cellphone_form_footer a {
        text-decoration: underline;
        color: #5B5B62;
    }

}


@media only screen and (min-width: 1441px) {

    /* personal_details_form starts */
    .login_cellphone_form {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        position: relative;
        overflow: hidden;
    }

    .login_cellphone_form_padding {
        padding-top: 0px;
    }

    .login_cellphone_form_notification_container {
        position: fixed;
        top: 33px;
        z-index: 100;
        left: 0px;
        width: 100vw;
    }

    .login_cellphone_form_notification_container .notification_container {
        grid-column: 1 / span 12;
    }

    .login_cellphone_form_left_side_form {
        z-index: 2;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .login_cellphone_form_background_img_desktop {
        display: block;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .login_cellphone_form_background_img_tablet {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .login_cellphone_form_background_img_mobile {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .login_cellphone_form_background_img_desktop img,
    .login_cellphone_form_background_img_tablet img,
    .login_cellphone_form_background_img_mobile img {
        width: calc(100% + 96px);
        transform: translateX(-48px);
        position: absolute;
        bottom: 0;
    }

    .login_cellphone_form_container {
        background-color: white;
        border-radius: 16px;
        padding: 36px 24px;
        margin-bottom: 291px;
        margin-top: 118px;
    }

    .login_cellphone_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
    }

    .login_cellphone_form_logo {
        width: 120px;
        height: 31px;
    }

    .login_cellphone_form_title {
        font-size: 31px;
        font-weight: bold;
        line-height: 40.3px;
        color: #1C1CA8;
        margin-bottom: 24px;
    }

    .login_cellphone_form_step {
        font-size: 14px;
        font-weight: 900;
        line-height: 21px;
        color: #FF4600;
        letter-spacing: 0.02em;
    }

    .login_cellphone_form_group {
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
    }

    .login_cellphone_form_label {
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 22.4px;
        color: #1C1CA8;
        font-weight: 500;
    }

    .login_cellphone_form_label.error {
        color: #FF1200;
    }

    .login_cellphone_form_input {
        font-size: 16px;
        font-weight: 450;
        line-height: 25.6px;
        height: 66px;
        background-color: #EEEEF0;
        border-radius: 8px;
        padding: 20px;
        outline: none;
        border: 0px;
    }

    .login_cellphone_form_input.error {
        border: 1px solid #FF1200;
    }

    .login_cellphone_form_error {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        margin-top: 8px;
        color: #FF1200;
    }

    .login_cellphone_form_submit_btn {
        height: 66px;
        background-color: #FF4600;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 9999px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .login_cellphone_form_submit_btn:disabled {
        opacity: 0.6;
    }

    .login_cellphone_form_submit_btn span {
        font-size: 16px;
        color: white;
        line-height: 20px;
        font-weight: bold;
    }

    .login_cellphone_form_submit_btn svg {
        width: 22px;
        height: 24px;
    }

    .login_cellphone_form_footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #5B5B62;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
    }

    .login_cellphone_form_footer a {
        text-decoration: underline;
        color: #5B5B62;
    }
}