@media only screen and (max-width: 480px) {
    .qr_code_center_content {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
        padding: 0;
        margin-bottom: 4.444vw;
    }

    .qr_code_center_content_statistic_container {
        width: 100%;
        box-shadow: 0px 2px 12px rgb(11 14 26 / 8%);
        box-sizing: border-box;
        border-radius: 16px;
        margin-bottom: 4.722vw;
        background-color: #055CF5;
        padding: 6.111vw 4.444vw 12.5vw 4.444vw;
    }

    .qr_code_center_content_top_user_level_container {
        width: 100%;
        margin-bottom: 27px;
        display: none;
    }

    .qr_code_center_content_top_user_level {
        color: #FFFFFF;
        width: fit-content;
        height: 32px;
        display: flex;
        font-size: 14px;
        align-items: center;
        font-weight: 900;
        line-height: 21px;
        white-space: nowrap;
        padding-left: 15px;
        border-radius: 6px;
        padding-right: 15px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        justify-content: center;
        background-color: #FFFFFF33;
    }

    .qr_code_center_content_statistic_container_div {
        display: flex;
        gap: 9.166vw;
    }

    .qr_code_center_content_statistic_percent_container {
        flex: none;
        width: 34.722vw;
        height: 34.722vw;
        position: relative;
    }

    .qr_code_center_content_statistic_percent_container .MuiCircularProgress-determinate {
        max-width: 100%;
        max-height: 100%;
    }

    .qr_code_center_content_bg_circular_pg {
        top: 0;
        left: 0;
        color: #377df7 !important;
        position: absolute;
    }

    .qr_code_center_content_circular_pg {
        color: #ffffff !important;
    }

    .qr_code_center_content_text_pg_container {
        top: 0;
        left: 0;
        color: #fff;
        right: 0;
        bottom: 0;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
    }

    .qr_code_center_content_statistic_percent_text {
        font-size: 10.833vw;
        font-family: 'Azo Sans', sans-serif !important;
        font-weight: 700;
        line-height: 14.083vw;
        letter-spacing: -0.02em;
    }

    .qr_code_center_content_statistic_percent_symbol {
        font-size: 6.666vw;
        font-family: 'Azo Sans', sans-serif !important;
        font-weight: 700;
        line-height: 9.333vw;
        padding-top: 1.666vw;
        letter-spacing: -0.02em;
    }

    .qr_code_center_content_static_right_container {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
    }

    .qr_code_center_content_static_text_container {
        padding-top: 0px;
    }

    .qr_code_center_content_user_level_mobile {
        display: block;
        width: 100%;
        margin-bottom: 6.388vw;
    }

    .qr_code_center_content_user_level_mobile .qr_code_center_content_user_level {
        display: flex;
    }

    .qr_code_center_content_user_level {
        color: #FFFFFF;
        width: fit-content;
        height: 8.888vw;
        display: none;
        font-size: 3.888vw;
        align-items: center;
        font-weight: 900;
        line-height: 5.833vw;
        white-space: nowrap;
        padding-left: 4.166vw;
        border-radius: 6px;
        margin-bottom: 0px;
        padding-right: 4.166vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        justify-content: center;
        background-color: #FFFFFF33;
    }

    .qr_code_center_content_points_earned {
        color: #FFFFFF;
        font-weight: 700;
        letter-spacing: -0.01em;
        font-size: 5.555vw;
        line-height: 8.333vw;
        margin-bottom: 3.888vw;
    }

    .qr_code_center_content_mobile_enter {
        display: block;
    }

    .qr_code_center_content_tasks_done {
        color: #FFFFFF;
        font-weight: 700;
        letter-spacing: -0.01em;
        font-size: 5.555vw;
        line-height: 8.333vw;
    }

    .qr_code_center_content_statistic_icon_container {
        display: none;
        width: 100px;
        padding-top: 9px;
    }

    .qr_code_center_content_statistic_icon_container img {
        width: 100%;
    }

    .qr_code_center_content_form_container {
        background-color: white;
        border-radius: 16px;
        padding: 24.33px 24px 24px 24px;
    }

    .qr_code_center_content_form_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 31px;
        line-height: 130%;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24.08px;
    }

    .qr_code_center_content_form_category_title {
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
        margin-bottom: 24px;
    }

    .qr_code_center_content_form_category_container {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        margin-bottom: 32px;
        row-gap: 16px;
        column-gap: 54px;
    }

    .qr_code_center_content_form_qr_item {
        display: flex;
        flex-direction: column;
        gap: 8px;
        cursor: pointer;
    }

    .qr_code_center_content_form_qr_item.locked {
        opacity: 0.5;
    }

    .qr_code_center_content_form_qr_item.locked .qr_code_center_content_form_qr_item_image img {
        width: 50px;
        height: 50px;
    }

    .qr_code_center_content_form_qr_item_image {
        width: 168px;
        height: 168px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .qr_code_center_content_form_qr_item_image img {
        width: 136px;
        height: 136px;
    }

    .qr_code_center_content_form_qr_item_description {
        font-weight: 700;
        font-size: 16px;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .qr_code_center_content_form_category_container:last-child {
        margin-bottom: 0px;
    }

    .qr_code_center_content_form_row {
        display: flex;
        gap: 24px;
        margin-bottom: 24px;
    }

    .qr_code_right_content {
        grid-row: 2;
        grid-column: 1 / span 4;
        position: relative;
        z-index: 0;
    }

    .qr_code_right_content_benefit {
        padding: 6.666vw 4.444vw;
        background-color: white;
        border-radius: 16px;
        margin-bottom: 4.444vw;
    }

    .qr_code_right_content_header {
        display: flex;
        gap: 3.333vw;
        align-items: center;
        margin-bottom: 3.333vw;
    }

    .qr_code_right_content_header img {
        width: 6.666vw;
        height: 6.666vw;
    }

    .qr_code_right_content_header span {
        font-weight: 900;
        font-size: 3.888vw;
        line-height: 5.833vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
    }

    .qr_code_right_content_benefit_title {
        font-weight: 500;
        font-size: 5.555vw;
        line-height: 8.333vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 3.333vw;
    }

    .qr_code_right_content_benefits {
        display: flex;
        flex-direction: column;
        gap: 2.777vw;
    }

    .qr_code_right_content_extra_container {
        position: relative;
        padding: 4.444vw;
        background-color: #FF4600;
        border-radius: 16px;
        overflow: hidden;
    }

    .qr_code_right_content_extra_header {
        display: flex;
        gap: 4.444vw;
        align-items: center;
        margin-bottom: 2.777vw;
    }

    .qr_code_right_content_extra_header img {
        width: 15.555vw;
        height: 15.555vw;
    }

    .qr_code_right_content_extra_header span {
        font-weight: 700;
        font-size: 5.555vw;
        line-height: 7.777vw;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .qr_code_right_content_extra_description {
        font-weight: 500;
        font-size: 4.444vw;
        line-height: 6.666vw;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 5vw;
        width: 45.833vw;
    }

    .qr_code_right_content_start_training_btn {
        width: 44.722vw;
        height: 9.444vw;
        background-color: #1C1CA8;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        font-weight: 700;
        font-size: 3.888vw;
        line-height: 6.111vw;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 1.666vw;
    }

    .qr_code_right_content_extra_bottom_circle {
        position: absolute;
        border-radius: 9999px;
        background-color: #1C1CA8;
        width: 44.722vw;
        height: 44.722vw;
        bottom: -7.777vw;
        right: -10vw;
    }

    .qr_code_right_content_bottom_image {
        position: absolute;
        right: -5.161vw;
        width: 34.722vw;
        bottom: -2.777vw;
        height: 50vw;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .qr_code_center_content {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 3 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
        padding: 0;
        margin-bottom: 6.875vw;
    }

    .qr_code_center_content_statistic_container {
        width: 100%;
        box-shadow: 0px 2px 12px rgb(11 14 26 / 8%);
        box-sizing: border-box;
        border-radius: 1.111vw;
        margin-bottom: 1.25vw;
        background-color: #055CF5;
        padding: 2.5vw 4.166vw 2.361vw 2.013vw;
    }

    .qr_code_center_content_top_user_level_container {
        width: 100%;
        margin-bottom: 1.875vw;
        display: none;
    }

    .qr_code_center_content_top_user_level {
        color: #FFFFFF;
        width: fit-content;
        height: 2.222vw;
        display: flex;
        font-size: 0.972vw;
        align-items: center;
        font-weight: 900;
        line-height: 1.458vw;
        white-space: nowrap;
        padding-left: 1.041vw;
        border-radius: 0.41vw;
        padding-right: 1.041vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        justify-content: center;
        background-color: #FFFFFF33;
    }

    .qr_code_center_content_statistic_container_div {
        display: flex;
        gap: 2.847vw;
    }

    .qr_code_center_content_statistic_percent_container {
        flex: none;
        width: 9.513vw;
        height: 9.513vw;
        position: relative;
    }

    .qr_code_center_content_statistic_percent_container .MuiCircularProgress-determinate {
        max-width: 100%;
        max-height: 100%;
    }

    .qr_code_center_content_bg_circular_pg {
        top: 0;
        left: 0;
        color: #377df7 !important;
        position: absolute;
    }

    .qr_code_center_content_circular_pg {
        color: #ffffff !important;
    }

    .qr_code_center_content_text_pg_container {
        top: 0;
        left: 0;
        color: #fff;
        right: 0;
        bottom: 0;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
    }

    .qr_code_center_content_statistic_percent_text {
        font-size: 2.708vw;
        font-family: 'Azo Sans', sans-serif !important;
        font-weight: 700;
        line-height: 3.52vw;
        letter-spacing: -0.02em;
    }

    .qr_code_center_content_statistic_percent_symbol {
        font-size: 1.666vw;
        font-family: 'Azo Sans', sans-serif !important;
        font-weight: 700;
        line-height: 2.333vw;
        padding-top: 0.416vw;
        letter-spacing: -0.02em;
    }

    .qr_code_center_content_static_right_container {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
    }

    .qr_code_center_content_static_text_container {
        padding-top: 0.416vw;
    }

    .qr_code_center_content_user_level_mobile {
        display: none;
        width: 100%;
        margin-bottom: 6.388vw;
    }

    .qr_code_center_content_user_level {
        color: #FFFFFF;
        width: fit-content;
        height: 2.222vw;
        display: flex;
        font-size: .972vw;
        align-items: center;
        font-weight: 900;
        line-height: 1.458vw;
        white-space: nowrap;
        padding-left: 1.041vw;
        border-radius: 0.416vw;
        margin-bottom: 0.833vw;
        padding-right: 1.041vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        justify-content: center;
        background-color: #FFFFFF33;
    }

    .qr_code_center_content_points_earned {
        color: #FFFFFF;
        font-weight: 700;
        letter-spacing: -0.01em;
        font-size: 1.736vw;
        line-height: 2.604vw;
    }

    .qr_code_center_content_mobile_enter {
        display: none;
    }

    .qr_code_center_content_tasks_done {
        color: #FFFFFF;
        font-size: 1.736vw;
        font-weight: 700;
        line-height: 2.604vw;
        letter-spacing: -0.01em;
    }

    .qr_code_center_content_statistic_icon_container {
        display: block;
        width: 6.944vw;
        padding-top: 0.625vw;
    }

    .qr_code_center_content_statistic_icon_container img {
        width: 100%;
    }

    .qr_code_center_content_form_container {
        background-color: white;
        border-radius: 1.111vw;
        padding: 1.689vw 1.666vw 1.666vw 1.666vw;
    }

    .qr_code_center_content_form_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 2.152vw;
        line-height: 130%;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.672vw;
    }

    .qr_code_center_content_form_category_title {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
        margin-bottom: 1.666vw;
    }

    .qr_code_center_content_form_category_container {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        margin-bottom: 2.222vw;
        row-gap: 1.111vw;
        column-gap: 3.75vw;
    }

    .qr_code_center_content_form_qr_item {
        display: flex;
        flex-direction: column;
        gap: 0.555vw;
        cursor: pointer;
    }

    .qr_code_center_content_form_qr_item.locked {
        opacity: 0.5;
    }

    .qr_code_center_content_form_qr_item.locked .qr_code_center_content_form_qr_item_image img {
        width: 3.472vw;
        height: 3.472vw;
    }

    .qr_code_center_content_form_qr_item_image {
        width: 11.666vw;
        height: 11.666vw;
        border-radius: 1.111vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .qr_code_center_content_form_qr_item_image img {
        width: 9.444vw;
        height: 9.444vw;
    }

    .qr_code_center_content_form_qr_item_description {
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .qr_code_center_content_form_category_container:last-child {
        margin-bottom: 0px;
    }

    .qr_code_right_content {
        grid-row: 1;
        grid-column: 7 / span 2;
        position: relative;
        z-index: 0;
    }

    .qr_code_right_content_benefit {
        padding: 1.666vw;
        background-color: white;
        border-radius: 1.111vw;
        margin-bottom: 1.666vw;
    }

    .qr_code_right_content_header {
        display: flex;
        gap: 0.833vw;
        align-items: center;
        margin-bottom: 0.833vw;
    }

    .qr_code_right_content_header img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .qr_code_right_content_header span {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
    }

    .qr_code_right_content_benefit_title {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 0.833vw;
    }

    .qr_code_right_content_benefits {
        display: flex;
        flex-direction: column;
        gap: 0.694vw;
    }

    .qr_code_right_content_extra_container {
        position: relative;
        padding: 1.111vw;
        background-color: #FF4600;
        border-radius: 1.111vw;
        overflow: hidden;
    }

    .qr_code_right_content_extra_header {
        display: flex;
        gap: 1.111vw;
        align-items: center;
        margin-bottom: 0.694vw;
    }

    .qr_code_right_content_extra_header img {
        width: 3.888vw;
        height: 3.888vw;
    }

    .qr_code_right_content_extra_header span {
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 1.944vw;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .qr_code_right_content_extra_description {
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.666vw;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 1.25vw;
        width: 11.458vw;
    }

    .qr_code_right_content_start_training_btn {
        width: 11.18vw;
        height: 2.361vw;
        background-color: #1C1CA8;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.555vw;
        font-weight: 700;
        font-size: 0.972vw;
        line-height: 1.527vw;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 0.416vw;
    }

    .qr_code_right_content_extra_bottom_circle {
        position: absolute;
        border-radius: 9999px;
        background-color: #1C1CA8;
        width: 11.18vw;
        height: 11.18vw;
        bottom: -1.944vw;
        right: -2.5vw;
    }

    .qr_code_right_content_bottom_image {
        position: absolute;
        right: -1.29vw;
        width: 8.68vw;
        bottom: -0.694vw;
        height: 12.5vw;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .qr_code_center_content {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
        padding: 0;
        margin-bottom: 6.875vw;
    }

    .qr_code_center_content_statistic_container {
        width: 100%;
        box-shadow: 0px 2px 12px rgb(11 14 26 / 8%);
        box-sizing: border-box;
        border-radius: 1.111vw;
        margin-bottom: 1.25vw;
        background-color: #055CF5;
        padding: 2.5vw 4.166vw 2.361vw 2.013vw;
    }

    .qr_code_center_content_top_user_level_container {
        width: 100%;
        margin-bottom: 1.875vw;
        display: none;
    }

    .qr_code_center_content_top_user_level {
        color: #FFFFFF;
        width: fit-content;
        height: 2.222vw;
        display: flex;
        font-size: 0.972vw;
        align-items: center;
        font-weight: 900;
        line-height: 1.458vw;
        white-space: nowrap;
        padding-left: 1.041vw;
        border-radius: 0.41vw;
        padding-right: 1.041vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        justify-content: center;
        background-color: #FFFFFF33;
    }

    .qr_code_center_content_statistic_container_div {
        display: flex;
        gap: 2.847vw;
    }

    .qr_code_center_content_statistic_percent_container {
        flex: none;
        width: 9.513vw;
        height: 9.513vw;
        position: relative;
    }

    .qr_code_center_content_statistic_percent_container .MuiCircularProgress-determinate {
        max-width: 100%;
        max-height: 100%;
    }

    .qr_code_center_content_bg_circular_pg {
        top: 0;
        left: 0;
        color: #377df7 !important;
        position: absolute;
    }

    .qr_code_center_content_circular_pg {
        color: #ffffff !important;
    }

    .qr_code_center_content_text_pg_container {
        top: 0;
        left: 0;
        color: #fff;
        right: 0;
        bottom: 0;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
    }

    .qr_code_center_content_statistic_percent_text {
        font-size: 2.708vw;
        font-family: 'Azo Sans', sans-serif !important;
        font-weight: 700;
        line-height: 3.52vw;
        letter-spacing: -0.02em;
    }

    .qr_code_center_content_statistic_percent_symbol {
        font-size: 1.666vw;
        font-family: 'Azo Sans', sans-serif !important;
        font-weight: 700;
        line-height: 2.333vw;
        padding-top: 0.416vw;
        letter-spacing: -0.02em;
    }

    .qr_code_center_content_static_right_container {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
    }

    .qr_code_center_content_static_text_container {
        padding-top: 0.416vw;
    }

    .qr_code_center_content_user_level_mobile {
        display: none;
        width: 100%;
        margin-bottom: 6.388vw;
    }

    .qr_code_center_content_user_level {
        color: #FFFFFF;
        width: fit-content;
        height: 2.222vw;
        display: flex;
        font-size: .972vw;
        align-items: center;
        font-weight: 900;
        line-height: 1.458vw;
        white-space: nowrap;
        padding-left: 1.041vw;
        border-radius: 0.416vw;
        margin-bottom: 0.833vw;
        padding-right: 1.041vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        justify-content: center;
        background-color: #FFFFFF33;
    }

    .qr_code_center_content_points_earned {
        color: #FFFFFF;
        font-weight: 700;
        letter-spacing: -0.01em;
        font-size: 1.736vw;
        line-height: 2.604vw;
    }

    .qr_code_center_content_mobile_enter {
        display: none;
    }

    .qr_code_center_content_tasks_done {
        color: #FFFFFF;
        font-size: 1.736vw;
        font-weight: 700;
        line-height: 2.604vw;
        letter-spacing: -0.01em;
    }

    .qr_code_center_content_statistic_icon_container {
        display: block;
        width: 6.944vw;
        padding-top: 0.625vw;
    }

    .qr_code_center_content_statistic_icon_container img {
        width: 100%;
    }

    .qr_code_center_content_form_container {
        background-color: white;
        border-radius: 1.111vw;
        padding: 1.689vw 1.666vw 1.666vw 1.666vw;
    }

    .qr_code_center_content_form_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 2.152vw;
        line-height: 130%;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.672vw;
    }

    .qr_code_center_content_form_category_title {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
        margin-bottom: 1.666vw;
    }

    .qr_code_center_content_form_category_container {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        margin-bottom: 2.222vw;
        row-gap: 1.111vw;
        column-gap: 3.75vw;
    }

    .qr_code_center_content_form_qr_item {
        display: flex;
        flex-direction: column;
        gap: 0.555vw;
        cursor: pointer;
    }

    .qr_code_center_content_form_qr_item.locked {
        opacity: 0.5;
    }

    .qr_code_center_content_form_qr_item.locked .qr_code_center_content_form_qr_item_image img {
        width: 3.472vw;
        height: 3.472vw;
    }

    .qr_code_center_content_form_qr_item_image {
        width: 11.666vw;
        height: 11.666vw;
        border-radius: 1.111vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .qr_code_center_content_form_qr_item_image img {
        width: 9.444vw;
        height: 9.444vw;
    }

    .qr_code_center_content_form_qr_item_description {
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .qr_code_center_content_form_category_container:last-child {
        margin-bottom: 0px;
    }

    .qr_code_right_content {
        grid-row: 1;
        grid-column: 10 / span 3;
        position: relative;
        z-index: 0;
    }

    .qr_code_right_content_benefit {
        padding: 1.666vw;
        background-color: white;
        border-radius: 1.111vw;
        margin-bottom: 1.666vw;
    }

    .qr_code_right_content_header {
        display: flex;
        gap: 0.833vw;
        align-items: center;
        margin-bottom: 0.833vw;
    }

    .qr_code_right_content_header img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .qr_code_right_content_header span {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
    }

    .qr_code_right_content_benefit_title {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 0.833vw;
    }

    .qr_code_right_content_benefits {
        display: flex;
        flex-direction: column;
        gap: 0.694vw;
    }

    .qr_code_right_content_extra_container {
        position: relative;
        padding: 1.111vw;
        background-color: #FF4600;
        border-radius: 1.111vw;
        overflow: hidden;
    }

    .qr_code_right_content_extra_header {
        display: flex;
        gap: 1.111vw;
        align-items: center;
        margin-bottom: 0.694vw;
    }

    .qr_code_right_content_extra_header img {
        width: 3.888vw;
        height: 3.888vw;
    }

    .qr_code_right_content_extra_header span {
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 1.944vw;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .qr_code_right_content_extra_description {
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.666vw;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 1.25vw;
        width: 11.458vw;
    }

    .qr_code_right_content_start_training_btn {
        width: 11.18vw;
        height: 2.361vw;
        background-color: #1C1CA8;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.555vw;
        font-weight: 700;
        font-size: 0.972vw;
        line-height: 1.527vw;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 0.416vw;
    }

    .qr_code_right_content_extra_bottom_circle {
        position: absolute;
        border-radius: 9999px;
        background-color: #1C1CA8;
        width: 11.18vw;
        height: 11.18vw;
        bottom: -1.944vw;
        right: -2.5vw;
    }

    .qr_code_right_content_bottom_image {
        position: absolute;
        right: -1.29vw;
        width: 8.68vw;
        bottom: -0.694vw;
        height: 12.5vw;
    }
}

@media only screen and (min-width: 1441px) {
    .qr_code_center_content {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
        padding: 0;
        margin-bottom: 99px;
    }

    .qr_code_center_content_statistic_container {
        width: 100%;
        box-shadow: 0px 2px 12px rgb(11 14 26 / 8%);
        box-sizing: border-box;
        border-radius: 16px;
        margin-bottom: 18px;
        background-color: #055CF5;
        padding: 36px 60px 34px 29px;
    }

    .qr_code_center_content_top_user_level_container {
        width: 100%;
        margin-bottom: 27px;
        display: none;
    }

    .qr_code_center_content_top_user_level {
        color: #FFFFFF;
        width: fit-content;
        height: 32px;
        display: flex;
        font-size: 14px;
        align-items: center;
        font-weight: 900;
        line-height: 21px;
        white-space: nowrap;
        padding-left: 15px;
        border-radius: 6px;
        padding-right: 15px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        justify-content: center;
        background-color: #FFFFFF33;
    }

    .qr_code_center_content_statistic_container_div {
        display: flex;
        gap: 41px;
    }

    .qr_code_center_content_statistic_percent_container {
        flex: none;
        width: 137px;
        height: 137px;
        position: relative;
    }

    .qr_code_center_content_statistic_percent_container .MuiCircularProgress-determinate {
        max-width: 100%;
        max-height: 100%;
    }

    .qr_code_center_content_bg_circular_pg {
        top: 0;
        left: 0;
        color: #377df7 !important;
        position: absolute;
    }

    .qr_code_center_content_circular_pg {
        color: #ffffff !important;
    }

    .qr_code_center_content_text_pg_container {
        top: 0;
        left: 0;
        color: #fff;
        right: 0;
        bottom: 0;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
    }

    .qr_code_center_content_statistic_percent_text {
        font-size: 39px;
        font-family: 'Azo Sans', sans-serif !important;
        font-weight: 700;
        line-height: 50.7px;
        letter-spacing: -0.02em;
    }

    .qr_code_center_content_statistic_percent_symbol {
        font-size: 24px;
        font-family: 'Azo Sans', sans-serif !important;
        font-weight: 700;
        line-height: 33.6px;
        padding-top: 6px;
        letter-spacing: -0.02em;
    }

    .qr_code_center_content_static_right_container {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
    }

    .qr_code_center_content_static_text_container {
        padding-top: 6px;
    }

    .qr_code_center_content_user_level_mobile {
        display: none;
        width: 100%;
        margin-bottom: 6.388vw;
    }

    .qr_code_center_content_user_level {
        color: #FFFFFF;
        width: fit-content;
        height: 32px;
        display: flex;
        font-size: 14px;
        align-items: center;
        font-weight: 900;
        line-height: 21px;
        white-space: nowrap;
        padding-left: 15px;
        border-radius: 6px;
        margin-bottom: 12px;
        padding-right: 15px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        justify-content: center;
        background-color: #FFFFFF33;
    }

    .qr_code_center_content_points_earned {
        color: #FFFFFF;
        font-weight: 700;
        letter-spacing: -0.01em;
        font-size: 25px;
        line-height: 37.5px;
    }

    .qr_code_center_content_mobile_enter {
        display: none;
    }

    .qr_code_center_content_tasks_done {
        color: #FFFFFF;
        font-weight: 700;
        line-height: 37.5px;
        letter-spacing: -0.01em;
        font-size: 25px;
        line-height: 37.5px
    }

    .qr_code_center_content_statistic_icon_container {
        display: block;
        width: 100px;
        padding-top: 9px;
    }

    .qr_code_center_content_statistic_icon_container img {
        width: 100%;
    }

    .qr_code_center_content_form_container {
        background-color: white;
        border-radius: 16px;
        padding: 24.33px 24px 24px 24px;
    }

    .qr_code_center_content_form_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 31px;
        line-height: 130%;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24.08px;
    }

    .qr_code_center_content_form_category_title {
        font-weight: 900;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
        margin-bottom: 24px;
    }

    .qr_code_center_content_form_category_container {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        margin-bottom: 32px;
        row-gap: 16px;
        column-gap: 54px;
    }

    .qr_code_center_content_form_qr_item {
        display: flex;
        flex-direction: column;
        gap: 8px;
        cursor: pointer;
    }

    .qr_code_center_content_form_qr_item.locked {
        opacity: 0.5;
    }

    .qr_code_center_content_form_qr_item.locked .qr_code_center_content_form_qr_item_image img {
        width: 50px;
        height: 50px;
    }

    .qr_code_center_content_form_qr_item_image {
        width: 168px;
        height: 168px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .qr_code_center_content_form_qr_item_image img {
        width: 136px;
        height: 136px;
    }

    .qr_code_center_content_form_qr_item_description {
        font-weight: 700;
        font-size: 16px;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .qr_code_center_content_form_category_container:last-child {
        margin-bottom: 0px;
    }

    .qr_code_right_content {
        grid-row: 1;
        grid-column: 10 / span 3;
        position: relative;
        z-index: 0;
    }

    .qr_code_right_content_benefit {
        padding: 24px;
        background-color: white;
        border-radius: 16px;
        margin-bottom: 24px;
    }

    .qr_code_right_content_header {
        display: flex;
        gap: 12px;
        align-items: center;
        margin-bottom: 12px;
    }

    .qr_code_right_content_header img {
        width: 24px;
        height: 24px;
    }

    .qr_code_right_content_header span {
        font-weight: 900;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
    }

    .qr_code_right_content_benefit_title {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 12px;
    }

    .qr_code_right_content_benefits {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .qr_code_right_content_extra_container {
        position: relative;
        padding: 16px;
        background-color: #FF4600;
        border-radius: 16px;
        overflow: hidden;
    }

    .qr_code_right_content_extra_header {
        display: flex;
        gap: 16px;
        align-items: center;
        margin-bottom: 10px;
    }

    .qr_code_right_content_extra_header img {
        width: 56px;
        height: 56px;
    }

    .qr_code_right_content_extra_header span {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .qr_code_right_content_extra_description {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 18px;
        width: 165px
    }

    .qr_code_right_content_start_training_btn {
        width: 161px;
        height: 34px;
        background-color: #1C1CA8;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 6px;
    }

    .qr_code_right_content_extra_bottom_circle {
        position: absolute;
        border-radius: 9999px;
        background-color: #1C1CA8;
        width: 161px;
        height: 161px;
        bottom: -28px;
        right: -36px;
    }

    .qr_code_right_content_bottom_image {
        position: absolute;
        right: -18.58px;
        width: 125px;
        bottom: -10px;
        height: 180px;
    }
}