@media only screen and (max-width: 480px) {
    .training_quiz_review {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_quiz_review_content {
        width: calc(100% + 16px);
        margin-left: -8px;
        padding: 6.666vw 4.444vw;
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 0px;
    }

    .training_quiz_review_title {
        font-weight: 700;
        font-size: 8.611vw;
        line-height: 11.111vw;
        letter-spacing: -0.02em;
        color: #27272F;
        margin-bottom: 6.666vw;
    }

    .training_quiz_review_question_lists {
        display: flex;
        flex-direction: column;
    }

    .training_quiz_review_question {
        border: 1px solid #EEEEF0;
        border-radius: 8px;
    }

    .training_quiz_review_question_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding: 4.444vw;
        height: 14.444vw;
    }

    .training_quiz_review_question_header_left {
        display: flex;
        align-items: center;
    }

    .training_quiz_review_question_header_left_img {
        width: 4.444vw;
        height: 4.444vw;
        margin-right: 3.333vw;
    }

    .training_quiz_review_question_header_left_text {
        font-weight: 700;
        font-size: 4.444vw;
        line-height: 5.555vw;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_quiz_review_question_header_right_img {
        width: 4.444vw;
        height: 4.444vw;
    }

    .training_quiz_review_question_body {
        padding: 2.222vw 4.444vw 4.444vw 4.444vw;
    }

    .training_quiz_review_question_body_text {
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 7.222vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .training_quiz_review_right {
        display: none;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .training_quiz_review {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 3 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_quiz_review_content {
        width: 100%;
        margin-left: 0px;
        padding: 2.777vw;
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 3.055vw;
    }

    .training_quiz_review_title {
        font-weight: 700;
        font-size: 2.152vw;
        line-height: 2.777vw;
        letter-spacing: -0.02em;
        color: #27272F;
        margin-bottom: 1.666vw;
    }

    .training_quiz_review_question_lists {
        display: flex;
        flex-direction: column;
    }

    .training_quiz_review_question {
        border: 1px solid #EEEEF0;
        border-radius: 8px;
    }

    .training_quiz_review_question_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding: 1.111vw;
        height: 3.611vw;
    }

    .training_quiz_review_question_header_left {
        display: flex;
        align-items: center;
    }

    .training_quiz_review_question_header_left_img {
        width: 1.111vw;
        height: 1.111vw;
        margin-right: 0.833vw;
    }

    .training_quiz_review_question_header_left_text {
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_quiz_review_question_header_right_img {
        width: 1.111vw;
        height: 1.111vw;
    }

    .training_quiz_review_question_body {
        padding: 0.555vw 1.111vw 1.111vw 1.111vw;
    }

    .training_quiz_review_question_body_text {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .training_quiz_review_right {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 7 / span 2;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_quiz_review_right_content {
        width: 100%;
        margin-left: 0px;
        padding: 2.222vw 1.944vw;
        background: #FFFFFF;
        border-radius: 16px;
    }

    .training_quiz_review_right_title {
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 1.111vw;
    }

    .training_quiz_review_right_progress {
        display: flex;
        flex-direction: column;
        gap: 0.555vw;
    }

    .training_quiz_review_right_progress_item {
        height: 2.222vw;
        display: flex;
        align-items: center;
        position: relative;
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.666vw;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_quiz_review_right_progress_item.active {
        color: #055CF5;
    }

    .training_quiz_review_right_progress_item:not(.with_icon) {
        padding-left: 1.944vw;
    }

    .training_quiz_review_right_progress_item:not(.with_icon).active::before {
        opacity: 1;
    }

    .training_quiz_review_right_progress_item.complete:not(.with_icon)::before {
        opacity: 1;
    }

    .training_quiz_review_right_progress_item:not(.with_icon)::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0.555vw;
        width: 0.555vw;
        border-radius: 2px;
        height: 100%;
        background-color: #055CF5;
        opacity: 0.2;
    }

    .training_quiz_review_right_progress_item.with_icon {
        gap: 0.277vw;
    }

    .training_quiz_review_right_progress_item.with_icon img {
        width: 1.666vw;
        height: 1.666vw;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .training_quiz_review {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_quiz_review_content {
        width: 100%;
        margin-left: 0px;
        padding: 2.777vw;
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 3.055vw;
    }

    .training_quiz_review_title {
        font-weight: 700;
        font-size: 2.152vw;
        line-height: 2.777vw;
        letter-spacing: -0.02em;
        color: #27272F;
        margin-bottom: 1.666vw;
    }

    .training_quiz_review_question_lists {
        display: flex;
        flex-direction: column;
    }

    .training_quiz_review_question {
        border: 1px solid #EEEEF0;
        border-radius: 8px;
    }

    .training_quiz_review_question_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding: 1.111vw;
        height: 3.611vw;
    }

    .training_quiz_review_question_header_left {
        display: flex;
        align-items: center;
    }

    .training_quiz_review_question_header_left_img {
        width: 1.111vw;
        height: 1.111vw;
        margin-right: 0.833vw;
    }

    .training_quiz_review_question_header_left_text {
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_quiz_review_question_header_right_img {
        width: 1.111vw;
        height: 1.111vw;
    }

    .training_quiz_review_question_body {
        padding: 0.555vw 1.111vw 1.111vw 1.111vw;
    }

    .training_quiz_review_question_body_text {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .training_quiz_review_right {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 10 / span 3;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_quiz_review_right_content {
        width: 100%;
        margin-left: 0px;
        padding: 2.222vw 1.944vw;
        background: #FFFFFF;
        border-radius: 16px;
    }

    .training_quiz_review_right_title {
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 1.111vw;
    }

    .training_quiz_review_right_progress {
        display: flex;
        flex-direction: column;
        gap: 0.555vw;
    }

    .training_quiz_review_right_progress_item {
        height: 2.222vw;
        display: flex;
        align-items: center;
        position: relative;
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.666vw;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_quiz_review_right_progress_item.active {
        color: #055CF5;
    }

    .training_quiz_review_right_progress_item:not(.with_icon) {
        padding-left: 1.944vw;
    }

    .training_quiz_review_right_progress_item:not(.with_icon).active::before {
        opacity: 1;
    }

    .training_quiz_review_right_progress_item.complete:not(.with_icon)::before {
        opacity: 1;
    }

    .training_quiz_review_right_progress_item:not(.with_icon)::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0.555vw;
        width: 0.555vw;
        border-radius: 2px;
        height: 100%;
        background-color: #055CF5;
        opacity: 0.2;
    }

    .training_quiz_review_right_progress_item.with_icon {
        gap: 0.277vw;
    }

    .training_quiz_review_right_progress_item.with_icon img {
        width: 1.666vw;
        height: 1.666vw;
    }
}

@media only screen and (min-width: 1441px) {
    .training_quiz_review {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_quiz_review_content {
        width: 100%;
        margin-left: 0px;
        padding: 40px;
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 44px;
    }

    .training_quiz_review_title {
        font-weight: 700;
        font-size: 31px;
        line-height: 40px;
        letter-spacing: -0.02em;
        color: #27272F;
        margin-bottom: 24px;
    }

    .training_quiz_review_question_lists {
        display: flex;
        flex-direction: column;
    }

    .training_quiz_review_question {
        border: 1px solid #EEEEF0;
        border-radius: 8px;
    }

    .training_quiz_review_question_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding: 16px;
        height: 52px;
    }

    .training_quiz_review_question_header_left {
        display: flex;
        align-items: center;
    }

    .training_quiz_review_question_header_left_img {
        width: 16px;
        height: 16px;
        margin-right: 12px;
    }

    .training_quiz_review_question_header_left_text {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_quiz_review_question_header_right_img {
        width: 16px;
        height: 16px;
    }

    .training_quiz_review_question_body {
        padding: 8px 16px 16px 16px;
    }

    .training_quiz_review_question_body_text {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .training_quiz_review_right {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 10 / span 3;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_quiz_review_right_content {
        width: 100%;
        margin-left: 0px;
        padding: 32px 28px;
        background: #FFFFFF;
        border-radius: 16px;
    }

    .training_quiz_review_right_title {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 16px;
    }

    .training_quiz_review_right_progress {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .training_quiz_review_right_progress_item {
        height: 32px;
        display: flex;
        align-items: center;
        position: relative;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_quiz_review_right_progress_item.active {
        color: #055CF5;
    }

    .training_quiz_review_right_progress_item:not(.with_icon) {
        padding-left: 28px;
    }

    .training_quiz_review_right_progress_item:not(.with_icon).active::before {
        opacity: 1;
    }

    .training_quiz_review_right_progress_item.complete:not(.with_icon)::before {
        opacity: 1;
    }

    .training_quiz_review_right_progress_item:not(.with_icon)::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 8px;
        width: 8px;
        border-radius: 2px;
        height: 100%;
        background-color: #055CF5;
        opacity: 0.2;
    }

    .training_quiz_review_right_progress_item.with_icon {
        gap: 4px;
    }

    .training_quiz_review_right_progress_item.with_icon img {
        width: 24px;
        height: 24px;
    }
}