@media only screen and (max-width: 480px) {
    .task_buy_starter_kit_payment_body {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        padding-top: 4.444vw;
        padding-bottom: 3.055vw;
        background-color: #EEEEF0;
    }

    .task_buy_starter_kit_payment_main {
        width: calc(100% + 16px);
        margin-left: -8px;
        grid-column: 1 / span 4;
        grid-row: 1;
    }

    .task_buy_starter_kit_payment_main_container {
        position: relative;
        background-color: white;
        border-radius: 4.444vw;
        padding: 9.722vw 4.444vw 9.166vw 4.444vw;
        margin-bottom: 4.444vw;
    }

    .task_buy_starter_kit_payment_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 9.166vw;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 4.444vw;
    }

    .task_buy_starter_kit_payment_description {
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 2.777vw;
    }

    .task_buy_starter_kit_payment_pin_container {
        display: flex;
        flex-direction: column;
    }

    .task_buy_starter_kit_payment_pin_input {
        width: 100%;
    }

    .task_buy_starter_kit_payment_pin_input_title {
        font-weight: 400;
        font-size: 5.555vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 3.333vw;
    }

    .task_buy_starter_kit_payment_pin_input_container {
        display: flex;
        border-radius: 2.222vw;
        overflow: hidden;
    }

    .task_buy_starter_kit_payment_pin_input_image {
        width: 16.944vw;
        height: 16.944vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .task_buy_starter_kit_payment_pin_input_image.one_for_you {
        padding: 4.33vw 2.447vw 3.952vw 2.447vw;
        background-color: #FF4600;
    }

    .task_buy_starter_kit_payment_pin_input_image.one_for_you img {
        width: 12.05vw;
        height: 8.661vw;
    }

    .task_buy_starter_kit_payment_pin_input_image.flash {
        padding: 4.166vw 4.444vw;
        background-color: #C2F83C;
    }

    .task_buy_starter_kit_payment_pin_input_image.flash img {
        width: 8.055vw;
        height: 8.611vw;
    }

    .task_buy_starter_kit_payment_pin_input_wrapper {
        display: flex;
        align-items: center;
        height: 16.944vw;
        flex-grow: 1;
        background-color: #EEEEF0;
        padding: 5.277vw 7.5vw 4.444vw 7.5vw;
    }

    .task_buy_starter_kit_payment_pin_input_wrapper input {
        border: 0px;
        outline: none;
        font-weight: 700;
        font-size: 4.444vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #27272F;
        text-align: left;
        background-color: transparent;
    }

    .task_buy_starter_kit_payment_pin_or {
        width: 100%;
        height: 20vw;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 4.444vw;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .task_buy_starter_kit_payment_pin_or::before {
        content: "";
        width: 35.138vw;
        height: 1px;
        background-color: #DDDEE1;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 1.388vw;
    }

    .task_buy_starter_kit_payment_pin_or::after {
        content: "";
        width: 35.138vw;
        height: 1px;
        background-color: #DDDEE1;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1.388vw;
    }

    .task_buy_starter_kit_payment_footer {
        background-color: white;
        box-shadow: 0px 2px 12px rgba(11, 14, 26, 0.08);
        width: 100vw;
        border-top: 1px solid #DDDEE1;
        position: fixed;
        left: 0px;
        bottom: 0px;
        padding-top: 3.611vw;
        padding-bottom: 2.777vw;
        padding-right: 6.388vw;
        padding-left: 6.388vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 1;
    }

    .task_buy_starter_kit_payment_total_price {
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 150%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .task_buy_starter_kit_payment_footer_btn {
        width: 42.777vw !important;
    }

    .task_buy_starter_kit_payment_tips {
        width: calc(100% + 16px);
        margin-left: -8px;
        grid-column: 1 / span 4;
        grid-row: 2;
    }

    .task_buy_starter_kit_payment_tips_container {
        background-color: white;
        border-radius: 4.444vw;
        padding: 6.666vw 4.444vw;
    }

    .task_buy_starter_kit_payment_tip_header {
        display: flex;
        align-items: center;
        gap: 3.333vw;
        margin-bottom: 3.333vw;
    }

    .task_buy_starter_kit_payment_tip_header img {
        width: 6.666vw;
        height: 6.666vw;
        flex: none;
    }

    .task_buy_starter_kit_payment_tip_header span {
        font-weight: 900;
        font-size: 3.888vw;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
    }

    .task_buy_starter_kit_payment_tip_title {
        font-weight: 500;
        font-size: 5.555vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 3.333vw;
    }

    .task_buy_starter_kit_payment_tip_lists {
        display: flex;
        flex-direction: column;
        gap: 2.777vw;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .task_buy_starter_kit_payment_body {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        padding-top: 1.736vw;
        padding-bottom: 9.027vw;
        background-color: #EEEEF0;
    }

    .task_buy_starter_kit_payment_main {
        grid-column: 1 / span 6;
        grid-row: 1;
    }

    .task_buy_starter_kit_payment_main_container {
        position: relative;
        background-color: white;
        border-radius: 1.111vw;
        padding: 3.541vw 3.333vw 12.708vw 3.333vw;
    }

    .task_buy_starter_kit_payment_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 2.708vw;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 0.833vw;
    }

    .task_buy_starter_kit_payment_description {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 1.527vw;
        max-width: 34.513vw;
    }

    .task_buy_starter_kit_payment_pin_container {
        display: flex;
        align-items: flex-end;
    }

    .task_buy_starter_kit_payment_pin_input {
        width: 21.597vw;
    }

    .task_buy_starter_kit_payment_pin_input_title {
        font-weight: 400;
        font-size: 1.388vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 0.972vw;
    }

    .task_buy_starter_kit_payment_pin_input_container {
        display: flex;
        border-radius: 0.555vw;
        overflow: hidden;
    }

    .task_buy_starter_kit_payment_pin_input_image {
        width: 4.236vw;
        height: 4.236vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .task_buy_starter_kit_payment_pin_input_image.one_for_you {
        padding: 1.082vw 0.611vw 0.988vw 0.611vw;
        background-color: #FF4600;
    }

    .task_buy_starter_kit_payment_pin_input_image.one_for_you img {
        width: 3.012vw;
        height: 2.165vw;
    }

    .task_buy_starter_kit_payment_pin_input_image.flash {
        padding: 1.041vw 1.111vw;
        background-color: #C2F83C;
    }

    .task_buy_starter_kit_payment_pin_input_image.flash img {
        width: 2.013vw;
        height: 2.152vw;
    }

    .task_buy_starter_kit_payment_pin_input_wrapper {
        display: flex;
        align-items: center;
        height: 4.236vw;
        flex-grow: 1;
        background-color: #EEEEF0;
        padding: 1.319vw 1.875vw 1.111vw 1.875vw;
    }

    .task_buy_starter_kit_payment_pin_input_wrapper input {
        border: 0px;
        outline: none;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #27272F;
        text-align: left;
        background-color: transparent;
    }

    .task_buy_starter_kit_payment_pin_or {
        width: 6.805vw;
        height: 4.236vw;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .task_buy_starter_kit_payment_footer {
        width: 100%;
        border-top: 1px solid #DDDEE1;
        position: absolute;
        left: 0px;
        bottom: 0px;
        padding-top: 0.833vw;
        padding-bottom: 0.902vw;
        padding-right: 3.333vw;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1.18vw;
    }

    .task_buy_starter_kit_payment_total_price {
        font-weight: 400;
        font-size: 1.388vw;
        line-height: 150%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .task_buy_starter_kit_payment_footer_btn {
        width: 12.5vw !important;
    }

    .task_buy_starter_kit_payment_tips {
        grid-column: 7 / span 2;
        grid-row: 1;
    }

    .task_buy_starter_kit_payment_tips_container {
        background-color: white;
        border-radius: 1.111vw;
        padding: 1.666vw;
    }

    .task_buy_starter_kit_payment_tip_header {
        display: flex;
        align-items: center;
        gap: 0.833vw;
        margin-bottom: 0.833vw;
    }

    .task_buy_starter_kit_payment_tip_header img {
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .task_buy_starter_kit_payment_tip_header span {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
    }

    .task_buy_starter_kit_payment_tip_title {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 0.833vw;
    }

    .task_buy_starter_kit_payment_tip_lists {
        display: flex;
        flex-direction: column;
        gap: 0.694vw;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .task_buy_starter_kit_payment_body {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        padding-top: 1.736vw;
        padding-bottom: 9.027vw;
        background-color: #EEEEF0;
    }

    .task_buy_starter_kit_payment_main {
        grid-column: 1 / span 9;
        grid-row: 1;
    }

    .task_buy_starter_kit_payment_main_container {
        position: relative;
        background-color: white;
        border-radius: 1.111vw;
        padding: 3.541vw 3.333vw 12.708vw 3.333vw;
    }

    .task_buy_starter_kit_payment_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 2.708vw;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 0.833vw;
    }

    .task_buy_starter_kit_payment_description {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 1.527vw;
        max-width: 34.513vw;
    }

    .task_buy_starter_kit_payment_pin_container {
        display: flex;
        align-items: flex-end;
    }

    .task_buy_starter_kit_payment_pin_input {
        width: 21.597vw;
    }

    .task_buy_starter_kit_payment_pin_input_title {
        font-weight: 400;
        font-size: 1.388vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 0.972vw;
    }

    .task_buy_starter_kit_payment_pin_input_container {
        display: flex;
        border-radius: 0.555vw;
        overflow: hidden;
    }

    .task_buy_starter_kit_payment_pin_input_image {
        width: 4.236vw;
        height: 4.236vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .task_buy_starter_kit_payment_pin_input_image.one_for_you {
        padding: 1.082vw 0.611vw 0.988vw 0.611vw;
        background-color: #FF4600;
    }

    .task_buy_starter_kit_payment_pin_input_image.one_for_you img {
        width: 3.012vw;
        height: 2.165vw;
    }

    .task_buy_starter_kit_payment_pin_input_image.flash {
        padding: 1.041vw 1.111vw;
        background-color: #C2F83C;
    }

    .task_buy_starter_kit_payment_pin_input_image.flash img {
        width: 2.013vw;
        height: 2.152vw;
    }

    .task_buy_starter_kit_payment_pin_input_wrapper {
        display: flex;
        align-items: center;
        height: 4.236vw;
        flex-grow: 1;
        background-color: #EEEEF0;
        padding: 1.319vw 1.875vw 1.111vw 1.875vw;
    }

    .task_buy_starter_kit_payment_pin_input_wrapper input {
        border: 0px;
        outline: none;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #27272F;
        text-align: left;
        background-color: transparent;
    }

    .task_buy_starter_kit_payment_pin_or {
        width: 6.805vw;
        height: 4.236vw;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .task_buy_starter_kit_payment_footer {
        width: 100%;
        border-top: 1px solid #DDDEE1;
        position: absolute;
        left: 0px;
        bottom: 0px;
        padding-top: 0.833vw;
        padding-bottom: 0.902vw;
        padding-right: 3.333vw;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1.18vw;
    }

    .task_buy_starter_kit_payment_total_price {
        font-weight: 400;
        font-size: 1.388vw;
        line-height: 150%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .task_buy_starter_kit_payment_footer_btn {
        width: 12.5vw !important;
    }

    .task_buy_starter_kit_payment_tips {
        grid-column: 10 / span 3;
        grid-row: 1;
    }

    .task_buy_starter_kit_payment_tips_container {
        background-color: white;
        border-radius: 1.111vw;
        padding: 1.666vw;
    }

    .task_buy_starter_kit_payment_tip_header {
        display: flex;
        align-items: center;
        gap: 0.833vw;
        margin-bottom: 0.833vw;
    }

    .task_buy_starter_kit_payment_tip_header img {
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .task_buy_starter_kit_payment_tip_header span {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
    }

    .task_buy_starter_kit_payment_tip_title {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 0.833vw;
    }

    .task_buy_starter_kit_payment_tip_lists {
        display: flex;
        flex-direction: column;
        gap: 0.694vw;
    }
}

@media only screen and (min-width: 1441px) {
    .task_buy_starter_kit_payment_body {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        padding-top: 25px;
        padding-bottom: 130px;
        background-color: #EEEEF0;
    }

    .task_buy_starter_kit_payment_main {
        grid-column: 1 / span 9;
        grid-row: 1;
    }

    .task_buy_starter_kit_payment_main_container {
        position: relative;
        background-color: white;
        border-radius: 16px;
        padding: 51px 48px 183px 48px;
    }

    .task_buy_starter_kit_payment_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 39px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 12px;
    }

    .task_buy_starter_kit_payment_description {
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 22px;
        max-width: 497px;
    }

    .task_buy_starter_kit_payment_pin_container {
        display: flex;
        align-items: flex-end;
    }

    .task_buy_starter_kit_payment_pin_input {
        width: 311px;
    }

    .task_buy_starter_kit_payment_pin_input_title {
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 14px;
    }

    .task_buy_starter_kit_payment_pin_input_container {
        display: flex;
        border-radius: 8px;
        overflow: hidden;
    }

    .task_buy_starter_kit_payment_pin_input_image {
        width: 61px;
        height: 61px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .task_buy_starter_kit_payment_pin_input_image.one_for_you {
        padding: 15.59px 8.81px 14.23px 8.81px;
        background-color: #FF4600;
    }

    .task_buy_starter_kit_payment_pin_input_image.one_for_you img {
        width: 43.38px;
        height: 31.18px;
    }

    .task_buy_starter_kit_payment_pin_input_image.flash {
        padding: 15px 16px;
        background-color: #C2F83C;
    }

    .task_buy_starter_kit_payment_pin_input_image.flash img {
        width: 29px;
        height: 31px;
    }

    .task_buy_starter_kit_payment_pin_input_wrapper {
        display: flex;
        align-items: center;
        height: 61px;
        flex-grow: 1;
        background-color: #EEEEF0;
        padding: 19px 27px 16px 27px;
    }

    .task_buy_starter_kit_payment_pin_input_wrapper input {
        border: 0px;
        outline: none;
        font-weight: 700;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #27272F;
        text-align: left;
        background-color: transparent;
    }

    .task_buy_starter_kit_payment_pin_or {
        width: 98px;
        height: 61px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 16px;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .task_buy_starter_kit_payment_footer {
        width: 100%;
        border-top: 1px solid #DDDEE1;
        position: absolute;
        left: 0px;
        bottom: 0px;
        padding-top: 12px;
        padding-bottom: 13px;
        padding-right: 48px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 17px;
    }

    .task_buy_starter_kit_payment_total_price {
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .task_buy_starter_kit_payment_footer_btn {
        width: 180px !important;
    }

    .task_buy_starter_kit_payment_tips {
        grid-column: 10 / span 3;
        grid-row: 1;
    }

    .task_buy_starter_kit_payment_tips_container {
        background-color: white;
        border-radius: 16px;
        padding: 24px;
    }

    .task_buy_starter_kit_payment_tip_header {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-bottom: 12px;
    }

    .task_buy_starter_kit_payment_tip_header img {
        width: 24px;
        height: 24px;
        flex: none;
    }

    .task_buy_starter_kit_payment_tip_header span {
        font-weight: 900;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
    }

    .task_buy_starter_kit_payment_tip_title {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 12px;
    }

    .task_buy_starter_kit_payment_tip_lists {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}