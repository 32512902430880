@media only screen and (max-width: 480px) {
    .task_submit_id_body {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        padding-top: 4.444vw;
        padding-bottom: 2.222vw;
        background-color: #EEEEF0;
    }

    .task_submit_id_main {
        width: calc(100% + 16px);
        margin-left: -8px;
        grid-column: 1 / span 4;
        grid-row: 1;
        margin-bottom: 4.444vw;
    }

    .task_submit_id_main_container {
        background-color: white;
        border-radius: 16px;
        padding: 13.055vw 4.444vw 5.277vw 4.444vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .task_submit_id_main_icon {
        width: 24.444vw;
        height: 20vw;
        margin-bottom: 7.313vw;
    }

    .task_submit_id_main_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 9.166vw;
        line-height: 110%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 4.444vw;
    }

    .task_submit_id_main_description {
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 7.777vw;
    }

    .task_submit_id_main_form_group {
        margin-bottom: 5.555vw;
        width: 100%;
    }

    .task_submit_id_main_form_label {
        font-weight: 400;
        font-size: 3.888vw;
        line-height: 160%;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        margin-bottom: 2.222vw;
    }

    .task_submit_id_main_form_label.danger {
        color: #FF1200 !important;
    }

    .task_submit_id_main_form_control {
        width: 100%;
    }

    .task_submit_id_main_form_control.dropdown {
        border: none;
        background-color: #EEEEF0;
    }

    .task_submit_id_main_form_control.dropdown.danger .dropdown-select {
        border: 1px solid red;
    }

    .task_submit_id_main_form_control.dropdown .dropdown-select {
        border-radius: inherit;
        padding: 5.555vw;
        height: 18.333vw;
    }

    .task_submit_id_main_form_control_danger_message {
        font-size: 3.888vw;
        line-height: 150%;
        color: #FF1200;
        margin-top: 2.222vw;
    }

    .task_submit_id_main_upload_btn_wrap {
        width: 100%;
        margin-bottom: 6.666vw;
    }

    .task_submit_id_main_upload_btn {
        height: 13.888vw;
        background-color: #055CF5;
        border-radius: 9999px;
        border: 0px;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 4.166vw;
        padding-right: 4.166vw;
        position: relative;
        width: 69.444vw;
        margin: auto;
    }

    .task_submit_id_main_upload_btn img {
        width: 6.666vw;
        height: 6.666vw;
        flex: none;
    }

    .task_submit_id_main_upload_btn div {
        position: absolute;
        top: 50%;
        left: 0px;
        transform: translateY(-50%);
        width: 100%;
        font-weight: 700;
        font-size: 4.444vw;
        line-height: 125%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .task_submit_id_main_next_steps {
        background-color: #055cf51a;
        padding: 4.069vw 4.444vw 7.777vw 4.444vw;
        border-radius: 16px;
        border: 1px solid #EEEEF0;
    }

    .task_submit_id_main_next_step_title {
        font-weight: 500;
        font-size: 5.555vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 4.444vw;
    }

    .task_submit_id_main_next_step_lists {
        margin-bottom: 3.986vw;
        display: flex;
        flex-direction: column;
        gap: 1.944vw;
    }

    .task_submit_id_main_next_step_list {
        display: flex;
        align-items: center;
        gap: 3.333vw;
    }

    .task_submit_id_main_next_step_list img {
        width: 6.666vw;
        height: 6.666vw;
        flex: none;
    }

    .task_submit_id_main_next_step_list span {
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .task_submit_id_main_next_step_description {
        font-weight: 400;
        font-size: 3.888vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .task_submit_id_tips {
        width: calc(100% + 16px);
        margin-left: -8px;
        grid-column: 1 / span 4;
        grid-row: 2;
    }

    .task_submit_id_tips_container {
        background-color: white;
        border-radius: 4.444vw;
        padding: 6.666vw 4.444vw;
    }

    .task_submit_id_tip_header {
        display: flex;
        align-items: center;
        gap: 3.333vw;
        margin-bottom: 3.333vw;
    }

    .task_submit_id_tip_header img {
        width: 6.666vw;
        height: 6.666vw;
        flex: none;
    }

    .task_submit_id_tip_header span {
        font-weight: 900;
        font-size: 3.888vw;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
    }

    .task_submit_id_tip_title {
        font-weight: 500;
        font-size: 5.555vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 3.333vw;
    }

    .task_submit_id_tip_lists {
        display: flex;
        flex-direction: column;
        gap: 2.777vw;
    }

    .task_submit_id_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        height: 100vh;
    }

    .task_submit_id_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .task_submit_id_modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 363px;
        padding: 22px 19px 33px 19px;
        background-color: white;
        border-radius: 16px;
    }

    .task_submit_id_modal_selfie_container {
        width: 325px;
        height: 325px;
        position: relative;
        border-radius: 6px;
        overflow: hidden;
        margin-bottom: 24px;
    }

    .task_submit_id_modal_actions {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 18px;
        align-items: center;
    }

    .task_submit_id_modal_set_btn {
        width: 200px !important;
    }

    .task_submit_id_modal_set_btn span {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .task_submit_id_modal_cancel_btn {
        border: 0px;
        outline: none;
        background-color: transparent;
        width: fit-content;
        height: fit-content;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .task_submit_id_main_action_submit {
        width: 206px !important;
        margin: auto;
    }

    .task_submit_id_main_image_section {
        position: relative;
        width: 77.222vw;
        height: 49.166vw;
        margin: auto;
        margin-top: 0px;
        margin-bottom: 6.666vw;
    }

    .task_submit_id_main_image {
        width: 100%;
        height: 100%;
    }

    .task_submit_id_main_image_delete_btn {
        position: absolute;
        width: 8.888vw;
        height: 8.888vw;
        right: -4.444vw;
        top: -2.5vw;
        background-color: #FF1200;
        border-radius: 9999px;
        border: 0px;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .task_submit_id_main_image_delete_btn img {
        width: 6.666vw;
        height: 6.666vw;
    }

    .task_submit_id_main_image_description {
        font-weight: 400;
        font-size: 3.888vw;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        margin-bottom: 6.666vw;
        text-align: center;
        color: #5B5B62;
    }


    .task_submit_id_main_notification {
        margin-bottom: 35px;
    }

    .task_submit_id_main_submitted_form {
        display: flex;
        flex-direction: column;
        gap: 6.944vw;
        margin-bottom: 10vw;
        width: 100%;
    }

    .task_submit_id_main_submitted_form_group {
        width: 100%;
    }

    .task_submit_id_main_submitted_form_label {
        font-weight: 500;
        font-size: 3.888vw;
        line-height: 160%;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .task_submit_id_main_submitted_form_field {
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 160%;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .task_submit_id_main_submitted_form_main_image {
        margin-bottom: 6.666vw;
        display: flex;
        justify-content: center;
    }

    .task_submit_id_main_submitted_form_main_image img {
        width: 77.222vw;
        height: 49.166vw;
    }

    .task_submit_id_main_image_description a {
        color: #5B5B62;
        text-decoration: underline;
    }

    .task_submit_id_main_submit_btn {
        width: 69.444vw;
        height: 13.888vw;
        background-color: #055CF5;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 4.444vw;
        line-height: 125%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 6.666vw;
        border: 0px;
        outline: none;
    }

    .task_submit_id_main_notification {
        margin-bottom: 6.666vw;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .task_submit_id_body {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        padding-top: 1.736vw;
        padding-bottom: 11.458vw;
        background-color: #EEEEF0;
    }

    .task_submit_id_main {
        grid-column: 2 / span 4;
        grid-row: 1;
    }

    .task_submit_id_main_container {
        background-color: white;
        border-radius: 1.111vw;
        padding: 2.777vw 3.958vw 3.263vw 3.958vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .task_submit_id_main_icon {
        width: 5.833vw;
        height: 5vw;
        margin-bottom: 1.111vw;
    }

    .task_submit_id_main_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 3.333vw;
        line-height: 3.68vw;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.111vw;
    }

    .task_submit_id_main_description {
        font-weight: 400;
        font-size: 1.388vw;
        line-height: 2.083vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 1.111vw;
    }

    .task_submit_id_main_form_group {
        margin-bottom: 1.388vw;
        width: 100%;
    }

    .task_submit_id_main_form_label {
        font-weight: 400;
        font-size: 0.972vw;
        line-height: 1.527vw;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        margin-bottom: 0.555vw;
    }

    .task_submit_id_main_form_label.danger {
        color: #FF1200 !important;
    }

    .task_submit_id_main_form_control {
        width: 100%;
    }

    .task_submit_id_main_form_control.dropdown {
        border: none;
        background-color: #EEEEF0;
    }

    .task_submit_id_main_form_control.dropdown.danger .dropdown-select {
        border: 1px solid red;
    }

    .task_submit_id_main_form_control.dropdown .dropdown-select {
        border-radius: inherit;
        padding: 1.388vw;
        height: 4.583vw;
    }

    .task_submit_id_main_form_control_danger_message {
        font-size: 0.972vw;
        line-height: 150%;
        color: #FF1200;
        margin-top: 0.555vw;
    }

    .task_submit_id_main_upload_btn_wrap {
        width: 100%;
        margin-bottom: 1.666vw;
    }

    .task_submit_id_main_upload_btn {
        height: 3.472vw;
        background-color: #055CF5;
        border-radius: 9999px;
        border: 0px;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 1.041vw;
        padding-right: 1.041vw;
        position: relative;
        width: 100%;
        margin: auto;
    }

    .task_submit_id_main_upload_btn img {
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .task_submit_id_main_upload_btn div {
        position: absolute;
        top: 50%;
        left: 0px;
        transform: translateY(-50%);
        width: 100%;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .task_submit_id_main_next_steps {
        background-color: #055cf51a;
        padding: 1.017vw 1.736vw 0.763vw 1.736vw;
        border-radius: 1.111vw;
        border: 1px solid #EEEEF0;
    }

    .task_submit_id_main_next_step_title {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 1.111vw;
    }

    .task_submit_id_main_next_step_lists {
        margin-bottom: 1.111vw;
        display: flex;
        flex-direction: column;
        gap: 0.486vw;
    }

    .task_submit_id_main_next_step_list {
        display: flex;
        align-items: center;
        gap: 0.833vw;
    }

    .task_submit_id_main_next_step_list img {
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .task_submit_id_main_next_step_list span {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .task_submit_id_main_next_step_description {
        font-weight: 400;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .task_submit_id_tips {
        grid-column: 6 / span 2;
        grid-row: 1;
    }

    .task_submit_id_tips_container {
        background-color: white;
        border-radius: 1.111vw;
        padding: 1.666vw;
    }

    .task_submit_id_tip_header {
        display: flex;
        align-items: center;
        gap: 0.833vw;
        margin-bottom: 0.833vw;
    }

    .task_submit_id_tip_header img {
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .task_submit_id_tip_header span {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
    }

    .task_submit_id_tip_title {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 0.833vw;
    }

    .task_submit_id_tip_lists {
        display: flex;
        flex-direction: column;
        gap: 0.694vw;
    }

    .task_submit_id_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        height: 100vh;
    }

    .task_submit_id_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .task_submit_id_modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 17.916vw;
        left: 50%;
        transform: translateX(-50%);
        width: 25.208vw;
        padding: 1.52vw 1.319vw 2.291vw 1.319vw;
        background-color: white;
        border-radius: 1.111vw;
    }

    .task_submit_id_modal_selfie_container {
        width: 22.569vw;
        height: 22.569vw;
        position: relative;
        border-radius: 0.416vw;
        overflow: hidden;
        margin-bottom: 1.666vw;
    }

    .task_submit_id_modal_actions {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1.25vw;
        align-items: center;
    }

    .task_submit_id_modal_set_btn {
        width: 13.888vw !important;
    }

    .task_submit_id_modal_set_btn span {
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .task_submit_id_modal_cancel_btn {
        border: 0px;
        outline: none;
        background-color: transparent;
        width: fit-content;
        height: fit-content;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .task_submit_id_main_action_submit {
        width: 14.305vw !important;
        margin: auto;
    }

    .task_submit_id_main_image_section {
        position: relative;
        width: 19.305vw;
        height: 12.291vw;
        margin: auto;
        margin-top: 0.208vw;
        margin-bottom: 0.763vw;
    }

    .task_submit_id_main_image {
        width: 100%;
        height: 100%;
    }

    .task_submit_id_main_image_delete_btn {
        position: absolute;
        width: 2.222vw;
        height: 2.222vw;
        right: -1.111vw;
        top: -0.625vw;
        background-color: #FF1200;
        border-radius: 9999px;
        border: 0px;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .task_submit_id_main_image_delete_btn img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .task_submit_id_main_image_description {
        font-weight: 400;
        font-size: 0.972vw;
        line-height: 1.458vw;
        text-align: center;
        letter-spacing: -0.01em;
        margin-bottom: 1.666vw;
        text-align: center;
        color: #5B5B62;
    }


    .task_submit_id_main_notification {
        margin-bottom: 2.43vw;
    }

    .task_submit_id_main_submitted_form {
        display: flex;
        flex-direction: column;
        gap: 1.875vw;
        margin-bottom: 3.125vw;
        width: 100%;
    }

    .task_submit_id_main_submitted_form_group {
        width: 100%;
    }

    .task_submit_id_main_submitted_form_label {
        font-weight: 500;
        font-size: 0.972vw;
        line-height: 1.527vw;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        margin-bottom: 0.486vw;
    }

    .task_submit_id_main_submitted_form_field {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .task_submit_id_main_submitted_form_main_image {
        margin-bottom: 2.083vw;
        display: flex;
        justify-content: center;
    }

    .task_submit_id_main_submitted_form_main_image img {
        width: 19.305vw;
        height: 12.291vw;
    }

    .task_submit_id_main_image_description a {
        color: #5B5B62;
        text-decoration: underline;
    }

    .task_submit_id_main_submit_btn {
        width: 100%;
        height: 3.472vw;
        background-color: #055CF5;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 1.666vw;
        border: 0px;
        outline: none;
    }

    .task_submit_id_main_notification {
        margin-bottom: 1.666vw;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .task_submit_id_body {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        padding-top: 1.736vw;
        padding-bottom: 11.458vw;
        background-color: #EEEEF0;
    }

    .task_submit_id_main {
        grid-column: 3 / span 5;
        grid-row: 1;
    }

    .task_submit_id_main_container {
        background-color: white;
        border-radius: 1.111vw;
        padding: 2.777vw 3.958vw 3.263vw 3.958vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .task_submit_id_main_icon {
        width: 5.833vw;
        height: 5vw;
        margin-bottom: 1.111vw;
    }

    .task_submit_id_main_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 3.333vw;
        line-height: 3.68vw;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.111vw;
    }

    .task_submit_id_main_description {
        font-weight: 400;
        font-size: 1.388vw;
        line-height: 2.083vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 1.111vw;
    }

    .task_submit_id_main_form_group {
        margin-bottom: 1.388vw;
        width: 100%;
    }

    .task_submit_id_main_form_label {
        font-weight: 400;
        font-size: 0.972vw;
        line-height: 1.527vw;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        margin-bottom: 0.555vw;
    }

    .task_submit_id_main_form_label.danger {
        color: #FF1200 !important;
    }

    .task_submit_id_main_form_control {
        width: 100%;
    }

    .task_submit_id_main_form_control.dropdown {
        border: none;
        background-color: #EEEEF0;
    }

    .task_submit_id_main_form_control.dropdown.danger .dropdown-select {
        border: 1px solid red;
    }

    .task_submit_id_main_form_control.dropdown .dropdown-select {
        border-radius: inherit;
        padding: 1.388vw;
        height: 4.583vw;
    }

    .task_submit_id_main_form_control_danger_message {
        font-size: 0.972vw;
        line-height: 150%;
        color: #FF1200;
        margin-top: 0.555vw;
    }

    .task_submit_id_main_upload_btn_wrap {
        width: 100%;
        margin-bottom: 1.666vw;
    }

    .task_submit_id_main_upload_btn {
        height: 3.472vw;
        background-color: #055CF5;
        border-radius: 9999px;
        border: 0px;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 1.041vw;
        padding-right: 1.041vw;
        position: relative;
        width: 100%;
        margin: auto;
    }

    .task_submit_id_main_upload_btn img {
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .task_submit_id_main_upload_btn div {
        position: absolute;
        top: 50%;
        left: 0px;
        transform: translateY(-50%);
        width: 100%;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .task_submit_id_main_next_steps {
        background-color: #055cf51a;
        padding: 1.017vw 1.736vw 0.763vw 1.736vw;
        border-radius: 1.111vw;
        border: 1px solid #EEEEF0;
    }

    .task_submit_id_main_next_step_title {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 1.111vw;
    }

    .task_submit_id_main_next_step_lists {
        margin-bottom: 1.111vw;
        display: flex;
        flex-direction: column;
        gap: 0.486vw;
    }

    .task_submit_id_main_next_step_list {
        display: flex;
        align-items: center;
        gap: 0.833vw;
    }

    .task_submit_id_main_next_step_list img {
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .task_submit_id_main_next_step_list span {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .task_submit_id_main_next_step_description {
        font-weight: 400;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .task_submit_id_tips {
        grid-column: 8 / span 3;
        grid-row: 1;
    }

    .task_submit_id_tips_container {
        background-color: white;
        border-radius: 1.111vw;
        padding: 1.666vw;
    }

    .task_submit_id_tip_header {
        display: flex;
        align-items: center;
        gap: 0.833vw;
        margin-bottom: 0.833vw;
    }

    .task_submit_id_tip_header img {
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .task_submit_id_tip_header span {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
    }

    .task_submit_id_tip_title {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 0.833vw;
    }

    .task_submit_id_tip_lists {
        display: flex;
        flex-direction: column;
        gap: 0.694vw;
    }

    .task_submit_id_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        height: 100vh;
    }

    .task_submit_id_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .task_submit_id_modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 17.916vw;
        left: 50%;
        transform: translateX(-50%);
        width: 25.208vw;
        padding: 1.52vw 1.319vw 2.291vw 1.319vw;
        background-color: white;
        border-radius: 1.111vw;
    }

    .task_submit_id_modal_selfie_container {
        width: 22.569vw;
        height: 22.569vw;
        position: relative;
        border-radius: 0.416vw;
        overflow: hidden;
        margin-bottom: 1.666vw;
    }

    .task_submit_id_modal_actions {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1.25vw;
        align-items: center;
    }

    .task_submit_id_modal_set_btn {
        width: 13.888vw !important;
    }

    .task_submit_id_modal_set_btn span {
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .task_submit_id_modal_cancel_btn {
        border: 0px;
        outline: none;
        background-color: transparent;
        width: fit-content;
        height: fit-content;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .task_submit_id_main_action_submit {
        width: 14.305vw !important;
        margin: auto;
    }

    .task_submit_id_main_image_section {
        position: relative;
        width: 19.305vw;
        height: 12.291vw;
        margin: auto;
        margin-top: 0.208vw;
        margin-bottom: 0.763vw;
    }

    .task_submit_id_main_image {
        width: 100%;
        height: 100%;
    }

    .task_submit_id_main_image_delete_btn {
        position: absolute;
        width: 2.222vw;
        height: 2.222vw;
        right: -1.111vw;
        top: -0.625vw;
        background-color: #FF1200;
        border-radius: 9999px;
        border: 0px;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .task_submit_id_main_image_delete_btn img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .task_submit_id_main_image_description {
        font-weight: 400;
        font-size: 0.972vw;
        line-height: 1.458vw;
        text-align: center;
        letter-spacing: -0.01em;
        margin-bottom: 1.666vw;
        text-align: center;
        color: #5B5B62;
    }


    .task_submit_id_main_notification {
        margin-bottom: 2.43vw;
    }

    .task_submit_id_main_submitted_form {
        display: flex;
        flex-direction: column;
        gap: 1.875vw;
        margin-bottom: 3.125vw;
        width: 100%;
    }

    .task_submit_id_main_submitted_form_group {
        width: 100%;
    }

    .task_submit_id_main_submitted_form_label {
        font-weight: 500;
        font-size: 0.972vw;
        line-height: 1.527vw;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        margin-bottom: 0.486vw;
    }

    .task_submit_id_main_submitted_form_field {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .task_submit_id_main_submitted_form_main_image {
        margin-bottom: 2.083vw;
        display: flex;
        justify-content: center;
    }

    .task_submit_id_main_submitted_form_main_image img {
        width: 19.305vw;
        height: 12.291vw;
    }

    .task_submit_id_main_image_description a {
        color: #5B5B62;
        text-decoration: underline;
    }

    .task_submit_id_main_submit_btn {
        width: 100%;
        height: 3.472vw;
        background-color: #055CF5;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 1.666vw;
        border: 0px;
        outline: none;
    }

    .task_submit_id_main_notification {
        margin-bottom: 1.666vw;
    }
}

@media only screen and (min-width: 1441px) {
    .task_submit_id_body {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        padding-top: 25px;
        padding-bottom: 165px;
        background-color: #EEEEF0;
    }

    .task_submit_id_main {
        grid-column: 3 / span 5;
        grid-row: 1;
    }

    .task_submit_id_main_container {
        background-color: white;
        border-radius: 16px;
        padding: 40px 57px 47px 57px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .task_submit_id_main_icon {
        width: 84px;
        height: 72px;
        margin-bottom: 16px;
    }

    .task_submit_id_main_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 48px;
        line-height: 53px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 16px;
    }

    .task_submit_id_main_description {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 16px;
    }

    .task_submit_id_main_form_group {
        margin-bottom: 20px;
        width: 100%;
    }

    .task_submit_id_main_form_label {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        margin-bottom: 8px;
    }

    .task_submit_id_main_form_label.danger {
        color: #FF1200 !important;
    }

    .task_submit_id_main_form_control {
        width: 100%;
    }

    .task_submit_id_main_form_control.dropdown {
        border: none;
        background-color: #EEEEF0;
    }

    .task_submit_id_main_form_control.dropdown.danger .dropdown-select {
        border: 1px solid red;
    }

    .task_submit_id_main_form_control.dropdown .dropdown-select {
        border-radius: inherit;
        padding: 20px;
        height: 66px;
    }

    .task_submit_id_main_form_control_danger_message {
        font-size: 14px;
        line-height: 21px;
        color: #FF1200;
        margin-top: 8px;
    }

    .task_submit_id_main_upload_btn_wrap {
        width: 100%;
        margin-bottom: 24px;
    }

    .task_submit_id_main_upload_btn {
        height: 50px;
        background-color: #055CF5;
        border-radius: 9999px;
        border: 0px;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 15px;
        padding-right: 15px;
        position: relative;
        width: 100%;
        margin: auto;
    }

    .task_submit_id_main_upload_btn img {
        width: 24px;
        height: 24px;
        flex: none;
    }

    .task_submit_id_main_upload_btn div {
        position: absolute;
        top: 50%;
        left: 0px;
        transform: translateY(-50%);
        width: 100%;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .task_submit_id_main_next_steps {
        background-color: #055cf51a;
        padding: 14.65px 25px 11px 25px;
        border-radius: 16px;
        border: 1px solid #EEEEF0;
    }

    .task_submit_id_main_next_step_title {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 16px;
    }

    .task_submit_id_main_next_step_lists {
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
        gap: 7px;
    }

    .task_submit_id_main_next_step_list {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .task_submit_id_main_next_step_list img {
        width: 24px;
        height: 24px;
        flex: none;
    }

    .task_submit_id_main_next_step_list span {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .task_submit_id_main_next_step_description {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .task_submit_id_tips {
        grid-column: 8 / span 3;
        grid-row: 1;
    }

    .task_submit_id_tips_container {
        background-color: white;
        border-radius: 16px;
        padding: 24px;
    }

    .task_submit_id_tip_header {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-bottom: 12px;
    }

    .task_submit_id_tip_header img {
        width: 24px;
        height: 24px;
        flex: none;
    }

    .task_submit_id_tip_header span {
        font-weight: 900;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
    }

    .task_submit_id_tip_title {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 12px;
    }

    .task_submit_id_tip_lists {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .task_submit_id_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        height: 100vh;
    }

    .task_submit_id_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .task_submit_id_modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 258px;
        left: 50%;
        transform: translateX(-50%);
        width: 363px;
        padding: 22px 19px 33px 19px;
        background-color: white;
        border-radius: 16px;
    }

    .task_submit_id_modal_selfie_container {
        width: 325px;
        height: 325px;
        position: relative;
        border-radius: 6px;
        overflow: hidden;
        margin-bottom: 24px;
    }

    .task_submit_id_modal_actions {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 18px;
        align-items: center;
    }

    .task_submit_id_modal_set_btn {
        width: 200px !important;
    }

    .task_submit_id_modal_set_btn span {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .task_submit_id_modal_cancel_btn {
        border: 0px;
        outline: none;
        background-color: transparent;
        width: fit-content;
        height: fit-content;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .task_submit_id_main_action_submit {
        width: 206px !important;
        margin: auto;
    }

    .task_submit_id_main_image_section {
        position: relative;
        width: 278px;
        height: 177px;
        margin: auto;
        margin-top: 3px;
        margin-bottom: 11px;
    }

    .task_submit_id_main_image {
        width: 100%;
        height: 100%;
    }

    .task_submit_id_main_image_delete_btn {
        position: absolute;
        width: 32px;
        height: 32px;
        right: -16px;
        top: -9px;
        background-color: #FF1200;
        border-radius: 9999px;
        border: 0px;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .task_submit_id_main_image_delete_btn img {
        width: 24px;
        height: 24px;
    }

    .task_submit_id_main_image_description {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        letter-spacing: -0.01em;
        margin-bottom: 24px;
        text-align: center;
        color: #5B5B62;
    }


    .task_submit_id_main_notification {
        margin-bottom: 35px;
    }

    .task_submit_id_main_submitted_form {
        display: flex;
        flex-direction: column;
        gap: 27px;
        margin-bottom: 45px;
        width: 100%;
    }

    .task_submit_id_main_submitted_form_group {
        width: 100%;
    }

    .task_submit_id_main_submitted_form_label {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        margin-bottom: 7px;
    }

    .task_submit_id_main_submitted_form_field {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .task_submit_id_main_submitted_form_main_image {
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
    }

    .task_submit_id_main_submitted_form_main_image img {
        width: 278px;
        height: 177px;
    }

    .task_submit_id_main_image_description a {
        color: #5B5B62;
        text-decoration: underline;
    }

    .task_submit_id_main_submit_btn {
        width: 100%;
        height: 50px;
        background-color: #055CF5;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 24px;
        border: 0px;
        outline: none;
    }

    .task_submit_id_main_notification {
        margin-bottom: 24px;
    }
}