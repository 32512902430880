@media only screen and (max-width: 480px) {
    .task_layout_header {
        position: relative;
        overflow: hidden;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #1C1CA8;
        color: white;
        padding: 4.722vw 3.333vw 4.722vw 4.444vw;
        height: 16.111vw;
    }

    .task_layout_header_left {
        z-index: 1;
    }

    .task_layout_header_menu_btn {
        background-color: transparent;
        border: 0;
        outline: none;
    }

    .task_layout_header_menu_icon {
        width: 6.666vw;
        height: 6.666vw;
    }

    .task_layout_header_title {
        z-index: 0;
        position: absolute;
        left: 0px;
        text-align: center;
        width: 100%;
        font-weight: 700;
        font-size: 4.444vw;
        line-height: 7.111vw;
        letter-spacing: 0.01em;
    }

    .task_layout_header_right {
        z-index: 1;
        width: fit-content;
    }

    .task_layout_header_close_btn {
        color: white;
        background-color: transparent;
        border: 0px;
        outline: none;
        display: flex;
        align-items: center;
        gap: 1.666vw;
        font-weight: 700;
        font-size: 4.444vw;
        line-height: 5.555vw;
        letter-spacing: -0.01em;
    }

    .task_layout_header_close_btn span {
        display: none;
    }

    .task_layout_header_close_icon {
        width: 6.666vw;
        height: 6.666vw;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .task_layout_header {
        position: relative;
        overflow: hidden;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #1C1CA8;
        color: white;
        padding: 1.319vw 2.5vw;
        height: 4.722vw;
    }

    .task_layout_header_left {
        z-index: 1;
    }

    .task_layout_header_menu_btn {
        background-color: transparent;
        border: 0;
        outline: none;
    }

    .task_layout_header_menu_icon {
        width: 1.666vw;
        height: 1.666vw;
    }

    .task_layout_header_title {
        z-index: 0;
        position: absolute;
        left: 0px;
        text-align: center;
        width: 100%;
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: 0.01em;
    }

    .task_layout_header_right {
        z-index: 1;
        width: fit-content;
    }

    .task_layout_header_close_btn {
        color: white;
        background-color: transparent;
        border: 0px;
        outline: none;
        display: flex;
        align-items: center;
        gap: 0.416vw;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
        letter-spacing: -0.01em;
    }

    .task_layout_header_close_btn span {
        display: block;
    }

    .task_layout_header_close_icon {
        width: 1.666vw;
        height: 1.666vw;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .task_layout_header {
        position: relative;
        overflow: hidden;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #1C1CA8;
        color: white;
        padding: 1.319vw 2.5vw;
        height: 4.722vw;
    }

    .task_layout_header_left {
        z-index: 1;
    }

    .task_layout_header_menu_btn {
        background-color: transparent;
        border: 0;
        outline: none;
    }

    .task_layout_header_menu_icon {
        width: 1.666vw;
        height: 1.666vw;
    }

    .task_layout_header_title {
        z-index: 0;
        position: absolute;
        left: 0px;
        text-align: center;
        width: 100%;
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: 0.01em;
    }

    .task_layout_header_right {
        z-index: 1;
        width: fit-content;
    }

    .task_layout_header_close_btn {
        color: white;
        background-color: transparent;
        border: 0px;
        outline: none;
        display: flex;
        align-items: center;
        gap: 0.416vw;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
        letter-spacing: -0.01em;
    }

    .task_layout_header_close_btn span {
        display: block;
    }

    .task_layout_header_close_icon {
        width: 1.666vw;
        height: 1.666vw;
    }
}

@media only screen and (min-width: 1441px) {
    .task_layout_header {
        position: relative;
        overflow: hidden;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #1C1CA8;
        color: white;
        padding: 19px 36px;
        height: 68px;
    }

    .task_layout_header_left {
        z-index: 1;
    }

    .task_layout_header_menu_btn {
        background-color: transparent;
        border: 0;
        outline: none;
    }

    .task_layout_header_menu_icon {
        width: 24px;
        height: 24px;
    }

    .task_layout_header_title {
        z-index: 0;
        position: absolute;
        left: 0px;
        text-align: center;
        width: 100%;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.01em;
    }

    .task_layout_header_right {
        z-index: 1;
        width: fit-content;
    }

    .task_layout_header_close_btn {
        color: white;
        background-color: transparent;
        border: 0px;
        outline: none;
        display: flex;
        align-items: center;
        gap: 6px;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.01em;
    }

    .task_layout_header_close_btn span {
        display: block;
    }

    .task_layout_header_close_icon {
        width: 24px;
        height: 24px;
    }
}