@media only screen and (max-width: 480px) {

    .login_otp_form {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        position: relative;
        overflow: hidden;
        padding-top: 1.388vw;
    }

    .login_otp_form_padding {
        padding-top: 1.388vw;
    }

    .login_otp_form_notification_container {
        position: fixed;
        top: 4.444vw;
        z-index: 100;
        left: 0px;
        width: 100vw;
    }

    .login_otp_form_notification_container .notification_container {
        grid-column: 1 / span 12;
    }

    .login_otp_form_left_side_form {
        z-index: 2;
        grid-column: 1 / span 12;
        grid-row: 2;
        width: 100%;
    }

    .login_otp_form_background_img_desktop {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .login_otp_form_background_img_tablet {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .login_otp_form_background_img_mobile {
        display: block;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
    }

    .login_otp_form_background_img_desktop img,
    .login_otp_form_background_img_tablet img,
    .login_otp_form_background_img_mobile img {
        width: calc(100% + 32px);
        transform: translateX(-18px);
        margin-bottom: -1.388vw;
    }

    .login_otp_form_container {
        width: calc(100% + 4.444vw);
        margin-left: -2.222vw;
        background-color: white;
        border-radius: 16px;
        padding: 10vw 4.444vw;
        margin-bottom: 17.777vw;
    }

    .login_otp_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 6.666vw;
    }

    .login_otp_form_logo {
        width: 33.333vw;
        height: 8.611vw;
    }

    .login_otp_form_title {
        font-size: 6.666vw;
        font-weight: bold;
        line-height: 8.666vw;
        color: #1C1CA8;
        margin-bottom: 4.444vw;
    }

    .login_otp_form_step {
        font-size: 3.888vw;
        font-weight: 900;
        line-height: 5vw;
        color: #FF4600;
        letter-spacing: 0.02em;
    }

    .otp_form_description {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-size: 4.444vw;
        font-weight: 400;
        line-height: 7.222vw;
        color: #27272F;
        letter-spacing: -0.01em;
    }

    .login_otp_form_group {
        margin-bottom: 6.666vw;
        display: flex;
        flex-direction: column;
    }

    .login_otp_input_container {
        width: 100% !important;
        height: 13.925vw;
    }

    .login_otp_input_container .styles_react-code-input__CRulA {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .login_otp_input_container .styles_react-code-input__CRulA>input {
        width: 11.7vw !important;
        height: 11.7vw !important;
        border: 0px !important;
        border-radius: 9999px !important;
        background-color: #EEEEF0 !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        font-size: 6.944vw !important;
        line-height: 10.416vw !important;
        color: #1C1CA8 !important;
        font-weight: 500 !important;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif !important;
    }

    .login_otp_resend_btn {
        cursor: pointer;
        font-size: 4.444vw;
        line-height: 5.555vw;
        font-weight: 700;
        text-decoration: none;
        margin: auto;
        color: #1C1CA8;
        display: flex;
        gap: 2.222vw;
    }

    .login_otp_resend_btn svg{
        width: 6.666vw;
        height: 6.666vw;
    }

    .login_otp_form_label {
        margin-bottom: 2.222vw;
        font-size: 3.888vw;
        line-height: 6.222vw;
        color: #1C1CA8;
        font-weight: 500;
    }

    .login_otp_form_label.error {
        color: #FF1200;
    }

    .login_otp_form_input {
        font-size: 4.444vw;
        font-weight: 450;
        line-height: 7.111vw;
        height: 18.333vw;
        background-color: #EEEEF0;
        border-radius: 8px;
        padding: 5.555vw;
        outline: none;
        border: 0px;
    }

    .login_otp_form_input.error {
        border: 1px solid #FF1200;
    }

    .login_otp_form_error {
        font-size: 3.888vw;
        line-height: 5.833vw;
        font-weight: 500;
        margin-top: 2.222vw;
        color: #FF1200;
    }

    .login_otp_form_submit_btn {
        height: 13.888vw;
        background-color: #FF4600;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 9999px;
        padding-left: 5.555vw;
        padding-right: 5.555vw;
    }

    .login_otp_form_submit_btn:disabled {
        opacity: 0.6;
    }

    .login_otp_form_submit_btn span {
        font-size: 4.444vw;
        color: white;
        line-height: 5.555vw;
        font-weight: bold;
    }

    .login_otp_form_submit_btn svg {
        width: 6.111vw;
        height: 6.666vw;
    }

    .login_otp_form_footer {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5B5B62;
        font-weight: 400;
        font-size: 3.888vw;
        line-height: 5.833vw;
    }

    .login_otp_form_footer a {
        text-decoration: underline;
        color: #5B5B62;
    }

    .login_otp_notifier_modal {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 1000;
        width: 100vw;
        height: 100vh;
    }

    .login_otp_notifier_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .login_otp_notifier_modal_body {
        height: fit-content;
        position: absolute;
        top: 57.5vw;
        z-index: 100;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100vw - 16px);
        background-color: white;
        border-radius: 4.444vw;
    }

    .login_otp_notifier_modal_close_btn {
        position: absolute;
        right: 2.5vw;
        top: 0px;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: 11.111vw;
        height: 11.111vw;
    }

    .login_otp_notifier_modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .login_otp_notifier_modal_content {
        width: 100%;
        padding: 11.111vw 4.444vw 6.666vw 4.444vw;
        display: flex;
        flex-direction: column;
    }

    .login_otp_notifier_modal_title {
        font-weight: 500;
        font-size: 5vw;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 4.444vw;
    }

    .login_otp_notifier_modal_actions {
        display: flex;
        flex-direction: column;
        gap: 4.444vw;
    }

    .login_otp_notifier_modal_yes {
        background-color: #41AD49 !important;
        width: 86.666vw !important;
        margin: auto;
    }

    .login_otp_notifier_modal_no {
        width: 86.666vw !important;
        margin: auto;
    }

    .login_otp_notifier_modal_phone_number {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 8.888vw;
        line-height: 138%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #262629;
        margin-bottom: 6.666vw;
    }

    .login_otp_notifier_modal_footer {
        padding-top: 4.444vw;
        padding-bottom: 6.666vw;
        border-top: 1px solid #878a914d;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2.222vw;
    }

    .login_otp_notifier_modal_footer span {
        font-weight: 500;
        font-size: 3.888vw;
        line-height: 143%;
        letter-spacing: -0.01em;
        color: #66696E;
    }

    .login_otp_notifier_modal_footer img {
        width: 6.666vw;
        height: 6.666vw;
    }

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* personal_details_form starts */

    .login_otp_form {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        position: relative;
        overflow: hidden;
    }

    .login_otp_form_padding {
        padding-top: 10.416vw;
    }

    .login_otp_form_notification_container {
        position: fixed;
        top: 3.125vw;
        z-index: 100;
        left: 0px;
        width: 100vw;
    }

    .login_otp_form_notification_container .notification_container {
        grid-column: 1 / span 12;
        min-height: 7.291vw;
        gap: 1.302vw;
    }

    .login_otp_form_notification_container .notification_container .notification_prefix_icon,
    .login_otp_form_notification_container .notification_container .notification_suffix_icon {
        width: 3.125vw;
        height: 3.125vw;
    }

    .login_otp_form_notification_container .notification_container .notification_text {
        font-size: 2.083vw;
        line-height: 3.125vw;
    }

    .login_otp_form_left_side_form {
        z-index: 2;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .login_otp_form_background_img_desktop {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .login_otp_form_background_img_tablet {
        display: block;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .login_otp_form_background_img_mobile {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .login_otp_form_background_img_desktop img,
    .login_otp_form_background_img_tablet img,
    .login_otp_form_background_img_mobile img {
        width: calc(100% + 24px);
        transform: translateX(-24px);
        position: absolute;
        bottom: 0;
    }

    .login_otp_form_container {
        background-color: white;
        border-radius: 16px;
        padding: 4.166vw 3.125vw 3.125vw 3.125vw;
        margin-top: 3.125vw;
        margin-bottom: 4.166vw;
    }

    .login_otp_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3.125vw;
    }

    .login_otp_form_logo {
        width: 9.114vw;
        height: 2.343vw;
    }

    .login_otp_form_title {
        font-size: 3.125vw;
        font-weight: bold;
        line-height: 4.036vw;
        color: #1C1CA8;
        margin-bottom: 2.083vw;
    }

    .login_otp_form_step {
        font-size: 1.562vw;
        font-weight: 900;
        line-height: 2.343vw;
        color: #FF4600;
        letter-spacing: 0.02em;
    }

    .otp_form_description {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-size: 2.083vw;
        font-weight: 400;
        line-height: 3.385vw;
        color: #27272F;
        letter-spacing: -0.01em;
    }

    .login_otp_form_group {
        margin-bottom: 1.667vw;
        display: flex;
        flex-direction: column;
    }

    .login_otp_input_container {
        width: 100% !important;
        height: 6.527vw;
    }

    .login_otp_input_container .styles_react-code-input__CRulA {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .login_otp_input_container .styles_react-code-input__CRulA>input {
        width: 3.8vw !important;
        height: 3.8vw !important;
        border: 0px !important;
        border-radius: 9999px !important;
        background-color: #EEEEF0 !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        font-size: 1.736vw !important;
        line-height: 2.604vw !important;
        color: #1C1CA8 !important;
        font-weight: 500 !important;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif !important;
    }

    .login_otp_resend_btn {
        cursor: pointer;
        font-size: 1.111vw;
        line-height: 1.388vw;
        font-weight: 700;
        text-decoration: none;
        margin: auto;
        color: #1C1CA8;
        display: flex;
        gap: 1.041vw;
    }

    .login_otp_resend_btn svg{
        width: 3.125vw;
        height: 3.125vw;
    }

    .login_otp_form_label {
        margin-bottom: 1.041vw;
        font-size: 1.822vw;
        line-height: 2.864vw;
        color: #1C1CA8;
        font-weight: 500;
    }

    .login_otp_form_label.error {
        color: #FF1200;
    }

    .login_otp_form_input {
        font-size: 2.083vw;
        font-weight: 450;
        line-height: 3.385vw;
        height: 8.593vw;
        background-color: #EEEEF0;
        border-radius: 8px;
        padding: 2.604vw;
        outline: none;
        border: 0px;
    }

    .login_otp_form_input.error {
        border: 1px solid #FF1200;
    }

    .login_otp_form_error {
        font-size: 0.972vw;
        line-height: 1.458vw;
        font-weight: 500;
        margin-top: 0.555vw;
        color: #FF1200;
    }

    .login_otp_form_submit_btn {
        height: 6.51vw;
        background-color: #FF4600;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 9999px;
        padding-left: 2.604vw;
        padding-right: 2.604vw;
    }

    .login_otp_form_submit_btn:disabled {
        opacity: 0.6;
    }

    .login_otp_form_submit_btn span {
        font-size: 2.083vw;
        color: white;
        line-height: 2.604vw;
        font-weight: bold;
    }

    .login_otp_form_submit_btn svg {
        width: 2.864vw;
        height: 3.125vw;
    }

    .login_otp_form_footer {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5B5B62;
        font-weight: 400;
        font-size: 1.822vw;
        line-height: 2.734vw;
    }

    .login_otp_form_footer a {
        text-decoration: underline;
        color: #5B5B62;
    }

    .login_otp_notifier_modal {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 1000;
        width: 100vw;
        height: 100vh;
    }

    .login_otp_notifier_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .login_otp_notifier_modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 17.916vw;
        left: 50%;
        transform: translateX(-50%);
        width: 38.68vw;
        background-color: white;
        border-radius: 16px;
    }

    .login_otp_notifier_modal_close_btn {
        position: absolute;
        right: 0.763vw;
        top: 0px;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: 2.777vw;
        height: 2.777vw;
    }

    .login_otp_notifier_modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .login_otp_notifier_modal_content {
        width: 100%;
        padding: 5vw 1.666vw 5.555vw 1.666vw;
        display: flex;
        flex-direction: column;
    }

    .login_otp_notifier_modal_title {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 2.083vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 2.777vw;
    }

    .login_otp_notifier_modal_actions {
        display: flex;
        flex-direction: column;
        gap: 1.111vw;
    }

    .login_otp_notifier_modal_yes {
        background-color: #41AD49 !important;
        width: 21.666vw !important;
        margin: auto;
    }

    .login_otp_notifier_modal_no {
        width: 21.666vw !important;
        margin: auto;
    }

    .login_otp_notifier_modal_phone_number {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 36px;
        line-height: 122%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #262629;
        margin-bottom: 20px;
    }

    .login_otp_notifier_modal_footer {
        padding-top: 20px;
        padding-bottom: 15px;
        border-top: 1px solid #878a914d;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    .login_otp_notifier_modal_footer span {
        font-weight: 500;
        font-size: 16px;
        line-height: 125%;
        letter-spacing: -0.01em;
        color: #66696E;
        margin-bottom: 4px;
    }

    .login_otp_notifier_modal_footer img {
        width: 24px;
        height: 24px;
    }

}

@media only screen and (min-width: 601px) and (max-width: 840px) {

    /* personal_details_form starts */

    .login_otp_form {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        position: relative;
        overflow: hidden;
    }

    .login_otp_form_padding {
        padding-top: 10.416vw;
    }

    .login_otp_form_notification_container {
        position: fixed;
        top: 3.125vw;
        z-index: 100;
        left: 0px;
        width: 100vw;
    }

    .login_otp_form_notification_container .notification_container {
        grid-column: 1 / span 12;
        min-height: 7.291vw;
        gap: 1.302vw;
    }

    .login_otp_form_notification_container .notification_container .notification_prefix_icon,
    .login_otp_form_notification_container .notification_container .notification_suffix_icon {
        width: 3.125vw;
        height: 3.125vw;
    }

    .login_otp_form_notification_container .notification_container .notification_text {
        font-size: 2.083vw;
        line-height: 3.125vw;
    }

    .login_otp_form_left_side_form {
        z-index: 2;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .login_otp_form_background_img_desktop {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .login_otp_form_background_img_tablet {
        display: block;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .login_otp_form_background_img_mobile {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .login_otp_form_background_img_desktop img,
    .login_otp_form_background_img_tablet img,
    .login_otp_form_background_img_mobile img {
        width: calc(100% + 24px);
        transform: translateX(-24px);
        position: absolute;
        bottom: 0;
    }

    .login_otp_form_container {
        background-color: white;
        border-radius: 16px;
        padding: 4.166vw 3.125vw 3.125vw 3.125vw;
        margin-top: 3.125vw;
        margin-bottom: 4.166vw;
    }

    .login_otp_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3.125vw;
    }

    .login_otp_form_logo {
        width: 9.114vw;
        height: 2.343vw;
    }

    .login_otp_form_title {
        font-size: 3.125vw;
        font-weight: bold;
        line-height: 4.036vw;
        color: #1C1CA8;
        margin-bottom: 2.083vw;
    }

    .login_otp_form_step {
        font-size: 1.562vw;
        font-weight: 900;
        line-height: 2.343vw;
        color: #FF4600;
        letter-spacing: 0.02em;
    }

    .otp_form_description {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-size: 2.083vw;
        font-weight: 400;
        line-height: 3.385vw;
        color: #27272F;
        letter-spacing: -0.01em;
    }

    .login_otp_form_group {
        margin-bottom: 1.667vw;
        display: flex;
        flex-direction: column;
    }

    .login_otp_input_container {
        width: 100% !important;
        height: 6.527vw;
    }

    .login_otp_input_container .styles_react-code-input__CRulA {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .login_otp_input_container .styles_react-code-input__CRulA>input {
        width: 3.8vw !important;
        height: 3.8vw !important;
        border: 0px !important;
        border-radius: 9999px !important;
        background-color: #EEEEF0 !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        font-size: 1.736vw !important;
        line-height: 2.604vw !important;
        color: #1C1CA8 !important;
        font-weight: 500 !important;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif !important;
    }

    .login_otp_resend_btn {
        cursor: pointer;
        font-size: 1.111vw;
        line-height: 1.388vw;
        font-weight: 700;
        text-decoration: none;
        margin: auto;
        color: #1C1CA8;
        display: flex;
        gap: 1.041vw;
    }

    .login_otp_resend_btn svg{
        width: 3.125vw;
        height: 3.125vw;
    }

    .login_otp_form_label {
        margin-bottom: 1.041vw;
        font-size: 1.822vw;
        line-height: 2.864vw;
        color: #1C1CA8;
        font-weight: 500;
    }

    .login_otp_form_label.error {
        color: #FF1200;
    }

    .login_otp_form_input {
        font-size: 2.083vw;
        font-weight: 450;
        line-height: 3.385vw;
        height: 8.593vw;
        background-color: #EEEEF0;
        border-radius: 8px;
        padding: 2.604vw;
        outline: none;
        border: 0px;
    }

    .login_otp_form_input.error {
        border: 1px solid #FF1200;
    }

    .login_otp_form_error {
        font-size: 0.972vw;
        line-height: 1.458vw;
        font-weight: 500;
        margin-top: 0.555vw;
        color: #FF1200;
    }

    .login_otp_form_submit_btn {
        height: 6.51vw;
        background-color: #FF4600;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 9999px;
        padding-left: 2.604vw;
        padding-right: 2.604vw;
    }

    .login_otp_form_submit_btn:disabled {
        opacity: 0.6;
    }

    .login_otp_form_submit_btn span {
        font-size: 2.083vw;
        color: white;
        line-height: 2.604vw;
        font-weight: bold;
    }

    .login_otp_form_submit_btn svg {
        width: 2.864vw;
        height: 3.125vw;
    }

    .login_otp_form_footer {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5B5B62;
        font-weight: 400;
        font-size: 1.822vw;
        line-height: 2.734vw;
    }

    .login_otp_form_footer a {
        text-decoration: underline;
        color: #5B5B62;
    }

    .login_otp_notifier_modal {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 1000;
        width: 100vw;
        height: 100vh;
    }

    .login_otp_notifier_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .login_otp_notifier_modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 8.854vw;
        left: 50%;
        transform: translateX(-50%);
        width: 46.875vw;
        background-color: white;
        border-radius: 2.083vw;
    }

    .login_otp_notifier_modal_close_btn {
        position: absolute;
        right: 1.302vw;
        top: 0px;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: 5.208vw;
        height: 5.208vw;
    }

    .login_otp_notifier_modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .login_otp_notifier_modal_content {
        width: 100%;
        padding: 7.291vw 3.125vw 3.125vw 3.125vw;
        display: flex;
        flex-direction: column;
    }

    .login_otp_notifier_modal_title {
        width: 39.062vw;
        margin: auto;
        font-weight: 500;
        font-size: 2.604vw;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #66696E;
        margin-bottom: 2.083vw;
    }

    .login_otp_notifier_modal_actions {
        display: flex;
        flex-direction: column;
        gap: 2.083vw;
    }

    .login_otp_notifier_modal_yes {
        background-color: #41AD49 !important;
        width: 40.625vw !important;
        height: 6.51vw !important;
        margin: auto;
    }

    .login_otp_notifier_modal_no {
        width: 40.625vw !important;
        height: 6.51vw !important;
        margin: auto;
    }

    .login_otp_notifier_modal_yes span {
        font-weight: 700;
        font-size: 16px !important;
        line-height: 125% !important;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }


    .login_otp_notifier_modal_no span {
        font-weight: 700;
        font-size: 2.083vw !important;
        line-height: 125% !important;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .login_otp_notifier_modal_phone_number {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 4.687vw;
        line-height: 122%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #262629;
        margin-bottom: 3.125vw;
    }

    .login_otp_notifier_modal_footer {
        padding-top: 2.083vw;
        padding-bottom: 2.083vw;
        border-top: 1px solid #878a914d;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.041vw;
    }

    .login_otp_notifier_modal_footer span {
        font-weight: 500;
        font-size: 2.083vw;
        line-height: 125%;
        letter-spacing: -0.01em;
        color: #66696E;
    }

    .login_otp_notifier_modal_footer img {
        width: 3.125vw;
        height: 3.125vw;
    }

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* personal_details_form starts */
    .login_otp_form {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        position: relative;
        overflow: hidden;
    }

    .login_otp_form_padding {
        padding-top: 0px;
    }

    .login_otp_form_notification_container {
        position: fixed;
        top: 2.291vw;
        z-index: 100;
        left: 0px;
        width: 100vw;
    }

    .login_otp_form_notification_container .notification_container {
        grid-column: 1 / span 12;
    }

    .login_otp_form_left_side_form {
        z-index: 2;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .login_otp_form_background_img_desktop {
        display: block;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .login_otp_form_background_img_tablet {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .login_otp_form_background_img_mobile {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .login_otp_form_background_img_desktop img,
    .login_otp_form_background_img_tablet img,
    .login_otp_form_background_img_mobile img {
        width: calc(100% + 96px);
        transform: translateX(-48px);
        position: absolute;
        bottom: 0;
    }

    .login_otp_form_container {
        background-color: white;
        border-radius: 16px;
        padding: 2.5vw 1.667vw;
        margin-top: 8.194vw;
        margin-bottom: 14.236vw;
    }

    .login_otp_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.667vw;
    }

    .login_otp_form_logo {
        width: 8.333vw;
        height: 2.152vw;
    }

    .login_otp_form_title {
        font-size: 2.15vw;
        font-weight: bold;
        line-height: 2.79vw;
        color: #1C1CA8;
        margin-bottom: 1.111vw;
    }

    .login_otp_form_step {
        font-size: 0.97vw;
        font-weight: 900;
        line-height: 1.458vw;
        color: #FF4600;
        letter-spacing: 0.02em;
    }

    .otp_form_description {
        max-width: 22.5vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-size: 1.111vw;
        font-weight: 400;
        line-height: 1.805vw;
        color: #27272F;
        letter-spacing: -0.01em;
    }

    .login_otp_form_group {
        margin-bottom: 1.667vw;
        display: flex;
        flex-direction: column;
    }

    .login_otp_input_container {
        width: 100% !important;
        height: 4.93vw;
    }

    .login_otp_input_container .styles_react-code-input__CRulA {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .login_otp_input_container .styles_react-code-input__CRulA>input {
        width: 3.8vw !important;
        height: 3.8vw !important;
        border: 0px !important;
        border-radius: 9999px !important;
        background-color: #EEEEF0 !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        font-size: 1.736vw !important;
        line-height: 2.604vw !important;
        color: #1C1CA8 !important;
        font-weight: 500 !important;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif !important;
    }

    .login_otp_resend_btn {
        cursor: pointer;
        font-size: 1.111vw;
        line-height: 1.388vw;
        font-weight: 700;
        text-decoration: none;
        margin: auto;
        color: #1C1CA8;
        display: flex;
        gap: 0.555vw;
    }

    .login_otp_resend_btn svg{
        width: 1.666vw;
        height: 1.666vw;
    }

    .login_otp_form_label {
        margin-bottom: 0.555vw;
        font-size: 0.972vw;
        line-height: 1.555vw;
        color: #1C1CA8;
        font-weight: 500;
    }

    .login_otp_form_input {
        font-size: 1.111vw;
        font-weight: 450;
        line-height: 1.777vw;
        height: 4.583vw;
        background-color: #EEEEF0;
        border-radius: 8px;
        padding: 1.388vw;
        outline: none;
        border: 0px;
    }

    .login_otp_form_input.error {
        border: 1px solid #FF1200;
    }

    .login_otp_form_error {
        font-size: 0.972vw;
        line-height: 1.458vw;
        font-weight: 500;
        margin-top: 0.555vw;
        color: #FF1200;
    }

    .login_otp_form_submit_btn {
        height: 4.583vw;
        background-color: #FF4600;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 9999px;
        padding-left: 1.388vw;
        padding-right: 1.388vw;
    }

    .login_otp_form_submit_btn:disabled {
        opacity: 0.6;
    }

    .login_otp_form_submit_btn span {
        font-size: 1.111vw;
        color: white;
        line-height: 1.388vw;
        font-weight: bold;
    }

    .login_otp_form_submit_btn svg {
        width: 1.527vw;
        height: 1.666vw;
    }

    .login_otp_form_footer {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5B5B62;
        font-weight: 400;
        font-size: 0.972vw;
        line-height: 1.458vw;
    }

    .login_otp_form_footer a {
        text-decoration: underline;
        color: #5B5B62;
    }

    .login_otp_notifier_modal {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 1000;
        width: 100vw;
        height: 100vh;
    }

    .login_otp_notifier_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .login_otp_notifier_modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 14.51vw;
        left: 50%;
        transform: translateX(-50%);
        width: 38.68vw;
        background-color: white;
        border-radius: 1.111vw;
    }

    .login_otp_notifier_modal_close_btn {
        position: absolute;
        right: 0.81vw;
        top: 0px;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: 2.777vw;
        height: 2.777vw;
    }

    .login_otp_notifier_modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .login_otp_notifier_modal_content {
        width: 100%;
        padding: 5vw 7.638vw 2.777vw 7.638vw;
        display: flex;
        flex-direction: column;
    }

    .login_otp_notifier_modal_title {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #66696E;
        margin-bottom: 1.388vw;
    }

    .login_otp_notifier_modal_actions {
        display: flex;
        flex-direction: column;
        gap: 1.111vw;
    }

    .login_otp_notifier_modal_yes {
        background-color: #41AD49 !important;
        width: 21.666vw !important;
        margin: auto;
    }

    .login_otp_notifier_modal_no {
        width: 21.666vw !important;
        margin: auto;
    }

    .login_otp_notifier_modal_phone_number {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 2.5vw;
        line-height: 122%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #262629;
        margin-bottom: 2.777vw;
    }

    .login_otp_notifier_modal_footer {
        padding-top: 1.388vw;
        padding-bottom: 1.041vw;
        border-top: 1px solid #878a914d;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.555vw;
    }

    .login_otp_notifier_modal_footer span {
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 125%;
        letter-spacing: -0.01em;
        color: #66696E;
        margin-bottom: 0.277vw;
    }

    .login_otp_notifier_modal_footer img {
        width: 1.666vw;
        height: 1.666vw;
    }

}

@media only screen and (min-width: 1441px) {

    /* personal_details_form starts */
    .login_otp_form {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        position: relative;
        overflow: hidden;
    }

    .login_otp_form_padding {
        padding-top: 0px;
    }

    .login_otp_form_notification_container {
        position: fixed;
        top: 33px;
        z-index: 100;
        left: 0px;
        width: 100vw;
    }

    .login_otp_form_notification_container .notification_container {
        grid-column: 1 / span 12;
    }

    .login_otp_form_left_side_form {
        z-index: 2;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .login_otp_form_background_img_desktop {
        display: block;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .login_otp_form_background_img_tablet {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .login_otp_form_background_img_mobile {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .login_otp_form_background_img_desktop img,
    .login_otp_form_background_img_tablet img,
    .login_otp_form_background_img_mobile img {
        width: calc(100% + 96px);
        transform: translateX(-48px);
        position: absolute;
        bottom: 0;
    }

    .login_otp_form_container {
        background-color: white;
        border-radius: 16px;
        padding: 36px 24px;
        margin-bottom: 205px;
        margin-top: 118px;
    }

    .login_otp_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
    }

    .login_otp_form_logo {
        width: 120px;
        height: 31px;
    }

    .login_otp_form_title {
        font-size: 31px;
        font-weight: bold;
        line-height: 40.3px;
        color: #1C1CA8;
        margin-bottom: 16px;
    }

    .login_otp_form_step {
        font-size: 14px;
        font-weight: 900;
        line-height: 21px;
        color: #FF4600;
        letter-spacing: 0.02em;
    }

    .otp_form_description {
        max-width: 324px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        color: #27272F;
        letter-spacing: -0.01em;
    }

    .login_otp_form_group {
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
    }

    .login_otp_input_container {
        width: 100% !important;
        height: 71px;
    }

    .login_otp_input_container .styles_react-code-input__CRulA {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .login_otp_input_container .styles_react-code-input__CRulA>input {
        width: 54.5px !important;
        height: 54.5px !important;
        border: 0px !important;
        border-radius: 9999px !important;
        background-color: #EEEEF0 !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        font-size: 25px !important;
        line-height: 37.5px !important;
        color: #1C1CA8 !important;
        font-weight: 500 !important;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif !important;
    }

    .login_otp_resend_btn {
        cursor: pointer;
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        text-decoration: none;
        margin: auto;
        color: #1C1CA8;
        display: flex;
        gap: 0.555vw;
    }

    .login_otp_resend_btn svg{
        width: 24px;
        height: 24px;
    }

    .login_otp_form_label {
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 22.4px;
        color: #1C1CA8;
        font-weight: 500;
    }

    .login_otp_form_label.error {
        color: #FF1200;
    }

    .login_otp_form_input {
        font-size: 16px;
        font-weight: 450;
        line-height: 25.6px;
        height: 66px;
        background-color: #EEEEF0;
        border-radius: 8px;
        padding: 20px;
        outline: none;
        border: 0px;
    }

    .login_otp_form_input.error {
        border: 1px solid #FF1200;
    }

    .login_otp_form_error {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        margin-top: 8px;
        color: #FF1200;
    }

    .login_otp_form_submit_btn {
        height: 66px;
        background-color: #FF4600;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 9999px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .login_otp_form_submit_btn:disabled {
        opacity: 0.6;
    }

    .login_otp_form_submit_btn span {
        font-size: 16px;
        color: white;
        line-height: 20px;
        font-weight: bold;
    }

    .login_otp_form_submit_btn svg {
        width: 22px;
        height: 24px;
    }

    .login_otp_form_footer {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5B5B62;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
    }

    .login_otp_form_footer a {
        text-decoration: underline;
        color: #5B5B62;
    }

    .login_otp_notifier_modal {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 1000;
        width: 100vw;
        height: 100vh;
    }

    .login_otp_notifier_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .login_otp_notifier_modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 209px;
        left: 50%;
        transform: translateX(-50%);
        width: 557px;
        background-color: white;
        border-radius: 16px;
    }

    .login_otp_notifier_modal_close_btn {
        position: absolute;
        right: 11.67px;
        top: 0px;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: 40px;
        height: 40px;
    }

    .login_otp_notifier_modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .login_otp_notifier_modal_content {
        width: 100%;
        padding: 72px 110px 40px 110px;
        display: flex;
        flex-direction: column;
    }

    .login_otp_notifier_modal_title {
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #66696E;
        margin-bottom: 20px;
    }

    .login_otp_notifier_modal_actions {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .login_otp_notifier_modal_yes {
        background-color: #41AD49 !important;
        width: 312px !important;
        margin: auto;
    }

    .login_otp_notifier_modal_no {
        width: 312px !important;
        margin: auto;
    }

    .login_otp_notifier_modal_phone_number {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 36px;
        line-height: 122%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #262629;
        margin-bottom: 40px;
    }

    .login_otp_notifier_modal_footer {
        padding-top: 20px;
        padding-bottom: 15px;
        border-top: 1px solid #878a914d;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    .login_otp_notifier_modal_footer span {
        font-weight: 500;
        font-size: 16px;
        line-height: 125%;
        letter-spacing: -0.01em;
        color: #66696E;
        margin-bottom: 4px;
    }

    .login_otp_notifier_modal_footer img {
        width: 24px;
        height: 24px;
    }
}