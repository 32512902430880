.header-section {
    /* background-color: #2121ad; */
    background-color: #1C1CA8;
}

.nabar-section {
    background-color: #1C1CA8;
}

.sales-section {
    background-color: #055CF5;
}

.header-section-white {
    background-color: #ffffff;
}

.header-section-gray {
    background-color: #F7F8FA;
}

.header-section-green {
    background-color: #41AD49;
}