@media only screen and (max-width: 480px) {
    .task_find_kiosk_body {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: white;
        padding-top: 6.111vw;
        padding-bottom: 0px;
    }

    .task_find_kiosk_main {
        grid-row: 1;
        grid-column: 1 / span 4;
        padding-left: 1.388vw;
        padding-right: 1.388vw;
        margin-bottom: 3.333vw;
    }

    .task_find_kiosk_map {
        grid-row: 2;
        grid-column: 1 / span 4;
        padding-left: 0px;
    }

    .task_find_kiosk_map>div {
        width: 100vw !important;
        margin-left: -4.444vw;
        border-radius: 0px;
        min-height: 187.222vw !important;
    }

    .task_find_kiosk_main_header {
        display: flex;
        flex-direction: column;
        padding-left: 0.833vw;
        padding-right: 0.833vw;
        gap: 6.666vw;
        align-items: center;
        margin-bottom: 6.666vw;
    }

    .task_find_kiosk_main_location_autocomplete {
        width: 100%;
        position: relative;
    }

    .task_find_kiosk_googleplace_autocomplete_input_wrapper {
        height: 18.333vw;
        padding: 5.555vw;
        background: #EEEEF0;
        border-radius: 2.222vw;
        display: flex;
        align-items: center;
        gap: 3.333vw;
    }

    .task_find_kiosk_googleplace_autocomplete_input_wrapper input {
        flex-grow: 1;
        border: 0px;
        background-color: transparent;
        outline: none;
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .task_find_kiosk_googleplace_autocomplete_input_wrapper img {
        width: 6.666vw;
        height: 6.666vw;
    }

    .task_find_kiosk_main_location_autocomplete .google-places-autocomplete__suggestions-container {
        position: absolute;
        left: 0px;
        width: 100%;
        background-color: white;
    }

    .task_find_kiosk_googleplace_autocomplete_menu_wrapper {
        padding: 5.555vw;
        display: flex;
        flex-direction: column;
        gap: 3.333vw;
    }

    .task_find_kiosk_main_header_right {
        display: flex;
        gap: 1.666vw;
        align-items: center;
        cursor: pointer;
    }

    .task_find_kiosk_main_header_right span {
        font-weight: 700;
        font-size: 4.444vw;
        line-height: 125%;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .task_find_kiosk_main_header_right img {
        width: 6.666vw;
        height: 6.666vw;
    }

    .task_find_kiosk_main_body {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        row-gap: 3.333vw;
        column-gap: 6.666vw;
    }

    .task_find_kiosk_main_location_card {
        background: #FFFFFF;
        border: 1px solid #DDDEE1;
        border-radius: 2.222vw;
        padding: 6.666vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        gap: 3.333vw;
    }

    .task_find_kiosk_main_location_card_top {
        display: flex;
        flex-direction: column;
        gap: 3.333vw;
    }

    .task_find_kiosk_main_location_card_title {
        font-weight: 400;
        font-size: 5.555vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .task_find_kiosk_main_location_card_description {
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .task_find_kiosk_main_location_card_action {
        display: flex;
        gap: 1.666vw;
        align-items: center;
    }

    .task_find_kiosk_main_location_card_action span {
        font-weight: 700;
        font-size: 3.888vw;
        line-height: 157%;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .task_find_kiosk_main_location_card_action img {
        width: 6.666vw;
        height: 6.666vw;
        transform: rotate(180deg);
    }

    .task_find_kiosk_main_location_card.active {
        box-shadow: 0px 2px 12px rgba(11, 14, 26, 0.08);
    }

    .task_find_kiosk_main_location_card.active .task_find_kiosk_main_location_card_title {
        font-weight: 400;
        color: #FF4600;
    }

    .task_find_kiosk_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .task_find_kiosk_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        min-height: unset;
        height: 100vh;
    }

    .task_find_kiosk_modal_body {
        height: fit-content;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        left: 0px;
        width: 100vw;
        padding: 11.111vw 6.666vw;
        background-color: white;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
    }

    .task_find_kiosk_modal_close_btn {
        position: absolute;
        right: 3.888vw;
        top: 0px;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: 11.111vw;
        height: 11.111vw;
    }

    .task_find_kiosk_modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .task_find_kiosk_modal_content {
        width: 100%;
        background-color: white;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        display: flex;
        flex-direction: column;
    }

    .task_find_kiosk_modal_img {
        width: 18.055vw;
        height: 18.055vw;
        margin: 0px auto 4.166vw auto;
    }

    .task_find_kiosk_modal_title {
        font-family: Azo Sans;
        font-size: 7.777vw;
        font-weight: 700;
        line-height: 10vw;
        letter-spacing: -0.02em;
        text-align: center;
        color: #1C1CA8;
        margin-bottom: 4.444vw;
    }

    .task_find_kiosk_modal_description {
        padding: 0px;
        font-family: Circular Std;
        font-size: 4.444vw;
        font-weight: 400;
        line-height: 6.666vw;
        letter-spacing: -0.02em;
        text-align: center;
        color: #5B5B62;
        margin-bottom: 8.888vw;
    }

    .task_find_kiosk_modal_btn {
        width: 59.166vw !important;
        height: 13.888vw !important;
        margin: auto;
    }

    .task_find_kiosk_confirm_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .task_find_kiosk_confirm_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        min-height: unset;
        height: 100vh;
    }

    .task_find_kiosk_confirm_modal_body {
        height: fit-content;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        left: 0px;
        width: 100vw;
        padding: 11.111vw 6.666vw;
        background-color: white;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
    }

    .task_find_kiosk_confirm_modal_close_btn {
        position: absolute;
        right: 3.888vw;
        top: 0px;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: 11.111vw;
        height: 11.111vw;
    }

    .task_find_kiosk_confirm_modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .task_find_kiosk_confirm_modal_content {
        width: 100%;
        background-color: white;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        display: flex;
        flex-direction: column;
    }

    .task_find_kiosk_confirm_modal_img {
        width: 18.055vw;
        height: 18.055vw;
        margin: 0px auto 4.166vw auto;
    }

    .task_find_kiosk_confirm_modal_title {
        font-family: Azo Sans;
        font-size: 7.777vw;
        font-weight: 700;
        line-height: 10vw;
        letter-spacing: -0.02em;
        text-align: center;
        color: #1C1CA8;
        margin-bottom: 4.444vw;
    }

    .task_find_kiosk_confirm_modal_description {
        padding: 0px;
        font-family: Circular Std;
        font-size: 4.444vw;
        font-weight: 400;
        line-height: 6.666vw;
        letter-spacing: -0.02em;
        text-align: center;
        color: #5B5B62;
        margin-bottom: 4.444vw;
    }

    .task_find_kiosk_confirm_modal_card {
        margin: 0px;
        border: 1px solid #DDDEE1;
        padding: 6.666vw;
        border-radius: 8px;
        margin-bottom: 6.666vw;
    }

    .task_find_kiosk_confirm_modal_card_title {
        font-family: Circular Std;
        font-size: 5.555vw;
        font-weight: 500;
        line-height: 8.333vw;
        letter-spacing: -0.01em;
        text-align: center;
        color: #FF4600;
        margin-bottom: 1.111vw;
    }

    .task_find_kiosk_confirm_modal_card_description {
        font-family: Circular Std;
        font-size: 4.444vw;
        font-weight: 450;
        line-height: 7.222vw;
        letter-spacing: -0.01em;
        text-align: center;
        color: #5B5B62;
    }

    .task_find_kiosk_confirm_modal_actions {
        margin: 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 2.222vw;
        margin-bottom: 6.666vw;
    }

    .task_find_kiosk_confirm_modal_actions a {
        width: 100%;
    }

    .task_find_kiosk_confirm_modal_action_btn {
        flex-grow: 1;
    }

    .task_find_kiosk_confirm_modal_tip {
        margin: 0px;
        font-family: Circular Std;
        font-size: 3.888vw;
        font-weight: 400;
        line-height: 5.833vw;
        letter-spacing: -0.01em;
        text-align: center;
        color: #5B5B62;
    }

    .task_find_kiosk_confirm_modal_tip br {
        display: none;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .task_find_kiosk_body {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: white;
        padding-top: 3.68vw;
        padding-bottom: 2.569vw;
    }

    .task_find_kiosk_main {
        grid-row: 1;
        grid-column: 1 / span 6;
    }

    .task_find_kiosk_map {
        grid-row: 1;
        grid-column: 7 / span 6;
        padding-left: 1.875vw;
    }

    .task_find_kiosk_map>div {
        border-radius: 16px;
        min-height: auto !important;
    }

    .task_find_kiosk_main_header {
        display: flex;
        flex-direction: row;
        gap: 3.472vw;
        align-items: center;
        margin-bottom: 2.638vw;
    }

    .task_find_kiosk_main_location_autocomplete {
        width: 30vw;
        position: relative;
    }

    .task_find_kiosk_googleplace_autocomplete_input_wrapper {
        height: 4.583vw;
        padding: 1.388vw;
        background: #EEEEF0;
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 0.833vw;
    }

    .task_find_kiosk_googleplace_autocomplete_input_wrapper input {
        flex-grow: 1;
        border: 0px;
        background-color: transparent;
        outline: none;
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .task_find_kiosk_googleplace_autocomplete_input_wrapper img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .task_find_kiosk_main_location_autocomplete .google-places-autocomplete__suggestions-container {
        position: absolute;
        left: 0px;
        width: 100%;
        background-color: white;
    }

    .task_find_kiosk_googleplace_autocomplete_menu_wrapper {
        padding: 1.388vw;
        display: flex;
        flex-direction: column;
        gap: 0.833vw;
    }

    .task_find_kiosk_main_header_right {
        display: flex;
        gap: 0.416vw;
        align-items: center;
        cursor: pointer;
    }

    .task_find_kiosk_main_header_right span {
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 125%;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .task_find_kiosk_main_header_right img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .task_find_kiosk_main_body {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        row-gap: 1.388vw;
        column-gap: 1.666vw;
    }

    .task_find_kiosk_main_location_card {
        background: #FFFFFF;
        border: 1px solid #DDDEE1;
        border-radius: 8px;
        padding: 1.666vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        gap: 0.833vw;
    }

    .task_find_kiosk_main_location_card_top {
        display: flex;
        flex-direction: column;
        gap: 0.833vw;
    }

    .task_find_kiosk_main_location_card_title {
        font-weight: 400;
        font-size: 1.388vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .task_find_kiosk_main_location_card_description {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .task_find_kiosk_main_location_card_action {
        display: flex;
        gap: 0.416vw;
        align-items: center;
    }

    .task_find_kiosk_main_location_card_action span {
        font-weight: 700;
        font-size: 0.972vw;
        line-height: 157%;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .task_find_kiosk_main_location_card_action img {
        width: 1.666vw;
        height: 1.666vw;
        transform: rotate(180deg);
    }

    .task_find_kiosk_main_location_card.active {
        box-shadow: 0px 2px 12px rgba(11, 14, 26, 0.08);
    }

    .task_find_kiosk_main_location_card.active .task_find_kiosk_main_location_card_title {
        font-weight: 400;
        color: #FF4600;
    }

    .task_find_kiosk_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .task_find_kiosk_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        min-height: 62.5vw;
        height: 100vh;
    }

    .task_find_kiosk_modal_body {
        height: fit-content;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 140px;
        left: 50%;
        transform: translateX(-50%);
        width: 38.68vw;
        padding: 5vw 1.631vw 4.791vw 1.631vw;
        background-color: white;
        border-radius: 16px;
    }

    .task_find_kiosk_modal_close_btn {
        position: absolute;
        right: 0.694vw;
        top: 0px;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: 2.777vw;
        height: 2.777vw;
    }

    .task_find_kiosk_modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .task_find_kiosk_modal_content {
        width: 100%;
        background-color: white;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
    }

    .task_find_kiosk_modal_img {
        width: 4.513vw;
        height: 4.513vw;
        margin: 0px auto 1.666vw auto;
    }

    .task_find_kiosk_modal_title {
        font-family: Azo Sans;
        font-size: 3.333vw;
        font-weight: 700;
        line-height: 3.68vw;
        letter-spacing: -0.02em;
        text-align: center;
        color: #1C1CA8;
        margin-bottom: 0.555vw;
    }

    .task_find_kiosk_modal_description {
        padding: 0px 2.708vw;
        font-family: Circular Std;
        font-size: 1.388vw;
        font-weight: 450;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        text-align: center;
        color: #5B5B62;
        margin-bottom: 2.222vw;
    }

    .task_find_kiosk_modal_btn {
        width: 14.791vw !important;
        height: 3.472vw !important;
        margin: auto;
    }

    .task_find_kiosk_confirm_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .task_find_kiosk_confirm_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        min-height: 62.5vw;
        height: 100vh;
    }

    .task_find_kiosk_confirm_modal_body {
        height: fit-content;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 8.125vw;
        left: 50%;
        transform: translateX(-50%);
        width: 38.68vw;
        padding: 2.777vw;
        background-color: white;
        border-radius: 16px;
    }

    .task_find_kiosk_confirm_modal_close_btn {
        position: absolute;
        right: 0.694vw;
        top: 0px;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: 2.777vw;
        height: 2.777vw;
    }

    .task_find_kiosk_confirm_modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .task_find_kiosk_confirm_modal_content {
        width: 100%;
        background-color: white;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
    }

    .task_find_kiosk_confirm_modal_img {
        width: 4.444vw;
        height: 4.444vw;
        margin: 2.222vw auto 1.666vw auto;
    }

    .task_find_kiosk_confirm_modal_title {
        font-family: Azo Sans;
        font-size: 3.333vw;
        font-weight: 700;
        line-height: 3.68vw;
        letter-spacing: -0.02em;
        text-align: center;
        color: #1C1CA8;
        margin-bottom: 0.555vw;
    }

    .task_find_kiosk_confirm_modal_description {
        font-family: Circular Std;
        font-size: 1.388vw;
        font-weight: 450;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        text-align: center;
        color: #5B5B62;
        margin-bottom: 1.666vw;
    }

    .task_find_kiosk_confirm_modal_card {
        margin: 0px 1.041vw;
        border: 1px solid #DDDEE1;
        padding: 1.666vw;
        border-radius: 0.555vw;
        margin-bottom: 2.222vw;
    }

    .task_find_kiosk_confirm_modal_card_title {
        font-family: Circular Std;
        font-size: 1.388vw;
        font-weight: 500;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        text-align: center;
        color: #FF4600;
        margin-bottom: 12px;
    }

    .task_find_kiosk_confirm_modal_card_description {
        font-family: Circular Std;
        font-size: 1.111vw;
        font-weight: 450;
        line-height: 1.805vw;
        letter-spacing: -0.01em;
        text-align: center;
        color: #5B5B62;
    }

    .task_find_kiosk_confirm_modal_actions {
        margin: 0px 1.041vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1.666vw;
        margin-bottom: 2.222vw;
    }

    .task_find_kiosk_confirm_modal_actions a {
        width: 100%;
    }

    .task_find_kiosk_confirm_modal_action_btn {
        flex-grow: 1;
    }

    .task_find_kiosk_confirm_modal_tip {
        margin: 0px 2.638vw;
        font-family: Circular Std;
        font-size: 0.972vw;
        font-weight: 400;
        line-height: 1.458vw;
        letter-spacing: -0.01em;
        text-align: center;
        color: #5B5B62;
    }

    .task_find_kiosk_confirm_modal_tip br {
        display: block;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .task_find_kiosk_body {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: white;
        padding-top: 3.68vw;
        padding-bottom: 2.569vw;
    }

    .task_find_kiosk_main {
        grid-row: 1;
        grid-column: 1 / span 6;
    }

    .task_find_kiosk_map {
        grid-row: 1;
        grid-column: 7 / span 6;
        padding-left: 1.875vw;
    }

    .task_find_kiosk_map>div {
        border-radius: 16px;
        min-height: auto !important;
    }

    .task_find_kiosk_main_header {
        display: flex;
        flex-direction: row;
        gap: 3.472vw;
        align-items: center;
        margin-bottom: 2.638vw;
    }

    .task_find_kiosk_main_location_autocomplete {
        width: 30vw;
        position: relative;
    }

    .task_find_kiosk_googleplace_autocomplete_input_wrapper {
        height: 4.583vw;
        padding: 1.388vw;
        background: #EEEEF0;
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 0.833vw;
    }

    .task_find_kiosk_googleplace_autocomplete_input_wrapper input {
        flex-grow: 1;
        border: 0px;
        background-color: transparent;
        outline: none;
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .task_find_kiosk_googleplace_autocomplete_input_wrapper img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .task_find_kiosk_main_location_autocomplete .google-places-autocomplete__suggestions-container {
        position: absolute;
        left: 0px;
        width: 100%;
        background-color: white;
    }

    .task_find_kiosk_googleplace_autocomplete_menu_wrapper {
        padding: 1.388vw;
        display: flex;
        flex-direction: column;
        gap: 0.833vw;
    }

    .task_find_kiosk_main_header_right {
        display: flex;
        gap: 0.416vw;
        align-items: center;
        cursor: pointer;
    }

    .task_find_kiosk_main_header_right span {
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 125%;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .task_find_kiosk_main_header_right img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .task_find_kiosk_main_body {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        row-gap: 1.388vw;
        column-gap: 1.666vw;
    }

    .task_find_kiosk_main_location_card {
        background: #FFFFFF;
        border: 1px solid #DDDEE1;
        border-radius: 8px;
        padding: 1.666vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        gap: 0.833vw;
    }

    .task_find_kiosk_main_location_card_top {
        display: flex;
        flex-direction: column;
        gap: 0.833vw;
    }

    .task_find_kiosk_main_location_card_title {
        font-weight: 400;
        font-size: 1.388vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .task_find_kiosk_main_location_card_description {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .task_find_kiosk_main_location_card_action {
        display: flex;
        gap: 0.416vw;
        align-items: center;
    }

    .task_find_kiosk_main_location_card_action span {
        font-weight: 700;
        font-size: 0.972vw;
        line-height: 157%;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .task_find_kiosk_main_location_card_action img {
        width: 1.666vw;
        height: 1.666vw;
        transform: rotate(180deg);
    }

    .task_find_kiosk_main_location_card.active {
        box-shadow: 0px 2px 12px rgba(11, 14, 26, 0.08);
    }

    .task_find_kiosk_main_location_card.active .task_find_kiosk_main_location_card_title {
        font-weight: 400;
        color: #FF4600;
    }

    .task_find_kiosk_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .task_find_kiosk_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        min-height: 62.5vw;
        height: 100vh;
    }

    .task_find_kiosk_modal_body {
        height: fit-content;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 140px;
        left: 50%;
        transform: translateX(-50%);
        width: 38.68vw;
        padding: 5vw 1.631vw 4.791vw 1.631vw;
        background-color: white;
        border-radius: 16px;
    }

    .task_find_kiosk_modal_close_btn {
        position: absolute;
        right: 0.694vw;
        top: 0px;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: 2.777vw;
        height: 2.777vw;
    }

    .task_find_kiosk_modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .task_find_kiosk_modal_content {
        width: 100%;
        background-color: white;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
    }

    .task_find_kiosk_modal_img {
        width: 4.513vw;
        height: 4.513vw;
        margin: 0px auto 1.666vw auto;
    }

    .task_find_kiosk_modal_title {
        font-family: Azo Sans;
        font-size: 3.333vw;
        font-weight: 700;
        line-height: 3.68vw;
        letter-spacing: -0.02em;
        text-align: center;
        color: #1C1CA8;
        margin-bottom: 0.555vw;
    }

    .task_find_kiosk_modal_description {
        padding: 0px 2.708vw;
        font-family: Circular Std;
        font-size: 1.388vw;
        font-weight: 450;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        text-align: center;
        color: #5B5B62;
        margin-bottom: 2.222vw;
    }

    .task_find_kiosk_modal_btn {
        width: 14.791vw !important;
        height: 3.472vw !important;
        margin: auto;
    }

    .task_find_kiosk_confirm_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .task_find_kiosk_confirm_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        min-height: 62.5vw;
        height: 100vh;
    }

    .task_find_kiosk_confirm_modal_body {
        height: fit-content;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 8.125vw;
        left: 50%;
        transform: translateX(-50%);
        width: 38.68vw;
        padding: 2.777vw;
        background-color: white;
        border-radius: 16px;
    }

    .task_find_kiosk_confirm_modal_close_btn {
        position: absolute;
        right: 0.694vw;
        top: 0px;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: 2.777vw;
        height: 2.777vw;
    }

    .task_find_kiosk_confirm_modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .task_find_kiosk_confirm_modal_content {
        width: 100%;
        background-color: white;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
    }

    .task_find_kiosk_confirm_modal_img {
        width: 4.444vw;
        height: 4.444vw;
        margin: 2.222vw auto 1.666vw auto;
    }

    .task_find_kiosk_confirm_modal_title {
        font-family: Azo Sans;
        font-size: 3.333vw;
        font-weight: 700;
        line-height: 3.68vw;
        letter-spacing: -0.02em;
        text-align: center;
        color: #1C1CA8;
        margin-bottom: 0.555vw;
    }

    .task_find_kiosk_confirm_modal_description {
        font-family: Circular Std;
        font-size: 1.388vw;
        font-weight: 450;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        text-align: center;
        color: #5B5B62;
        margin-bottom: 1.666vw;
    }

    .task_find_kiosk_confirm_modal_card {
        margin: 0px 1.041vw;
        border: 1px solid #DDDEE1;
        padding: 1.666vw;
        border-radius: 0.555vw;
        margin-bottom: 2.222vw;
    }

    .task_find_kiosk_confirm_modal_card_title {
        font-family: Circular Std;
        font-size: 1.388vw;
        font-weight: 500;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        text-align: center;
        color: #FF4600;
        margin-bottom: 12px;
    }

    .task_find_kiosk_confirm_modal_card_description {
        font-family: Circular Std;
        font-size: 1.111vw;
        font-weight: 450;
        line-height: 1.805vw;
        letter-spacing: -0.01em;
        text-align: center;
        color: #5B5B62;
    }

    .task_find_kiosk_confirm_modal_actions {
        margin: 0px 1.041vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1.666vw;
        margin-bottom: 2.222vw;
    }

    .task_find_kiosk_confirm_modal_actions a {
        width: 100%;
    }

    .task_find_kiosk_confirm_modal_action_btn {
        flex-grow: 1;
    }

    .task_find_kiosk_confirm_modal_tip {
        margin: 0px 2.638vw;
        font-family: Circular Std;
        font-size: 0.972vw;
        font-weight: 400;
        line-height: 1.458vw;
        letter-spacing: -0.01em;
        text-align: center;
        color: #5B5B62;
    }

    .task_find_kiosk_confirm_modal_tip br {
        display: block;
    }
}

@media only screen and (min-width: 1441px) {
    .task_find_kiosk_body {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: white;
        padding-top: 53px;
        padding-bottom: 37px;
    }

    .task_find_kiosk_main {
        grid-row: 1;
        grid-column: 1 / span 6;
    }

    .task_find_kiosk_map {
        grid-row: 1;
        grid-column: 7 / span 6;
        padding-left: 27px;
    }

    .task_find_kiosk_map>div {
        border-radius: 16px;
        min-height: auto !important;
    }

    .task_find_kiosk_main_header {
        display: flex;
        flex-direction: row;
        gap: 50px;
        align-items: center;
        margin-bottom: 38px;
    }

    .task_find_kiosk_main_location_autocomplete {
        width: 432px;
        position: relative;
    }

    .task_find_kiosk_googleplace_autocomplete_input_wrapper {
        height: 66px;
        padding: 20px;
        background: #EEEEF0;
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .task_find_kiosk_googleplace_autocomplete_input_wrapper input {
        flex-grow: 1;
        border: 0px;
        background-color: transparent;
        outline: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .task_find_kiosk_googleplace_autocomplete_input_wrapper img {
        width: 24px;
        height: 24px;
    }

    .task_find_kiosk_main_location_autocomplete .google-places-autocomplete__suggestions-container {
        position: absolute;
        left: 0px;
        width: 100%;
        background-color: white;
    }

    .task_find_kiosk_googleplace_autocomplete_menu_wrapper {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .task_find_kiosk_main_header_right {
        display: flex;
        gap: 6px;
        align-items: center;
        cursor: pointer;
    }

    .task_find_kiosk_main_header_right span {
        font-weight: 700;
        font-size: 16px;
        line-height: 125%;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .task_find_kiosk_main_header_right img {
        width: 24px;
        height: 24px;
    }

    .task_find_kiosk_main_body {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        row-gap: 20px;
        column-gap: 24px;
    }

    .task_find_kiosk_main_location_card {
        background: #FFFFFF;
        border: 1px solid #DDDEE1;
        border-radius: 8px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        gap: 12px;
    }

    .task_find_kiosk_main_location_card_top {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .task_find_kiosk_main_location_card_title {
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .task_find_kiosk_main_location_card_description {
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .task_find_kiosk_main_location_card_action {
        display: flex;
        gap: 6px;
        align-items: center;
    }

    .task_find_kiosk_main_location_card_action span {
        font-weight: 700;
        font-size: 14px;
        line-height: 157%;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .task_find_kiosk_main_location_card_action img {
        width: 24px;
        height: 24px;
        transform: rotate(180deg);
    }

    .task_find_kiosk_main_location_card.active {
        box-shadow: 0px 2px 12px rgba(11, 14, 26, 0.08);
    }

    .task_find_kiosk_main_location_card.active .task_find_kiosk_main_location_card_title {
        font-weight: 400;
        color: #FF4600;
    }

    .task_find_kiosk_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .task_find_kiosk_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        min-height: 900px;
        height: 100vh;
    }

    .task_find_kiosk_modal_body {
        height: fit-content;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 140px;
        left: 50%;
        transform: translateX(-50%);
        width: 557px;
        padding: 72px 23.5px 69px 23.5px;
        background-color: white;
        border-radius: 16px;
    }

    .task_find_kiosk_modal_close_btn {
        position: absolute;
        right: 10px;
        top: 0px;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: 40px;
        height: 40px;
    }

    .task_find_kiosk_modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .task_find_kiosk_modal_content {
        width: 100%;
        background-color: white;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
    }

    .task_find_kiosk_modal_img {
        width: 65px;
        height: 65px;
        margin: 0px auto 24px auto;
    }

    .task_find_kiosk_modal_title {
        font-family: Azo Sans;
        font-size: 48px;
        font-weight: 700;
        line-height: 53px;
        letter-spacing: -0.02em;
        text-align: center;
        color: #1C1CA8;
        margin-bottom: 8px;
    }

    .task_find_kiosk_modal_description {
        padding: 0px 39px;
        font-family: Circular Std;
        font-size: 20px;
        font-weight: 450;
        line-height: 30px;
        letter-spacing: -0.01em;
        text-align: center;
        color: #5B5B62;
        margin-bottom: 32px;
    }

    .task_find_kiosk_modal_btn {
        width: 213px !important;
        height: 50px !important;
        margin: auto;
    }

    .task_find_kiosk_confirm_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .task_find_kiosk_confirm_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        min-height: 900px;
        height: 100vh;
    }

    .task_find_kiosk_confirm_modal_body {
        height: fit-content;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 117px;
        left: 50%;
        transform: translateX(-50%);
        width: 557px;
        padding: 40px;
        background-color: white;
        border-radius: 16px;
    }

    .task_find_kiosk_confirm_modal_close_btn {
        position: absolute;
        right: 10px;
        top: 0px;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: 40px;
        height: 40px;
    }

    .task_find_kiosk_confirm_modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .task_find_kiosk_confirm_modal_content {
        width: 100%;
        background-color: white;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
    }

    .task_find_kiosk_confirm_modal_img {
        width: 64px;
        height: 64px;
        margin: 32px auto 24px auto;
    }

    .task_find_kiosk_confirm_modal_title {
        font-family: Azo Sans;
        font-size: 48px;
        font-weight: 700;
        line-height: 53px;
        letter-spacing: -0.02em;
        text-align: center;
        color: #1C1CA8;
        margin-bottom: 8px;
    }

    .task_find_kiosk_confirm_modal_description {
        font-family: Circular Std;
        font-size: 20px;
        font-weight: 450;
        line-height: 30px;
        letter-spacing: -0.01em;
        text-align: center;
        color: #5B5B62;
        margin-bottom: 24px;
    }

    .task_find_kiosk_confirm_modal_card {
        margin: 0px 15px;
        border: 1px solid #DDDEE1;
        padding: 24px;
        border-radius: 8px;
        margin-bottom: 32px;
    }

    .task_find_kiosk_confirm_modal_card_title {
        font-family: Circular Std;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: -0.01em;
        text-align: center;
        color: #FF4600;
        margin-bottom: 12px;
    }

    .task_find_kiosk_confirm_modal_card_description {
        font-family: Circular Std;
        font-size: 16px;
        font-weight: 450;
        line-height: 26px;
        letter-spacing: -0.01em;
        text-align: center;
        color: #5B5B62;
    }

    .task_find_kiosk_confirm_modal_actions {
        margin: 0px 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 24px;
        margin-bottom: 32px;
    }

    .task_find_kiosk_confirm_modal_actions a {
        width: 100%;
    }

    .task_find_kiosk_confirm_modal_action_btn {
        flex-grow: 1;
    }

    .task_find_kiosk_confirm_modal_tip {
        margin: 0px 38px;
        font-family: Circular Std;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: -0.01em;
        text-align: center;
        color: #5B5B62;
    }

    .task_find_kiosk_confirm_modal_tip br {
        display: block;
    }
}