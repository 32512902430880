@media only screen and (max-width: 480px) {

    .forgot_password_form {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        position: relative;
        overflow: hidden;
        padding-top: 1.388vw;
    }

    .forgot_password_form_left_side_form {
        z-index: 2;
        grid-column: 1 / span 12;
        grid-row: 2;
        width: 100%;
    }

    .forgot_password_form_background_img_desktop {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .forgot_password_form_background_img_tablet {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .forgot_password_form_background_img_mobile {
        display: block;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
    }

    .forgot_password_form_background_img_desktop img,
    .forgot_password_form_background_img_tablet img,
    .forgot_password_form_background_img_mobile img {
        width: calc(100% + 32px);
        transform: translateX(-18px);
        margin-bottom: -1.388vw;
    }

    .forgot_password_form_container {
        width: calc(100% + 4.444vw);
        margin-left: -2.222vw;
        background-color: white;
        border-radius: 16px;
        padding: 10vw 4.444vw;
        margin-bottom: 20vw;
        height: 184.722vw;
    }

    .forgot_password_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 6.666vw;
    }

    .forgot_password_form_logo {
        width: 33.333vw;
        height: 8.611vw;
    }

    .forgot_password_form_title {
        font-size: 6.666vw;
        font-weight: bold;
        line-height: 8.611vw;
        color: #1C1CA8;
        margin-bottom: 4.444vw;
    }

    .forgot_password_form_step {
        font-size: 3.333vw;
        font-weight: 900;
        line-height: 5vw;
        color: #FF4600;
        letter-spacing: 0.02em;
    }

    .forgot_password_form_description {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-size: 4.444vw;
        font-weight: 400;
        line-height: 7.222vw;
        color: #27272F;
        letter-spacing: -0.01em;
        margin-bottom: 6.666vw;
    }

    .forgot_password_form_group {
        margin-bottom: 6.666vw;
        display: flex;
        flex-direction: column;
    }

    .forgot_password_form_label {
        margin-bottom: 2.222vw;
        font-size: 3.888vw;
        line-height: 6.222vw;
        color: #1C1CA8;
        font-weight: 500;
    }

    .forgot_password_form_label.error {
        color: #FF1200;
    }

    .forgot_password_form_input {
        font-size: 4.444vw;
        font-weight: 450;
        line-height: 7.111vw;
        height: 18.333vw;
        background-color: #EEEEF0;
        border-radius: 8px;
        padding: 5.555vw;
        outline: none;
        border: 0px;
    }

    .forgot_password_form_input.error {
        border: 1px solid #FF1200;
    }

    .forgot_password_form_error {
        font-size: 3.888vw;
        line-height: 5.833vw;
        font-weight: 500;
        margin-top: 2.222vw;
        color: #FF1200;
    }

    .forgot_password_form_submit_btn {
        height: 13.888vw;
        background-color: #FF4600;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 9999px;
        padding-left: 5.555vw;
        padding-right: 5.555vw;
    }

    .forgot_password_form_submit_btn:disabled {
        opacity: 0.6;
    }

    .forgot_password_form_submit_btn span {
        font-size: 4.444vw;
        color: white;
        line-height: 5.555vw;
        font-weight: bold;
    }

    .forgot_password_form_submit_btn svg {
        width: 6.111vw;
        height: 6.666vw;
    }

    .forgot_password_form_footer {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5B5B62;
        font-weight: 400;
        font-size: 3.888vw;
        line-height: 5.833vw;
    }

    .forgot_password_form_footer a {
        text-decoration: underline;
        color: #5B5B62;
    }

    .forgot_password_call_numbers {
        margin-bottom: 6.666vw;
    }

    .forgot_password_call_numbers_title {
        font-family: 'Azo Sans';
        font-weight: 700;
        font-size: 3.888vw;
        line-height: 4.722vw;
        text-transform: uppercase;
        color: #0C0C0C;
        margin-bottom: 4.444vw;
        height: 3.333vw;
    }

    .forgot_password_call_numbers_number {
        background: #055CF5;
        border-radius: 32px;
        position: relative;
        display: flex;
        padding-left: 2.777vw;
        padding-right: 2.777vw;
        gap: 2.5vw;
        align-items: center;
        height: 13.888vw;
        cursor: pointer;
    }

    .forgot_password_call_numbers_number img {
        width: 8.333vw;
        height: 8.333vw;
    }

    .forgot_password_call_numbers_number span {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 3.055vw;
        line-height: 3.888vw;
        letter-spacing: 0.09em;
        text-transform: uppercase;
        text-align: center;
        color: #FFFFFF;
        width: 59.444vw;
    }

    .forgot_password_call_numbers_or {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        margin: 4.444vw 0px;
        display: flex;
        justify-content: center;
    }

    .forgot_password_call_numbers_or span {
        font-weight: 400;
        font-size: 3.055vw;
        line-height: 3.888vw;
        text-align: center;
        color: #000000;
        opacity: 0.3;
        width: 12.87vw;
        position: relative;
    }

    .forgot_password_call_numbers_or span::before {
        content: "";
        width: 34.536vw;
        border: 1px solid #878A91;
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
    }

    .forgot_password_call_numbers_or span::after {
        content: "";
        width: 34.536vw;
        border: 1px solid #878A91;
        position: absolute;
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
    }

    .forgot_password_operating_hours {
        margin-bottom: 10.555vw;
    }

    .forgot_password_operating_hours_title {
        font-family: 'Azo Sans';
        font-weight: 700;
        font-size: 3.888vw;
        line-height: 4.722vw;
        text-transform: uppercase;
        color: #0C0C0C;
        margin-bottom: 5.555vw;
    }

    .forgot_password_operating_hours_body {
        display: flex;
        justify-content: space-between;
    }

    .forgot_password_operating_hours_days {
        width: 50%;
    }

    .forgot_password_operating_hours_times {
        width: fit-content;
    }

    .forgot_password_operating_hours_content {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-weight: 400;
        font-size: 3.888vw;
        line-height: 6.944vw;
        color: #666666;
    }

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* personal_details_form starts */

    .forgot_password_form {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        position: relative;
        overflow: hidden;
    }

    .forgot_password_form_left_side_form {
        z-index: 2;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .forgot_password_form_background_img_desktop {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .forgot_password_form_background_img_tablet {
        display: block;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .forgot_password_form_background_img_mobile {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .forgot_password_form_background_img_desktop img,
    .forgot_password_form_background_img_tablet img,
    .forgot_password_form_background_img_mobile img {
        width: calc(100% + 24px);
        transform: translateX(-24px);
        position: absolute;
        bottom: 0;
    }

    .forgot_password_form_container {
        background-color: white;
        border-radius: 16px;
        padding: 4.166vw 3.125vw 3.125vw 3.125vw;
        margin-top: 3.125vw;
        margin-bottom: 4.036vw;
        height: 79.427vw;
    }

    .forgot_password_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3.125vw;
    }

    .forgot_password_form_logo {
        width: 13.595vw;
        height: 4.036vw;
    }

    .forgot_password_form_title {
        font-size: 3.125vw;
        font-weight: bold;
        line-height: 4.036vw;
        color: #1C1CA8;
        margin-bottom: 2.083vw;
    }

    .forgot_password_form_step {
        font-size: 1.562vw;
        font-weight: 900;
        line-height: 2.343vw;
        color: #FF4600;
        letter-spacing: 0.02em;
    }

    .forgot_password_form_description {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-size: 2.083vw;
        font-weight: 400;
        line-height: 3.385vw;
        color: #27272F;
        letter-spacing: -0.01em;
        margin-bottom: 2.213vw;
    }

    .forgot_password_form_group {
        margin-bottom: 2.083vw;
        display: flex;
        flex-direction: column;
    }

    .forgot_password_form_label {
        margin-bottom: 1.041vw;
        font-size: 1.822vw;
        line-height: 2.864vw;
        color: #1C1CA8;
        font-weight: 500;
    }

    .forgot_password_form_label.error {
        color: #FF1200;
    }

    .forgot_password_form_input {
        font-size: 2.083vw;
        font-weight: 450;
        line-height: 3.385vw;
        height: 8.593vw;
        background-color: #EEEEF0;
        border-radius: 8px;
        padding: 2.604vw;
        outline: none;
        border: 0px;
    }

    .forgot_password_form_input.error {
        border: 1px solid #FF1200;
    }

    .forgot_password_form_error {
        font-size: 0.972vw;
        line-height: 1.458vw;
        font-weight: 500;
        margin-top: 0.555vw;
        color: #FF1200;
    }

    .forgot_password_form_submit_btn {
        height: 6.51vw;
        background-color: #FF4600;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 9999px;
        padding-left: 2.604vw;
        padding-right: 2.604vw;
    }

    .forgot_password_form_submit_btn:disabled {
        opacity: 0.6;
    }

    .forgot_password_form_submit_btn span {
        font-size: 2.083vw;
        color: white;
        line-height: 2.604vw;
        font-weight: bold;
    }

    .forgot_password_form_submit_btn svg {
        width: 2.864vw;
        height: 3.125vw;
    }

    .forgot_password_form_footer {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5B5B62;
        font-weight: 400;
        font-size: 1.822vw;
        line-height: 2.734vw;
    }

    .forgot_password_form_footer a {
        text-decoration: underline;
        color: #5B5B62;
    }

    .forgot_password_call_numbers {
        margin-bottom: 2.083vw;
    }

    .forgot_password_call_numbers_title {
        font-family: 'Azo Sans';
        font-weight: 700;
        font-size: 1.822vw;
        line-height: 2.213vw;
        text-transform: uppercase;
        color: #0C0C0C;
        margin-bottom: 2.083vw;
        height: 1.562vw;
    }

    .forgot_password_call_numbers_number {
        background: #055CF5;
        border-radius: 32px;
        position: relative;
        display: flex;
        padding-left: 1.302vw;
        padding-right: 1.302vw;
        gap: 1.041vw;
        align-items: center;
        height: 6.51vw;
        cursor: pointer;
    }

    .forgot_password_call_numbers_number img {
        width: 3.906vw;
        height: 3.906vw;
    }

    .forgot_password_call_numbers_number span {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 1.432vw;
        line-height: 1.822vw;
        letter-spacing: 0.09em;
        text-transform: uppercase;
        text-align: center;
        color: #FFFFFF;
        width: 27.343vw;
    }

    .forgot_password_call_numbers_or {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        margin: 1.041vw 0px;
        display: flex;
        justify-content: center;
    }

    .forgot_password_call_numbers_or span {
        font-weight: 400;
        font-size: 1.432vw;
        line-height: 1.822vw;
        text-align: center;
        color: #000000;
        opacity: 0.3;
        width: 6.25vw;
        position: relative;
    }

    .forgot_password_call_numbers_or span::before {
        content: "";
        width: 16.786vw;
        border: 1px solid #878A91;
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
    }

    .forgot_password_call_numbers_or span::after {
        content: "";
        width: 16.786vw;
        border: 1px solid #878A91;
        position: absolute;
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
    }

    .forgot_password_operating_hours {
        margin-bottom: 3.906vw;
    }

    .forgot_password_operating_hours_title {
        font-family: 'Azo Sans';
        font-weight: 700;
        font-size: 1.822vw;
        line-height: 2.213vw;
        text-transform: uppercase;
        color: #0C0C0C;
        margin-bottom: 1.562vw;
    }

    .forgot_password_operating_hours_body {
        display: flex;
        justify-content: space-between;
    }

    .forgot_password_operating_hours_days {
        width: 50%;
    }

    .forgot_password_operating_hours_times {
        width: 50%;
    }

    .forgot_password_operating_hours_content {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-weight: 400;
        font-size: 1.822vw;
        line-height: 3.255vw;
        color: #666666;
    }

}

@media only screen and (min-width: 601px) and (max-width: 840px) {

    /* personal_details_form starts */

    .forgot_password_form {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        position: relative;
        overflow: hidden;
    }

    .forgot_password_form_left_side_form {
        z-index: 2;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .forgot_password_form_background_img_desktop {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .forgot_password_form_background_img_tablet {
        display: block;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .forgot_password_form_background_img_mobile {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .forgot_password_form_background_img_desktop img,
    .forgot_password_form_background_img_tablet img,
    .forgot_password_form_background_img_mobile img {
        width: calc(100% + 24px);
        transform: translateX(-24px);
        position: absolute;
        bottom: 0;
    }

    .forgot_password_form_container {
        background-color: white;
        border-radius: 16px;
        padding: 4.166vw 3.125vw 3.125vw 3.125vw;
        margin-top: 3.125vw;
        margin-bottom: 4.036vw;
        height: 79.427vw;
    }

    .forgot_password_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3.125vw;
    }

    .forgot_password_form_logo {
        width: 13.595vw;
        height: 4.036vw;
    }

    .forgot_password_form_title {
        font-size: 3.125vw;
        font-weight: bold;
        line-height: 4.036vw;
        color: #1C1CA8;
        margin-bottom: 2.083vw;
    }

    .forgot_password_form_step {
        font-size: 1.562vw;
        font-weight: 900;
        line-height: 2.343vw;
        color: #FF4600;
        letter-spacing: 0.02em;
    }

    .forgot_password_form_description {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-size: 2.083vw;
        font-weight: 400;
        line-height: 3.385vw;
        color: #27272F;
        letter-spacing: -0.01em;
        margin-bottom: 2.213vw;
    }

    .forgot_password_form_group {
        margin-bottom: 2.083vw;
        display: flex;
        flex-direction: column;
    }

    .forgot_password_form_label {
        margin-bottom: 1.041vw;
        font-size: 1.822vw;
        line-height: 2.864vw;
        color: #1C1CA8;
        font-weight: 500;
    }

    .forgot_password_form_label.error {
        color: #FF1200;
    }

    .forgot_password_form_input {
        font-size: 2.083vw;
        font-weight: 450;
        line-height: 3.385vw;
        height: 8.593vw;
        background-color: #EEEEF0;
        border-radius: 8px;
        padding: 2.604vw;
        outline: none;
        border: 0px;
    }

    .forgot_password_form_input.error {
        border: 1px solid #FF1200;
    }

    .forgot_password_form_error {
        font-size: 0.972vw;
        line-height: 1.458vw;
        font-weight: 500;
        margin-top: 0.555vw;
        color: #FF1200;
    }

    .forgot_password_form_submit_btn {
        height: 6.51vw;
        background-color: #FF4600;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 9999px;
        padding-left: 2.604vw;
        padding-right: 2.604vw;
    }

    .forgot_password_form_submit_btn:disabled {
        opacity: 0.6;
    }

    .forgot_password_form_submit_btn span {
        font-size: 2.083vw;
        color: white;
        line-height: 2.604vw;
        font-weight: bold;
    }

    .forgot_password_form_submit_btn svg {
        width: 2.864vw;
        height: 3.125vw;
    }

    .forgot_password_form_footer {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5B5B62;
        font-weight: 400;
        font-size: 1.822vw;
        line-height: 2.734vw;
    }

    .forgot_password_form_footer a {
        text-decoration: underline;
        color: #5B5B62;
    }

    .forgot_password_call_numbers {
        margin-bottom: 2.083vw;
    }

    .forgot_password_call_numbers_title {
        font-family: 'Azo Sans';
        font-weight: 700;
        font-size: 1.822vw;
        line-height: 2.213vw;
        text-transform: uppercase;
        color: #0C0C0C;
        margin-bottom: 2.083vw;
        height: 1.562vw;
    }

    .forgot_password_call_numbers_number {
        background: #055CF5;
        border-radius: 32px;
        position: relative;
        display: flex;
        padding-left: 1.302vw;
        padding-right: 1.302vw;
        gap: 1.041vw;
        align-items: center;
        height: 6.51vw;
        cursor: pointer;
    }

    .forgot_password_call_numbers_number img {
        width: 3.906vw;
        height: 3.906vw;
    }

    .forgot_password_call_numbers_number span {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 1.432vw;
        line-height: 1.822vw;
        letter-spacing: 0.09em;
        text-transform: uppercase;
        text-align: center;
        color: #FFFFFF;
        width: 27.343vw;
    }

    .forgot_password_call_numbers_or {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        margin: 1.041vw 0px;
        display: flex;
        justify-content: center;
    }

    .forgot_password_call_numbers_or span {
        font-weight: 400;
        font-size: 1.432vw;
        line-height: 1.822vw;
        text-align: center;
        color: #000000;
        opacity: 0.3;
        width: 6.25vw;
        position: relative;
    }

    .forgot_password_call_numbers_or span::before {
        content: "";
        width: 16.786vw;
        border: 1px solid #878A91;
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
    }

    .forgot_password_call_numbers_or span::after {
        content: "";
        width: 16.786vw;
        border: 1px solid #878A91;
        position: absolute;
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
    }

    .forgot_password_operating_hours {
        margin-bottom: 3.906vw;
    }

    .forgot_password_operating_hours_title {
        font-family: 'Azo Sans';
        font-weight: 700;
        font-size: 1.822vw;
        line-height: 2.213vw;
        text-transform: uppercase;
        color: #0C0C0C;
        margin-bottom: 1.562vw;
    }

    .forgot_password_operating_hours_body {
        display: flex;
        justify-content: space-between;
    }

    .forgot_password_operating_hours_days {
        width: 50%;
    }

    .forgot_password_operating_hours_times {
        width: 50%;
    }

    .forgot_password_operating_hours_content {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-weight: 400;
        font-size: 1.822vw;
        line-height: 3.255vw;
        color: #666666;
    }

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* personal_details_form starts */
    .forgot_password_form {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        position: relative;
        overflow: hidden;
    }

    .forgot_password_form_left_side_form {
        z-index: 2;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .forgot_password_form_background_img_desktop {
        display: block;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .forgot_password_form_background_img_tablet {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .forgot_password_form_background_img_mobile {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .forgot_password_form_background_img_desktop img,
    .forgot_password_form_background_img_tablet img,
    .forgot_password_form_background_img_mobile img {
        width: calc(100% + 96px);
        transform: translateX(-48px);
        position: absolute;
        bottom: 0;
    }

    .forgot_password_form_container {
        background-color: white;
        border-radius: 16px;
        padding: 2.5vw 1.667vw;
        margin-top: 8.194vw;
        margin-bottom: 5.347vw;
        height: 44.236vw;
    }

    .forgot_password_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.667vw;
    }

    .forgot_password_form_logo {
        width: 9vw;
        height: 2.152vw;
    }

    .forgot_password_form_title {
        font-size: 2.15vw;
        font-weight: bold;
        line-height: 2.79vw;
        color: #1C1CA8;
        margin-bottom: 1.111vw;
    }

    .forgot_password_form_step {
        font-size: 0.97vw;
        font-weight: 900;
        line-height: 1.458vw;
        color: #FF4600;
        letter-spacing: 0.02em;
    }

    .forgot_password_form_description {
        max-width: 22.5vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-size: 1.111vw;
        font-weight: 400;
        line-height: 1.805vw;
        color: #27272F;
        letter-spacing: -0.01em;
        margin-bottom: 1.444vw;
    }

    .forgot_password_form_group {
        margin-bottom: 1.667vw;
        display: flex;
        flex-direction: column;
    }

    .forgot_password_form_label {
        margin-bottom: 0.555vw;
        font-size: 0.972vw;
        line-height: 1.555vw;
        color: #1C1CA8;
        font-weight: 500;
    }

    .forgot_password_form_input {
        font-size: 1.111vw;
        font-weight: 450;
        line-height: 1.777vw;
        height: 4.583vw;
        background-color: #EEEEF0;
        border-radius: 8px;
        padding: 1.388vw;
        outline: none;
        border: 0px;
    }

    .forgot_password_form_input.error {
        border: 1px solid #FF1200;
    }

    .forgot_password_form_error {
        font-size: 0.972vw;
        line-height: 1.458vw;
        font-weight: 500;
        margin-top: 0.555vw;
        color: #FF1200;
    }

    .forgot_password_form_submit_btn {
        height: 4.583vw;
        background-color: #FF4600;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 9999px;
        padding-left: 1.388vw;
        padding-right: 1.388vw;
    }

    .forgot_password_form_submit_btn:disabled {
        opacity: 0.6;
    }

    .forgot_password_form_submit_btn span {
        font-size: 1.111vw;
        color: white;
        line-height: 1.388vw;
        font-weight: bold;
    }

    .forgot_password_form_submit_btn svg {
        width: 1.527vw;
        height: 1.666vw;
    }

    .forgot_password_form_footer {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5B5B62;
        font-weight: 400;
        font-size: 0.972vw;
        line-height: 1.458vw;
    }

    .forgot_password_form_footer a {
        text-decoration: underline;
        color: #5B5B62;
    }

    .forgot_password_call_numbers {
        margin-bottom: 1.25vw;
    }

    .forgot_password_call_numbers_title {
        font-family: 'Azo Sans';
        font-weight: 700;
        font-size: 0.972vw;
        line-height: 1.18vw;
        text-transform: uppercase;
        color: #0C0C0C;
        margin-bottom: 1.319vw;
        height: 0.833vw;
    }

    .forgot_password_call_numbers_number {
        background: #055CF5;
        border-radius: 32px;
        position: relative;
        display: flex;
        padding-left: 0.942vw;
        padding-right: 0.942vw;
        gap: 0.959vw;
        align-items: center;
        height: 3.472vw;
        cursor: pointer;
    }

    .forgot_password_call_numbers_number img {
        width: 2.195vw;
        height: 2.083vw;
    }

    .forgot_password_call_numbers_number span {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 0.763vw;
        line-height: 0.972vw;
        letter-spacing: 0.09em;
        text-transform: uppercase;
        text-align: center;
        color: #FFFFFF;
        width: 18.146vw;
    }

    .forgot_password_call_numbers_or {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        margin: 0.972vw 0px;
        display: flex;
        justify-content: center;
    }

    .forgot_password_call_numbers_or span {
        font-weight: 400;
        font-size: 0.763vw;
        line-height: 0.972vw;
        text-align: center;
        color: #000000;
        opacity: 0.3;
        width: 3.219vw;
        position: relative;
    }

    .forgot_password_call_numbers_or span::before {
        content: "";
        width: 8.634vw;
        border: 1px solid #878A91;
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
    }

    .forgot_password_call_numbers_or span::after {
        content: "";
        width: 8.634vw;
        border: 1px solid #878A91;
        position: absolute;
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
    }

    .forgot_password_operating_hours {
        margin-bottom: 1.875vw;
    }

    .forgot_password_operating_hours_title {
        font-family: 'Azo Sans';
        font-weight: 700;
        font-size: 0.972vw;
        line-height: 1.18vw;
        text-transform: uppercase;
        color: #0C0C0C;
        margin-bottom: 0.416vw;
    }

    .forgot_password_operating_hours_body {
        display: flex;
        justify-content: space-between;
    }

    .forgot_password_operating_hours_days {
        width: 50%;
    }

    .forgot_password_operating_hours_times {
        width: 50%;
        padding-left: 0.833vw;
    }

    .forgot_password_operating_hours_content {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-weight: 400;
        font-size: 0.972vw;
        line-height: 1.736vw;
        color: #666666;
    }

}


@media only screen and (min-width: 1441px) {

    /* personal_details_form starts */
    .forgot_password_form {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        position: relative;
        overflow: hidden;
    }

    .forgot_password_form_left_side_form {
        z-index: 2;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
    }

    .forgot_password_form_background_img_desktop {
        display: block;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .forgot_password_form_background_img_tablet {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .forgot_password_form_background_img_mobile {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .forgot_password_form_background_img_desktop img,
    .forgot_password_form_background_img_tablet img,
    .forgot_password_form_background_img_mobile img {
        width: calc(100% + 96px);
        transform: translateX(-48px);
        position: absolute;
        bottom: 0;
    }

    .forgot_password_form_container {
        background-color: white;
        border-radius: 16px;
        padding: 36px 24px;
        margin-bottom: 77px;
        margin-top: 118px;
        height: 637px;
    }

    .forgot_password_form_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
    }

    .forgot_password_form_logo {
        width: 129.61px;
        height: 31px;
    }

    .forgot_password_form_title {
        font-size: 31px;
        font-weight: bold;
        line-height: 40.3px;
        color: #1C1CA8;
        margin-bottom: 16px;
    }

    .forgot_password_form_step {
        font-size: 14px;
        font-weight: 900;
        line-height: 21px;
        color: #FF4600;
        letter-spacing: 0.02em;
    }

    .forgot_password_form_description {
        max-width: 324px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        color: #27272F;
        letter-spacing: -0.01em;
        margin-bottom: 20.8px;
    }

    .forgot_password_form_group {
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
    }

    .forgot_password_form_label {
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 22.4px;
        color: #1C1CA8;
        font-weight: 500;
    }

    .forgot_password_form_label.error {
        color: #FF1200;
    }

    .forgot_password_form_input {
        font-size: 16px;
        font-weight: 450;
        line-height: 25.6px;
        height: 66px;
        background-color: #EEEEF0;
        border-radius: 8px;
        padding: 20px;
        outline: none;
        border: 0px;
    }

    .forgot_password_form_input.error {
        border: 1px solid #FF1200;
    }

    .forgot_password_form_error {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        margin-top: 8px;
        color: #FF1200;
    }

    .forgot_password_form_submit_btn {
        height: 66px;
        background-color: #FF4600;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 9999px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .forgot_password_form_submit_btn:disabled {
        opacity: 0.6;
    }

    .forgot_password_form_submit_btn span {
        font-size: 16px;
        color: white;
        line-height: 20px;
        font-weight: bold;
    }

    .forgot_password_form_submit_btn svg {
        width: 22px;
        height: 24px;
    }

    .forgot_password_form_footer {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5B5B62;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
    }

    .forgot_password_form_footer a {
        text-decoration: underline;
        color: #5B5B62;
    }

    .forgot_password_call_numbers {
        margin-bottom: 18px;
    }

    .forgot_password_call_numbers_title {
        font-family: 'Azo Sans';
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;
        color: #0C0C0C;
        margin-bottom: 19px;
        height: 12px;
    }

    .forgot_password_call_numbers_number {
        background: #055CF5;
        border-radius: 32px;
        position: relative;
        display: flex;
        padding-left: 13.57px;
        padding-right: 13.57px;
        gap: 13.82px;
        align-items: center;
        height: 50px;
        cursor: pointer;
    }

    .forgot_password_call_numbers_number img {
        width: 31.61px;
        height: 30px;
    }

    .forgot_password_call_numbers_number span {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 11px;
        line-height: 14px;
        letter-spacing: 0.09em;
        text-transform: uppercase;
        text-align: center;
        color: #FFFFFF;
        width: 261.31px;
    }

    .forgot_password_call_numbers_or {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        margin: 14px 0px;
        display: flex;
        justify-content: center;
    }

    .forgot_password_call_numbers_or span {
        font-weight: 400;
        font-size: 11px;
        line-height: 14px;
        text-align: center;
        color: #000000;
        opacity: 0.3;
        width: 46.36px;
        position: relative;
    }

    .forgot_password_call_numbers_or span::before {
        content: "";
        width: 124.33px;
        border: 1px solid #878A91;
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
    }

    .forgot_password_call_numbers_or span::after {
        content: "";
        width: 124.33px;
        border: 1px solid #878A91;
        position: absolute;
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
    }

    .forgot_password_operating_hours {
        margin-bottom: 27px;
    }

    .forgot_password_operating_hours_title {
        font-family: 'Azo Sans';
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;
        color: #0C0C0C;
        margin-bottom: 6px;
    }

    .forgot_password_operating_hours_body {
        display: flex;
        justify-content: space-between;
    }

    .forgot_password_operating_hours_days {
        width: 50%;
    }

    .forgot_password_operating_hours_times {
        width: 50%;
        padding-left: 12px;
    }

    .forgot_password_operating_hours_content {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
        color: #666666;
    }
}