@media only screen and (max-width: 480px) {
    .profile_center_content {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 1 / span 4;
        grid-row: 1;
        position: relative;
        width: calc(100% + 16px);
        margin-left: -8px;
        z-index: 3;
        display: block;
        padding: 0;
        margin-bottom: 4.444vw;
    }

    .profile_center_content_statistic_container {
        width: 100%;
        box-shadow: 0px 2px 12px rgb(11 14 26 / 8%);
        box-sizing: border-box;
        border-radius: 16px;
        margin-bottom: 4.722vw;
        background-color: #055CF5;
        padding: 6.111vw 4.444vw 12.5vw 4.444vw;
    }

    .profile_center_content_top_user_level_container {
        width: 100%;
        margin-bottom: 27px;
        display: none;
    }

    .profile_center_content_top_user_level {
        color: #FFFFFF;
        width: fit-content;
        height: 32px;
        display: flex;
        font-size: 14px;
        align-items: center;
        font-weight: 900;
        line-height: 21px;
        white-space: nowrap;
        padding-left: 15px;
        border-radius: 6px;
        padding-right: 15px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        justify-content: center;
        background-color: #FFFFFF33;
    }

    .profile_center_content_statistic_container_div {
        display: flex;
        gap: 9.166vw;
    }

    .profile_center_content_statistic_percent_container {
        flex: none;
        width: 34.722vw;
        height: 34.722vw;
        position: relative;
    }

    .profile_center_content_statistic_percent_container .MuiCircularProgress-determinate {
        max-width: 100%;
        max-height: 100%;
    }

    .profile_center_content_bg_circular_pg {
        top: 0;
        left: 0;
        color: #377df7 !important;
        position: absolute;
    }

    .profile_center_content_circular_pg {
        color: #ffffff !important;
    }

    .profile_center_content_text_pg_container {
        top: 0;
        left: 0;
        color: #fff;
        right: 0;
        bottom: 0;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
    }

    .profile_center_content_statistic_percent_text {
        font-size: 10.833vw;
        font-family: 'Azo Sans', sans-serif !important;
        font-weight: 700;
        line-height: 14.083vw;
        letter-spacing: -0.02em;
    }

    .profile_center_content_statistic_percent_symbol {
        font-size: 6.666vw;
        font-family: 'Azo Sans', sans-serif !important;
        font-weight: 700;
        line-height: 9.333vw;
        padding-top: 1.666vw;
        letter-spacing: -0.02em;
    }

    .profile_center_content_static_right_container {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
    }

    .profile_center_content_static_text_container {
        padding-top: 0px;
    }

    .profile_center_content_user_level_mobile {
        display: block;
        width: 100%;
        margin-bottom: 6.388vw;
    }

    .profile_center_content_user_level_mobile .profile_center_content_user_level {
        display: flex;
    }

    .profile_center_content_user_level {
        color: #FFFFFF;
        width: fit-content;
        height: 8.888vw;
        display: none;
        font-size: 3.888vw;
        align-items: center;
        font-weight: 900;
        line-height: 5.833vw;
        white-space: nowrap;
        padding-left: 4.166vw;
        border-radius: 6px;
        margin-bottom: 0px;
        padding-right: 4.166vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        justify-content: center;
        background-color: #FFFFFF33;
    }

    .profile_center_content_points_earned {
        color: #FFFFFF;
        font-weight: 700;
        letter-spacing: -0.01em;
        font-size: 5.555vw;
        line-height: 8.333vw;
        margin-bottom: 3.888vw;
    }

    .profile_center_content_mobile_enter {
        display: block;
    }

    .profile_center_content_tasks_done {
        color: #FFFFFF;
        font-weight: 700;
        letter-spacing: -0.01em;
        font-size: 5.555vw;
        line-height: 8.333vw;
    }

    .profile_center_content_statistic_icon_container {
        display: none;
        width: 100px;
        padding-top: 9px;
    }

    .profile_center_content_statistic_icon_container img {
        width: 100%;
    }

    .profile_center_content_form_container {
        background-color: white;
        border-radius: 4.444vw;
        padding: 6.666vw;
    }

    .profile_center_content_form_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 8.611vw;
        line-height: 130%;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24.08px;
    }

    .profile_center_content_form_row {
        display: flex;
        flex-direction: column;
        gap: 6.666vw;
        margin-bottom: 6.666vw;
    }

    .profile_center_content_form_col {
        width: 100%;
    }

    .profile_center_content_form_group {
        width: 100%;
    }

    .profile_center_content_form_label {
        font-weight: 500;
        font-size: 3.888vw;
        line-height: 160%;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        margin-bottom: 2.222vw;
    }

    .profile_center_content_form_error {
        font-size: 3.888vw;
        line-height: 5.833vw;
        font-weight: 500;
        margin-top: 2.222vw;
        color: #FF1200;
    }

    .profile_center_content_form_actions {
        margin-top: 2.222vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .profile_center_content_form_save {
        width: 100% !important;
        margin-bottom: 6.666vw;
    }

    .profile_center_content_form_delete {
        width: fit-content;
        height: fit-content;
        background-color: transparent;
        outline: none;
        border: none;
        font-weight: 700;
        font-size: 16px;
        line-height: 125%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .profile_delete_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 1000;
        width: 100vw;
        height: 100vh;
    }

    .profile_delete_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .profile_delete_modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
    }

    .profile_delete_modal_close_btn {
        position: absolute;
        right: 0.763vw;
        top: 0px;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: 11.111vw;
        height: 11.111vw;
    }

    .profile_delete_modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .profile_delete_modal_content {
        width: 100%;
        padding: 8.888vw 6.666vw 11.111vw 6.666vw;
        background-color: white;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        display: flex;
        flex-direction: column;
    }

    .profile_delete_modal_icon {
        width: 18.055vw;
        height: 18.055vw;
        margin: auto;
        margin-bottom: 4.166vw;
    }

    .profile_delete_modal_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 48px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24px;
    }

    .profile_delete_modal_title_otp {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 31px;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 16px;
    }

    .profile_delete_modal_description {
        font-weight: 450;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 32px;
    }

    .profile_delete_modal_description_otp {
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #27272F;
        width: 324px;
        margin-bottom: 40px;
        margin-left: auto;
        margin-right: auto;
    }

    .profile_delete_modal_resend_otp {
        cursor: pointer;
        font-weight: 700;
        font-size: 16px;
        line-height: 125%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        margin-bottom: 32px;
    }

    .profile_delete_otp_input_container {
        width: 100% !important;
        padding-left: 52px;
        padding-right: 52px;
        height: 71px;
        margin-bottom: 24px;
    }

    .profile_delete_otp_input_container .styles_react-code-input__CRulA {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
    }

    .profile_delete_otp_input_container .styles_react-code-input__CRulA>input {
        width: 71px !important;
        height: 71px !important;
        border: 0px !important;
        border-radius: 9999px !important;
        background-color: #EEEEF0 !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        font-weight: 500;
        font-size: 25px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif !important;
    }

    .profile_delete_modal_footer_otp {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-top: 32px;
    }

    .profile_delete_modal_actions {
        display: flex;
        gap: 4.444vw;
    }

    .profile_delete_modal_cancel {
        margin: auto;
    }

    .profile_delete_modal_delete {
        margin: auto;
    }

    .profile_delete_modal_notification {
        margin-bottom: 32px;
        align-items: flex-start;
    }

    .profile_right_content {
        grid-row: 2;
        grid-column: 1 / span 4;
        width: calc(100% + 16px);
        margin-left: -8px;
        position: relative;
        z-index: 0;
    }

    .profile_right_content_benefit {
        padding: 6.666vw 4.444vw;
        background-color: white;
        border-radius: 16px;
        margin-bottom: 4.444vw;
    }

    .profile_right_content_header {
        display: flex;
        gap: 3.333vw;
        align-items: center;
        margin-bottom: 3.333vw;
    }

    .profile_right_content_header img {
        width: 6.666vw;
        height: 6.666vw;
    }

    .profile_right_content_header span {
        font-weight: 900;
        font-size: 3.888vw;
        line-height: 5.833vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
    }

    .profile_right_content_benefit_title {
        font-weight: 500;
        font-size: 5.555vw;
        line-height: 8.333vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 3.333vw;
    }

    .profile_right_content_benefits {
        display: flex;
        flex-direction: column;
        gap: 2.777vw;
    }

    .profile_right_content_extra_container {
        position: relative;
        padding: 4.444vw;
        background-color: #FF4600;
        border-radius: 16px;
        overflow: hidden;
    }

    .profile_right_content_extra_header {
        display: flex;
        gap: 4.444vw;
        align-items: center;
        margin-bottom: 2.777vw;
    }

    .profile_right_content_extra_header img {
        width: 15.555vw;
        height: 15.555vw;
    }

    .profile_right_content_extra_header span {
        font-weight: 700;
        font-size: 5.555vw;
        line-height: 7.777vw;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .profile_right_content_extra_description {
        font-weight: 500;
        font-size: 4.444vw;
        line-height: 6.666vw;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 5vw;
        width: 45.833vw;
    }

    .profile_right_content_start_training_btn {
        width: 44.722vw;
        height: 9.444vw;
        background-color: #1C1CA8;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        font-weight: 700;
        font-size: 3.888vw;
        line-height: 6.111vw;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 1.666vw;
    }

    .profile_right_content_extra_bottom_circle {
        position: absolute;
        border-radius: 9999px;
        background-color: #1C1CA8;
        width: 44.722vw;
        height: 44.722vw;
        bottom: -7.777vw;
        right: -10vw;
    }

    .profile_right_content_bottom_image {
        position: absolute;
        right: -5.161vw;
        width: 34.722vw;
        bottom: -2.777vw;
        height: 50vw;
    }

    .profile_googleplace_autocomplete_input_wrapper {
        height: 18.333vw;
        padding: 5.555vw;
        background: #EEEEF0;
        border-radius: 2.222vw;
        display: flex;
        align-items: center;
        gap: 3.333vw;
    }

    .profile_googleplace_autocomplete_input_wrapper input {
        flex-grow: 1;
        border: 0px;
        background-color: transparent;
        outline: none;
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .profile_googleplace_autocomplete_input_wrapper img {
        width: 6.666vw;
        height: 6.666vw;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .profile_center_content {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 3 / span 4;
        grid-row: 1;
        position: relative;
        width: 100%;
        z-index: 3;
        display: block;
        padding: 0;
        margin-bottom: 6.875vw;
    }

    .profile_center_content_statistic_container {
        width: 100%;
        box-shadow: 0px 2px 12px rgb(11 14 26 / 8%);
        box-sizing: border-box;
        border-radius: 1.111vw;
        margin-bottom: 1.25vw;
        background-color: #055CF5;
        padding: 2.5vw 4.166vw 2.361vw 2.013vw;
    }

    .profile_center_content_top_user_level_container {
        width: 100%;
        margin-bottom: 1.875vw;
        display: none;
    }

    .profile_center_content_top_user_level {
        color: #FFFFFF;
        width: fit-content;
        height: 2.222vw;
        display: flex;
        font-size: 0.972vw;
        align-items: center;
        font-weight: 900;
        line-height: 1.458vw;
        white-space: nowrap;
        padding-left: 1.041vw;
        border-radius: 0.41vw;
        padding-right: 1.041vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        justify-content: center;
        background-color: #FFFFFF33;
    }

    .profile_center_content_statistic_container_div {
        display: flex;
        gap: 2.847vw;
    }

    .profile_center_content_statistic_percent_container {
        flex: none;
        width: 9.513vw;
        height: 9.513vw;
        position: relative;
    }

    .profile_center_content_statistic_percent_container .MuiCircularProgress-determinate {
        max-width: 100%;
        max-height: 100%;
    }

    .profile_center_content_bg_circular_pg {
        top: 0;
        left: 0;
        color: #377df7 !important;
        position: absolute;
    }

    .profile_center_content_circular_pg {
        color: #ffffff !important;
    }

    .profile_center_content_text_pg_container {
        top: 0;
        left: 0;
        color: #fff;
        right: 0;
        bottom: 0;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
    }

    .profile_center_content_statistic_percent_text {
        font-size: 2.708vw;
        font-family: 'Azo Sans', sans-serif !important;
        font-weight: 700;
        line-height: 3.52vw;
        letter-spacing: -0.02em;
    }

    .profile_center_content_statistic_percent_symbol {
        font-size: 1.666vw;
        font-family: 'Azo Sans', sans-serif !important;
        font-weight: 700;
        line-height: 2.333vw;
        padding-top: 0.416vw;
        letter-spacing: -0.02em;
    }

    .profile_center_content_static_right_container {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
    }

    .profile_center_content_static_text_container {
        padding-top: 0.416vw;
    }

    .profile_center_content_user_level_mobile {
        display: none;
        width: 100%;
        margin-bottom: 6.388vw;
    }

    .profile_center_content_user_level {
        color: #FFFFFF;
        width: fit-content;
        height: 2.222vw;
        display: flex;
        font-size: .972vw;
        align-items: center;
        font-weight: 900;
        line-height: 1.458vw;
        white-space: nowrap;
        padding-left: 1.041vw;
        border-radius: 0.416vw;
        margin-bottom: 0.833vw;
        padding-right: 1.041vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        justify-content: center;
        background-color: #FFFFFF33;
    }

    .profile_center_content_points_earned {
        color: #FFFFFF;
        font-weight: 700;
        letter-spacing: -0.01em;
        font-size: 1.736vw;
        line-height: 2.604vw;
    }

    .profile_center_content_mobile_enter {
        display: none;
    }

    .profile_center_content_tasks_done {
        color: #FFFFFF;
        font-size: 1.736vw;
        font-weight: 700;
        line-height: 2.604vw;
        letter-spacing: -0.01em;
    }

    .profile_center_content_statistic_icon_container {
        display: block;
        width: 6.944vw;
        padding-top: 0.625vw;
    }

    .profile_center_content_statistic_icon_container img {
        width: 100%;
    }

    .profile_center_content_form_container {
        background-color: white;
        border-radius: 1.111vw;
        padding: 1.689vw 1.666vw 1.666vw 1.666vw;
    }

    .profile_center_content_form_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 2.152vw;
        line-height: 130%;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.672vw;
    }

    .profile_center_content_form_row {
        display: flex;
        flex-direction: row;
        gap: 1.666vw;
        margin-bottom: 1.666vw;
    }

    .profile_center_content_form_col {
        width: 100%;
    }

    .profile_center_content_form_group {
        width: 100%;
    }

    .profile_center_content_form_label {
        font-weight: 500;
        font-size: 0.972vw;
        line-height: 160%;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        margin-bottom: 0.555vw;
    }

    .profile_center_content_form_error {
        font-size: 0.972vw;
        line-height: 1.458vw;
        font-weight: 500;
        margin-top: 0.555vw;
        color: #FF1200;
    }

    .profile_center_content_form_actions {
        margin-top: 0.555vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .profile_center_content_form_save {
        width: 18.194vw !important;
    }

    .profile_center_content_form_delete {
        width: fit-content;
        height: fit-content;
        background-color: transparent;
        outline: none;
        border: none;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 125%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .profile_delete_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 1000;
        width: 100vw;
        height: 100vh;
    }

    .profile_delete_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .profile_delete_modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 23.263vw;
        left: 50%;
        transform: translateX(-50%);
        width: 38.68vw;
    }

    .profile_delete_modal_close_btn {
        position: absolute;
        right: 0.763vw;
        top: 0px;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: 2.777vw;
        height: 2.777vw;
    }

    .profile_delete_modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .profile_delete_modal_content {
        width: 100%;
        padding: 5vw 4.222vw 5.555vw 4.222vw;
        background-color: white;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
    }

    .profile_delete_modal_icon {
        width: 4.444vw;
        height: 4.444vw;
        margin: auto;
        margin-bottom: 1.666vw;
    }

    .profile_delete_modal_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 3.333vw;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.666vw;
    }

    .profile_delete_modal_title_otp {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 2.152vw;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.111vw;
    }

    .profile_delete_modal_description {
        font-weight: 450;
        font-size: 1.388vw;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 2.222vw;
    }

    .profile_delete_modal_description_otp {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #27272F;
        width: 22.5vw;
        margin-bottom: 2.777vw;
        margin-left: auto;
        margin-right: auto;
    }

    .profile_delete_modal_resend_otp {
        cursor: pointer;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 125%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        margin-bottom: 2.222vw;
    }

    .profile_delete_otp_input_container {
        width: 100% !important;
        padding-left: 3.611vw;
        padding-right: 3.611vw;
        height: 4.93vw;
        margin-bottom: 1.666vw;
    }

    .profile_delete_otp_input_container .styles_react-code-input__CRulA {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1.111vw;
    }

    .profile_delete_otp_input_container .styles_react-code-input__CRulA>input {
        width: 4.93vw !important;
        height: 4.93vw !important;
        border: 0px !important;
        border-radius: 9999px !important;
        background-color: #EEEEF0 !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        font-weight: 500;
        font-size: 1.736vw;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif !important;
    }

    .profile_delete_modal_footer_otp {
        font-weight: 400;
        font-size: 0.972vw;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-top: 2.222vw;
    }

    .profile_delete_modal_actions {
        display: flex;
        gap: 1.111vw;
    }

    .profile_delete_modal_cancel {
        margin: auto;
    }

    .profile_delete_modal_delete {
        margin: auto;
    }

    .profile_delete_modal_notification {
        margin-bottom: 2.222vw;
        align-items: flex-start;
    }

    .profile_right_content {
        grid-row: 1;
        grid-column: 7 / span 2;
        position: relative;
        z-index: 0;
    }

    .profile_right_content_benefit {
        padding: 1.666vw;
        background-color: white;
        border-radius: 1.111vw;
        margin-bottom: 1.666vw;
    }

    .profile_right_content_header {
        display: flex;
        gap: 0.833vw;
        align-items: center;
        margin-bottom: 0.833vw;
    }

    .profile_right_content_header img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .profile_right_content_header span {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
    }

    .profile_right_content_benefit_title {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 0.833vw;
    }

    .profile_right_content_benefits {
        display: flex;
        flex-direction: column;
        gap: 0.694vw;
    }

    .profile_right_content_extra_container {
        position: relative;
        padding: 1.111vw;
        background-color: #FF4600;
        border-radius: 1.111vw;
        overflow: hidden;
    }

    .profile_right_content_extra_header {
        display: flex;
        gap: 1.111vw;
        align-items: center;
        margin-bottom: 0.694vw;
    }

    .profile_right_content_extra_header img {
        width: 3.888vw;
        height: 3.888vw;
    }

    .profile_right_content_extra_header span {
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 1.944vw;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .profile_right_content_extra_description {
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.666vw;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 1.25vw;
        width: 11.458vw;
    }

    .profile_right_content_start_training_btn {
        width: 11.18vw;
        height: 2.361vw;
        background-color: #1C1CA8;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.555vw;
        font-weight: 700;
        font-size: 0.972vw;
        line-height: 1.527vw;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 0.416vw;
    }

    .profile_right_content_extra_bottom_circle {
        position: absolute;
        border-radius: 9999px;
        background-color: #1C1CA8;
        width: 11.18vw;
        height: 11.18vw;
        bottom: -1.944vw;
        right: -2.5vw;
    }

    .profile_right_content_bottom_image {
        position: absolute;
        right: -1.29vw;
        width: 8.68vw;
        bottom: -0.694vw;
        height: 12.5vw;
    }

    .profile_googleplace_autocomplete_input_wrapper {
        height: 4.583vw;
        padding: 1.388vw;
        background: #EEEEF0;
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 0.833vw;
    }

    .profile_googleplace_autocomplete_input_wrapper input {
        flex-grow: 1;
        border: 0px;
        background-color: transparent;
        outline: none;
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .profile_googleplace_autocomplete_input_wrapper img {
        width: 1.666vw;
        height: 1.666vw;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .profile_center_content {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 4 / span 6;
        grid-row: 1;
        position: relative;
        width: 100%;
        z-index: 3;
        display: block;
        padding: 0;
        margin-bottom: 6.875vw;
    }

    .profile_center_content_statistic_container {
        width: 100%;
        box-shadow: 0px 2px 12px rgb(11 14 26 / 8%);
        box-sizing: border-box;
        border-radius: 1.111vw;
        margin-bottom: 1.25vw;
        background-color: #055CF5;
        padding: 2.5vw 4.166vw 2.361vw 2.013vw;
    }

    .profile_center_content_top_user_level_container {
        width: 100%;
        margin-bottom: 1.875vw;
        display: none;
    }

    .profile_center_content_top_user_level {
        color: #FFFFFF;
        width: fit-content;
        height: 2.222vw;
        display: flex;
        font-size: 0.972vw;
        align-items: center;
        font-weight: 900;
        line-height: 1.458vw;
        white-space: nowrap;
        padding-left: 1.041vw;
        border-radius: 0.41vw;
        padding-right: 1.041vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        justify-content: center;
        background-color: #FFFFFF33;
    }

    .profile_center_content_statistic_container_div {
        display: flex;
        gap: 2.847vw;
    }

    .profile_center_content_statistic_percent_container {
        flex: none;
        width: 9.513vw;
        height: 9.513vw;
        position: relative;
    }

    .profile_center_content_statistic_percent_container .MuiCircularProgress-determinate {
        max-width: 100%;
        max-height: 100%;
    }

    .profile_center_content_bg_circular_pg {
        top: 0;
        left: 0;
        color: #377df7 !important;
        position: absolute;
    }

    .profile_center_content_circular_pg {
        color: #ffffff !important;
    }

    .profile_center_content_text_pg_container {
        top: 0;
        left: 0;
        color: #fff;
        right: 0;
        bottom: 0;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
    }

    .profile_center_content_statistic_percent_text {
        font-size: 2.708vw;
        font-family: 'Azo Sans', sans-serif !important;
        font-weight: 700;
        line-height: 3.52vw;
        letter-spacing: -0.02em;
    }

    .profile_center_content_statistic_percent_symbol {
        font-size: 1.666vw;
        font-family: 'Azo Sans', sans-serif !important;
        font-weight: 700;
        line-height: 2.333vw;
        padding-top: 0.416vw;
        letter-spacing: -0.02em;
    }

    .profile_center_content_static_right_container {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
    }

    .profile_center_content_static_text_container {
        padding-top: 0.416vw;
    }

    .profile_center_content_user_level_mobile {
        display: none;
        width: 100%;
        margin-bottom: 6.388vw;
    }

    .profile_center_content_user_level {
        color: #FFFFFF;
        width: fit-content;
        height: 2.222vw;
        display: flex;
        font-size: .972vw;
        align-items: center;
        font-weight: 900;
        line-height: 1.458vw;
        white-space: nowrap;
        padding-left: 1.041vw;
        border-radius: 0.416vw;
        margin-bottom: 0.833vw;
        padding-right: 1.041vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        justify-content: center;
        background-color: #FFFFFF33;
    }

    .profile_center_content_points_earned {
        color: #FFFFFF;
        font-weight: 700;
        letter-spacing: -0.01em;
        font-size: 1.736vw;
        line-height: 2.604vw;
    }

    .profile_center_content_mobile_enter {
        display: none;
    }

    .profile_center_content_tasks_done {
        color: #FFFFFF;
        font-size: 1.736vw;
        font-weight: 700;
        line-height: 2.604vw;
        letter-spacing: -0.01em;
    }

    .profile_center_content_statistic_icon_container {
        display: block;
        width: 6.944vw;
        padding-top: 0.625vw;
    }

    .profile_center_content_statistic_icon_container img {
        width: 100%;
    }

    .profile_center_content_form_container {
        background-color: white;
        border-radius: 1.111vw;
        padding: 1.689vw 1.666vw 1.666vw 1.666vw;
    }

    .profile_center_content_form_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 2.152vw;
        line-height: 130%;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.672vw;
    }

    .profile_center_content_form_row {
        display: flex;
        flex-direction: row;
        gap: 1.666vw;
        margin-bottom: 1.666vw;
    }

    .profile_center_content_form_col {
        width: 100%;
    }

    .profile_center_content_form_group {
        width: 100%;
    }

    .profile_center_content_form_label {
        font-weight: 500;
        font-size: 0.972vw;
        line-height: 160%;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        margin-bottom: 0.555vw;
    }

    .profile_center_content_form_error {
        font-size: 0.972vw;
        line-height: 1.458vw;
        font-weight: 500;
        margin-top: 0.555vw;
        color: #FF1200;
    }

    .profile_center_content_form_actions {
        margin-top: 0.555vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .profile_center_content_form_save {
        width: 18.194vw !important;
    }

    .profile_center_content_form_delete {
        width: fit-content;
        height: fit-content;
        background-color: transparent;
        outline: none;
        border: none;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 125%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .profile_delete_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 1000;
        width: 100vw;
        height: 100vh;
    }

    .profile_delete_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .profile_delete_modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 23.263vw;
        left: 50%;
        transform: translateX(-50%);
        width: 38.68vw;
    }

    .profile_delete_modal_close_btn {
        position: absolute;
        right: 0.763vw;
        top: 0px;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: 2.777vw;
        height: 2.777vw;
    }

    .profile_delete_modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .profile_delete_modal_content {
        width: 100%;
        padding: 5vw 4.222vw 5.555vw 4.222vw;
        background-color: white;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
    }

    .profile_delete_modal_icon {
        width: 4.444vw;
        height: 4.444vw;
        margin: auto;
        margin-bottom: 1.666vw;
    }

    .profile_delete_modal_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 3.333vw;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.666vw;
    }

    .profile_delete_modal_title_otp {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 2.152vw;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.111vw;
    }

    .profile_delete_modal_description {
        font-weight: 450;
        font-size: 1.388vw;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 2.222vw;
    }

    .profile_delete_modal_description_otp {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #27272F;
        width: 22.5vw;
        margin-bottom: 2.777vw;
        margin-left: auto;
        margin-right: auto;
    }

    .profile_delete_modal_resend_otp {
        cursor: pointer;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 125%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        margin-bottom: 2.222vw;
    }

    .profile_delete_otp_input_container {
        width: 100% !important;
        padding-left: 3.611vw;
        padding-right: 3.611vw;
        height: 4.93vw;
        margin-bottom: 1.666vw;
    }

    .profile_delete_otp_input_container .styles_react-code-input__CRulA {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1.111vw;
    }

    .profile_delete_otp_input_container .styles_react-code-input__CRulA>input {
        width: 4.93vw !important;
        height: 4.93vw !important;
        border: 0px !important;
        border-radius: 9999px !important;
        background-color: #EEEEF0 !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        font-weight: 500;
        font-size: 1.736vw;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif !important;
    }

    .profile_delete_modal_footer_otp {
        font-weight: 400;
        font-size: 0.972vw;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-top: 2.222vw;
    }

    .profile_delete_modal_actions {
        display: flex;
        gap: 1.111vw;
    }

    .profile_delete_modal_cancel {
        margin: auto;
    }

    .profile_delete_modal_delete {
        margin: auto;
    }

    .profile_delete_modal_notification {
        margin-bottom: 2.222vw;
        align-items: flex-start;
    }

    .profile_right_content {
        grid-row: 1;
        grid-column: 10 / span 3;
        position: relative;
        z-index: 0;
    }

    .profile_right_content_benefit {
        padding: 1.666vw;
        background-color: white;
        border-radius: 1.111vw;
        margin-bottom: 1.666vw;
    }

    .profile_right_content_header {
        display: flex;
        gap: 0.833vw;
        align-items: center;
        margin-bottom: 0.833vw;
    }

    .profile_right_content_header img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .profile_right_content_header span {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
    }

    .profile_right_content_benefit_title {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 0.833vw;
    }

    .profile_right_content_benefits {
        display: flex;
        flex-direction: column;
        gap: 0.694vw;
    }

    .profile_right_content_extra_container {
        position: relative;
        padding: 1.111vw;
        background-color: #FF4600;
        border-radius: 1.111vw;
        overflow: hidden;
    }

    .profile_right_content_extra_header {
        display: flex;
        gap: 1.111vw;
        align-items: center;
        margin-bottom: 0.694vw;
    }

    .profile_right_content_extra_header img {
        width: 3.888vw;
        height: 3.888vw;
    }

    .profile_right_content_extra_header span {
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 1.944vw;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .profile_right_content_extra_description {
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.666vw;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 1.25vw;
        width: 11.458vw;
    }

    .profile_right_content_start_training_btn {
        width: 11.18vw;
        height: 2.361vw;
        background-color: #1C1CA8;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.555vw;
        font-weight: 700;
        font-size: 0.972vw;
        line-height: 1.527vw;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 0.416vw;
    }

    .profile_right_content_extra_bottom_circle {
        position: absolute;
        border-radius: 9999px;
        background-color: #1C1CA8;
        width: 11.18vw;
        height: 11.18vw;
        bottom: -1.944vw;
        right: -2.5vw;
    }

    .profile_right_content_bottom_image {
        position: absolute;
        right: -1.29vw;
        width: 8.68vw;
        bottom: -0.694vw;
        height: 12.5vw;
    }

    .profile_googleplace_autocomplete_input_wrapper {
        height: 4.583vw;
        padding: 1.388vw;
        background: #EEEEF0;
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 0.833vw;
    }

    .profile_googleplace_autocomplete_input_wrapper input {
        flex-grow: 1;
        border: 0px;
        background-color: transparent;
        outline: none;
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .profile_googleplace_autocomplete_input_wrapper img {
        width: 1.666vw;
        height: 1.666vw;
    }
}

@media only screen and (min-width: 1441px) {
    .profile_center_content {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 4 / span 6;
        grid-row: 1;
        position: relative;
        width: 100%;
        z-index: 3;
        display: block;
        padding: 0;
        margin-bottom: 99px;
    }

    .profile_center_content_statistic_container {
        width: 100%;
        box-shadow: 0px 2px 12px rgb(11 14 26 / 8%);
        box-sizing: border-box;
        border-radius: 16px;
        margin-bottom: 18px;
        background-color: #055CF5;
        padding: 36px 60px 34px 29px;
    }

    .profile_center_content_top_user_level_container {
        width: 100%;
        margin-bottom: 27px;
        display: none;
    }

    .profile_center_content_top_user_level {
        color: #FFFFFF;
        width: fit-content;
        height: 32px;
        display: flex;
        font-size: 14px;
        align-items: center;
        font-weight: 900;
        line-height: 21px;
        white-space: nowrap;
        padding-left: 15px;
        border-radius: 6px;
        padding-right: 15px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        justify-content: center;
        background-color: #FFFFFF33;
    }

    .profile_center_content_statistic_container_div {
        display: flex;
        gap: 41px;
    }

    .profile_center_content_statistic_percent_container {
        flex: none;
        width: 137px;
        height: 137px;
        position: relative;
    }

    .profile_center_content_statistic_percent_container .MuiCircularProgress-determinate {
        max-width: 100%;
        max-height: 100%;
    }

    .profile_center_content_bg_circular_pg {
        top: 0;
        left: 0;
        color: #377df7 !important;
        position: absolute;
    }

    .profile_center_content_circular_pg {
        color: #ffffff !important;
    }

    .profile_center_content_text_pg_container {
        top: 0;
        left: 0;
        color: #fff;
        right: 0;
        bottom: 0;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
    }

    .profile_center_content_statistic_percent_text {
        font-size: 39px;
        font-family: 'Azo Sans', sans-serif !important;
        font-weight: 700;
        line-height: 50.7px;
        letter-spacing: -0.02em;
    }

    .profile_center_content_statistic_percent_symbol {
        font-size: 24px;
        font-family: 'Azo Sans', sans-serif !important;
        font-weight: 700;
        line-height: 33.6px;
        padding-top: 6px;
        letter-spacing: -0.02em;
    }

    .profile_center_content_static_right_container {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
    }

    .profile_center_content_static_text_container {
        padding-top: 6px;
    }

    .profile_center_content_user_level_mobile {
        display: none;
        width: 100%;
        margin-bottom: 6.388vw;
    }

    .profile_center_content_user_level {
        color: #FFFFFF;
        width: fit-content;
        height: 32px;
        display: flex;
        font-size: 14px;
        align-items: center;
        font-weight: 900;
        line-height: 21px;
        white-space: nowrap;
        padding-left: 15px;
        border-radius: 6px;
        margin-bottom: 12px;
        padding-right: 15px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        justify-content: center;
        background-color: #FFFFFF33;
    }

    .profile_center_content_points_earned {
        color: #FFFFFF;
        font-weight: 700;
        letter-spacing: -0.01em;
        font-size: 25px;
        line-height: 37.5px;
    }

    .profile_center_content_mobile_enter {
        display: none;
    }

    .profile_center_content_tasks_done {
        color: #FFFFFF;
        font-weight: 700;
        line-height: 37.5px;
        letter-spacing: -0.01em;
        font-size: 25px;
        line-height: 37.5px
    }

    .profile_center_content_statistic_icon_container {
        display: block;
        width: 100px;
        padding-top: 9px;
    }

    .profile_center_content_statistic_icon_container img {
        width: 100%;
    }

    .profile_center_content_form_container {
        background-color: white;
        border-radius: 16px;
        padding: 24.33px 24px 24px 24px;
    }

    .profile_center_content_form_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 31px;
        line-height: 130%;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24.08px;
    }

    .profile_center_content_form_row {
        display: flex;
        flex-direction: row;
        gap: 24px;
        margin-bottom: 24px;
    }

    .profile_center_content_form_col {
        width: 100%;
    }

    .profile_center_content_form_group {
        width: 100%;
    }

    .profile_center_content_form_label {
        font-weight: 500;
        font-size: 14px;
        line-height: 160%;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        margin-bottom: 8px;
    }

    .profile_center_content_form_error {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        margin-top: 8px;
        color: #FF1200;
    }

    .profile_center_content_form_actions {
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .profile_center_content_form_save {
        width: 262px !important;
    }

    .profile_center_content_form_delete {
        width: fit-content;
        height: fit-content;
        background-color: transparent;
        outline: none;
        border: none;
        font-weight: 700;
        font-size: 16px;
        line-height: 125%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .profile_delete_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 1000;
        width: 100vw;
        height: 100vh;
    }

    .profile_delete_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .profile_delete_modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 335px;
        left: 50%;
        transform: translateX(-50%);
        width: 557px;
    }

    .profile_delete_modal_close_btn {
        position: absolute;
        right: 11px;
        top: 0px;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: 40px;
        height: 40px;
    }

    .profile_delete_modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .profile_delete_modal_content {
        width: 100%;
        padding: 72px 60.8px 80px 60.8px;
        background-color: white;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
    }

    .profile_delete_modal_icon {
        width: 64px;
        height: 64px;
        margin: auto;
        margin-bottom: 24px;
    }

    .profile_delete_modal_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 48px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24px;
    }

    .profile_delete_modal_title_otp {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 31px;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 16px;
    }

    .profile_delete_modal_description {
        font-weight: 450;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 32px;
    }

    .profile_delete_modal_description_otp {
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #27272F;
        width: 324px;
        margin-bottom: 40px;
        margin-left: auto;
        margin-right: auto;
    }

    .profile_delete_modal_resend_otp {
        cursor: pointer;
        font-weight: 700;
        font-size: 16px;
        line-height: 125%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        margin-bottom: 32px;
    }

    .profile_delete_otp_input_container {
        width: 100% !important;
        padding-left: 52px;
        padding-right: 52px;
        height: 71px;
        margin-bottom: 24px;
    }

    .profile_delete_otp_input_container .styles_react-code-input__CRulA {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
    }

    .profile_delete_otp_input_container .styles_react-code-input__CRulA>input {
        width: 71px !important;
        height: 71px !important;
        border: 0px !important;
        border-radius: 9999px !important;
        background-color: #EEEEF0 !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        font-weight: 500;
        font-size: 25px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif !important;
    }

    .profile_delete_modal_footer_otp {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-top: 32px;
    }

    .profile_delete_modal_actions {
        display: flex;
        gap: 16px;
    }

    .profile_delete_modal_cancel {
        margin: auto;
    }

    .profile_delete_modal_delete {
        margin: auto;
    }

    .profile_delete_modal_notification {
        margin-bottom: 32px;
        align-items: flex-start;
    }

    .profile_right_content {
        grid-row: 1;
        grid-column: 10 / span 3;
        position: relative;
        z-index: 0;
    }

    .profile_right_content_benefit {
        padding: 24px;
        background-color: white;
        border-radius: 16px;
        margin-bottom: 24px;
    }

    .profile_right_content_header {
        display: flex;
        gap: 12px;
        align-items: center;
        margin-bottom: 12px;
    }

    .profile_right_content_header img {
        width: 24px;
        height: 24px;
    }

    .profile_right_content_header span {
        font-weight: 900;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
    }

    .profile_right_content_benefit_title {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 12px;
    }

    .profile_right_content_benefits {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .profile_right_content_extra_container {
        position: relative;
        padding: 16px;
        background-color: #FF4600;
        border-radius: 16px;
        overflow: hidden;
    }

    .profile_right_content_extra_header {
        display: flex;
        gap: 16px;
        align-items: center;
        margin-bottom: 10px;
    }

    .profile_right_content_extra_header img {
        width: 56px;
        height: 56px;
    }

    .profile_right_content_extra_header span {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .profile_right_content_extra_description {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 18px;
        width: 165px
    }

    .profile_right_content_start_training_btn {
        width: 161px;
        height: 34px;
        background-color: #1C1CA8;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 6px;
    }

    .profile_right_content_extra_bottom_circle {
        position: absolute;
        border-radius: 9999px;
        background-color: #1C1CA8;
        width: 161px;
        height: 161px;
        bottom: -28px;
        right: -36px;
    }

    .profile_right_content_bottom_image {
        position: absolute;
        right: -18.58px;
        width: 125px;
        bottom: -10px;
        height: 180px;
    }

    .profile_googleplace_autocomplete_input_wrapper {
        height: 66px;
        padding: 20px;
        background: #EEEEF0;
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .profile_googleplace_autocomplete_input_wrapper input {
        flex-grow: 1;
        border: 0px;
        background-color: transparent;
        outline: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .profile_googleplace_autocomplete_input_wrapper img {
        width: 24px;
        height: 24px;
    }
}