@media only screen and (max-width: 480px) {
    .task_take_selfie_complete {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        display: flex;
        flex-direction: column;
        background-color: #1C1CA8;
    }

    .task_take_selfie_complete_wrapper {
        width: 86.111vw;
        height: 140.277vw;
        margin-top: 16.666vw;
        margin-bottom: 20.833vw;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .task_take_selfie_complete_img {
        width: 45.833vw;
        height: 38.888vw;
        margin-bottom: 8.888vw;
    }

    .task_take_selfie_complete_img_desktop {
        display: none;
    }

    .task_take_selfie_complete_img_mobile {
        display: block;
    }

    .task_take_selfie_complete_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 11.111vw;
        line-height: 12.222vw;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 4.444vw;
    }

    .task_take_selfie_complete_description {
        max-width: 100%;
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 7.222vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 11.111vw;
    }

    .task_take_selfie_complete_actions {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 4.444vw;
    }

    .task_take_selfie_complete_actions .btn {
        width: 69.444vw;
    }

    .task_take_selfie_complete_footer {
        display: none;
        position: relative;
        padding: 20px 39px;
        background-color: white;
    }

    .task_take_selfie_complete_footer_logo {
        width: 106px;
        height: 27px;
    }

    .task_take_selfie_complete_footer_text {
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .task_take_selfie_complete_footer_text a {
        color: #5B5B62;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .task_take_selfie_complete {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        display: flex;
        flex-direction: column;
        background-color: #1C1CA8;
    }

    .task_take_selfie_complete_wrapper {
        width: 35.833vw;
        margin-top: 8.541vw;
        margin-bottom: 14.861vw;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .task_take_selfie_complete_img {
        width: 15.972vw;
        height: 12.847vw;
        margin-bottom: 2.222vw;
    }

    .task_take_selfie_complete_img_desktop {
        display: block;
    }

    .task_take_selfie_complete_img_mobile {
        display: none;
    }

    .task_take_selfie_complete_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 4.236vw;
        line-height: 4.652vw;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 1.597vw;
    }

    .task_take_selfie_complete_description {
        max-width: 32.291vw;
        font-weight: 400;
        font-size: 1.388vw;
        line-height: 2.083vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 4.375vw;
    }

    .task_take_selfie_complete_actions {
        display: flex;
        align-items: center;
        gap: 1.111vw;
    }

    .task_take_selfie_complete_actions .btn {
        width: 17.361vw;
    }

    .task_take_selfie_complete_footer {
        position: relative;
        padding: 1.388vw 2.708vw;
        background-color: white;
    }

    .task_take_selfie_complete_footer_logo {
        width: 7.361vw;
        height: 1.875vw;
    }

    .task_take_selfie_complete_footer_text {
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 400;
        font-size: 0.972vw;
        line-height: 1.458vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .task_take_selfie_complete_footer_text a {
        color: #5B5B62;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .task_take_selfie_complete {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        display: flex;
        flex-direction: column;
        background-color: #1C1CA8;
    }

    .task_take_selfie_complete_wrapper {
        width: 35.833vw;
        margin-top: 8.541vw;
        margin-bottom: 14.861vw;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .task_take_selfie_complete_img {
        width: 15.972vw;
        height: 12.847vw;
        margin-bottom: 2.222vw;
    }

    .task_take_selfie_complete_img_desktop {
        display: block;
    }

    .task_take_selfie_complete_img_mobile {
        display: none;
    }

    .task_take_selfie_complete_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 4.236vw;
        line-height: 4.652vw;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 1.597vw;
    }

    .task_take_selfie_complete_description {
        max-width: 32.291vw;
        font-weight: 400;
        font-size: 1.388vw;
        line-height: 2.083vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 4.375vw;
    }

    .task_take_selfie_complete_actions {
        display: flex;
        align-items: center;
        gap: 1.111vw;
    }

    .task_take_selfie_complete_actions .btn {
        width: 17.361vw;
    }

    .task_take_selfie_complete_footer {
        position: relative;
        padding: 1.388vw 2.708vw;
        background-color: white;
    }

    .task_take_selfie_complete_footer_logo {
        width: 7.361vw;
        height: 1.875vw;
    }

    .task_take_selfie_complete_footer_text {
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 400;
        font-size: 0.972vw;
        line-height: 1.458vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .task_take_selfie_complete_footer_text a {
        color: #5B5B62;
    }
}

@media only screen and (min-width: 1441px) {
    .task_take_selfie_complete {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        display: flex;
        flex-direction: column;
        background-color: #1C1CA8;
    }

    .task_take_selfie_complete_wrapper {
        width: 516px;
        margin-top: 123px;
        margin-bottom: 214px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .task_take_selfie_complete_img {
        width: 230px;
        height: 185px;
        margin-bottom: 32px;
    }

    .task_take_selfie_complete_img_desktop {
        display: block;
    }

    .task_take_selfie_complete_img_mobile {
        display: none;
    }

    .task_take_selfie_complete_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 61px;
        line-height: 67px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 23px;
    }

    .task_take_selfie_complete_description {
        max-width: 465px;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 63px;
    }

    .task_take_selfie_complete_actions {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .task_take_selfie_complete_actions .btn {
        width: 250px;
    }

    .task_take_selfie_complete_footer {
        position: relative;
        padding: 20px 39px;
        background-color: white;
    }

    .task_take_selfie_complete_footer_logo {
        width: 106px;
        height: 27px;
    }

    .task_take_selfie_complete_footer_text {
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .task_take_selfie_complete_footer_text a {
        color: #5B5B62;
    }
}