@media only screen and (max-width: 480px) {
    .account_deleted_modal_close_btn {
        right: 3.888vw !important;
        top: -4.444vw !important;
        transform: translateY(0px) !important;
    }

    .account_deleted_modal_icon {
        width: 18.055vw;
        height: 18.055vw;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 4.166vw;
    }

    .account_deleted_modal_title {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 7.777vw;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 4.444vw;
    }

    .account_deleted_modal_question {
        font-weight: 500;
        font-size: 5.555vw;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 4.444vw;
    }

    .account_deleted_modal_answers {
        padding: 0px;
        display: flex;
        flex-direction: column;
        gap: 2.222vw;
        margin-bottom: 8.888vw;
    }

    .account_deleted_modal_answer {
        cursor: pointer;
        padding: 2.222vw 4.444vw;
        border-radius: 1.666vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12.777vw;
    }

    .account_deleted_modal_answer_text {
        font-weight: 700;
        font-size: 3.888vw;
        line-height: 157%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .account_deleted_modal_answer_checkbox {
        width: 5.555vw;
        height: 5.555vw;
        flex: none;
    }

    .account_deleted_modal_answer.active {
        background-color: #055CF5;
    }

    .account_deleted_modal_answer.active .account_deleted_modal_answer_text {
        color: white;
    }

    .account_deleted_modal_answer_other {
        background-color: #EEEEF0;
        border: 1px solid #C3C4C7;
        border-radius: 6px;
        height: 11.111vw;
        padding: 2.5vw 4.444vw;
        font-weight: 450;
        font-size: 3.888vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #27272F;
        outline: none;
    }

    .account_deleted_modal_answer_submit_btn {
        width: 100% !important;
        margin: 0px;
    }

    .account_deleted_modal_actions {
        margin-top: 2.222vw;
        margin-bottom: 11.111vw;
        display: flex;
        flex-direction: column;
        gap: 4.444vw;
        justify-content: center;
    }

    .account_deleted_modal_actions .btn {
        width: 100%;
    }

    .account_deleted_callback {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        display: flex;
        flex-direction: column;
        background-color: #1C1CA8;
    }

    .account_deleted_callback_wrapper {
        margin-top: 36.388vw;
        margin-bottom: 36.388vw;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .account_deleted_callback_img {
        width: 45.833vw;
        height: 38.888vw;
        margin-bottom: 8.888vw;
    }

    .account_deleted_callback_img_desktop {
        display: none;
    }

    .account_deleted_callback_img_mobile {
        display: block;
    }

    .account_deleted_callback_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 11.111vw;
        line-height: 12.222vw;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 6.666vw;
        max-width: 63.888vw;
        margin-left: auto;
        margin-right: auto;
    }

    .account_deleted_callback_actions {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 4.444vw;
    }

    .account_deleted_callback_actions .btn {
        width: 69.444vw;
    }

    .account_deleted_callback_footer {
        display: none;
        position: relative;
        padding: 20px 39px;
        background-color: white;
    }

    .account_deleted_callback_footer_logo {
        width: 106px;
        height: 27px;
    }

    .account_deleted_callback_footer_text {
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .account_deleted_callback_footer_text a {
        color: #5B5B62;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .account_deleted_modal_close_btn {}

    .account_deleted_modal_icon {
        width: 4.513vw;
        height: 4.513vw;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2.222vw;
        margin-bottom: 1.597vw;
    }

    .account_deleted_modal_title {
        max-width: 29.861vw;
        margin-left: auto;
        margin-right: auto;
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 3.333vw;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.666vw;
    }

    .account_deleted_modal_question {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 1.666vw;
    }

    .account_deleted_modal_answers {
        padding: 0px 5.902vw;
        display: flex;
        flex-direction: column;
        gap: 0.555vw;
        margin-bottom: 2.222vw;
    }

    .account_deleted_modal_answer {
        cursor: pointer;
        padding: 0.555vw 1.111vw;
        border-radius: 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2.291vw;
    }

    .account_deleted_modal_answer_text {
        font-weight: 700;
        font-size: 0.972vw;
        line-height: 157%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .account_deleted_modal_answer_checkbox {
        width: 1.388vw;
        height: 1.388vw;
        flex: none;
    }

    .account_deleted_modal_answer.active {
        background-color: #055CF5;
    }

    .account_deleted_modal_answer.active .account_deleted_modal_answer_text {
        color: white;
    }

    .account_deleted_modal_answer_other {
        background-color: #EEEEF0;
        border: 1px solid #C3C4C7;
        border-radius: 0.416vw;
        height: 2.777vw;
        padding: 0.625vw 1.111vw;
        font-weight: 450;
        font-size: 0.972vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #27272F;
        outline: none;
    }

    .account_deleted_modal_answer_submit_btn {
        width: 21.666vw !important;
        margin: 0px auto 2.777vw auto;
    }

    .account_deleted_modal_actions {
        margin-top: 0.555vw;
        margin-bottom: 2.777vw;
        display: flex;
        flex-direction: row;
        gap: 1.111vw;
        justify-content: center;
    }

    .account_deleted_modal_actions .btn {
        width: 14.583vw;
    }

    .account_deleted_callback {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        display: flex;
        flex-direction: column;
        background-color: #1C1CA8;
    }

    .account_deleted_callback_wrapper {
        margin-top: 12.152vw;
        margin-bottom: 15vw;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .account_deleted_callback_img {
        width: 15.972vw;
        height: 12.847vw;
        margin-bottom: 2.777vw;
    }

    .account_deleted_callback_img_desktop {
        display: block;
    }

    .account_deleted_callback_img_mobile {
        display: none;
    }

    .account_deleted_callback_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 4.236vw;
        line-height: 4.652vw;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 2.222vw;
        max-width: 36.8vw;
        margin-left: auto;
        margin-right: auto;
    }

    .account_deleted_callback_actions {
        display: flex;
        align-items: center;
        gap: 1.111vw;
    }

    .account_deleted_callback_actions .btn {
        width: 14.791vw;
    }

    .account_deleted_callback_footer {
        position: relative;
        padding: 1.388vw 2.708vw;
        background-color: white;
    }

    .account_deleted_callback_footer_logo {
        width: 7.361vw;
        height: 1.875vw;
    }

    .account_deleted_callback_footer_text {
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 400;
        font-size: 0.972vw;
        line-height: 1.458vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .account_deleted_callback_footer_text a {
        color: #5B5B62;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .account_deleted_modal_close_btn {}

    .account_deleted_modal_icon {
        width: 4.513vw;
        height: 4.513vw;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2.222vw;
        margin-bottom: 1.597vw;
    }

    .account_deleted_modal_title {
        max-width: 29.861vw;
        margin-left: auto;
        margin-right: auto;
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 3.333vw;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.666vw;
    }

    .account_deleted_modal_question {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 1.666vw;
    }

    .account_deleted_modal_answers {
        padding: 0px 5.902vw;
        display: flex;
        flex-direction: column;
        gap: 0.555vw;
        margin-bottom: 2.222vw;
    }

    .account_deleted_modal_answer {
        cursor: pointer;
        padding: 0.555vw 1.111vw;
        border-radius: 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2.291vw;
    }

    .account_deleted_modal_answer_text {
        font-weight: 700;
        font-size: 0.972vw;
        line-height: 157%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .account_deleted_modal_answer_checkbox {
        width: 1.388vw;
        height: 1.388vw;
        flex: none;
    }

    .account_deleted_modal_answer.active {
        background-color: #055CF5;
    }

    .account_deleted_modal_answer.active .account_deleted_modal_answer_text {
        color: white;
    }

    .account_deleted_modal_answer_other {
        background-color: #EEEEF0;
        border: 1px solid #C3C4C7;
        border-radius: 0.416vw;
        height: 2.777vw;
        padding: 0.625vw 1.111vw;
        font-weight: 450;
        font-size: 0.972vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #27272F;
        outline: none;
    }

    .account_deleted_modal_answer_submit_btn {
        width: 21.666vw !important;
        margin: 0px auto 2.777vw auto;
    }

    .account_deleted_modal_actions {
        margin-top: 0.555vw;
        margin-bottom: 2.777vw;
        display: flex;
        flex-direction: row;
        gap: 1.111vw;
        justify-content: center;
    }

    .account_deleted_modal_actions .btn {
        width: 14.583vw;
    }

    .account_deleted_callback {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        display: flex;
        flex-direction: column;
        background-color: #1C1CA8;
    }

    .account_deleted_callback_wrapper {
        margin-top: 12.152vw;
        margin-bottom: 15vw;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .account_deleted_callback_img {
        width: 15.972vw;
        height: 12.847vw;
        margin-bottom: 2.777vw;
    }

    .account_deleted_callback_img_desktop {
        display: block;
    }

    .account_deleted_callback_img_mobile {
        display: none;
    }

    .account_deleted_callback_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 4.236vw;
        line-height: 4.652vw;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 2.222vw;
        max-width: 36.8vw;
        margin-left: auto;
        margin-right: auto;
    }

    .account_deleted_callback_actions {
        display: flex;
        align-items: center;
        gap: 1.111vw;
    }

    .account_deleted_callback_actions .btn {
        width: 14.791vw;
    }

    .account_deleted_callback_footer {
        position: relative;
        padding: 1.388vw 2.708vw;
        background-color: white;
    }

    .account_deleted_callback_footer_logo {
        width: 7.361vw;
        height: 1.875vw;
    }

    .account_deleted_callback_footer_text {
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 400;
        font-size: 0.972vw;
        line-height: 1.458vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .account_deleted_callback_footer_text a {
        color: #5B5B62;
    }
}

@media only screen and (min-width: 1441px) {
    .account_deleted_modal_close_btn {}

    .account_deleted_modal_icon {
        width: 65px;
        height: 65px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 32px;
        margin-bottom: 23px;
    }

    .account_deleted_modal_title {
        max-width: 430px;
        margin-left: auto;
        margin-right: auto;
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 48px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 24px;
    }

    .account_deleted_modal_question {
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 24px;
    }

    .account_deleted_modal_answers {
        padding: 0px 85px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 32px;
    }

    .account_deleted_modal_answer {
        cursor: pointer;
        padding: 8px 16px;
        border-radius: 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 33px;
    }

    .account_deleted_modal_answer_text {
        font-weight: 700;
        font-size: 14px;
        line-height: 157%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .account_deleted_modal_answer_checkbox {
        width: 20px;
        height: 20px;
        flex: none;
    }

    .account_deleted_modal_answer.active {
        background-color: #055CF5;
    }

    .account_deleted_modal_answer.active .account_deleted_modal_answer_text {
        color: white;
    }

    .account_deleted_modal_answer_other {
        background-color: #EEEEF0;
        border: 1px solid #C3C4C7;
        border-radius: 6px;
        height: 40px;
        padding: 9px 16px;
        font-weight: 450;
        font-size: 14px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #27272F;
        outline: none;
    }

    .account_deleted_modal_answer_submit_btn {
        width: 312px !important;
        margin: 0px auto 40px auto;
    }

    .account_deleted_modal_actions {
        margin-top: 8px;
        margin-bottom: 40px;
        display: flex;
        flex-direction: row;
        gap: 16px;
        justify-content: center;
    }

    .account_deleted_modal_actions .btn {
        width: 210px;
    }

    .account_deleted_callback {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        display: flex;
        flex-direction: column;
        background-color: #1C1CA8;
    }

    .account_deleted_callback_wrapper {
        margin-top: 175px;
        margin-bottom: 216px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .account_deleted_callback_img {
        width: 230px;
        height: 185px;
        margin-bottom: 40px;
    }

    .account_deleted_callback_img_desktop {
        display: block;
    }

    .account_deleted_callback_img_mobile {
        display: none;
    }

    .account_deleted_callback_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 61px;
        line-height: 67px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 32px;
        max-width: 524px;
        margin-left: auto;
        margin-right: auto;
    }

    .account_deleted_callback_actions {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .account_deleted_callback_actions .btn {
        width: 213px;
    }

    .account_deleted_callback_footer {
        position: relative;
        padding: 20px 39px;
        background-color: white;
    }

    .account_deleted_callback_footer_logo {
        width: 106px;
        height: 27px;
    }

    .account_deleted_callback_footer_text {
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .account_deleted_callback_footer_text a {
        color: #5B5B62;
    }
}