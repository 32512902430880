@media only screen and (max-width: 480px) {
    .task_take_selfie_body {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        padding-top: 4.444vw;
        padding-bottom: 4.444vw;
        background-color: #EEEEF0;
    }

    .task_take_selfie_main {
        width: calc(100% + 16px);
        margin-left: -8px;
        grid-column: 1 / span 4;
        grid-row: 1;
        margin-bottom: 4.444vw;
    }

    .task_take_selfie_main_container {
        background-color: white;
        border-radius: 4.444vw;
        padding: 6.666vw 4.444vw 5vw 4.444vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .task_take_selfie_main_icon {
        transform: translateX(0px);
        width: 21.111vw;
        height: 24.166vw;
        margin-bottom: 4.444vw;
    }

    .task_take_selfie_main_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 9.166vw;
        line-height: 110%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 4.444vw;
    }

    .task_take_selfie_main_description {
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 5.555vw;
    }

    .task_take_selfie_main_actions {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 3.333vw;
        margin-bottom: 5.555vw;
    }

    .task_take_selfie_main_action_btn {
        position: relative;
        width: 69.444vw;
        height: 15vw;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4.166vw;
        border: 0px;
    }

    .task_take_selfie_main_action_camera {
        background-color: #FF4600;
    }

    .task_take_selfie_main_action_upload {
        background-color: #055CF5;
    }

    .task_take_selfie_main_action_btn img {
        width: 6.666vw;
        height: 6.666vw;
    }

    .task_take_selfie_main_action_btn span {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-weight: 700;
        font-size: 4.444vw;
        line-height: 125%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .task_take_selfie_main_next_steps {
        background-color: #055cf51a;
        padding: 4.069vw 4.444vw 1.944vw 4.444vw;
        border-radius: 4.444vw;
        border: 1px solid #EEEEF0;
    }

    .task_take_selfie_main_next_step_title {
        font-weight: 500;
        font-size: 5.555vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 4.444vw;
    }

    .task_take_selfie_main_next_step_lists {
        margin-bottom: 3.986vw;
        display: flex;
        flex-direction: column;
        gap: 1.944vw;
    }

    .task_take_selfie_main_next_step_list {
        display: flex;
        align-items: center;
        gap: 3.333vw;
    }

    .task_take_selfie_main_next_step_list img {
        width: 6.666vw;
        height: 6.666vw;
        flex: none;
    }

    .task_take_selfie_main_next_step_list span {
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .task_take_selfie_main_next_step_description {
        font-weight: 400;
        font-size: 3.888vw;
        line-height: 150%;
        height: 29.166vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .task_take_selfie_tips {
        width: calc(100% + 16px);
        margin-left: -8px;
        grid-column: 1 / span 4;
        grid-row: 2;
    }

    .task_take_selfie_tips_container {
        background-color: white;
        border-radius: 4.444vw;
        padding: 6.666vw 4.444vw;
    }

    .task_take_selfie_tip_header {
        display: flex;
        align-items: center;
        gap: 3.333vw;
        margin-bottom: 3.333vw;
    }

    .task_take_selfie_tip_header img {
        width: 6.666vw;
        height: 6.666vw;
        flex: none;
    }

    .task_take_selfie_tip_header span {
        font-weight: 900;
        font-size: 3.888vw;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
    }

    .task_take_selfie_tip_title {
        font-weight: 500;
        font-size: 5.555vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 3.333vw;
    }

    .task_take_selfie_tip_lists {
        display: flex;
        flex-direction: column;
        gap: 2.777vw;
    }

    .task_take_selfie_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .task_take_selfie_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        min-height: 190.44vw;
        height: 100vh;
    }

    .task_take_selfie_modal_body {
        height: fit-content;
        position: absolute;
        top: 5.833vw;
        z-index: 100;
        position: absolute;
        left: 8px;
        width: calc(100vw - 16px);
        padding: 6.916vw 4.444vw 5.372vw 4.444vw;
        background-color: white;
        border-radius: 2.222vw;
    }

    .task_take_selfie_modal_selfie_container {
        overflow: hidden;
        max-width: 100%;
        width: 86.138vw;
        height: 129.208vw;
        position: relative;
        border-radius: 6px;
        overflow: hidden;
        margin-bottom: 1.366vw;
    }

    .task_take_selfie_modal_rotate_btn {
        width: 14.883vw;
        height: 8.888vw;
        display: flex;
        align-items: center;
        gap: 0.552vw;
        margin-bottom: 1.294vw;
        border: none;
        outline: none;
        background-color: transparent;
    }

    .task_take_selfie_modal_rotate_btn img {
        width: 4.052vw;
        height: 4.052vw;
    }

    .task_take_selfie_modal_rotate_btn span {
        font-weight: 700;
        font-size: 3.463vw;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .task_take_selfie_modal_actions {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .task_take_selfie_modal_set_btn {
        width: 55.555vw !important;
        margin-bottom: 5vw;
    }

    .task_take_selfie_modal_set_btn span {
        font-weight: 700;
        font-size: 4.444vw;
        line-height: 125%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .task_take_selfie_modal_cancel_btn {
        border: 0px;
        outline: none;
        background-color: transparent;
        width: fit-content;
        height: fit-content;
        font-weight: 700;
        font-size: 4.444vw;
        line-height: 125%;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .task_take_selfie_main_action_submit {
        width: 69.444vw !important;
        margin: auto;
    }

    .task_take_selfie_main_image_section {
        position: relative;
        width: 29.722vw;
        height: 29.722vw;
        margin-bottom: 6.666vw;
    }

    .task_take_selfie_main_image {
        width: 100%;
        height: 100%;
        border-radius: 9999px;
    }

    .task_take_selfie_main_image_delete_btn {
        position: absolute;
        width: 8.888vw;
        height: 8.888vw;
        right: -2.222vw;
        top: -1px;
        background-color: #FF1200;
        border-radius: 9999px;
        border: 0px;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .task_take_selfie_main_image_delete_btn img {
        width: 6.666vw;
        height: 6.666vw;
    }

    .task_take_selfie_main_notification {
        margin-bottom: 6.666vw;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .task_take_selfie_body {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        padding-top: 1.736vw;
        padding-bottom: 2.916vw;
        background-color: #EEEEF0;
    }

    .task_take_selfie_main {
        width: 100%;
        grid-column: 2 / span 4;
        grid-row: 1;
    }

    .task_take_selfie_main_container {
        background-color: white;
        border-radius: 1.111vw;
        padding: 2.777vw 3.958vw 4.166vw 3.958vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .task_take_selfie_main_icon {
        transform: translateX(0.694vw);
        width: 5.277vw;
        height: 6.041vw;
        margin-bottom: 0.694vw;
    }

    .task_take_selfie_main_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 3.333vw;
        line-height: 3.68vw;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.111vw;
    }

    .task_take_selfie_main_description {
        font-weight: 400;
        font-size: 1.388vw;
        line-height: 2.083vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 1.666vw;
    }

    .task_take_selfie_main_actions {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 1.25vw;
        margin-bottom: 1.666vw;
    }

    .task_take_selfie_main_action_btn {
        width: 100%;
        height: 3.472vw;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.555vw;
        border: 0px;
    }

    .task_take_selfie_main_action_camera {
        background-color: #FF4600;
    }

    .task_take_selfie_main_action_upload {
        background-color: #055CF5;
    }

    .task_take_selfie_main_action_btn img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .task_take_selfie_main_action_btn span {
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .task_take_selfie_main_next_steps {
        background-color: #055cf51a;
        padding: 1.017vw 1.736vw 0.763vw 1.736vw;
        border-radius: 16px;
        border: 1px solid #EEEEF0;
    }

    .task_take_selfie_main_next_step_title {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 1.111vw;
    }

    .task_take_selfie_main_next_step_lists {
        margin-bottom: 1.111vw;
        display: flex;
        flex-direction: column;
        gap: 0.486vw;
    }

    .task_take_selfie_main_next_step_list {
        display: flex;
        align-items: center;
        gap: 0.833vw;
    }

    .task_take_selfie_main_next_step_list img {
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .task_take_selfie_main_next_step_list span {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .task_take_selfie_main_next_step_description {
        font-weight: 400;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .task_take_selfie_tips {
        width: 100%;
        grid-column: 6 / span 2;
        grid-row: 1;
    }

    .task_take_selfie_tips_container {
        background-color: white;
        border-radius: 1.111vw;
        padding: 1.666vw;
    }

    .task_take_selfie_tip_header {
        display: flex;
        align-items: center;
        gap: 0.833vw;
        margin-bottom: 0.833vw;
    }

    .task_take_selfie_tip_header img {
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .task_take_selfie_tip_header span {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
    }

    .task_take_selfie_tip_title {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 0.833vw;
    }

    .task_take_selfie_tip_lists {
        display: flex;
        flex-direction: column;
        gap: 0.694vw;
    }

    .task_take_selfie_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .task_take_selfie_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        min-height: 69.44vw;
        height: 100vh;
    }

    .task_take_selfie_modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 11.597vw;
        left: 50%;
        transform: translateX(-50%);
        width: 25.208vw;
        padding: 1.52vw 1.319vw 2.291vw 1.319vw;
        background-color: white;
        border-radius: 1.111vw;
    }

    .task_take_selfie_modal_selfie_container {
        overflow: hidden;
        max-width: 100%;
        width: 22.638vw;
        height: 33.958vw;
        position: relative;
        border-radius: 0.416vw;
        overflow: hidden;
        margin-bottom: 0.301vw;
    }

    .task_take_selfie_modal_rotate_btn {
        width: 3.72vw;
        height: 2.222vw;
        display: flex;
        align-items: center;
        gap: 0.552vw;
        margin-bottom: 0.323vw;
        border: none;
        outline: none;
        background-color: transparent;
    }

    .task_take_selfie_modal_rotate_btn img {
        width: 1.013vw;
        height: 1.013vw;
    }

    .task_take_selfie_modal_rotate_btn span {
        font-weight: 700;
        font-size: 0.865vw;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .task_take_selfie_modal_actions {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .task_take_selfie_modal_set_btn {
        width: 13.888vw !important;
        margin-bottom: 1.25vw;
    }

    .task_take_selfie_modal_set_btn span {
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .task_take_selfie_modal_cancel_btn {
        border: 0px;
        outline: none;
        background-color: transparent;
        width: fit-content;
        height: fit-content;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .task_take_selfie_main_action_submit {
        width: 14.305vw !important;
        margin: auto;
    }

    .task_take_selfie_main_image_section {
        position: relative;
        width: 7.43vw;
        height: 7.43vw;
        margin-bottom: 1.111vw;
    }

    .task_take_selfie_main_image {
        width: 100%;
        height: 100%;
        border-radius: 9999px;
    }

    .task_take_selfie_main_image_delete_btn {
        position: absolute;
        width: 2.222vw;
        height: 2.222vw;
        right: -0.833vw;
        top: -1px;
        background-color: #FF1200;
        border-radius: 9999px;
        border: 0px;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .task_take_selfie_main_image_delete_btn img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .task_take_selfie_main_notification {
        margin-bottom: 1.666vw;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .task_take_selfie_body {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        padding-top: 1.736vw;
        padding-bottom: 2.916vw;
        background-color: #EEEEF0;
    }

    .task_take_selfie_main {
        width: 100%;
        grid-column: 3 / span 5;
        grid-row: 1;
    }

    .task_take_selfie_main_container {
        background-color: white;
        border-radius: 1.111vw;
        padding: 2.777vw 3.958vw 4.166vw 3.958vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .task_take_selfie_main_icon {
        transform: translateX(0.694vw);
        width: 5.277vw;
        height: 6.041vw;
        margin-bottom: 0.694vw;
    }

    .task_take_selfie_main_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 3.333vw;
        line-height: 3.68vw;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 1.111vw;
    }

    .task_take_selfie_main_description {
        font-weight: 400;
        font-size: 1.388vw;
        line-height: 2.083vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 1.666vw;
    }

    .task_take_selfie_main_actions {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 1.25vw;
        margin-bottom: 1.666vw;
    }

    .task_take_selfie_main_action_btn {
        width: 100%;
        height: 3.472vw;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.555vw;
        border: 0px;
    }

    .task_take_selfie_main_action_camera {
        background-color: #FF4600;
    }

    .task_take_selfie_main_action_upload {
        background-color: #055CF5;
    }

    .task_take_selfie_main_action_btn img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .task_take_selfie_main_action_btn span {
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .task_take_selfie_main_next_steps {
        background-color: #055cf51a;
        padding: 1.017vw 1.736vw 0.763vw 1.736vw;
        border-radius: 16px;
        border: 1px solid #EEEEF0;
    }

    .task_take_selfie_main_next_step_title {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 1.111vw;
    }

    .task_take_selfie_main_next_step_lists {
        margin-bottom: 1.111vw;
        display: flex;
        flex-direction: column;
        gap: 0.486vw;
    }

    .task_take_selfie_main_next_step_list {
        display: flex;
        align-items: center;
        gap: 0.833vw;
    }

    .task_take_selfie_main_next_step_list img {
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .task_take_selfie_main_next_step_list span {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .task_take_selfie_main_next_step_description {
        font-weight: 400;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .task_take_selfie_tips {
        width: 100%;
        grid-column: 8 / span 3;
        grid-row: 1;
    }

    .task_take_selfie_tips_container {
        background-color: white;
        border-radius: 1.111vw;
        padding: 1.666vw;
    }

    .task_take_selfie_tip_header {
        display: flex;
        align-items: center;
        gap: 0.833vw;
        margin-bottom: 0.833vw;
    }

    .task_take_selfie_tip_header img {
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .task_take_selfie_tip_header span {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
    }

    .task_take_selfie_tip_title {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 0.833vw;
    }

    .task_take_selfie_tip_lists {
        display: flex;
        flex-direction: column;
        gap: 0.694vw;
    }

    .task_take_selfie_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .task_take_selfie_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        min-height: 69.44vw;
        height: 100vh;
    }

    .task_take_selfie_modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 11.597vw;
        left: 50%;
        transform: translateX(-50%);
        width: 25.208vw;
        padding: 1.52vw 1.319vw 2.291vw 1.319vw;
        background-color: white;
        border-radius: 1.111vw;
    }

    .task_take_selfie_modal_selfie_container {
        overflow: hidden;
        max-width: 100%;
        width: 22.638vw;
        height: 33.958vw;
        position: relative;
        border-radius: 0.416vw;
        overflow: hidden;
        margin-bottom: 0.301vw;
    }

    .task_take_selfie_modal_rotate_btn {
        width: 3.72vw;
        height: 2.222vw;
        display: flex;
        align-items: center;
        gap: 0.552vw;
        margin-bottom: 0.323vw;
        border: none;
        outline: none;
        background-color: transparent;
    }

    .task_take_selfie_modal_rotate_btn img {
        width: 1.013vw;
        height: 1.013vw;
    }

    .task_take_selfie_modal_rotate_btn span {
        font-weight: 700;
        font-size: 0.865vw;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .task_take_selfie_modal_actions {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .task_take_selfie_modal_set_btn {
        width: 13.888vw !important;
        margin-bottom: 1.25vw;
    }

    .task_take_selfie_modal_set_btn span {
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .task_take_selfie_modal_cancel_btn {
        border: 0px;
        outline: none;
        background-color: transparent;
        width: fit-content;
        height: fit-content;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .task_take_selfie_main_action_submit {
        width: 14.305vw !important;
        margin: auto;
    }

    .task_take_selfie_main_image_section {
        position: relative;
        width: 7.43vw;
        height: 7.43vw;
        margin-bottom: 1.111vw;
    }

    .task_take_selfie_main_image {
        width: 100%;
        height: 100%;
        border-radius: 9999px;
    }

    .task_take_selfie_main_image_delete_btn {
        position: absolute;
        width: 2.222vw;
        height: 2.222vw;
        right: -0.833vw;
        top: -1px;
        background-color: #FF1200;
        border-radius: 9999px;
        border: 0px;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .task_take_selfie_main_image_delete_btn img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .task_take_selfie_main_notification {
        margin-bottom: 1.666vw;
    }
}

@media only screen and (min-width: 1441px) {
    .task_take_selfie_body {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        padding-top: 25px;
        padding-bottom: 42px;
        background-color: #EEEEF0;
    }

    .task_take_selfie_main {
        width: 100%;
        grid-column: 3 / span 5;
        grid-row: 1;
    }

    .task_take_selfie_main_container {
        background-color: white;
        border-radius: 16px;
        padding: 40px 57px 60px 57px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .task_take_selfie_main_icon {
        transform: translateX(10px);
        width: 76px;
        height: 87px;
        margin-bottom: 10px;
    }

    .task_take_selfie_main_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 48px;
        line-height: 53px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1C1CA8;
        margin-bottom: 16px;
    }

    .task_take_selfie_main_description {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 24px;
    }

    .task_take_selfie_main_actions {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 18px;
        margin-bottom: 24px;
    }

    .task_take_selfie_main_action_btn {
        width: 100%;
        height: 50px;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        border: 0px;
    }

    .task_take_selfie_main_action_camera {
        background-color: #FF4600;
    }

    .task_take_selfie_main_action_upload {
        background-color: #055CF5;
    }

    .task_take_selfie_main_action_btn img {
        width: 24px;
        height: 24px;
    }

    .task_take_selfie_main_action_btn span {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .task_take_selfie_main_next_steps {
        background-color: #055cf51a;
        padding: 14.65px 25px 11px 25px;
        border-radius: 16px;
        border: 1px solid #EEEEF0;
    }

    .task_take_selfie_main_next_step_title {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 16px;
    }

    .task_take_selfie_main_next_step_lists {
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
        gap: 7px;
    }

    .task_take_selfie_main_next_step_list {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .task_take_selfie_main_next_step_list img {
        width: 24px;
        height: 24px;
        flex: none;
    }

    .task_take_selfie_main_next_step_list span {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .task_take_selfie_main_next_step_description {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .task_take_selfie_tips {
        width: 100%;
        grid-column: 8 / span 3;
        grid-row: 1;
    }

    .task_take_selfie_tips_container {
        background-color: white;
        border-radius: 16px;
        padding: 24px;
    }

    .task_take_selfie_tip_header {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-bottom: 12px;
    }

    .task_take_selfie_tip_header img {
        width: 24px;
        height: 24px;
        flex: none;
    }

    .task_take_selfie_tip_header span {
        font-weight: 900;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
    }

    .task_take_selfie_tip_title {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 12px;
    }

    .task_take_selfie_tip_lists {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .task_take_selfie_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .task_take_selfie_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        min-height: 1000px;
        height: 100vh;
    }

    .task_take_selfie_modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 167px;
        left: 50%;
        transform: translateX(-50%);
        width: 363px;
        padding: 22px 19px 33px 19px;
        background-color: white;
        border-radius: 16px;
    }

    .task_take_selfie_modal_selfie_container {
        overflow: hidden;
        max-width: 100%;
        width: 326px;
        height: 489px;
        position: relative;
        border-radius: 6px;
        overflow: hidden;
        margin-bottom: 4.34px;
    }

    .task_take_selfie_modal_rotate_btn {
        width: 53.58px;
        height: 32px;
        display: flex;
        align-items: center;
        gap: 1.99px;
        margin-bottom: 4.66px;
        border: none;
        outline: none;
        background-color: transparent;
    }

    .task_take_selfie_modal_rotate_btn img {
        width: 14.59px;
        height: 14.59px;
    }

    .task_take_selfie_modal_rotate_btn span {
        font-weight: 700;
        font-size: 12.4675px;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .task_take_selfie_modal_actions {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .task_take_selfie_modal_set_btn {
        width: 200px !important;
        margin-bottom: 18px;
    }

    .task_take_selfie_modal_set_btn span {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .task_take_selfie_modal_cancel_btn {
        border: 0px;
        outline: none;
        background-color: transparent;
        width: fit-content;
        height: fit-content;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #1C1CA8;
    }

    .task_take_selfie_main_action_submit {
        width: 206px !important;
        margin: auto;
    }

    .task_take_selfie_main_image_section {
        position: relative;
        width: 107px;
        height: 107px;
        margin-bottom: 16px;
    }

    .task_take_selfie_main_image {
        width: 100%;
        height: 100%;
        border-radius: 9999px;
    }

    .task_take_selfie_main_image_delete_btn {
        position: absolute;
        width: 32px;
        height: 32px;
        right: -12px;
        top: -1px;
        background-color: #FF1200;
        border-radius: 9999px;
        border: 0px;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .task_take_selfie_main_image_delete_btn img {
        width: 24px;
        height: 24px;
    }

    .task_take_selfie_main_notification {
        margin-bottom: 24px;
    }
}