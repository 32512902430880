@media only screen and (max-width: 480px) {
    .dashboard_trainig_center_content {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: calc(100% + 16px);
        margin-left: -8px;
        z-index: 3;
        display: block;
        padding: 0;
        margin-bottom: 4.444vw;
        background-color: white;
        border-top-left-radius: 4.444vw;
        border-top-right-radius: 4.444vw;
    }

    .dashboard_trainig_center_content_statistic_container {
        width: 100%;
        height: 32.777vw;
        border-radius: 4.444vw;
        background-color: #055CF5;
        display: flex;
        margin-bottom: 4.444vw;
        position: relative;
    }

    .dashboard_trainig_center_content_statistic_container::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 0px;
        height: 100%;
        border: 1px solid #ffffff33;
    }

    .dashboard_trainig_center_content_statistic_left {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .dashboard_trainig_center_content_statistic_number {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 7.777vw;
        line-height: 10vw;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .dashboard_trainig_center_content_statistic_description {
        font-weight: 500;
        font-size: 4.444vw;
        line-height: 6.666vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .dashboard_trainig_center_content_statistic_right {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .dashboard_trainig_center_content_items {
        background-color: white;
        padding-left: 4.444vw;
        padding-right: 4.444vw;
        border-radius: 4.444vw;
        overflow: hidden;
    }

    .dashboard_trainig_center_content_item_category {
        padding-top: 6.666vw;
        padding-left: 2.222vw;
        padding-bottom: 6.666vw;
        font-weight: 900;
        font-size: 3.888vw;
        line-height: 5.833vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
    }

    .dashboard_trainig_center_content_item_category:not(:first-child) {
        padding-top: 6.666vw;
        border-top: 1px solid #EEEEF0;
    }

    .dashboard_training_right_content {
        grid-row: 2;
        grid-column: 1 / span 4;
        position: relative;
        z-index: 3;
    }

    .dashboard_training_right_content_extra_container {
        position: relative;
        padding: 4.444vw;
        background-color: #FF4600;
        border-radius: 4.444vw;
        overflow: hidden;
    }

    .dashboard_training_right_content_extra_header {
        display: flex;
        gap: 4.444vw;
        align-items: center;
        margin-bottom: 2.777vw;
    }

    .dashboard_training_right_content_extra_header img {
        width: 15.555vw;
        height: 15.555vw;
    }

    .dashboard_training_right_content_extra_header span {
        font-weight: 700;
        font-size: 5.555vw;
        line-height: 7.777vw;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .dashboard_training_right_content_extra_description {
        font-weight: 500;
        font-size: 4.444vw;
        line-height: 6.666vw;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 5vw;
        width: 45.833vw;
    }

    .dashboard_training_right_content_start_training_btn {
        width: 44.722vw;
        height: 9.444vw;
        background-color: #1C1CA8;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        font-weight: 700;
        font-size: 3.888vw;
        line-height: 6.111vw;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 1.666vw;
    }

    .dashboard_training_right_content_extra_bottom_circle {
        position: absolute;
        border-radius: 9999px;
        background-color: #1C1CA8;
        width: 44.722vw;
        height: 44.722vw;
        bottom: -7.777vw;
        right: -10vw;
    }

    .dashboard_training_right_content_bottom_image {
        position: absolute;
        right: -5.161vw;
        width: 34.722vw;
        bottom: -2.777vw;
        height: 50vw;
    }

    .dashboard_training_right_content_extra_earn_quick {
        margin-top: 6.666vw;
        background-color: #055CF5 !important;
    }

    .dashboard_training_right_content_extra_earn_quick .dashboard_training_right_content_start_training_btn {
        background-color: #FFBF00;
        color: #1C1CA8;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .dashboard_trainig_center_content {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 3 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
        padding: 0;
        margin-bottom: 10.416vw;
    }

    .dashboard_trainig_center_content_statistic_container {
        width: 100%;
        height: 10.763vw;
        border-radius: 1.111vw;
        background-color: #055CF5;
        display: flex;
        margin-bottom: 1.666vw;
        position: relative;
    }

    .dashboard_trainig_center_content_statistic_container::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 0px;
        height: 100%;
        border: 1px solid #ffffff33;
    }

    .dashboard_trainig_center_content_statistic_left {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .dashboard_trainig_center_content_statistic_number {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 2.708vw;
        line-height: 3.541vw;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .dashboard_trainig_center_content_statistic_description {
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.666vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .dashboard_trainig_center_content_statistic_right {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .dashboard_trainig_center_content_items {
        background-color: white;
        padding-left: 1.666vw;
        padding-right: 1.666vw;
        border-radius: 1.111vw;
        overflow: hidden;
    }

    .dashboard_trainig_center_content_item_category {
        padding-top: 1.666vw;
        padding-bottom: 1.666vw;
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
    }

    .dashboard_trainig_center_content_item_category:not(:first-child) {
        padding-top: 1.944vw;
        border-top: 1px solid #EEEEF0;
    }

    .dashboard_training_right_content {
        grid-row: 1;
        grid-column: 7 / span 2;
        position: relative;
        z-index: 3;
    }

    .dashboard_training_right_content_extra_container {
        position: relative;
        padding: 1.111vw;
        background-color: #FF4600;
        border-radius: 1.111vw;
        overflow: hidden;
    }

    .dashboard_training_right_content_extra_header {
        display: flex;
        gap: 1.111vw;
        align-items: center;
        margin-bottom: 0.694vw;
    }

    .dashboard_training_right_content_extra_header img {
        width: 3.888vw;
        height: 3.888vw;
    }

    .dashboard_training_right_content_extra_header span {
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 1.944vw;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .dashboard_training_right_content_extra_description {
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.666vw;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 1.25vw;
        width: 11.458vw;
    }

    .dashboard_training_right_content_start_training_btn {
        width: 11.18vw;
        height: 2.361vw;
        background-color: #1C1CA8;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.555vw;
        font-weight: 700;
        font-size: 0.972vw;
        line-height: 1.527vw;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 0.416vw;
    }

    .dashboard_training_right_content_extra_bottom_circle {
        position: absolute;
        border-radius: 9999px;
        background-color: #1C1CA8;
        width: 11.18vw;
        height: 11.18vw;
        bottom: -1.944vw;
        right: -2.5vw;
    }

    .dashboard_training_right_content_bottom_image {
        position: absolute;
        right: -1.29vw;
        width: 8.68vw;
        bottom: -0.694vw;
        height: 12.5vw;
    }

    .dashboard_training_right_content_extra_earn_quick {
        margin-top: 1.666vw;
        background-color: #055CF5 !important;
    }

    .dashboard_training_right_content_extra_earn_quick .dashboard_training_right_content_start_training_btn {
        background-color: #FFBF00;
        color: #1C1CA8;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .dashboard_trainig_center_content {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
        padding: 0;
        margin-bottom: 10.416vw;
    }

    .dashboard_trainig_center_content_statistic_container {
        width: 100%;
        height: 10.763vw;
        border-radius: 1.111vw;
        background-color: #055CF5;
        display: flex;
        margin-bottom: 1.666vw;
        position: relative;
    }

    .dashboard_trainig_center_content_statistic_container::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 0px;
        height: 100%;
        border: 1px solid #ffffff33;
    }

    .dashboard_trainig_center_content_statistic_left {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .dashboard_trainig_center_content_statistic_number {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 2.708vw;
        line-height: 3.541vw;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .dashboard_trainig_center_content_statistic_description {
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.666vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .dashboard_trainig_center_content_statistic_right {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .dashboard_trainig_center_content_items {
        background-color: white;
        padding-left: 1.666vw;
        padding-right: 1.666vw;
        border-radius: 1.111vw;
        overflow: hidden;
    }

    .dashboard_trainig_center_content_item_category {
        padding-top: 1.666vw;
        padding-bottom: 1.666vw;
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
    }

    .dashboard_trainig_center_content_item_category:not(:first-child) {
        padding-top: 1.944vw;
        border-top: 1px solid #EEEEF0;
    }

    .dashboard_training_right_content {
        grid-row: 1;
        grid-column: 10 / span 3;
        position: relative;
        z-index: 3;
    }

    .dashboard_training_right_content_extra_container {
        position: relative;
        padding: 1.111vw;
        background-color: #FF4600;
        border-radius: 1.111vw;
        overflow: hidden;
    }

    .dashboard_training_right_content_extra_header {
        display: flex;
        gap: 1.111vw;
        align-items: center;
        margin-bottom: 0.694vw;
    }

    .dashboard_training_right_content_extra_header img {
        width: 3.888vw;
        height: 3.888vw;
    }

    .dashboard_training_right_content_extra_header span {
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 1.944vw;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .dashboard_training_right_content_extra_description {
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.666vw;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 1.25vw;
        width: 11.458vw;
    }

    .dashboard_training_right_content_start_training_btn {
        width: 11.18vw;
        height: 2.361vw;
        background-color: #1C1CA8;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.555vw;
        font-weight: 700;
        font-size: 0.972vw;
        line-height: 1.527vw;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 0.416vw;
    }

    .dashboard_training_right_content_extra_bottom_circle {
        position: absolute;
        border-radius: 9999px;
        background-color: #1C1CA8;
        width: 11.18vw;
        height: 11.18vw;
        bottom: -1.944vw;
        right: -2.5vw;
    }

    .dashboard_training_right_content_bottom_image {
        position: absolute;
        right: -1.29vw;
        width: 8.68vw;
        bottom: -0.694vw;
        height: 12.5vw;
    }

    .dashboard_training_right_content_extra_earn_quick {
        margin-top: 1.666vw;
        background-color: #055CF5 !important;
    }

    .dashboard_training_right_content_extra_earn_quick .dashboard_training_right_content_start_training_btn {
        background-color: #FFBF00;
        color: #1C1CA8;
    }
}

@media only screen and (min-width: 1441px) {
    .dashboard_trainig_center_content {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
        padding: 0;
        margin-bottom: 150px;
    }

    .dashboard_trainig_center_content_statistic_container {
        width: 100%;
        height: 155px;
        border-radius: 16px;
        background-color: #055CF5;
        display: flex;
        margin-bottom: 24px;
        position: relative;
    }

    .dashboard_trainig_center_content_statistic_container::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 0px;
        height: 100%;
        border: 1px solid #ffffff33;
    }

    .dashboard_trainig_center_content_statistic_left {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .dashboard_trainig_center_content_statistic_number {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 39px;
        line-height: 51px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .dashboard_trainig_center_content_statistic_description {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
    }

    .dashboard_trainig_center_content_statistic_right {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .dashboard_trainig_center_content_items {
        background-color: white;
        padding-left: 24px;
        padding-right: 24px;
        border-radius: 16px;
        overflow: hidden;
    }

    .dashboard_trainig_center_content_item_category {
        padding-top: 24px;
        padding-bottom: 24px;
        font-weight: 900;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8F9095;
    }

    .dashboard_trainig_center_content_item_category:not(:first-child) {
        padding-top: 28px;
        border-top: 1px solid #EEEEF0;
    }

    .dashboard_training_right_content {
        grid-row: 1;
        grid-column: 10 / span 3;
        position: relative;
        z-index: 3;
    }

    .dashboard_training_right_content_extra_container {
        position: relative;
        padding: 16px;
        background-color: #FF4600;
        border-radius: 16px;
        overflow: hidden;
    }

    .dashboard_training_right_content_extra_header {
        display: flex;
        gap: 16px;
        align-items: center;
        margin-bottom: 10px;
    }

    .dashboard_training_right_content_extra_header img {
        width: 56px;
        height: 56px;
    }

    .dashboard_training_right_content_extra_header span {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .dashboard_training_right_content_extra_description {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 18px;
        width: 165px
    }

    .dashboard_training_right_content_start_training_btn {
        width: 161px;
        height: 34px;
        background-color: #1C1CA8;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 6px;
    }

    .dashboard_training_right_content_extra_bottom_circle {
        position: absolute;
        border-radius: 9999px;
        background-color: #1C1CA8;
        width: 161px;
        height: 161px;
        bottom: -28px;
        right: -36px;
    }

    .dashboard_training_right_content_bottom_image {
        position: absolute;
        right: -18.58px;
        width: 125px;
        bottom: -10px;
        height: 180px;
    }

    .dashboard_training_right_content_extra_earn_quick {
        margin-top: 24px;
        background-color: #055CF5 !important;
    }

    .dashboard_training_right_content_extra_earn_quick .dashboard_training_right_content_start_training_btn {
        background-color: #FFBF00;
        color: #1C1CA8;
    }
}