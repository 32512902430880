@media only screen and (max-width: 480px) {
    .training_quiz_question3 {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_quiz_question3_content {
        width: calc(100% + 16px);
        margin-left: -8px;
        padding: 6.666vw 4.444vw;
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 0px;
    }

    .training_quiz_question3_title {
        font-weight: 900;
        font-size: 3.333vw;
        line-height: 5vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 2.222vw;
    }

    .training_quiz_question3_description {
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 6.666vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 6.666vw;
    }

    .training_quiz_question3_question {
        font-weight: 700;
        font-size: 5.555vw;
        line-height: 8.333vw;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 4.444vw;
    }

    .training_quiz_question3_chechbox_lists {
        border: 1px solid #EDEDF0;
        border-radius: 4.444vw;
        margin-bottom: 8.888vw;
    }

    .training_quiz_question3_chechbox_list {
        padding: 5.277vw 5.555vw;
        height: 16.666vw;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #EDEDF0;
        gap: 4.444vw;
    }

    .training_quiz_question3_chechbox_list.selected {
        background-color: #F2F7FE;
    }

    .training_quiz_question3_chechbox_list:last-child {
        margin-bottom: 0px;
    }

    .training_quiz_question3_chechbox_list input[type=radio] {
        width: 5.555vw;
        height: 5.555vw;
        border: 1px solid #D9DBE0;
        border-radius: 4px;
        opacity: 0.5;
    }

    .training_quiz_question3_chechbox_list input[type=radio]:checked {
        opacity: 1;
    }

    .training_quiz_question3_chechbox_list label {
        font-weight: 500;
        font-size: 4.444vw;
        line-height: 6.111vw;
        display: flex;
        align-items: center;
        color: #262629;
        margin: 0px;
    }

    .training_quiz_question3_footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 4.444vw;
    }

    .training_quiz_question3_footer .next_btn {
        width: 100%;
    }

    .training_quiz_question3_footer .back_btn {
        width: 33.333vw;
    }

    .training_quiz_question3_right {
        display: none;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .training_quiz_question3 {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 3 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_quiz_question3_content {
        width: 100%;
        margin-left: 0px;
        padding: 2.777vw;
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 3.055vw;
    }

    .training_quiz_question3_title {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 0.555vw;
    }

    .training_quiz_question3_description {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 1.666vw;
    }

    .training_quiz_question3_question {
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 1.111vw;
    }

    .training_quiz_question3_chechbox_lists {
        border: 1px solid #EDEDF0;
        border-radius: 1.111vw;
        margin-bottom: 2.222vw;
    }

    .training_quiz_question3_chechbox_list {
        padding: 1.319vw 1.388vw;
        height: 4.166vw;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #EDEDF0;
        gap: 1.111vw;
    }

    .training_quiz_question3_chechbox_list.selected {
        background-color: #F2F7FE;
    }

    .training_quiz_question3_chechbox_list:last-child {
        margin-bottom: 0px;
    }

    .training_quiz_question3_chechbox_list input[type=radio] {
        width: 1.388vw;
        height: 1.388vw;
        border: 1px solid #D9DBE0;
        border-radius: 4px;
        opacity: 0.5;
    }

    .training_quiz_question3_chechbox_list input[type=radio]:checked {
        opacity: 1;
    }

    .training_quiz_question3_chechbox_list label {
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.527vw;
        display: flex;
        align-items: center;
        color: #262629;
        margin: 0px;
    }

    .training_quiz_question3_footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1.111vw;
    }

    .training_quiz_question3_footer .next_btn {
        width: 12.638vw;
    }

    .training_quiz_question3_footer .back_btn {
        width: 8.333vw;
    }

    .training_quiz_question3_right {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 7 / span 2;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_quiz_question3_right_content {
        width: 100%;
        margin-left: 0px;
        padding: 2.222vw 1.944vw;
        background: #FFFFFF;
        border-radius: 16px;
    }

    .training_quiz_question3_right_title {
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 1.111vw;
    }

    .training_quiz_question3_right_progress {
        display: flex;
        flex-direction: column;
        gap: 0.555vw;
    }

    .training_quiz_question3_right_progress_item {
        height: 2.222vw;
        display: flex;
        align-items: center;
        position: relative;
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.666vw;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_quiz_question3_right_progress_item.active {
        color: #055CF5;
    }

    .training_quiz_question3_right_progress_item:not(.with_icon) {
        padding-left: 1.944vw;
    }

    .training_quiz_question3_right_progress_item:not(.with_icon).active::before {
        opacity: 1;
    }

    .training_quiz_question3_right_progress_item.complete:not(.with_icon)::before {
        opacity: 1;
    }

    .training_quiz_question3_right_progress_item:not(.with_icon)::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0.555vw;
        width: 0.555vw;
        border-radius: 2px;
        height: 100%;
        background-color: #055CF5;
        opacity: 0.2;
    }

    .training_quiz_question3_right_progress_item.with_icon {
        gap: 0.277vw;
    }

    .training_quiz_question3_right_progress_item.with_icon img {
        width: 1.666vw;
        height: 1.666vw;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .training_quiz_question3 {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_quiz_question3_content {
        width: 100%;
        margin-left: 0px;
        padding: 2.777vw;
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 3.055vw;
    }

    .training_quiz_question3_title {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 0.555vw;
    }

    .training_quiz_question3_description {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 1.666vw;
    }

    .training_quiz_question3_question {
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 1.111vw;
    }

    .training_quiz_question3_chechbox_lists {
        border: 1px solid #EDEDF0;
        border-radius: 1.111vw;
        margin-bottom: 2.222vw;
    }

    .training_quiz_question3_chechbox_list {
        padding: 1.319vw 1.388vw;
        height: 4.166vw;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #EDEDF0;
        gap: 1.111vw;
    }

    .training_quiz_question3_chechbox_list.selected {
        background-color: #F2F7FE;
    }

    .training_quiz_question3_chechbox_list:last-child {
        margin-bottom: 0px;
    }

    .training_quiz_question3_chechbox_list input[type=radio] {
        width: 1.388vw;
        height: 1.388vw;
        border: 1px solid #D9DBE0;
        border-radius: 4px;
        opacity: 0.5;
    }

    .training_quiz_question3_chechbox_list input[type=radio]:checked {
        opacity: 1;
    }

    .training_quiz_question3_chechbox_list label {
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.527vw;
        display: flex;
        align-items: center;
        color: #262629;
        margin: 0px;
    }

    .training_quiz_question3_footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1.111vw;
    }

    .training_quiz_question3_footer .next_btn {
        width: 12.638vw;
    }

    .training_quiz_question3_footer .back_btn {
        width: 8.333vw;
    }

    .training_quiz_question3_right {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 10 / span 3;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_quiz_question3_right_content {
        width: 100%;
        margin-left: 0px;
        padding: 2.222vw 1.944vw;
        background: #FFFFFF;
        border-radius: 16px;
    }

    .training_quiz_question3_right_title {
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 1.111vw;
    }

    .training_quiz_question3_right_progress {
        display: flex;
        flex-direction: column;
        gap: 0.555vw;
    }

    .training_quiz_question3_right_progress_item {
        height: 2.222vw;
        display: flex;
        align-items: center;
        position: relative;
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.666vw;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_quiz_question3_right_progress_item.active {
        color: #055CF5;
    }

    .training_quiz_question3_right_progress_item:not(.with_icon) {
        padding-left: 1.944vw;
    }

    .training_quiz_question3_right_progress_item:not(.with_icon).active::before {
        opacity: 1;
    }

    .training_quiz_question3_right_progress_item.complete:not(.with_icon)::before {
        opacity: 1;
    }

    .training_quiz_question3_right_progress_item:not(.with_icon)::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0.555vw;
        width: 0.555vw;
        border-radius: 2px;
        height: 100%;
        background-color: #055CF5;
        opacity: 0.2;
    }

    .training_quiz_question3_right_progress_item.with_icon {
        gap: 0.277vw;
    }

    .training_quiz_question3_right_progress_item.with_icon img {
        width: 1.666vw;
        height: 1.666vw;
    }
}

@media only screen and (min-width: 1441px) {
    .training_quiz_question3 {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_quiz_question3_content {
        width: 100%;
        margin-left: 0px;
        padding: 40px;
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 44px;
    }

    .training_quiz_question3_title {
        font-weight: 900;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 8px;
    }

    .training_quiz_question3_description {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 24px;
    }

    .training_quiz_question3_question {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 16px;
    }

    .training_quiz_question3_chechbox_lists {
        border: 1px solid #EDEDF0;
        border-radius: 16px;
        margin-bottom: 32px;
    }

    .training_quiz_question3_chechbox_list {
        padding: 19px 20px;
        height: 60px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #EDEDF0;
        gap: 16px;
    }

    .training_quiz_question3_chechbox_list.selected {
        background-color: #F2F7FE;
    }

    .training_quiz_question3_chechbox_list:last-child {
        margin-bottom: 0px;
    }

    .training_quiz_question3_chechbox_list input[type=radio] {
        width: 20px;
        height: 20px;
        border: 1px solid #D9DBE0;
        border-radius: 4px;
        opacity: 0.5;
    }

    .training_quiz_question3_chechbox_list input[type=radio]:checked {
        opacity: 1;
    }

    .training_quiz_question3_chechbox_list label {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        align-items: center;
        color: #262629;
        margin: 0px;
    }

    .training_quiz_question3_footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;
    }

    .training_quiz_question3_footer .next_btn {
        width: 182px;
    }

    .training_quiz_question3_footer .back_btn {
        width: 120px;
    }

    .training_quiz_question3_right {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 10 / span 3;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_quiz_question3_right_content {
        width: 100%;
        margin-left: 0px;
        padding: 32px 28px;
        background: #FFFFFF;
        border-radius: 16px;
    }

    .training_quiz_question3_right_title {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 16px;
    }

    .training_quiz_question3_right_progress {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .training_quiz_question3_right_progress_item {
        height: 32px;
        display: flex;
        align-items: center;
        position: relative;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_quiz_question3_right_progress_item.active {
        color: #055CF5;
    }

    .training_quiz_question3_right_progress_item:not(.with_icon) {
        padding-left: 28px;
    }

    .training_quiz_question3_right_progress_item:not(.with_icon).active::before {
        opacity: 1;
    }

    .training_quiz_question3_right_progress_item.complete:not(.with_icon)::before {
        opacity: 1;
    }

    .training_quiz_question3_right_progress_item:not(.with_icon)::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 8px;
        width: 8px;
        border-radius: 2px;
        height: 100%;
        background-color: #055CF5;
        opacity: 0.2;
    }

    .training_quiz_question3_right_progress_item.with_icon {
        gap: 4px;
    }

    .training_quiz_question3_right_progress_item.with_icon img {
        width: 24px;
        height: 24px;
    }
}