@media only screen and (max-width: 480px) {
    .training_course_lesson {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_course_lesson_content {
        width: calc(100% + 16px);
        margin-left: -8px;
        padding: 6.666vw 4.444vw;
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 0px;
    }

    .training_course_lesson_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 6.666vw;
        line-height: 8.611vw;
        letter-spacing: -0.02em;
        color: #27272F;
        margin-bottom: 6.666vw;
    }

    .training_course_lesson_img {
        display: flex;
        width: 100%;
        margin-bottom: 6.666vw;
        border-radius: 8px;
    }

    .training_course_lesson_img img {
        max-width: 100%;
    }

    .training_course_lesson_img.desktop {
        justify-content: center;
        display: none;
    }

    .training_course_lesson_img.mobile {
        display: flex;
        justify-content: center;
    }

    .training_course_lesson_sub_title {
        font-weight: 700;
        font-size: 5.555vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 2.222vw;
    }

    .training_course_lesson_body {
        display: flex;
        flex-direction: column;
    }

    .training_course_lesson_body[mobile-placement=below] {
        flex-direction: column-reverse;
    }

    .training_course_lesson_body[mobile-placement=above] {
        flex-direction: column;
    }

    .training_course_lesson_description {
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 6.666vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 6.666vw;
    }

    .training_course_lesson_tick_blue {
        display: flex;
        gap: 2.222vw;
        margin-bottom: 6.666vw;
    }

    .training_course_lesson_tick_blue img {
        width: 6.666vw;
        height: 6.666vw;
        flex: none;
    }

    .training_course_lesson_tick_blue span {
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 6.666vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .training_course_lesson_tick_blue ol {
        list-style: none;
        display: flex;
        align-items: center;
    }

    .training_course_lesson_message {
        padding: 4.444vw;
        display: flex;
        gap: 4.444vw;
        background-color: #CDDEFD;
        margin-bottom: 8.888vw;
        border-radius: 8px;
    }

    .training_course_lesson_message.message_success {
        background-color: #D9EFDB;
    }

    .training_course_lesson_message_icon {
        width: 6.666vw !important;
        height: 6.666vw;
        flex: none;
    }

    .training_course_lesson_message_text {
        text-align: left;
        flex-grow: 1;
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 6.666vw;
        color: #27272F;
        white-space: break-spaces;
    }

    .training_course_lesson_checklist_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 2.222vw;
        padding-right: 2.222vw;
        gap: 9.166vw;
        margin-bottom: 6.666vw;
    }

    .training_course_lesson_checklist_item_left {
        flex-grow: 1;
        display: flex;
        gap: 9.166vw;
        align-items: center;
    }

    .training_course_lesson_checklist_item_left_wrapper {
        display: flex;
        flex-direction: column;
        gap: 0.555vw;
    }

    .training_course_lesson_checklist_item_icon {
        flex: none;
        min-width: 12.222vw;
        height: 12.222vw;
    }

    .training_course_lesson_checklist_item_title {
        font-weight: 600;
        font-size: 5vw;
        line-height: 100%;
        color: black;
    }

    .training_course_lesson_checklist_item_body {
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 6.666vw;
        color: #5B5B62;
    }

    .training_course_lesson_checklist_item_checkbox {
        width: 5.555vw;
        height: 5.555vw;
        flex: none;
        cursor: pointer;
    }

    .training_course_lesson_checklist_item_checkbox img {
        width: 100%;
        height: 100%;
    }

    .training_course_lesson_icon_list_multiple_items {
        display: flex;
        flex-wrap: wrap;
        gap: 14.444vw;
        margin-bottom: 8.888vw;
    }

    .training_course_lesson_icon_list_multiple_item {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        gap: 2.777vw;
        opacity: 0.3;
    }

    .training_course_lesson_icon_list_multiple_item.active {
        opacity: 1;
    }

    .training_course_lesson_icon_list_multiple_item_icon {
        object-fit: contain;
        object-position: left;
        height: 17.777vw;
        flex: none;
    }

    .training_course_lesson_icon_list_multiple_item_title {
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_course_lesson_icon_list_multiple_title {
        font-weight: 700;
        font-size: 5.555vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 4.444vw;
    }

    .training_course_lesson_icon_list_multiple_body {
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 4.444vw;
    }

    .training_course_lesson_card_list {
        display: none;
        gap: 8.888vw;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        margin-bottom: 8.888vw;
    }

    .training_course_lesson_card_list:has(.training_course_lesson_card_list_item[placement]) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .training_course_lesson_card_list_mobile {
        display: block;
        margin-bottom: 8.888vw;
        width: calc(100% + 8.888vw);
        margin-left: -4.444vw;
        padding-bottom: 10.833vw;
    }

    .training_course_lesson_card_list_mobile .carousel {
        overflow: visible;
    }

    .training_course_lesson_card_list_mobile .slider .slide {
        padding: 0px 2.222vw;
        width: 80.8139%;
    }

    .training_course_lesson_card_list_mobile .control-dots {
        bottom: -9.166vw;
        gap: 2.222vw;
        display: flex;
        justify-content: center;
        margin: 0px;
    }

    .training_course_lesson_card_list_mobile_indicator {
        width: 1.666vw;
        height: 1.666vw;
        border-radius: 9999px;
        background-color: #4D4F52;
        opacity: 0.2;
    }

    .training_course_lesson_card_list_mobile_indicator.selected {
        opacity: 1;
        width: 4.444vw;
    }

    .training_course_lesson_card_list_item[placement] {
        padding: 0px;
        gap: 0px;
    }

    .training_course_lesson_card_list_item[placement] .training_course_lesson_card_list_item_content {
        padding: 6.666vw;
    }

    .training_course_lesson_card_list_item[placement] .training_course_lesson_card_list_item_title,
    .training_course_lesson_card_list_item[placement] .training_course_lesson_card_list_item_body {
        padding-left: 0px;
    }

    .training_course_lesson_card_list_item {
        overflow: hidden;
        justify-content: flex-start;
        padding: 6.666vw 4.444vw;
        background-color: #F7F8FA;
        border: 1px solid #DDDEE1;
        border-radius: 4.444vw;
        display: flex;
        flex-direction: column;
        gap: 4.444vw;
        height: 100%;
    }

    .training_course_lesson_card_list_item[mobile-placement=left] {
        flex-direction: row;
    }

    .training_course_lesson_card_list_item[mobile-placement=right] {
        flex-direction: row-reverse;
    }

    .training_course_lesson_card_list_item[mobile-placement=above] {
        flex-direction: column;
    }

    .training_course_lesson_card_list_item[mobile-placement=below] {
        flex-direction: column-reverse;
    }

    .training_course_lesson_card_list_item_content {
        flex-grow: 1;
    }

    .training_course_lesson_card_list_item_image {
        width: fit-content;
        max-width: 100%;
        max-height: 66.666vw;
        height: 66.666vw;
        flex: none;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .training_course_lesson_card_list_item_image_desktop {
        max-width: 100% !important;
        display: none !important;
    }

    .training_course_lesson_card_list_item_image_mobile {
        width: unset !important;
        display: block !important;
    }

    .training_course_lesson_card_list_item_icon {
        object-fit: contain;
        object-position: left;
        max-width: 100%;
        height: 17.777vw !important;
    }

    .training_course_lesson_card_list_item_title {
        text-align: left;
        padding-left: 8px;
        font-weight: 700;
        font-size: 5.555vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_course_lesson_card_list_item_body {
        text-align: left;
        padding-left: 8px;
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .training_course_lesson_accordin_list {
        display: flex;
        flex-direction: column;
        gap: 4.444vw;
        margin-bottom: 8.888vw;
    }

    .training_course_lesson_accordion_item {
        border: 1px solid #EDEDF0;
        border-radius: 8px;
    }

    .training_course_lesson_accordion_item_select {
        height: 13.888vw;
        padding: 4.444vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }

    .training_course_lesson_accordion_item_select_text {
        font-weight: 700;
        font-size: 4.444vw;
        line-height: 125%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_course_lesson_accordion_item_select_icon {
        width: 4.444vw;
        height: 4.444vw;
    }

    .training_course_lesson_accordion_item_body {
        display: flex;
        padding: 4.444vw;
        gap: 4.444vw;
        padding-top: 0px;
    }

    .training_course_lesson_accordion_item_body.right {
        flex-direction: row;
    }

    .training_course_lesson_accordion_item_body.left {
        flex-direction: row-reverse;
    }

    .training_course_lesson_accordion_item_body.above {
        flex-direction: column-reverse;
    }

    .training_course_lesson_accordion_item_body.below {
        flex-direction: column;
    }

    .training_course_lesson_accordion_item_body_text {
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 150%;
        color: #5B5B62;
        flex-grow: 1;
    }

    .training_course_lesson_accordion_item_body.right .training_course_lesson_accordion_item_body_image,
    .training_course_lesson_accordion_item_body.left .training_course_lesson_accordion_item_body_image {
        width: 27.777vw;
        flex: none;
    }

    .training_course_lesson_accordion_item_body.above .training_course_lesson_accordion_item_body_image,
    .training_course_lesson_accordion_item_body.below .training_course_lesson_accordion_item_body_image {
        width: 100%;
        flex: none;
    }

    .training_course_lesson_action {
        display: flex;
        justify-content: flex-end;
        gap: 2.777vw;
    }

    .training_course_lesson_action .back_btn {
        width: 33.333vw;
    }

    .training_course_lesson_action .next_btn {
        white-space: nowrap;
        flex-grow: 1;
        width: unset;
    }

    .training_course_lesson_right {
        display: none;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .training_course_lesson {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 3 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_course_lesson_content {
        width: 100%;
        margin-left: 0px;
        padding: 2.777vw;
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 3.055vw;
    }

    .training_course_lesson_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 2.152vw;
        line-height: 2.777vw;
        letter-spacing: -0.02em;
        color: #27272F;
        margin-bottom: 1.666vw;
    }

    .training_course_lesson_img {
        display: flex;
        width: 100%;
        margin-bottom: 2.222vw;
        border-radius: 8px;
    }

    .training_course_lesson_img img {
        max-width: 100%;
    }

    .training_course_lesson_img.desktop {
        justify-content: center;
        display: flex;
    }

    .training_course_lesson_img.mobile {
        display: none;
        justify-content: center;
    }

    .training_course_lesson_sub_title {
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 0.555vw;
    }

    .training_course_lesson_body {
        display: flex;
        flex-direction: column;
    }

    .training_course_lesson_body[placement=below] {
        flex-direction: column-reverse;
    }

    .training_course_lesson_body[placement=above] {
        flex-direction: column;
    }

    .training_course_lesson_description {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 2.222vw;
    }

    .training_course_lesson_tick_blue {
        display: flex;
        gap: 0.555vw;
        margin-bottom: 2.222vw;
    }

    .training_course_lesson_tick_blue img {
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .training_course_lesson_tick_blue span {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .training_course_lesson_tick_blue ol {
        list-style: none;
        display: flex;
        align-items: center;
    }

    .training_course_lesson_message {
        padding: 1.111vw;
        display: flex;
        gap: 1.111vw;
        background-color: #CDDEFD;
        margin-bottom: 2.222vw;
        border-radius: 8px;
    }

    .training_course_lesson_message.message_success {
        background-color: #D9EFDB;
    }

    .training_course_lesson_message_icon {
        width: 1.666vw !important;
        height: 1.666vw;
        flex: none;
    }

    .training_course_lesson_message_text {
        text-align: left;
        flex-grow: 1;
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.666vw;
        color: #27272F;
        white-space: break-spaces;
    }

    .training_course_lesson_checklist_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 0.555vw;
        padding-right: 0.555vw;
        gap: 1.319vw;
        margin-bottom: 1.666vw;
    }

    .training_course_lesson_checklist_item_left {
        flex-grow: 1;
        display: flex;
        gap: 1.319vw;
        align-items: center;
    }

    .training_course_lesson_checklist_item_left_wrapper {
        display: flex;
        flex-direction: column;
        gap: 0.138vw;
    }

    .training_course_lesson_checklist_item_icon {
        flex: none;
        min-width: 3.055vw;
        height: 3.055vw;
    }

    .training_course_lesson_checklist_item_title {
        font-weight: 600;
        font-size: 1.25vw;
        line-height: 100%;
        color: black;
    }

    .training_course_lesson_checklist_item_body {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.666vw;
        color: #5B5B62;
    }

    .training_course_lesson_checklist_item_checkbox {
        width: 1.388vw;
        height: 1.388vw;
        flex: none;
        cursor: pointer;
    }

    .training_course_lesson_checklist_item_checkbox img {
        width: 100%;
        height: 100%;
    }

    .training_course_lesson_icon_list_multiple_items {
        display: flex;
        flex-wrap: wrap;
        gap: 1.666vw;
        margin-bottom: 2.222vw;
    }

    .training_course_lesson_icon_list_multiple_item {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        gap: 1.111vw;
        opacity: 0.3;
    }

    .training_course_lesson_icon_list_multiple_item.active {
        opacity: 1;
    }

    .training_course_lesson_icon_list_multiple_item_icon {
        object-fit: contain;
        object-position: left;
        height: 4.444vw;
        flex: none;
    }

    .training_course_lesson_icon_list_multiple_item_title {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_course_lesson_icon_list_multiple_title {
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 1.111vw;
    }

    .training_course_lesson_icon_list_multiple_body {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 1.111vw;
    }

    .training_course_lesson_card_list {
        display: grid;
        gap: 2.222vw;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        margin-bottom: 2.222vw;
    }

    .training_course_lesson_card_list:has(.training_course_lesson_card_list_item[placement]) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .training_course_lesson_card_list_mobile {
        display: none;
    }

    .training_course_lesson_card_list_item[placement] {
        padding: 0px;
        gap: 0px;
    }

    .training_course_lesson_card_list_item[placement] .training_course_lesson_card_list_item_content {
        padding: 1.666vw;
    }

    .training_course_lesson_card_list_item[placement] .training_course_lesson_card_list_item_title,
    .training_course_lesson_card_list_item[placement] .training_course_lesson_card_list_item_body {
        padding-left: 0px;
    }

    .training_course_lesson_card_list_item {
        overflow: hidden;
        justify-content: flex-start;
        padding: 1.666vw 1.111vw;
        background-color: #F7F8FA;
        border: 1px solid #DDDEE1;
        border-radius: 1.111vw;
        display: flex;
        flex-direction: column;
        gap: 1.111vw;
        height: 100%;
    }

    .training_course_lesson_card_list_item[placement=left] {
        flex-direction: row;
    }

    .training_course_lesson_card_list_item[placement=right] {
        flex-direction: row-reverse;
    }

    .training_course_lesson_card_list_item[placement=above] {
        flex-direction: column;
    }

    .training_course_lesson_card_list_item[placement=below] {
        flex-direction: column-reverse;
    }

    .training_course_lesson_card_list_item_content {
        flex-grow: 1;
    }

    .training_course_lesson_card_list_item_image {
        width: 20.138vw;
        max-width: 100%;
        height: auto;
        flex: none;
    }

    .training_course_lesson_card_list_item_image_desktop {
        max-width: 100% !important;
        display: block !important;
    }

    .training_course_lesson_card_list_item_image_mobile {
        max-width: 100% !important;
        display: none !important;
    }

    .training_course_lesson_card_list_item_icon {
        object-fit: contain;
        object-position: left;
        max-width: 100%;
        height: 4.444vw;
    }

    .training_course_lesson_card_list_item_title {
        text-align: left;
        padding-left: 8px;
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_course_lesson_card_list_item_body {
        text-align: left;
        padding-left: 0.555vw;
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .training_course_lesson_accordin_list {
        display: flex;
        flex-direction: column;
        gap: 1.111vw;
        margin-bottom: 2.222vw;
    }

    .training_course_lesson_accordion_item {
        border: 1px solid #EDEDF0;
        border-radius: 0.555vw;
    }

    .training_course_lesson_accordion_item_select {
        height: 3.472vw;
        padding: 1.111vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }

    .training_course_lesson_accordion_item_select_text {
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 125%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_course_lesson_accordion_item_select_icon {
        width: 1.111vw;
        height: 1.111vw;
    }

    .training_course_lesson_accordion_item_body {
        display: flex;
        padding: 1.111vw;
        gap: 1.111vw;
        padding-top: 0px;
    }

    .training_course_lesson_accordion_item_body.right {
        flex-direction: row;
    }

    .training_course_lesson_accordion_item_body.left {
        flex-direction: row-reverse;
    }

    .training_course_lesson_accordion_item_body.above {
        flex-direction: column-reverse;
    }

    .training_course_lesson_accordion_item_body.below {
        flex-direction: column;
    }

    .training_course_lesson_accordion_item_body_text {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 150%;
        color: #5B5B62;
        flex-grow: 1;
    }

    .training_course_lesson_accordion_item_body.right .training_course_lesson_accordion_item_body_image,
    .training_course_lesson_accordion_item_body.left .training_course_lesson_accordion_item_body_image {
        width: 6.944vw;
        flex: none;
    }

    .training_course_lesson_accordion_item_body.above .training_course_lesson_accordion_item_body_image,
    .training_course_lesson_accordion_item_body.below .training_course_lesson_accordion_item_body_image {
        width: 100%;
        flex: none;
    }

    .training_course_lesson_action {
        display: flex;
        justify-content: flex-end;
        gap: 1.111vw;
    }

    .training_course_lesson_action .back_btn {
        width: 8.333vw;
    }

    .training_course_lesson_action .next_btn {
        width: 12.638vw;
    }

    .training_course_lesson_right {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 7 / span 2;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_course_lesson_right_content {
        width: 100%;
        margin-left: 0px;
        padding: 2.222vw 1.944vw;
        background: #FFFFFF;
        border-radius: 16px;
    }

    .training_course_lesson_right_title {
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 1.111vw;
    }

    .training_course_lesson_right_progress {
        display: flex;
        flex-direction: column;
        gap: 0.555vw;
    }

    .training_course_lesson_right_progress_item {
        height: 2.222vw;
        display: flex;
        align-items: center;
        position: relative;
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 100%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_course_lesson_right_progress_item.active {
        color: #055CF5;
    }

    .training_course_lesson_right_progress_item:not(.with_icon) {
        padding-left: 1.944vw;
    }

    .training_course_lesson_right_progress_item:not(.with_icon).active::before {
        opacity: 1;
    }

    .training_course_lesson_right_progress_item.complete:not(.with_icon)::before {
        opacity: 1;
    }

    .training_course_lesson_right_progress_item:not(.with_icon)::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0.555vw;
        width: 0.555vw;
        border-radius: 2px;
        height: 100%;
        background-color: #055CF5;
        opacity: 0.2;
    }

    .training_course_lesson_right_progress_item.with_icon {
        gap: 0.277vw;
    }

    .training_course_lesson_right_progress_item.with_icon img {
        width: 1.666vw;
        height: 1.666vw;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .training_course_lesson {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_course_lesson_content {
        width: 100%;
        margin-left: 0px;
        padding: 2.777vw;
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 3.055vw;
    }

    .training_course_lesson_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 2.152vw;
        line-height: 2.777vw;
        letter-spacing: -0.02em;
        color: #27272F;
        margin-bottom: 1.666vw;
    }

    .training_course_lesson_img {
        display: flex;
        width: 100%;
        margin-bottom: 2.222vw;
        border-radius: 8px;
    }

    .training_course_lesson_img img {
        max-width: 100%;
    }

    .training_course_lesson_img.desktop {
        justify-content: center;
        display: flex;
    }

    .training_course_lesson_img.mobile {
        display: none;
        justify-content: center;
    }

    .training_course_lesson_sub_title {
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 0.555vw;
    }

    .training_course_lesson_body {
        display: flex;
        flex-direction: column;
    }

    .training_course_lesson_body[placement=below] {
        flex-direction: column-reverse;
    }

    .training_course_lesson_body[placement=above] {
        flex-direction: column;
    }

    .training_course_lesson_description {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 2.222vw;
    }

    .training_course_lesson_tick_blue {
        display: flex;
        gap: 0.555vw;
        margin-bottom: 2.222vw;
    }

    .training_course_lesson_tick_blue img {
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .training_course_lesson_tick_blue span {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .training_course_lesson_tick_blue ol {
        list-style: none;
        display: flex;
        align-items: center;
    }

    .training_course_lesson_message {
        padding: 1.111vw;
        display: flex;
        gap: 1.111vw;
        background-color: #CDDEFD;
        margin-bottom: 2.222vw;
        border-radius: 8px;
    }

    .training_course_lesson_message.message_success {
        background-color: #D9EFDB;
    }

    .training_course_lesson_message_icon {
        width: 1.666vw !important;
        height: 1.666vw;
        flex: none;
    }

    .training_course_lesson_message_text {
        text-align: left;
        flex-grow: 1;
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.666vw;
        color: #27272F;
        white-space: break-spaces;
    }

    .training_course_lesson_checklist_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 0.555vw;
        padding-right: 0.555vw;
        gap: 1.319vw;
        margin-bottom: 1.666vw;
    }

    .training_course_lesson_checklist_item_left {
        flex-grow: 1;
        display: flex;
        gap: 1.319vw;
        align-items: center;
    }

    .training_course_lesson_checklist_item_left_wrapper {
        display: flex;
        flex-direction: column;
        gap: 0.138vw;
    }

    .training_course_lesson_checklist_item_icon {
        flex: none;
        min-width: 3.055vw;
        height: 3.055vw;
    }

    .training_course_lesson_checklist_item_title {
        font-weight: 600;
        font-size: 1.25vw;
        line-height: 100%;
        color: black;
    }

    .training_course_lesson_checklist_item_body {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.666vw;
        color: #5B5B62;
    }

    .training_course_lesson_checklist_item_checkbox {
        width: 1.388vw;
        height: 1.388vw;
        flex: none;
        cursor: pointer;
    }

    .training_course_lesson_checklist_item_checkbox img {
        width: 100%;
        height: 100%;
    }

    .training_course_lesson_icon_list_multiple_items {
        display: flex;
        flex-wrap: wrap;
        gap: 1.666vw;
        margin-bottom: 2.222vw;
    }

    .training_course_lesson_icon_list_multiple_item {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        gap: 1.111vw;
        opacity: 0.3;
    }

    .training_course_lesson_icon_list_multiple_item.active {
        opacity: 1;
    }

    .training_course_lesson_icon_list_multiple_item_icon {
        object-fit: contain;
        object-position: left;
        height: 4.444vw;
        flex: none;
    }

    .training_course_lesson_icon_list_multiple_item_title {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_course_lesson_icon_list_multiple_title {
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 1.111vw;
    }

    .training_course_lesson_icon_list_multiple_body {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 1.111vw;
    }

    .training_course_lesson_card_list {
        display: grid;
        gap: 2.222vw;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        margin-bottom: 2.222vw;
    }

    .training_course_lesson_card_list:has(.training_course_lesson_card_list_item[placement]) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .training_course_lesson_card_list_mobile {
        display: none;
    }

    .training_course_lesson_card_list_item[placement] {
        padding: 0px;
        gap: 0px;
    }

    .training_course_lesson_card_list_item[placement] .training_course_lesson_card_list_item_content {
        padding: 1.666vw;
    }

    .training_course_lesson_card_list_item[placement] .training_course_lesson_card_list_item_title,
    .training_course_lesson_card_list_item[placement] .training_course_lesson_card_list_item_body {
        padding-left: 0px;
    }

    .training_course_lesson_card_list_item {
        overflow: hidden;
        justify-content: flex-start;
        padding: 1.666vw 1.111vw;
        background-color: #F7F8FA;
        border: 1px solid #DDDEE1;
        border-radius: 1.111vw;
        display: flex;
        flex-direction: column;
        gap: 1.111vw;
        height: 100%;
    }

    .training_course_lesson_card_list_item[placement=left] {
        flex-direction: row;
    }

    .training_course_lesson_card_list_item[placement=right] {
        flex-direction: row-reverse;
    }

    .training_course_lesson_card_list_item[placement=above] {
        flex-direction: column;
    }

    .training_course_lesson_card_list_item[placement=below] {
        flex-direction: column-reverse;
    }

    .training_course_lesson_card_list_item_content {
        flex-grow: 1;
    }

    .training_course_lesson_card_list_item_image {
        width: 20.138vw;
        max-width: 100%;
        height: auto;
        flex: none;
    }

    .training_course_lesson_card_list_item_image_desktop {
        max-width: 100% !important;
        display: block !important;
    }

    .training_course_lesson_card_list_item_image_mobile {
        max-width: 100% !important;
        display: none !important;
    }

    .training_course_lesson_card_list_item_icon {
        object-fit: contain;
        object-position: left;
        max-width: 100%;
        height: 4.444vw;
    }

    .training_course_lesson_card_list_item_title {
        text-align: left;
        padding-left: 8px;
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_course_lesson_card_list_item_body {
        text-align: left;
        padding-left: 0.555vw;
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .training_course_lesson_accordin_list {
        display: flex;
        flex-direction: column;
        gap: 1.111vw;
        margin-bottom: 2.222vw;
    }

    .training_course_lesson_accordion_item {
        border: 1px solid #EDEDF0;
        border-radius: 0.555vw;
    }

    .training_course_lesson_accordion_item_select {
        height: 3.472vw;
        padding: 1.111vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }

    .training_course_lesson_accordion_item_select_text {
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 125%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_course_lesson_accordion_item_select_icon {
        width: 1.111vw;
        height: 1.111vw;
    }

    .training_course_lesson_accordion_item_body {
        display: flex;
        padding: 1.111vw;
        gap: 1.111vw;
        padding-top: 0px;
    }

    .training_course_lesson_accordion_item_body.right {
        flex-direction: row;
    }

    .training_course_lesson_accordion_item_body.left {
        flex-direction: row-reverse;
    }

    .training_course_lesson_accordion_item_body.above {
        flex-direction: column-reverse;
    }

    .training_course_lesson_accordion_item_body.below {
        flex-direction: column;
    }

    .training_course_lesson_accordion_item_body_text {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 150%;
        color: #5B5B62;
        flex-grow: 1;
    }

    .training_course_lesson_accordion_item_body.right .training_course_lesson_accordion_item_body_image,
    .training_course_lesson_accordion_item_body.left .training_course_lesson_accordion_item_body_image {
        width: 6.944vw;
        flex: none;
    }

    .training_course_lesson_accordion_item_body.above .training_course_lesson_accordion_item_body_image,
    .training_course_lesson_accordion_item_body.below .training_course_lesson_accordion_item_body_image {
        width: 100%;
        flex: none;
    }

    .training_course_lesson_action {
        display: flex;
        justify-content: flex-end;
        gap: 1.111vw;
    }

    .training_course_lesson_action .back_btn {
        width: 8.333vw;
    }

    .training_course_lesson_action .next_btn {
        width: 12.638vw;
    }

    .training_course_lesson_right {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 10 / span 3;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_course_lesson_right_content {
        width: 100%;
        margin-left: 0px;
        padding: 2.222vw 1.944vw;
        background: #FFFFFF;
        border-radius: 16px;
    }

    .training_course_lesson_right_title {
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 1.111vw;
    }

    .training_course_lesson_right_progress {
        display: flex;
        flex-direction: column;
        gap: 0.555vw;
    }

    .training_course_lesson_right_progress_item {
        height: 2.222vw;
        display: flex;
        align-items: center;
        position: relative;
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 100%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_course_lesson_right_progress_item.active {
        color: #055CF5;
    }

    .training_course_lesson_right_progress_item:not(.with_icon) {
        padding-left: 1.944vw;
    }

    .training_course_lesson_right_progress_item:not(.with_icon).active::before {
        opacity: 1;
    }

    .training_course_lesson_right_progress_item.complete:not(.with_icon)::before {
        opacity: 1;
    }

    .training_course_lesson_right_progress_item:not(.with_icon)::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0.555vw;
        width: 0.555vw;
        border-radius: 2px;
        height: 100%;
        background-color: #055CF5;
        opacity: 0.2;
    }

    .training_course_lesson_right_progress_item.with_icon {
        gap: 0.277vw;
    }

    .training_course_lesson_right_progress_item.with_icon img {
        width: 1.666vw;
        height: 1.666vw;
    }
}

@media only screen and (min-width: 1441px) {
    .training_course_lesson {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_course_lesson_content {
        width: 100%;
        margin-left: 0px;
        padding: 40px;
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 44px;
    }

    .training_course_lesson_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 31px;
        line-height: 40px;
        letter-spacing: -0.02em;
        color: #27272F;
        margin-bottom: 24px;
    }

    .training_course_lesson_img {
        display: flex;
        width: 100%;
        margin-bottom: 32px;
        border-radius: 8px;
    }

    .training_course_lesson_img img {
        max-width: 100%;
    }

    .training_course_lesson_img.desktop {
        justify-content: center;
        display: flex;
    }

    .training_course_lesson_img.mobile {
        display: none;
        justify-content: center;
    }

    .training_course_lesson_sub_title {
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 8px;
    }

    .training_course_lesson_body {
        display: flex;
        flex-direction: column;
    }

    .training_course_lesson_body[placement=below] {
        flex-direction: column-reverse;
    }

    .training_course_lesson_body[placement=above] {
        flex-direction: column;
    }

    .training_course_lesson_description {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 32px;
    }

    .training_course_lesson_tick_blue {
        display: flex;
        gap: 8px;
        margin-bottom: 32px;
    }

    .training_course_lesson_tick_blue img {
        width: 24px;
        height: 24px;
        flex: none;
    }

    .training_course_lesson_tick_blue span {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .training_course_lesson_tick_blue ol {
        list-style: none;
        display: flex;
        align-items: center;
    }

    .training_course_lesson_familar_item {
        margin-bottom: 24px;
        cursor: pointer;
    }

    .training_course_lesson_message {
        padding: 16px;
        display: flex;
        gap: 16px;
        background-color: #CDDEFD;
        margin-bottom: 32px;
        border-radius: 8px;
    }

    .training_course_lesson_message.message_success {
        background-color: #D9EFDB;
    }

    .training_course_lesson_message_icon {
        width: 24px !important;
        height: 24px;
        flex: none;
    }

    .training_course_lesson_message_text {
        text-align: left;
        flex-grow: 1;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #27272F;
        white-space: break-spaces;
    }

    .training_course_lesson_checklist_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 8px;
        padding-right: 8px;
        gap: 19px;
        margin-bottom: 24px;
    }

    .training_course_lesson_checklist_item_left {
        flex-grow: 1;
        display: flex;
        gap: 19px;
        align-items: center;
    }

    .training_course_lesson_checklist_item_left_wrapper {
        display: flex;
        flex-direction: column;
        gap: 2px;
    }

    .training_course_lesson_checklist_item_icon {
        flex: none;
        min-width: 44px;
        height: 44px;
    }

    .training_course_lesson_checklist_item_title {
        font-weight: 600;
        font-size: 18px;
        line-height: 100%;
        color: black;
    }

    .training_course_lesson_checklist_item_body {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #5B5B62;
    }

    .training_course_lesson_checklist_item_checkbox {
        width: 20px;
        height: 20px;
        flex: none;
        cursor: pointer;
    }

    .training_course_lesson_checklist_item_checkbox img {
        width: 100%;
        height: 100%;
    }

    .training_course_lesson_icon_list_multiple_items {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        margin-bottom: 32px;
    }

    .training_course_lesson_icon_list_multiple_item {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        gap: 16px;
        opacity: 0.3;
    }

    .training_course_lesson_icon_list_multiple_item.active {
        opacity: 1;
    }

    .training_course_lesson_icon_list_multiple_item_icon {
        object-fit: contain;
        object-position: left;
        height: 64px;
        flex: none;
    }

    .training_course_lesson_icon_list_multiple_item_title {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_course_lesson_icon_list_multiple_title {
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 16px;
    }

    .training_course_lesson_icon_list_multiple_body {
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 16px;
    }

    .training_course_lesson_card_list {
        display: grid;
        gap: 32px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        margin-bottom: 32px;
    }

    .training_course_lesson_card_list:has(.training_course_lesson_card_list_item[placement]) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .training_course_lesson_card_list_mobile {
        display: none;
    }

    .training_course_lesson_card_list_item[placement] {
        padding: 0px;
        gap: 0px;
    }

    .training_course_lesson_card_list_item[placement] .training_course_lesson_card_list_item_content {
        padding: 24px;
    }

    .training_course_lesson_card_list_item[placement] .training_course_lesson_card_list_item_title,
    .training_course_lesson_card_list_item[placement] .training_course_lesson_card_list_item_body {
        padding-left: 0px;
    }

    .training_course_lesson_card_list_item {
        overflow: hidden;
        justify-content: flex-start;
        padding: 24px 16px;
        background-color: #F7F8FA;
        border: 1px solid #DDDEE1;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: 100%;
    }

    .training_course_lesson_card_list_item[placement=left] {
        flex-direction: row;
    }

    .training_course_lesson_card_list_item[placement=right] {
        flex-direction: row-reverse;
    }

    .training_course_lesson_card_list_item[placement=above] {
        flex-direction: column;
    }

    .training_course_lesson_card_list_item[placement=below] {
        flex-direction: column-reverse;
    }

    .training_course_lesson_card_list_item_content {
        flex-grow: 1;
    }

    .training_course_lesson_card_list_item_image {
        width: 290px;
        max-width: 100%;
        height: auto;
        flex: none;
    }

    .training_course_lesson_card_list_item_image_desktop {
        max-width: 100% !important;
        display: block !important;
    }

    .training_course_lesson_card_list_item_image_mobile {
        max-width: 100% !important;
        display: none !important;
    }

    .training_course_lesson_card_list_item_icon {
        object-fit: contain;
        object-position: left;
        max-width: 100%;
        height: 64px;
    }

    .training_course_lesson_card_list_item_title {
        text-align: left;
        padding-left: 8px;
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_course_lesson_card_list_item_body {
        text-align: left;
        padding-left: 8px;
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .training_course_lesson_accordin_list {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 32px;
    }

    .training_course_lesson_accordion_item {
        border: 1px solid #EDEDF0;
        border-radius: 8px;
    }

    .training_course_lesson_accordion_item_select {
        height: 50px;
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }

    .training_course_lesson_accordion_item_select_text {
        font-weight: 700;
        font-size: 16px;
        line-height: 125%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_course_lesson_accordion_item_select_icon {
        width: 16px;
        height: 16px;
    }

    .training_course_lesson_accordion_item_body {
        display: flex;
        padding: 16px;
        gap: 16px;
        padding-top: 0px;
    }

    .training_course_lesson_accordion_item_body.right {
        flex-direction: row;
    }

    .training_course_lesson_accordion_item_body.left {
        flex-direction: row-reverse;
    }

    .training_course_lesson_accordion_item_body.above {
        flex-direction: column-reverse;
    }

    .training_course_lesson_accordion_item_body.below {
        flex-direction: column;
    }

    .training_course_lesson_accordion_item_body_text {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #5B5B62;
        flex-grow: 1;
    }

    .training_course_lesson_accordion_item_body.right .training_course_lesson_accordion_item_body_image,
    .training_course_lesson_accordion_item_body.left .training_course_lesson_accordion_item_body_image {
        width: 100px;
        flex: none;
    }

    .training_course_lesson_accordion_item_body.above .training_course_lesson_accordion_item_body_image,
    .training_course_lesson_accordion_item_body.below .training_course_lesson_accordion_item_body_image {
        width: 100%;
        flex: none;
    }

    .training_course_lesson_action {
        display: flex;
        justify-content: flex-end;
        gap: 16px;
    }

    .training_course_lesson_action .back_btn {
        width: 120px;
    }

    .training_course_lesson_action .next_btn {
        width: 182px;
    }

    .training_course_lesson_right {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 10 / span 3;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_course_lesson_right_content {
        width: 100%;
        margin-left: 0px;
        padding: 32px 28px;
        background: #FFFFFF;
        border-radius: 16px;
    }

    .training_course_lesson_right_title {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 16px;
    }

    .training_course_lesson_right_progress {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .training_course_lesson_right_progress_item {
        height: 32px;
        display: flex;
        align-items: center;
        position: relative;
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_course_lesson_right_progress_item.active {
        color: #055CF5;
    }

    .training_course_lesson_right_progress_item:not(.with_icon) {
        padding-left: 28px;
    }

    .training_course_lesson_right_progress_item:not(.with_icon).active::before {
        opacity: 1;
    }

    .training_course_lesson_right_progress_item.complete:not(.with_icon)::before {
        opacity: 1;
    }

    .training_course_lesson_right_progress_item:not(.with_icon)::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 8px;
        width: 8px;
        border-radius: 2px;
        height: 100%;
        background-color: #055CF5;
        opacity: 0.2;
    }

    .training_course_lesson_right_progress_item.with_icon {
        gap: 4px;
    }

    .training_course_lesson_right_progress_item.with_icon img {
        width: 24px;
        height: 24px;
    }
}