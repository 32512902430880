@media only screen and (max-width: 480px) {
    .training_quiz {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_quiz_content {
        width: calc(100% + 16px);
        margin-left: -8px;
        padding: 6.666vw 4.444vw;
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 0px;
    }

    .training_quiz_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 6.666vw;
        line-height: 8.611vw;
        letter-spacing: -0.02em;
        color: #27272F;
        margin-bottom: 6.666vw;
    }

    .training_quiz_img {
        display: flex;
        width: 100%;
        margin-bottom: 6.666vw;
        border-radius: 8px;
    }

    .training_quiz_img img {
        width: 100%;
    }

    .training_quiz_description {
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 7.222vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 6.666vw;
    }

    .training_quiz_next .btn {
        width: 100%;
        margin-left: auto;
    }

    .training_quiz_modal {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        height: 100vh;
    }

    .training_quiz_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .training_quiz_modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
    }

    .training_quiz_modal_close_btn {
        position: absolute;
        right: 0.763vw;
        top: 0px;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: 11.111vw;
        height: 11.111vw;
    }

    .training_quiz_modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .training_quiz_modal_content {
        width: 100%;
        padding: 8.888vw 6.666vw 11.111vw 6.666vw;
        background-color: white;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        display: flex;
        flex-direction: column;
    }

    .training_quiz_modal_icon {
        width: 18.055vw;
        height: 18.055vw;
        margin: auto;
        margin-bottom: 4.166vw;
    }

    .training_quiz_modal_title {
        font-weight: 500;
        font-size: 5.555vw;
        line-height: 8.333vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 2.222vw;
    }

    .training_quiz_modal_description {
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 6.666vw;
        text-align: center;
        color: #5B5B62;
        margin-bottom: 6.666vw;
    }

    .training_quiz_modal_actions {
        display: flex;
        flex-direction: column;
        gap: 4.444vw;
    }

    .training_quiz_modal_back {
        width: 69.444vw !important;
        margin: auto;
    }

    .training_quiz_modal_next {
        width: 69.444vw !important;
        margin: auto;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .training_quiz {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 3 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_quiz_content {
        width: 100%;
        margin-left: 0px;
        padding: 2.777vw;
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 3.055vw;
    }

    .training_quiz_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 2.152vw;
        line-height: 2.777vw;
        letter-spacing: -0.02em;
        color: #27272F;
        margin-bottom: 2.222vw;
    }

    .training_quiz_img {
        display: flex;
        width: 100%;
        margin-bottom: 2.222vw;
        border-radius: 8px;
    }

    .training_quiz_img img {
        width: 100%;
    }

    .training_quiz_description {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 2.222vw;
    }

    .training_quiz_next .btn {
        width: 12.638vw;
        margin-left: auto;
    }

    .training_quiz_modal {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        height: 100vh;
    }

    .training_quiz_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .training_quiz_modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 23.263vw;
        left: 50%;
        transform: translateX(-50%);
        width: 38.68vw;
    }

    .training_quiz_modal_close_btn {
        position: absolute;
        right: 0.763vw;
        top: 0px;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: 2.777vw;
        height: 2.777vw;
    }

    .training_quiz_modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .training_quiz_modal_content {
        width: 100%;
        padding: 5vw 1.666vw 5.555vw 1.666vw;
        background-color: white;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
    }

    .training_quiz_modal_icon {
        width: 4.513vw;
        height: 4.513vw;
        margin: auto;
        margin-bottom: 1.597vw;
    }

    .training_quiz_modal_title {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 2.083vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 0.555vw;
    }

    .training_quiz_modal_description {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.666vw;
        text-align: center;
        color: #5B5B62;
        margin-bottom: 2.777vw;
    }

    .training_quiz_modal_actions {
        display: flex;
        flex-direction: column;
        gap: 1.111vw;
    }

    .training_quiz_modal_back {
        width: 21.666vw !important;
        margin: auto;
    }

    .training_quiz_modal_next {
        width: 21.666vw !important;
        margin: auto;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .training_quiz {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_quiz_content {
        width: 100%;
        margin-left: 0px;
        padding: 2.777vw;
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 3.055vw;
    }

    .training_quiz_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 2.152vw;
        line-height: 2.777vw;
        letter-spacing: -0.02em;
        color: #27272F;
        margin-bottom: 2.222vw;
    }

    .training_quiz_img {
        display: flex;
        width: 100%;
        margin-bottom: 2.222vw;
        border-radius: 8px;
    }

    .training_quiz_img img {
        width: 100%;
    }

    .training_quiz_description {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 2.222vw;
    }

    .training_quiz_next .btn {
        width: 12.638vw;
        margin-left: auto;
    }

    .training_quiz_modal {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        height: 100vh;
    }

    .training_quiz_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .training_quiz_modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 23.263vw;
        left: 50%;
        transform: translateX(-50%);
        width: 38.68vw;
    }

    .training_quiz_modal_close_btn {
        position: absolute;
        right: 0.763vw;
        top: 0px;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: 2.777vw;
        height: 2.777vw;
    }

    .training_quiz_modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .training_quiz_modal_content {
        width: 100%;
        padding: 5vw 1.666vw 5.555vw 1.666vw;
        background-color: white;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
    }

    .training_quiz_modal_icon {
        width: 4.513vw;
        height: 4.513vw;
        margin: auto;
        margin-bottom: 1.597vw;
    }

    .training_quiz_modal_title {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 2.083vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 0.555vw;
    }

    .training_quiz_modal_description {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.666vw;
        text-align: center;
        color: #5B5B62;
        margin-bottom: 2.777vw;
    }

    .training_quiz_modal_actions {
        display: flex;
        flex-direction: column;
        gap: 1.111vw;
    }

    .training_quiz_modal_back {
        width: 21.666vw !important;
        margin: auto;
    }

    .training_quiz_modal_next {
        width: 21.666vw !important;
        margin: auto;
    }
}

@media only screen and (min-width: 1441px) {
    .training_quiz {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_quiz_content {
        width: 100%;
        margin-left: 0px;
        padding: 40px;
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 44px;
    }

    .training_quiz_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 31px;
        line-height: 40px;
        letter-spacing: -0.02em;
        color: #27272F;
        margin-bottom: 32px;
    }

    .training_quiz_img {
        display: flex;
        width: 100%;
        margin-bottom: 32px;
        border-radius: 8px;
    }

    .training_quiz_img img {
        width: 100%;
    }

    .training_quiz_description {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 32px;
    }

    .training_quiz_familar_item {
        margin-bottom: 24px;
        cursor: pointer;
    }

    .training_quiz_next .btn {
        width: 182px;
        margin-left: auto;
    }

    .training_quiz_modal {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        height: 100vh;
    }

    .training_quiz_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .training_quiz_modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 335px;
        left: 50%;
        transform: translateX(-50%);
        width: 557px;
    }

    .training_quiz_modal_close_btn {
        position: absolute;
        right: 11px;
        top: 0px;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: 40px;
        height: 40px;
    }

    .training_quiz_modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .training_quiz_modal_content {
        width: 100%;
        padding: 72px 24px 80px 24px;
        background-color: white;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
    }

    .training_quiz_modal_icon {
        width: 65px;
        height: 65px;
        margin: auto;
        margin-bottom: 23px;
    }

    .training_quiz_modal_title {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 8px;
    }

    .training_quiz_modal_description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #5B5B62;
        margin-bottom: 40px;
    }

    .training_quiz_modal_actions {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .training_quiz_modal_back {
        width: 312px !important;
        margin: auto;
    }

    .training_quiz_modal_next {
        width: 312px !important;
        margin: auto;
    }
}