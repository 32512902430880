@media only screen and (max-width: 480px) {

    .not_found {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        position: relative;
        overflow: hidden;
    }

    .not_found_padding {
        padding-top: 1.388vw;
    }

    .not_found_notification_container {
        position: fixed;
        top: 4.444vw;
        z-index: 100;
        left: 0px;
        width: 100vw;
    }

    .not_found_notification_container .notification_container {
        grid-column: 1 / span 12;
    }

    .not_found_left_side_form {
        z-index: 2;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
    }

    .not_found_background_img_desktop {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .not_found_background_img_tablet {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .not_found_background_img_mobile {
        display: block;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
    }

    .not_found_background_img_desktop img,
    .not_found_background_img_tablet img,
    .not_found_background_img_mobile img {
        width: calc(100% - 6.666vw);
        margin-top: 4.444vw;
    }

    .not_found_container {
        margin-top: 24.444vw;
        margin-bottom: 92.5vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .not_found_img {
        width: 53.888vw;
        height: 43.333vw;
        margin-bottom: 8.333vw;
    }

    .not_found_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 900;
        font-size: 13.333vw;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-bottom: 6.666vw;
    }

    .not_found_description {
        font-weight: 500;
        font-size: 4.444vw;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 6.666vw;
    }

    .not_found_btn {
        width: calc(100% - 6.666vw) !important;
        height: 13.888vw !important;
        padding: 3.611vw 5.555vw !important;
        margin: auto !important;
    }

    .not_found_btn span {
        font-weight: 700 !important;
        font-size: 4.444vw !important;
        line-height: 125% !important;
        letter-spacing: -0.01em !important;
        color: #FFFFFF !important;
    }

    .not_found_footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #5B5B62;
        font-weight: 400;
        font-size: 3.888vw;
        line-height: 5.833vw;
    }

    .not_found_footer a {
        text-decoration: underline;
        color: #5B5B62;
    }

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    /* personal_details_form starts */

    .not_found {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        position: relative;
        overflow: hidden;
    }

    .not_found_padding {
        padding-top: 10.416vw;
    }

    .not_found_notification_container {
        position: fixed;
        top: 3.125vw;
        z-index: 100;
        left: 0px;
        width: 100vw;
    }

    .not_found_notification_container .notification_container {
        grid-column: 1 / span 12;
        min-height: 7.291vw;
        gap: 1.302vw;
    }

    .not_found_notification_container .notification_container .notification_prefix_icon,
    .not_found_notification_container .notification_container .notification_suffix_icon {
        width: 3.125vw;
        height: 3.125vw;
    }

    .not_found_notification_container .notification_container .notification_text {
        font-size: 2.083vw;
        line-height: 3.125vw;
    }

    .not_found_left_side_form {
        z-index: 2;
        grid-column: 1 / span 3;
        grid-row: 1;
        width: 100%;
    }

    .not_found_background_img_desktop {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .not_found_background_img_tablet {
        display: block;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .not_found_background_img_mobile {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .not_found_background_img_desktop img,
    .not_found_background_img_tablet img,
    .not_found_background_img_mobile img {
        width: calc(100% + 24px);
        transform: translateX(-24px);
        position: absolute;
        bottom: 0;
    }

    .not_found_container {
        margin-top: 10.625vw;
        margin-bottom: 13.472vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: fit-content;
        margin-left: auto;
    }

    .not_found_img {
        width: 16.944vw;
        height: 13.611vw;
        margin-bottom: 1.805vw;
        margin-right: 1.111vw;
    }

    .not_found_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 900;
        font-size: 6.597vw;
        line-height: 100%;
        text-align: center;
        letter-spacing: 0.005em;
        color: #FFFFFF;
        margin-bottom: 1.666vw;
    }

    .not_found_description {
        font-weight: 500;
        font-size: 1.736vw;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0em;
        color: #FFFFFF;
        margin-bottom: 2.777vw;
        transform: translate(-0.625vw, 0.138vw);
    }

    .not_found_btn {
        width: 20.972vw !important;
        height: 4.583vw !important;
        padding: 1.597vw 1.666vw !important;
        margin: auto !important;
        transform: translateX(-0.555vw) !important;
    }

    .not_found_btn span {
        font-weight: 700 !important;
        font-size: 1.388vw !important;
        line-height: 100% !important;
        letter-spacing: -0.01em !important;
        color: #FFFFFF !important;
    }

    .not_found_footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #5B5B62;
        font-weight: 400;
        font-size: 1.822vw;
        line-height: 2.734vw;
    }

    .not_found_footer a {
        text-decoration: underline;
        color: #5B5B62;
    }

}

@media only screen and (min-width: 601px) and (max-width: 840px) {

    /* personal_details_form starts */

    .not_found {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        position: relative;
        overflow: hidden;
    }

    .not_found_padding {
        padding-top: 10.416vw;
    }

    .not_found_notification_container {
        position: fixed;
        top: 3.125vw;
        z-index: 100;
        left: 0px;
        width: 100vw;
    }

    .not_found_notification_container .notification_container {
        grid-column: 1 / span 12;
        min-height: 7.291vw;
        gap: 1.302vw;
    }

    .not_found_notification_container .notification_container .notification_prefix_icon,
    .not_found_notification_container .notification_container .notification_suffix_icon {
        width: 3.125vw;
        height: 3.125vw;
    }

    .not_found_notification_container .notification_container .notification_text {
        font-size: 2.083vw;
        line-height: 3.125vw;
    }

    .not_found_left_side_form {
        z-index: 2;
        grid-column: 1 / span 3;
        grid-row: 1;
        width: 100%;
    }

    .not_found_background_img_desktop {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .not_found_background_img_tablet {
        display: block;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .not_found_background_img_mobile {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .not_found_background_img_desktop img,
    .not_found_background_img_tablet img,
    .not_found_background_img_mobile img {
        width: calc(100% + 24px);
        transform: translateX(-24px);
        position: absolute;
        bottom: 0;
    }

    .not_found_container {
        margin-top: 10.625vw;
        margin-bottom: 13.472vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: fit-content;
        margin-left: auto;
    }

    .not_found_img {
        width: 16.944vw;
        height: 13.611vw;
        margin-bottom: 1.805vw;
        margin-right: 1.111vw;
    }

    .not_found_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 900;
        font-size: 6.597vw;
        line-height: 100%;
        text-align: center;
        letter-spacing: 0.005em;
        color: #FFFFFF;
        margin-bottom: 1.666vw;
    }

    .not_found_description {
        font-weight: 500;
        font-size: 1.736vw;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0em;
        color: #FFFFFF;
        margin-bottom: 2.777vw;
        transform: translate(-0.625vw, 0.138vw);
    }

    .not_found_btn {
        width: 20.972vw !important;
        height: 4.583vw !important;
        padding: 1.597vw 1.666vw !important;
        margin: auto !important;
        transform: translateX(-0.555vw) !important;
    }

    .not_found_btn span {
        font-weight: 700 !important;
        font-size: 1.388vw !important;
        line-height: 100% !important;
        letter-spacing: -0.01em !important;
        color: #FFFFFF !important;
    }

    .not_found_footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #5B5B62;
        font-weight: 400;
        font-size: 1.822vw;
        line-height: 2.734vw;
    }

    .not_found_footer a {
        text-decoration: underline;
        color: #5B5B62;
    }

}

@media only screen and (min-width: 841px) and (max-width: 1440px) {

    /* personal_details_form starts */
    .not_found {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        position: relative;
        overflow: hidden;
    }

    .not_found_padding {
        padding-top: 0px;
    }

    .not_found_notification_container {
        position: fixed;
        top: 2.291vw;
        z-index: 100;
        left: 0px;
        width: 100vw;
    }

    .not_found_notification_container .notification_container {
        grid-column: 1 / span 12;
    }

    .not_found_left_side_form {
        z-index: 2;
        grid-column: 1 / span 5;
        grid-row: 1;
        width: 100%;
    }

    .not_found_background_img_desktop {
        display: block;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .not_found_background_img_tablet {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .not_found_background_img_mobile {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .not_found_background_img_desktop img,
    .not_found_background_img_tablet img,
    .not_found_background_img_mobile img {
        width: calc(100% + 96px);
        transform: translateX(-48px);
        position: absolute;
        bottom: 0;
    }

    .not_found_container {
        margin-top: 10.625vw;
        margin-bottom: 13.472vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: fit-content;
        margin-left: auto;
    }

    .not_found_img {
        width: 16.944vw;
        height: 13.611vw;
        margin-bottom: 1.805vw;
        margin-right: 1.111vw;
    }

    .not_found_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 900;
        font-size: 6.597vw;
        line-height: 100%;
        text-align: center;
        letter-spacing: 0.005em;
        color: #FFFFFF;
        margin-bottom: 1.666vw;
    }

    .not_found_description {
        font-weight: 500;
        font-size: 1.736vw;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0em;
        color: #FFFFFF;
        margin-bottom: 2.777vw;
        transform: translate(-0.625vw, 0.138vw);
    }

    .not_found_btn {
        width: 20.972vw !important;
        height: 4.583vw !important;
        padding: 1.597vw 1.666vw !important;
        margin: auto !important;
        transform: translateX(-0.555vw) !important;
    }

    .not_found_btn span {
        font-weight: 700 !important;
        font-size: 1.388vw !important;
        line-height: 100% !important;
        letter-spacing: -0.01em !important;
        color: #FFFFFF !important;
    }

    .not_found_footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #5B5B62;
        font-weight: 400;
        font-size: 0.972vw;
        line-height: 1.458vw;
    }

    .not_found_footer a {
        text-decoration: underline;
        color: #5B5B62;
    }

}


@media only screen and (min-width: 1441px) {

    /* personal_details_form starts */
    .not_found {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        background-color: #1C1CA8;
        position: relative;
        overflow: hidden;
    }

    .not_found_padding {
        padding-top: 0px;
    }

    .not_found_notification_container {
        position: fixed;
        top: 33px;
        z-index: 100;
        left: 0px;
        width: 100vw;
    }

    .not_found_notification_container .notification_container {
        grid-column: 1 / span 12;
    }

    .not_found_left_side_form {
        z-index: 2;
        grid-column: 1 / span 5;
        grid-row: 1;
        width: 100%;
    }

    .not_found_background_img_desktop {
        display: block;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .not_found_background_img_tablet {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .not_found_background_img_mobile {
        display: none;
        grid-column: 1 / span 12;
        grid-row: 1;
        width: 100%;
        z-index: 0;
        margin: auto 0px;
        position: absolute;
        bottom: 0;
    }

    .not_found_background_img_desktop img,
    .not_found_background_img_tablet img,
    .not_found_background_img_mobile img {
        width: calc(100% + 96px);
        transform: translateX(-48px);
        position: absolute;
        bottom: 0;
    }

    .not_found_container {
        margin-top: 153px;
        margin-bottom: 194px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: fit-content;
        margin-left: auto;
    }

    .not_found_img {
        width: 244px;
        height: 196px;
        margin-bottom: 26px;
        margin-right: 16px;
    }

    .not_found_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 900;
        font-size: 95px;
        line-height: 100%;
        text-align: center;
        letter-spacing: 0.005em;
        color: #FFFFFF;
        margin-bottom: 24px;
    }

    .not_found_description {
        font-weight: 500;
        font-size: 25px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0em;
        color: #FFFFFF;
        margin-bottom: 40px;
        transform: translate(-9px, 2px);
    }

    .not_found_btn {
        width: 302px !important;
        height: 66px !important;
        padding: 23px 24px !important;
        margin: auto !important;
        transform: translateX(-8px) !important;
    }

    .not_found_btn span {
        font-weight: 700 !important;
        font-size: 20px !important;
        line-height: 100% !important;
        letter-spacing: -0.01em !important;
        color: #FFFFFF !important;
    }

    .not_found_footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #5B5B62;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
    }

    .not_found_footer a {
        text-decoration: underline;
        color: #5B5B62;
    }
}