@media only screen and (max-width: 480px) {
    .training_layout_header {
        position: relative;
        overflow: hidden;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #1C1CA8;
        color: white;
        padding: 4.722vw 3.333vw 4.722vw 4.444vw;
        height: 16.111vw;
    }

    .training_layout_header_left {
        display: flex;
        align-items: center;
        z-index: 1;
        visibility: visible;
    }

    .training_layout_header_menu_btn {
        background-color: transparent;
        border: 0;
        outline: none;
        height: 6.666vw;
    }

    .training_layout_header_menu_icon {
        width: 6.666vw;
        height: 6.666vw;
    }

    .training_layout_header_title {
        z-index: 0;
        position: absolute;
        left: 0px;
        text-align: center;
        width: 100%;
        font-weight: 700;
        font-size: 4.444vw;
        line-height: 7.111vw;
        letter-spacing: 0.01em;
    }

    .training_layout_header_right {
        z-index: 1;
        width: fit-content;
    }

    .training_layout_header_close_btn {
        color: white;
        background-color: transparent;
        border: 0px;
        outline: none;
        display: flex;
        align-items: center;
        gap: 1.666vw;
        font-weight: 700;
        font-size: 4.444vw;
        line-height: 5.555vw;
        letter-spacing: -0.01em;
    }

    .training_layout_header_close_btn span {
        display: none;
    }

    .training_layout_header_close_icon {
        width: 6.666vw;
        height: 6.666vw;
    }

    .training_layout_breadcrumb {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        padding: 5vw 6.666vw;
        display: flex;
        align-items: center;
        gap: 2.222vw;
        flex-wrap: wrap;
    }

    .training_layout_breadcrumb_item {
        display: flex;
        align-items: center;
        gap: 2.222vw;
        min-height: 6.111vw;
    }

    .training_layout_breadcrumb_label {
        font-weight: 700;
        font-size: 3.888vw;
        line-height: 6.111vw;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        white-space: wrap;
    }

    .training_layout_breadcrumb_item.active .training_layout_breadcrumb_label {
        color: #FF4600;
    }

    .training_layout_breadcrumb_arrow_icon {
        width: 4.444vw;
        white-space: nowrap;
        height: 4.444vw;
    }

    .training_layout_body {
        padding-top: 2.222vw;
        padding-bottom: 4.444vw;
        background-color: #E5E5E5;
    }

    .training_layout_body.position-fixed {
        position: fixed !important;
    }

    .training_layout_sidebar {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 1 / span 3;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: none;
    }

    .training_layout_sidebar_content {
        background-color: #055CF5;
        border-radius: 16px;
        margin-bottom: 22.5vw;
    }

    .training_layout_sidebar_content_header {
        padding: 6.666vw 6.666vw 8.888vw 6.666vw;
        color: white;
    }

    .training_layout_sidebar_overview {
        display: flex;
        align-items: center;
        font-weight: 900;
        font-size: 3.333vw;
        color: #FFFFFF;
        text-transform: uppercase;
        line-height: 5vw;
        margin-bottom: 4.444vw;
    }

    .training_layout_sidebar_overview_icon {
        flex: none;
        width: 13.333vw;
        height: 13.333vw;
        margin-right: 6.666vw;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .training_layout_sidebar_overview_icon img {
        width: 6.666vw;
        height: 6.666vw;
    }

    .training_layout_sidebar_overview_icon.icon_menu img {
        width: 8.888vw;
        height: 8.888vw;
    }

    .training_layout_sidebar_overview_left_lessons {
        white-space: nowrap;
        margin-right: 4.444vw;
    }

    .training_layout_sidebar_overview_points {
        white-space: nowrap;
    }

    .training_layout_sidebar_overview_divider {
        height: 8.888vw;
        border-right: 2px solid #FFFFFF;
        opacity: 0.2;
        margin-right: 4.444vw;
    }

    .training_layout_sidebar_close_btn {
        background-color: transparent;
        border: 0px;
        outline: none;
        cursor: pointer;
        position: absolute;
        right: 4.444vw;
        top: 4.444vw;
    }

    .training_layout_sidebar_close_icon {
        width: 6.666vw;
        height: 6.666vw;
    }


    .training_layout_sidebar_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 8.611vw;
        line-height: 11.111vw;
        letter-spacing: -0.02em;
        text-shadow: 0px 2px 12px #0b0e1a14;
        margin-bottom: 6.666vw;
    }

    .training_layout_sidebar_progress_bar {
        display: flex;
        align-items: center;
        height: 2.222vw;
        gap: 1.666vw;
    }

    .training_layout_sidebar_progress_item {
        height: 100%;
        background: #FFFFFF;
        opacity: 0.2;
        width: 100%;
        border-radius: 2px;
    }

    .training_layout_sidebar_progress_item.active {
        opacity: 1;
    }

    .training_layout_sidebar_content_body {
        padding: 8.888vw 7.777vw;
        background: #FFFFFF;
        border-radius: 16px;
    }

    .training_layout_sidebar_categories {
        display: flex;
        flex-direction: column;
        gap: 6.666vw;
        margin-bottom: 6.666vw;
    }

    .training_layout_sidebar_category {
        display: flex;
        align-items: center;
        height: 8.333vw;
        gap: 4.444vw;
    }

    .training_layout_sidebar_category_icon {
        width: 6.666vw;
        height: 6.666vw;
    }

    .training_layout_sidebar_category_name {
        font-weight: 500;
        font-size: 5.555vw;
        line-height: 8.333vw;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_layout_sidebar_category.active .training_layout_sidebar_category_name {
        color: #055CF5;
    }

    .training_layout_sidebar_lessons {
        display: flex;
        flex-direction: column;
        margin-bottom: 6.666vw;
    }

    .training_layout_sidebar_lesson {
        padding-top: 6.666vw;
        padding-bottom: 6.666vw;
        gap: 2.222vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        border-bottom: 1px solid #EEEEF0;
    }

    .training_layout_sidebar_lesson:first-child {
        border-top: 1px solid #EEEEF0;
    }

    .training_layout_sidebar_lesson_divider {
        border-bottom: 1px solid #EEEEF0;
    }

    .training_layout_sidebar_lesson_title {
        font-weight: 900;
        font-size: 3.888vw;
        line-height: 5.833vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #A9AAAE;
        margin-bottom: 2.222vw;
    }

    .training_layout_sidebar_lesson.type_ongoing .training_layout_sidebar_lesson_title {
        color: #27272F;
    }

    .training_layout_sidebar_lesson.type_locked .training_layout_sidebar_lesson_title {
        color: #A9AAAE;
    }

    .training_layout_sidebar_lesson_name {
        font-weight: 500;
        font-size: 5.555vw;
        line-height: 8.333vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
        flex-grow: 1;
    }

    .training_layout_sidebar_lesson.type_ongoing .training_layout_sidebar_lesson_name {
        color: #055CF5;
    }

    .training_layout_sidebar_lesson.type_quiz .training_layout_sidebar_lesson_name {
        color: #27272F;
    }

    .training_layout_sidebar_lesson_icon {
        width: 8.888vw;
        height: 8.888vw;
        flex: none;
    }

    .training_layout_header_sidebar_wrapper {
        z-index: 1000;
        position: fixed;
        width: 100%;
        top: 0px;
        height: 100vh;
        overflow: auto;
    }

    .training_layout_header_sidebar_wrapper.show {
        display: block !important;
    }

    .training_layout_header_sidebar_wrapper.hide {
        display: none !important;
    }

    .training_layout_header_sidebar_wrapper .training_layout_sidebar {
        width: 100%;
        display: block;
    }

    .training_layout_header_sidebar_wrapper .training_layout_sidebar .training_layout_sidebar_content {
        border-radius: 0px;
        margin-bottom: 0px;
    }

    .training_layout_header_sidebar_wrapper .training_layout_sidebar .training_layout_sidebar_content .training_layout_sidebar_content_body {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    #root.position-fixed {
        position: fixed;
        width: 100vw;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .training_layout_header {
        position: relative;
        overflow: hidden;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #1C1CA8;
        color: white;
        padding: 1.319vw 2.5vw;
        height: 4.722vw;
    }

    .training_layout_header_left {
        display: flex;
        align-items: center;
        z-index: 1;
        visibility: hidden;
    }

    .training_layout_header_menu_btn {
        background-color: transparent;
        border: 0;
        outline: none;
        height: 6.666vw;
    }

    .training_layout_header_menu_icon {
        width: 1.666vw;
        height: 1.666vw;
    }

    .training_layout_header_title {
        z-index: 0;
        position: absolute;
        left: 0px;
        text-align: center;
        width: 100%;
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: 0.01em;
    }

    .training_layout_header_right {
        z-index: 1;
        width: fit-content;
    }

    .training_layout_header_close_btn {
        color: white;
        background-color: transparent;
        border: 0px;
        outline: none;
        display: flex;
        align-items: center;
        gap: 0.416vw;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
        letter-spacing: -0.01em;
    }

    .training_layout_header_close_btn span {
        display: block;
    }

    .training_layout_header_close_icon {
        width: 1.666vw;
        height: 1.666vw;
    }

    .training_layout_breadcrumb {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        padding-top: 1.597vw;
        padding-bottom: 1.597vw;
        display: flex;
        align-items: center;
        gap: 0.555vw;
        flex-wrap: wrap;
    }

    .training_layout_breadcrumb_item {
        display: flex;
        align-items: center;
        gap: 0.555vw;
        min-height: 1.527vw;
    }

    .training_layout_breadcrumb_label {
        font-weight: 700;
        font-size: 0.972vw;
        line-height: 1.527vw;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        white-space: wrap;
    }

    .training_layout_breadcrumb_item.active .training_layout_breadcrumb_label {
        color: #FF4600;
    }

    .training_layout_breadcrumb_arrow_icon {
        width: 1.111vw;
        white-space: nowrap;
        height: 1.111vw;
    }

    .training_layout_body {
        padding-top: 1.666vw;
        background-color: #E5E5E5;
    }

    .training_layout_body.position-fixed {
        position: relative !important;
    }

    .training_layout_sidebar {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 1 / span 2;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_layout_sidebar_content {
        background-color: #055CF5;
        border-radius: 1.111vw;
        margin-bottom: 5.625vw;
    }

    .training_layout_sidebar_content_header {
        padding: 1.666vw 1.666vw 2.222vw 1.666vw;
        color: white;
    }

    .training_layout_sidebar_overview {
        display: flex;
        align-items: center;
        font-weight: 900;
        font-size: 0.972vw;
        color: #FFFFFF;
        text-transform: uppercase;
        line-height: 1.458vw;
        margin-bottom: 1.111vw;
    }

    .training_layout_sidebar_overview_icon {
        flex: none;
        width: 3.333vw;
        height: 3.333vw;
        margin-right: 1.666vw;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .training_layout_sidebar_overview_icon img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .training_layout_sidebar_overview_icon.icon_menu img {
        width: 2.222vw;
        height: 2.222vw;
    }

    .training_layout_sidebar_overview_left_lessons {
        white-space: nowrap;
        margin-right: 1.111vw;
    }

    .training_layout_sidebar_overview_points {
        white-space: nowrap;
    }

    .training_layout_sidebar_overview_divider {
        height: 2.222vw;
        border-right: 2px solid #FFFFFF;
        opacity: 0.2;
        margin-right: 1.111vw;
    }

    .training_layout_sidebar_close_btn {
        background-color: transparent;
        border: 0px;
        outline: none;
        cursor: pointer;
        position: absolute;
        right: 16px;
        top: 16px;
    }

    .training_layout_sidebar_close_icon {
        width: 3.333vw;
        height: 3.333vw;
    }


    .training_layout_sidebar_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 2.152vw;
        line-height: 2.777vw;
        letter-spacing: -0.02em;
        text-shadow: 0px 0.138vw 0.833vw #0b0e1a14;
        margin-bottom: 1.666vw;
    }

    .training_layout_sidebar_progress_bar {
        display: flex;
        align-items: center;
        height: 0.555vw;
        gap: 0.416vw;
    }

    .training_layout_sidebar_progress_item {
        height: 100%;
        background: #FFFFFF;
        opacity: 0.2;
        width: 100%;
        border-radius: 2px;
    }

    .training_layout_sidebar_progress_item.active {
        opacity: 1;
    }

    .training_layout_sidebar_content_body {
        padding: 2.222vw 1.944vw;
        background: #FFFFFF;
        border-radius: 1.111vw;
    }

    .training_layout_sidebar_categories {
        display: flex;
        flex-direction: column;
        gap: 1.666vw;
        margin-bottom: 1.666vw;
    }

    .training_layout_sidebar_category {
        display: flex;
        align-items: center;
        height: 2.083vw;
        gap: 1.111vw;
    }

    .training_layout_sidebar_category_icon {
        width: 1.666vw;
        height: 1.666vw;
    }

    .training_layout_sidebar_category_name {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_layout_sidebar_category.active .training_layout_sidebar_category_name {
        color: #055CF5;
    }

    .training_layout_sidebar_lessons {
        display: flex;
        flex-direction: column;
        margin-bottom: 2.222vw;
    }

    .training_layout_sidebar_lesson {
        padding-top: 1.666vw;
        padding-bottom: 1.666vw;
        gap: 0.555vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        border-bottom: 1px solid #EEEEF0;
    }

    .training_layout_sidebar_lesson:first-child {
        border-top: 1px solid #EEEEF0;
    }

    .training_layout_sidebar_lesson_divider {
        border-bottom: 1px solid #EEEEF0;
    }

    .training_layout_sidebar_lesson_title {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #A9AAAE;
        margin-bottom: 0.555vw;
    }

    .training_layout_sidebar_lesson.type_ongoing .training_layout_sidebar_lesson_title {
        color: #27272F;
    }

    .training_layout_sidebar_lesson.type_locked .training_layout_sidebar_lesson_title {
        color: #A9AAAE;
    }

    .training_layout_sidebar_lesson_name {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
        flex-grow: 1;
    }

    .training_layout_sidebar_lesson.type_ongoing .training_layout_sidebar_lesson_name {
        color: #055CF5;
    }

    .training_layout_sidebar_lesson.type_quiz .training_layout_sidebar_lesson_name {
        color: #27272F;
    }

    .training_layout_sidebar_lesson_icon {
        width: 2.222vw;
        height: 2.222vw;
        flex: none;
    }

    .training_layout_header_sidebar_wrapper {
        display: none !important;
    }

    #root.position-fixed {
        position: fixed;
        width: 100vw;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .training_layout_header {
        position: relative;
        overflow: hidden;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #1C1CA8;
        color: white;
        padding: 1.319vw 2.5vw;
        height: 4.722vw;
    }

    .training_layout_header_left {
        display: flex;
        align-items: center;
        z-index: 1;
        visibility: hidden;
    }

    .training_layout_header_menu_btn {
        background-color: transparent;
        border: 0;
        outline: none;
        height: 6.666vw;
    }

    .training_layout_header_menu_icon {
        width: 1.666vw;
        height: 1.666vw;
    }

    .training_layout_header_title {
        z-index: 0;
        position: absolute;
        left: 0px;
        text-align: center;
        width: 100%;
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: 0.01em;
    }

    .training_layout_header_right {
        z-index: 1;
        width: fit-content;
    }

    .training_layout_header_close_btn {
        color: white;
        background-color: transparent;
        border: 0px;
        outline: none;
        display: flex;
        align-items: center;
        gap: 0.416vw;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
        letter-spacing: -0.01em;
    }

    .training_layout_header_close_btn span {
        display: block;
    }

    .training_layout_header_close_icon {
        width: 1.666vw;
        height: 1.666vw;
    }

    .training_layout_breadcrumb {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        padding-top: 1.597vw;
        padding-bottom: 1.597vw;
        display: flex;
        align-items: center;
        gap: 0.555vw;
        flex-wrap: wrap;
    }

    .training_layout_breadcrumb_item {
        display: flex;
        align-items: center;
        gap: 0.555vw;
        min-height: 1.527vw;
    }

    .training_layout_breadcrumb_label {
        font-weight: 700;
        font-size: 0.972vw;
        line-height: 1.527vw;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        white-space: wrap;
    }

    .training_layout_breadcrumb_item.active .training_layout_breadcrumb_label {
        color: #FF4600;
    }

    .training_layout_breadcrumb_arrow_icon {
        width: 1.111vw;
        white-space: nowrap;
        height: 1.111vw;
    }

    .training_layout_body {
        padding-top: 1.666vw;
        background-color: #E5E5E5;
    }

    .training_layout_body.position-fixed {
        position: relative !important;
    }

    .training_layout_sidebar {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 1 / span 3;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_layout_sidebar_content {
        background-color: #055CF5;
        border-radius: 1.111vw;
        margin-bottom: 5.625vw;
    }

    .training_layout_sidebar_content_header {
        padding: 1.666vw 1.666vw 2.222vw 1.666vw;
        color: white;
    }

    .training_layout_sidebar_overview {
        display: flex;
        align-items: center;
        font-weight: 900;
        font-size: 0.972vw;
        color: #FFFFFF;
        text-transform: uppercase;
        line-height: 1.458vw;
        margin-bottom: 1.111vw;
    }

    .training_layout_sidebar_overview_icon {
        flex: none;
        width: 3.333vw;
        height: 3.333vw;
        margin-right: 1.666vw;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .training_layout_sidebar_overview_icon img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .training_layout_sidebar_overview_icon.icon_menu img {
        width: 2.222vw;
        height: 2.222vw;
    }

    .training_layout_sidebar_overview_left_lessons {
        white-space: nowrap;
        margin-right: 1.111vw;
    }

    .training_layout_sidebar_overview_points {
        white-space: nowrap;
    }

    .training_layout_sidebar_overview_divider {
        height: 2.222vw;
        border-right: 2px solid #FFFFFF;
        opacity: 0.2;
        margin-right: 1.111vw;
    }

    .training_layout_sidebar_close_btn {
        background-color: transparent;
        border: 0px;
        outline: none;
        cursor: pointer;
        position: absolute;
        right: 16px;
        top: 16px;
    }

    .training_layout_sidebar_close_icon {
        width: 3.333vw;
        height: 3.333vw;
    }


    .training_layout_sidebar_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 2.152vw;
        line-height: 2.777vw;
        letter-spacing: -0.02em;
        text-shadow: 0px 0.138vw 0.833vw #0b0e1a14;
        margin-bottom: 1.666vw;
    }

    .training_layout_sidebar_progress_bar {
        display: flex;
        align-items: center;
        height: 0.555vw;
        gap: 0.416vw;
    }

    .training_layout_sidebar_progress_item {
        height: 100%;
        background: #FFFFFF;
        opacity: 0.2;
        width: 100%;
        border-radius: 2px;
    }

    .training_layout_sidebar_progress_item.active {
        opacity: 1;
    }

    .training_layout_sidebar_content_body {
        padding: 2.222vw 1.944vw;
        background: #FFFFFF;
        border-radius: 1.111vw;
    }

    .training_layout_sidebar_categories {
        display: flex;
        flex-direction: column;
        gap: 1.666vw;
        margin-bottom: 1.666vw;
    }

    .training_layout_sidebar_category {
        display: flex;
        align-items: center;
        height: 2.083vw;
        gap: 1.111vw;
    }

    .training_layout_sidebar_category_icon {
        width: 1.666vw;
        height: 1.666vw;
    }

    .training_layout_sidebar_category_name {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_layout_sidebar_category.active .training_layout_sidebar_category_name {
        color: #055CF5;
    }

    .training_layout_sidebar_lessons {
        display: flex;
        flex-direction: column;
        margin-bottom: 2.222vw;
    }

    .training_layout_sidebar_lesson {
        padding-top: 1.666vw;
        padding-bottom: 1.666vw;
        gap: 0.555vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        border-bottom: 1px solid #EEEEF0;
    }

    .training_layout_sidebar_lesson:first-child {
        border-top: 1px solid #EEEEF0;
    }

    .training_layout_sidebar_lesson_divider {
        border-bottom: 1px solid #EEEEF0;
    }

    .training_layout_sidebar_lesson_title {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #A9AAAE;
        margin-bottom: 0.555vw;
    }

    .training_layout_sidebar_lesson.type_ongoing .training_layout_sidebar_lesson_title {
        color: #27272F;
    }

    .training_layout_sidebar_lesson.type_locked .training_layout_sidebar_lesson_title {
        color: #A9AAAE;
    }

    .training_layout_sidebar_lesson_name {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
        flex-grow: 1;
    }

    .training_layout_sidebar_lesson.type_ongoing .training_layout_sidebar_lesson_name {
        color: #055CF5;
    }

    .training_layout_sidebar_lesson.type_quiz .training_layout_sidebar_lesson_name {
        color: #27272F;
    }

    .training_layout_sidebar_lesson_icon {
        width: 2.222vw;
        height: 2.222vw;
        flex: none;
    }

    .training_layout_header_sidebar_wrapper {
        display: none !important;
    }

    #root.position-fixed {
        position: fixed;
        width: 100vw;
    }
}

@media only screen and (min-width: 1441px) {
    .training_layout_header {
        position: relative;
        overflow: hidden;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #1C1CA8;
        color: white;
        padding: 19px 36px;
        height: 68px;
    }

    .training_layout_header_left {
        display: flex;
        align-items: center;
        z-index: 1;
        visibility: hidden;
    }

    .training_layout_header_menu_btn {
        background-color: transparent;
        border: 0;
        outline: none;
        height: 6.666vw;
    }

    .training_layout_header_menu_icon {
        width: 24px;
        height: 24px;
    }

    .training_layout_header_title {
        z-index: 0;
        position: absolute;
        left: 0px;
        text-align: center;
        width: 100%;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.01em;
    }

    .training_layout_header_right {
        z-index: 1;
        width: fit-content;
    }

    .training_layout_header_close_btn {
        color: white;
        background-color: transparent;
        border: 0px;
        outline: none;
        display: flex;
        align-items: center;
        gap: 6px;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.01em;
    }

    .training_layout_header_close_btn span {
        display: block;
    }

    .training_layout_header_close_icon {
        width: 24px;
        height: 24px;
    }

    .training_layout_breadcrumb {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        padding-top: 23px;
        padding-bottom: 23px;
        display: flex;
        align-items: center;
        gap: 8px;
        flex-wrap: wrap;
    }

    .training_layout_breadcrumb_item {
        display: flex;
        align-items: center;
        gap: 8px;
        min-height: 22px;
    }

    .training_layout_breadcrumb_label {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #1C1CA8;
        white-space: wrap;
    }

    .training_layout_breadcrumb_item.active .training_layout_breadcrumb_label {
        color: #FF4600;
    }

    .training_layout_breadcrumb_arrow_icon {
        width: 16px;
        white-space: nowrap;
        height: 16px;
    }

    .training_layout_body {
        padding-top: 24px;
        background-color: #E5E5E5;
    }

    .training_layout_body.position-fixed {
        position: relative !important;
    }

    .training_layout_sidebar {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 1 / span 3;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_layout_sidebar_content {
        background-color: #055CF5;
        border-radius: 16px;
        margin-bottom: 81px;
    }

    .training_layout_sidebar_content_header {
        padding: 24px 24px 32px 24px;
        color: white;
    }

    .training_layout_sidebar_overview {
        display: flex;
        align-items: center;
        font-weight: 900;
        font-size: 14px;
        color: #FFFFFF;
        text-transform: uppercase;
        line-height: 21px;
        margin-bottom: 16px;
    }

    .training_layout_sidebar_overview_icon {
        flex: none;
        width: 48px;
        height: 48px;
        margin-right: 24px;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .training_layout_sidebar_overview_icon img {
        width: 24px;
        height: 24px;
    }

    .training_layout_sidebar_overview_icon.icon_menu img {
        width: 32px;
        height: 32px;
    }

    .training_layout_sidebar_overview_left_lessons {
        white-space: nowrap;
        margin-right: 16px;
    }

    .training_layout_sidebar_overview_points {
        white-space: nowrap;
    }

    .training_layout_sidebar_overview_divider {
        height: 32px;
        border-right: 2px solid #FFFFFF;
        opacity: 0.2;
        margin-right: 16px;
    }

    .training_layout_sidebar_close_btn {
        background-color: transparent;
        border: 0px;
        outline: none;
        cursor: pointer;
        position: absolute;
        right: 16px;
        top: 16px;
    }

    .training_layout_sidebar_close_icon {
        width: 48px;
        height: 48px;
    }


    .training_layout_sidebar_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 31px;
        line-height: 40px;
        letter-spacing: -0.02em;
        text-shadow: 0px 2px 12px #0b0e1a14;
        margin-bottom: 24px;
    }

    .training_layout_sidebar_progress_bar {
        display: flex;
        align-items: center;
        height: 8px;
        gap: 6px;
    }

    .training_layout_sidebar_progress_item {
        height: 100%;
        background: #FFFFFF;
        opacity: 0.2;
        width: 100%;
        border-radius: 2px;
    }

    .training_layout_sidebar_progress_item.active {
        opacity: 1;
    }

    .training_layout_sidebar_content_body {
        padding: 32px 28px;
        background: #FFFFFF;
        border-radius: 16px;
    }

    .training_layout_sidebar_categories {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 24px;
    }

    .training_layout_sidebar_category {
        display: flex;
        align-items: center;
        height: 30px;
        gap: 16px;
    }

    .training_layout_sidebar_category_icon {
        width: 24px;
        height: 24px;
    }

    .training_layout_sidebar_category_name {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_layout_sidebar_category.active .training_layout_sidebar_category_name {
        color: #055CF5;
    }

    .training_layout_sidebar_lessons {
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;
    }

    .training_layout_sidebar_lesson {
        padding-top: 24px;
        padding-bottom: 24px;
        gap: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        border-bottom: 1px solid #EEEEF0;
    }

    .training_layout_sidebar_lesson:first-child {
        border-top: 1px solid #EEEEF0;
    }

    .training_layout_sidebar_lesson_divider {
        border-bottom: 1px solid #EEEEF0;
    }

    .training_layout_sidebar_lesson_title {
        font-weight: 900;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #A9AAAE;
        margin-bottom: 8px;
    }

    .training_layout_sidebar_lesson.type_ongoing .training_layout_sidebar_lesson_title {
        color: #27272F;
    }

    .training_layout_sidebar_lesson.type_locked .training_layout_sidebar_lesson_title {
        color: #A9AAAE;
    }

    .training_layout_sidebar_lesson_name {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.01em;
        color: #5B5B62;
        flex-grow: 1;
    }

    .training_layout_sidebar_lesson.type_ongoing .training_layout_sidebar_lesson_name {
        color: #055CF5;
    }

    .training_layout_sidebar_lesson.type_quiz .training_layout_sidebar_lesson_name {
        color: #27272F;
    }

    .training_layout_sidebar_lesson_icon {
        width: 32px;
        height: 32px;
        flex: none;
    }

    .training_layout_header_sidebar_wrapper {
        display: none !important;
    }

    #root.position-fixed {
        position: fixed;
        width: 100vw;
    }
}